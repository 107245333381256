import deepEqual from 'react-fast-compare'
import { useEffect, useState } from 'react';
import { useFetchProject, useFetchProjects } from 'src/API/projects';
import { Divisor } from 'src/components';
import { MODULE } from 'src/data/Modules';
import { Project } from 'src/data/Project';
import { Button } from 'src/lib/button';
import { Formik, Form } from 'src/lib/forms'
import { Text } from 'src/lib/text';
import { updateProject } from 'src/API/projects'
import { Loader } from 'src/lib/loader';
import { useHistory } from 'react-router';
import { DeleteProjectDialog } from './DeleteProjectDialog';
import { notify } from 'src/lib/modals';
import { CONFIG } from 'src/cp.config';

interface Props {
	onLoad: () => void
	project: Project
}

const ViewSettings = ({
	onLoad,
	project
}: Props) => {

	const history = useHistory()
	const { data: projects } = useFetchProjects()
	const { mutate } = useFetchProject(project.id)
	const [ projectUpdateError, setProjectUpdateError ] = useState<Error | null>(null)
	const [ isConfirmDeleteProjectOpen, setIsConfirmDeleteProjectOpen ] = useState(false)
	const { mutate: mutateProjects } = useFetchProjects()

	useEffect(onLoad, []) //eslint-disable-line

	function handleDeleteProjectSuccess() {
		projects && mutateProjects(projects.filter(p => p.id !== project.id))
		history.push('/projects')
	}

	return (
		<div className='view_modules'>

			<Formik
				initialValues={Project.toFormData(project)}
				validationSchema={project.formSchema(projects)}
				onSubmit={(values, { setSubmitting }) => {
					const submitProject = project.clone().updateWithFormData(values)

					if (deepEqual(Project.toApi(submitProject), Project.toApi(project))) {
						setTimeout(() => setSubmitting(false), 500)
						return
					}

					updateProject(submitProject)
						.then(() => {
							setSubmitting(false)
							setProjectUpdateError(null)
							mutate()
						})
						.catch((e) => {
							setProjectUpdateError(e)
							setSubmitting(false)
							notify('danger')('An error has occured, please try again later.')
						})
				}}>

				{({ isSubmitting }) => (

					<Form.Root>

						<div className="row pb-5">
							<div className="flex-col col-6 align-stretch">

								<Form.Labelled.Text
									name='title'
									autoComplete='off'
									label={(
										<Text>
											<strong>Project name <em>*</em></strong>
										</Text>
									)}
								/>
							</div>
						</div>

						<div className="row pb-3">
							<div className="flex-col col-6 align-stretch">
								<Form.Labelled.Text
									name='description'
									autoComplete='off'
									label={(
										<Text>
											<strong>Project description <em>*</em></strong>
										</Text>
									)}
								/>
							</div>
						</div>

						<Divisor className='mt-4 mb-6' />

						<Text>
							<strong>Project modules <em>*</em></strong>
						</Text>

						<Text className='pb-2' style={{ marginTop: '5px' }} opacity={0.85}>Choose the modules you want to use for this project. You can change it later on.</Text>

						<div className='relative'>
							<Form.Error name='modules' className='absolute bottom-full left-0 -mb-3.5' />
							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.ARK_SEARCH}
								className='mt-4'
								showError={false}
								disabled={!CONFIG.MODULES.AVAILABLE.ARK_SEARCH}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>ARK search</strong></Text>
										<Text opacity={0.85}>Engineering document search engine.</Text>
									</div>
								)}
							/>
							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.BOM_GENERATION}
								className='mt-4'
								disabled={!CONFIG.MODULES.AVAILABLE.BOM_GENERATION}
								showError={false}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>BOM generation</strong></Text>
										<Text opacity={0.85}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</Text>
									</div>
								)}
							/>

							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.PNID_ANALYSIS}
								className='mt-4'
								disabled={!CONFIG.MODULES.AVAILABLE.PNID_ANALYSIS}
								showError={false}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>P&ID analysis</strong></Text>
										<Text opacity={0.85}>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis.</Text>
									</div>
								)}
							/>

							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.SITE_REGISTER}
								className='mt-4'
								disabled={!CONFIG.MODULES.AVAILABLE.SITE_REGISTER}
								showError={false}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>Site register</strong></Text>
										<Text opacity={0.85}>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis.</Text>
									</div>
								)}
							/>

						</div>

						<Divisor className='my-6' />


						<div className='flex items-center mt-2'>
							<Button.Contained
								intent='primary'
								type={isSubmitting ? 'button' : 'submit'}
								style={{ opacity: isSubmitting ? 0.6 : 1 }} >
								Save Changes
							</Button.Contained>

							<Button.Contained
								className='ml-3'
								type='button'
								intent='danger'
								onClick={() => setIsConfirmDeleteProjectOpen(true)}>
								Delete Project
							</Button.Contained>

							{isSubmitting && <Loader.Dots className='ml-3' height={11} />}
							{projectUpdateError && <em className='ml-3'>{projectUpdateError.message}</em>}
						</div>


						<DeleteProjectDialog
							isOpen={isConfirmDeleteProjectOpen}
							close={() => setIsConfirmDeleteProjectOpen(false)}
							onDeleteSuccess={handleDeleteProjectSuccess}
							project={project}
						/>
					</Form.Root>
				)}
			</Formik>

		</div>
	)
}

export default ViewSettings;