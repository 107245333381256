
import { MODULE } from './Modules'


enum STATE {
    AVAILABLE = 0,
    BUSY = 1,
    IMPORTED = 2
}

export interface ApiImport {
    id: number;
    title: string;
    description: string;
    state: STATE;
    module_type: MODULE;
}

interface IImport {
    id: number;
    title: string;
    description: string;
    state: STATE;
    moduleType: MODULE;
}

export class Import implements IImport {
    id: number;
    title: string;
    description: string;
    state: STATE;
    moduleType: MODULE;

    constructor(data: IImport | Import) {
        this.id = data.id
        this.title = data.title
        this.description = data.description
        this.state = data.state
        this.moduleType = data.moduleType
    }

    static fromApi(apiImport: ApiImport): Import {
        const data: IImport = {
            id: apiImport.id,
            title: apiImport.title,
            description: apiImport.description,
            state: apiImport.state,
            moduleType: apiImport.module_type,
        }
        return new Import(data)
    }

    clone(): Import {
        return new Import(this)
    }

    get isAvailable() {
        return this.state === STATE.AVAILABLE
    }

    get isBusy() {
        return this.state === STATE.BUSY
    }

    setToBusy() {
        this.state = STATE.BUSY
        return this
    }

    get isImported() {
        return this.state === STATE.IMPORTED
    }

    setState(newState: 'imported' | 'available') {
        this.state = newState === 'imported'
            ? STATE.IMPORTED
            : STATE.AVAILABLE
        return this
    }
}