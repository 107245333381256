import './Result.css'
import { Result } from 'src/data/module.ark';
import { Text } from 'src/lib/text';
import parse from 'html-react-parser'
import { Button } from 'src/lib/button';
import { Icon } from 'src/lib/icons';
import { cn, isNullish } from 'src/helpers';
import { Grid } from 'src/lib/grid';
import { Link } from 'react-router-dom';
import { useProjectIdFromParams } from 'src/hooks/params';
import PnidLink from './PnidLink';
import { useViewFileLinkUrl } from 'src/API/_urls';


interface BaseProps {
	result: Result
	className?: string
	onClickPreview?: () => void
}

interface Props {
	result: Result
	className?: string
	onClickPreview?: (result: Result) => void
	isPreview: boolean
	resultNumber: number
}

const ResultView = ({
	result,
	onClickPreview = () => { },
	className = '',
	isPreview,
	resultNumber,
}: Props) => {

	const projectId = useProjectIdFromParams()

	const fileLinkUrl = useViewFileLinkUrl({
		fileId: result.fileId,
		numPages: result.numPages,
		pageNumber: result.pageNumber,
		sourcePath: result.sourcePath,
	})

	if (isNullish(projectId)) return null

	return (
		<div className={className}>
			<Grid.Root columns={3} growCols={[ 1 ]} className='mb-0.5 gap-2'>
				<Grid.Col>
					<Link to={fileLinkUrl} className='h-full flex items-center'>
						<Text className='text-base' variant='primary-darker' title={result.fileName.slice(1)} stronger truncate >
							{resultNumber}.  {result.fileName.slice(1)}
						</Text>
					</Link>
				</Grid.Col>

				<Grid.Col className='flex-center ml-1'>
					{result.isPnid && (
						<PnidLink
							className='px-2 py-px rounded-md text-primary-darker'
							projectId={projectId}
							result={result}
						/>
					)}
				</Grid.Col>

				<Grid.Col className='flex-center'>
					<Button.Disc
						className={cn`-mb-0.5 mr-1 ml-auto opacity-50 ${isPreview && 'hover'} transition-all hover:opacity-100 `}
						iconSize={16}
						onClick={() => onClickPreview(result)}>
						<Icon icon='eye-open' />
					</Button.Disc>
				</Grid.Col>
			</Grid.Root>
			<Path
				className='mb-1'
				result={result}
			/>
			<Snippet
				className='mb-1 ark_search_result-text_snippet'
				result={result}
			/>
			<Meta
				className='mb-1'
				result={result}
			/>
		</div>
	)
}

export default ResultView;


const Path = ({
	result,
	className = ''
}: BaseProps) => (

	<Text
		className={className}
		variant='info'
		smaller
		truncate
		opacity={0.93}
		title={result.pathWithoutFileName}>
		{result.pathWithoutFileName}{result.fileName}
	</Text>
)

const Snippet = ({
	result,
	className = ''
}: BaseProps) => (

	<Text className={className} style={{ lineHeight: 1.7 }} smaller>
		{result.textSnippets.map(str => (
			parse(str.trim() + '... ')
		))}
	</Text>
)

const Meta = ({
	result,
	className = ''
}: BaseProps) => (

	<Text
		className={className}
		variant='info'
		opacity={0.93}
		smaller>
		Page {result.pageNumber}/{result.numPages}     Size {result.fileSize}
	</Text>
)