import React from 'react';
import { Button } from 'src/lib/button';
import { getPageMap } from './utils';
import { cn } from 'src/helpers';
import { Icon } from 'src/lib/icons';

interface Props {
	totalHits: number
	results: any[]
	maxPageSize: number
	currentPageIndex: number
	onClickPrev: () => void
	onClickNext: () => void
	onClickPage: (pageIndex: number) => void
}

const Pager = ({
	totalHits,
	results,
	maxPageSize,
	currentPageIndex,
	onClickPrev,
	onClickNext,
	onClickPage
}: Props) => {

	const pageMap = getPageMap({ currentPageIndex, maxPageSize, totalHits })

	function getIsNextPageDisabled() {
		const resultsCountForCurrentPage = results.length
		const highestDisplayedPageNumber = maxPageSize * currentPageIndex + resultsCountForCurrentPage
		return totalHits <= highestDisplayedPageNumber
	}

	if (pageMap.length <= 1) return null

	return (
		<div className="flex items-center justify-center gap-2 my-8">
			<Button.Disc
				onClick={onClickPrev}
				className='transform rotate-180'
				disabled={!currentPageIndex}>
				<Icon icon='chevron-right' />
			</Button.Disc>
			{pageMap.map(pageNumber => (
				<Button.Inline
					className={cn`
						py-1
						px-2
						${currentPageIndex === pageNumber - 1 && 'active'}
						active:text-primary
						active:font-bolder
						active:no-underline
					`}
					onClick={() => onClickPage(pageNumber)}
					key={`ark-pager-${pageNumber}`}>
					{pageNumber}
				</Button.Inline>
			))}
			<Button.Disc
				onClick={onClickNext}
				disabled={getIsNextPageDisabled()}>
				<Icon icon='chevron-right' />
			</Button.Disc>
		</div>
	)
}

export default Pager;