export interface ApiFileRecord {
	createDate: string
	fileRecord_id: number
	file_id: number
	modifyDate: string
	sourcePath: string
}

interface IFileRecord {
	createdOn: string
	updatedOn: string
	fileId: number
	fileRecordId: number
	sourcePath: string
}

export class FileRecord implements IFileRecord {
	createdOn: string
	updatedOn: string
	fileId: number
	fileRecordId: number
	sourcePath: string

	constructor(data: IFileRecord | FileRecord) {
		this.createdOn = data.createdOn
		this.updatedOn = data.updatedOn
		this.fileId = data.fileId
		this.fileRecordId = data.fileRecordId
		this.sourcePath = data.sourcePath
	}

	static fromApi(apiData: ApiFileRecord): FileRecord {
		const data: IFileRecord = {
			createdOn: apiData.createDate,
			fileRecordId: apiData.fileRecord_id,
			fileId: apiData.file_id,
			updatedOn: apiData.modifyDate,
			sourcePath: apiData.sourcePath
		}
		return new FileRecord(data)
	}
}