import { Intent } from 'src/types';
import { useEffect } from 'react';
import { useFetchUnreadNotificationsWithInterval } from 'src/API/notifications';
import { NotificationType } from 'src/data/Notification'
import { notify } from 'src/lib/modals';

const getIntent = (type: NotificationType): Intent => {
	const dictionary: Record<NotificationType, Intent> = {
		info: 'primary',
		warning: 'warning',
		error: 'danger',
		success: 'primary'
	}
	return dictionary[ type ]
}

interface Props {
	interval: number
}

const Notifications = ({
	interval
}: Props) => {

	const { data: notifications } = useFetchUnreadNotificationsWithInterval(interval)

	useEffect(() => {
		if (!notifications) return

		notifications
			.filter(n => {
				return !n.isFileSyncStarted
			})
			.forEach(n => {
				if (!n.text) return
				notify(getIntent(n.type))(n.text)
			})
	}, [ notifications ])

	return null

}

export default Notifications;