import { Hotspot } from 'src/data/module.ark'
import { Grid } from 'src/lib/grid'


interface Props {
	hotspot: Hotspot
}

export const Tooltip = ({
	hotspot
}: Props) => {



	return (
		<Grid.Root columns={2} growCols={[ 2 ]} className='gap-x-6 gap-y-2 max-w-md p-2'>
			<Grid.Col><strong>Contractor</strong></Grid.Col>
			<Grid.Col>{hotspot.pnid?.contractorName}</Grid.Col>

			<Grid.Col><strong>Title</strong></Grid.Col>
			<Grid.Col>{hotspot.pnid?.drawingTitle}</Grid.Col>

			<Grid.Col><strong>Doc. ID</strong></Grid.Col>
			<Grid.Col>{hotspot.pnid?.contractorDocumentId}</Grid.Col>

			<Grid.Col><strong>Sheet</strong></Grid.Col>
			<Grid.Col>{hotspot.pnid?.sheet}</Grid.Col>

			<Grid.Col><strong>Facility</strong></Grid.Col>
			<Grid.Col>{hotspot.pnid?.facilityName}</Grid.Col>

			<Grid.Col><strong>Revision</strong></Grid.Col>
			<Grid.Col>{hotspot.pnid?.revision}</Grid.Col>
		</Grid.Root>
	)
}