import { useRef } from 'react';
import { useGlobalKeyDown, useGlobalKeyUp, useGlobalBlur } from 'src/hooks/listeners'
import { useKeyBinding } from 'src/hooks/key-binding'


export const useOnToggleKeyBinding = (keys: string | string[], callback: (isActive: boolean) => void) => {

	const keyBinding = useKeyBinding()
	const _keys = typeof keys === 'string' ? [ keys ] : keys
	const isActiveRef = useRef(false)

	useGlobalKeyDown(() => {

		if (keyBinding.matches(..._keys)) {

			if (!isActiveRef.current) {
				callback(true)
				isActiveRef.current = true
			}
		}
	})

	useGlobalKeyUp(() => {

		if (isActiveRef.current) {
			callback(false)
			isActiveRef.current = false
		}
	})

	useGlobalBlur(() => {

		if (isActiveRef.current) {
			callback(false)
			isActiveRef.current = false
		}
	})

	return isActiveRef
}
