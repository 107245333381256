import axios, { AxiosError } from 'axios'
import { PasswordConfig } from 'src/data/Password'
import { Token } from 'src/data/Token'
import { ApiUser, User } from 'src/data/User'
import useSWR, { SWRConfiguration, } from 'swr'
import { useSwrCache } from './utils'




export const fetchCurrentUser = (url: string): Promise<User> => (
	axios
		.get(url)
		.then(res => res.data as ApiUser)
		// .then(x => (console.log(x), x))
		.then(apiUser => User.fromApi(apiUser))
)

export const useFetchCurrentUser = (condition: boolean, config: SWRConfiguration<User, AxiosError> = {}) => {
	const token = Token.getStore()
	return useSWR(condition && token ? '/cpf/v1/profile/' : null, fetchCurrentUser, config)
}

export const useCurrentUserCache = (config: SWRConfiguration<User, AxiosError> = {}) => (
	useSwrCache('/cpf/v1/profile/', fetchCurrentUser, config)
)

export function updatePwd(config: PasswordConfig) {
	return axios
		.post('/cpf/v1/user/password/', PasswordConfig.toApi(config))
		.then(res => res.data)
	// .then(x => (console.log(x), x))
}
