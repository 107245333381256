import { time } from 'src/helpers'

export enum STATUS {
	INACTIVE = 0,
	STARTED = 1,
	COMPLETED = 2,
	CANCEL = 3
}

export enum SYSTEM {
	UNDEFINED = 0,
	FILE_SYNC = 1,
	BOM_TAG_SPIR = 2,
	BOM_FIND_TABLE = 3,
	BOM_PROCESS_DOCUMENT = 4
}

const descriptionDictionary: Record<SYSTEM, string> = {
	[ SYSTEM.UNDEFINED ]: 'Undefined',
	[ SYSTEM.FILE_SYNC ]: 'File Sync',
	[ SYSTEM.BOM_TAG_SPIR ]: 'BOM: Tag SPIR',
	[ SYSTEM.BOM_FIND_TABLE ]: 'BOM: Find Tables',
	[ SYSTEM.BOM_PROCESS_DOCUMENT ]: 'BOM: Process Documents',
}

export interface ApiTask {
	id: number;
	system: SYSTEM;
	status: STATUS;
	date_created: string;
	date_completed: string;
	completed: number;
	total: number;
	username: string;
}

interface ITask {
	id: number
	system: SYSTEM
	status: STATUS
	createdOn: string
	isCompleted: number
	createdBy: string
	progress: {
		current: number,
		total: number
	}
}

export class Task implements ITask {
	readonly id: number
	readonly system: SYSTEM
	readonly status: STATUS
	readonly createdOn: string
	readonly isCompleted: number
	readonly createdBy: string
	readonly progress: {
		current: number,
		total: number
	}

	constructor(task: Task | ITask) {
		this.id = task.id
		this.system = task.system
		this.status = task.status
		this.createdOn = task.createdOn
		this.isCompleted = task.isCompleted
		this.createdBy = task.createdBy
		this.progress = task.progress
	}

	static fromApi(apiData: ApiTask): Task {
		const data: ITask = {
			id: apiData.id,
			system: apiData.system,
			status: apiData.status,
			createdOn: time.ago(apiData.date_created),
			isCompleted: apiData.completed,
			createdBy: apiData.username,
			progress: {
				current: apiData.completed,
				total: apiData.total
			}
		}

		return new Task(data)
	}

	get isFileSyncStarted() {
		return this.system === SYSTEM.FILE_SYNC && this.status === STATUS.STARTED
	}

	get isBomProcessDocsStarted() {
		return this.system === SYSTEM.BOM_PROCESS_DOCUMENT && this.status === STATUS.STARTED
	}

	get description(): string {
		return descriptionDictionary[ this.system ]
	}

	get isCancelled() {
		return this.status === STATUS.CANCEL
	}
}