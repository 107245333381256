import { useUserBookmark } from 'src/API/bookmark';
import { useFetchPageContent } from 'src/API/cms';
import { useFetchAllNotifications } from 'src/API/notifications';

const SwrPrefetcher = () => {

	useUserBookmark('leftNav', false)
	useUserBookmark('showHotspots', false)
	useFetchAllNotifications()
	useFetchPageContent()

	return null
}

export default SwrPrefetcher;