export const tags = [
  { pages: [2], tag: "Food" },
  { pages: [4], tag: "Travel" },
  { pages: [3], tag: "Fitness" },
  { pages: [3], tag: "Gardening" },
  { pages: [2], tag: "Technology" },
  { pages: [1], tag: "Fashion" },
  { pages: [4], tag: "Photography" },
  { pages: [1], tag: "Music" },
  { pages: [3], tag: "Cooking" },
  { pages: [2], tag: "Art" },
  { pages: [1], tag: "Books" },
  { pages: [3], tag: "History" },
  { pages: [4], tag: "Architecture" },
  { pages: [1], tag: "Sports" },
  { pages: [2], tag: "Movies" },
  { pages: [3], tag: "Cooking Tips" },
  { pages: [3], tag: "Pets" },
  { pages: [4], tag: "Nature" },
  { pages: [1], tag: "DIY" },
  { pages: [3], tag: "Beauty" },
];


export const tagSuggestions = [
  "Computer",
  "Construction",
  "Life",
  "Live",
  "Architecture",
  "Tricks",
  "Self"
]
