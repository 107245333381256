import React from 'react';
import { iconMap } from './iconMap';


type IconName = keyof typeof iconMap

interface Props extends React.ComponentProps<'svg'> {
	icon: IconName
	iconSize?: number
}

const Icon = ({
	icon,
	iconSize,
	...props
}: Props) => {

	const Tag = iconMap[ icon ]

	return iconSize
		? <Tag width={iconSize} height={iconSize} {...props} />
		: <Tag {...props} />
}

export default Icon;