import { useEffect, useRef } from 'react';
import { Hotspot } from 'src/data/module.ark';

export function getStyles(bounds: Hotspot[ 'bounds' ]) {
	return {
		top: `calc(${bounds.minY * 100 + '%'} - 1px)`,
		left: `calc(${bounds.minX * 100 + '%'} - 1px)`,
		right: `calc(${100 - bounds.maxX * 100 + '%'} - 1px)`,
		bottom: `calc(${100 - bounds.maxY * 100 + '%'} - 1px)`,
	}
}

export function useStopNativeMouseDownPropaggation() {

	const elRef = useRef<HTMLAnchorElement | null>(null)

	useEffect(() => {
		const el = elRef.current
		if (!el) return

		const listener = (e: MouseEvent) => {
			e.stopPropagation()
		}

		el.addEventListener('mousedown', listener)
		return () => el.removeEventListener('mousedown', listener)
	}, [])

	return elRef
}

export const getRotationClassName = (rotation: number) => {

	switch (rotation) {
		case 90:
			return `transform rotate-90 scale-75`
		case 180:
			return 'transform rotate-180'
		case 270:
			return `transform -rotate-90 scale-75`
		default:
			return ''
	}
}