import { useState } from 'react';
import { Project } from 'src/data/Project';
import { ContentWithCustomScrollbars } from 'src/lib/layout';
import { Heading, Text } from 'src/lib/text';
import { Divisor } from 'src/components'
import { Button } from 'src/lib/button';
import { postNewProject, useFetchProjects } from 'src/API/projects'
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'src/lib/forms'
import { Modules } from 'src/data';
import { MODULE } from 'src/data/Modules';
import { notify } from 'src/lib/modals';
import { CONFIG } from 'src/cp.config';


const emptyProject = new Project()

const Page = () => {

	const history = useHistory()
	const { data: projects } = useFetchProjects()
	const [ projectPostError, setProjectPostError ] = useState<Error | null>(null)


	return (
		<ContentWithCustomScrollbars>
			<Formik
				initialValues={Project.toFormData(emptyProject)}
				validationSchema={emptyProject.formSchema(projects)}
				onSubmit={(values, { setSubmitting }) => {
					const project = Project.fromFormData(values)

					postNewProject(project)
						.then(() => history.push('/projects'))
						.catch((e) => {
							setProjectPostError(e)
							setSubmitting(false)
							notify('danger')('An error has occured, please try again later.')
						})
				}}>

				{({ isSubmitting }) => (

					<Form.Root>

						<Heading tag='h2'>Create a new project</Heading>

						<Text className='mt-2'>A project contains one or more module. The modules share the same project files</Text>

						<Divisor className='my-6' />

						<div className="row pb-5">
							<div className="flex-col col-6 align-stretch">

								<Form.Labelled.Text
									name='title'
									autoComplete='off'
									label={(
										<Text>
											<strong>Project name <em>*</em></strong>
										</Text>
									)}
								/>
							</div>
						</div>

						<div className="row pb-3">
							<div className="flex-col col-6 align-stretch">
								<Form.Labelled.Text
									name='description'
									autoComplete='off'
									label={(
										<Text>
											<strong>Project description <em>*</em></strong>
										</Text>
									)}
								/>
							</div>
						</div>

						<Divisor className='mt-4 mb-6' />

						<Text>
							<strong>Project modules <em>*</em></strong>
						</Text>

						<Text className='pb-2' style={{ marginTop: '5px' }} opacity={0.85}>Choose the modules you want to use for this project. You can change it later on.</Text>

						<div className='relative'>
							<Form.Error name='modules' className='absolute bottom-full left-0 -mb-3.5' />
							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.ARK_SEARCH}
								className='mt-4'
								showError={false}
								disabled={!CONFIG.MODULES.AVAILABLE.ARK_SEARCH}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>ARK search</strong></Text>
										<Text opacity={0.85}>Engineering document search engine.</Text>
									</div>
								)}
							/>
							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.BOM_GENERATION}
								className='mt-4'
								disabled={!CONFIG.MODULES.AVAILABLE.BOM_GENERATION}
								showError={false}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>BOM generation</strong></Text>
										<Text opacity={0.85}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</Text>
									</div>
								)}
							/>

							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.PNID_ANALYSIS}
								className='mt-4'
								disabled={!CONFIG.MODULES.AVAILABLE.PNID_ANALYSIS}
								showError={false}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>P&ID analysis</strong></Text>
										<Text opacity={0.85}>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis.</Text>
									</div>
								)}
							/>

							<Form.Labelled.Checkbox
								name='modules'
								value={MODULE.SITE_REGISTER}
								className='mt-4'
								disabled={!CONFIG.MODULES.AVAILABLE.SITE_REGISTER}
								showError={false}
								label={(
									<div className='pl-2' style={{ userSelect: 'none' }}>
										<Text><strong>Site register</strong></Text>
										<Text opacity={0.85}>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis.</Text>
									</div>
								)}
							/>

						</div>

						<Divisor className='my-6' />

						<div className="flex items-center mt-2">

							<Button.Contained intent='primary' type='submit' disabled={isSubmitting}>
								Create Project
							</Button.Contained>

							{projectPostError && <em className='ml-3'>{projectPostError.message}</em>}
						</div>

					</Form.Root>
				)}
			</Formik>
		</ContentWithCustomScrollbars>
	)
}

export default Page;