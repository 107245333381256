import React from 'react';
import { useDownloadPdfFileUrl } from 'src/API/_urls';
import { Children } from 'src/types';
import JsFileDownloader from 'js-file-downloader';
import { Token } from 'src/data/Token';
import { Tooltip } from 'src/lib/modals';


interface Props extends Omit<React.ComponentProps<'div'>, 'onClick'> {
	fileName: string
	fileId: string | number
	children: Children
}

export const DownloadPdfFile = ({
	fileName,
	fileId,
	children,
	...props
}: Props) => {

	const url = useDownloadPdfFileUrl(fileId)

	function handleClick() {
		url && new JsFileDownloader({
			url, filename: fileName, headers: [ {
				name: 'Authorization',
				value: `Token ${Token.getStore()?.access}`
			} ]
		})
	}


	return (
		<Tooltip text={`Download:  ${fileName}`} delay={400} padding={7} renderDivContainer={false}>
			<div
				onClick={handleClick}
				{...props}>
				{children}
			</div>
		</Tooltip>
	)
}
