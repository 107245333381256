import React from 'react';
import { cn } from 'src/helpers';
import { getTemplateCols } from './utils';

interface Props extends React.ComponentProps<'div'> {
	columns: number,
	growCols?: number[]
	className?: string
}

const Root = ({
	columns,
	growCols = [],
	className = '',
	style = {},
	...props
}: Props) => {

	style = {
		gridTemplateColumns: getTemplateCols(columns, growCols),
		...style
	}

	return (
		<div
			className={cn`
				grid
				auto-rows-auto
				${className}
			`}
			style={style}
			{...props}
		/>
	)
}

export default Root;