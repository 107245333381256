import tinyColor from 'tinycolor2'

export function getDarkHexFromHex(hex: string) {
	let loops = 0

	while (!tinyColor(hex).isDark()) {
		hex = tinyColor(hex).darken(1).saturate(100).toHexString()

		if (loops > 10) {
			hex = '#000000';
			break
		}
	}

	return hex
}
