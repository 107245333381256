import { useEffect, useState } from 'react'
import { useUserBookmark } from 'src/API/bookmark'
import { useUpdateEffect } from 'src/hooks/effects'
import { useIsKeyBindingActive } from 'src/hooks/key-binding'
import { Tooltip } from 'src/lib/modals'
import { Children } from 'src/types'

interface ShowAllProps {
	onToggle: (showAll: boolean) => void
	className?: string
	hotkey: string
	label: Children
}

export const ShowAll = ({
	onToggle,
	className = '',
	hotkey,
	label,
}: ShowAllProps) => {

	const isControlDown = useIsKeyBindingActive(hotkey)
	const [ showAll, setShowAll ] = useUserBookmark('showHotspots', false)

	useUpdateEffect(() => {
		setShowAll(isControlDown)
	}, [ isControlDown ])

	useEffect(() => {
		onToggle(!!showAll)
	}, [ showAll ]) //eslint-disable-line

	return (
		<Tooltip text={`${hotkey} key`} delay={400} padding={13}>
			<label className={`flex items-center select-none gap-2 ${className}`}>
				<input
					className='custom_input-checkbox'
					type='checkbox'
					name='show-all'
					checked={showAll}
					onChange={() => setShowAll(!showAll)}
				/>
				{label}
			</label>
		</Tooltip>
	)
}