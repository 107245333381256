import useSWR, { SWRConfiguration } from 'swr'
import { Member, ROLE } from 'src/data/DEPRECATED-Member'

export const fetchMembers = (url: string, projectId: string | number): Promise<Member[] | null> => (
	new Promise(resolve => {
		setTimeout(() => {
			resolve([
				new Member({ id: 'K0592997', name: 'Laurent Blondy', role: ROLE.ADMIN }),
				new Member({ id: 'K0592999', name: 'Terence Tan', role: ROLE.ADMIN }),
				new Member({ id: 'B0268745', name: 'Renaud Montaron', role: ROLE.SUPERVISOR }),
				new Member({ id: 'B0245945', name: 'François Montaron', role: ROLE.SUPERVISOR }),
				new Member({ id: 'K0845266', name: 'Amanda Sivaraj', role: ROLE.REVIEWER }),
				new Member({ id: 'B0058764', name: 'Linda Begnini', role: ROLE.REVIEWER }),
			])
		}, 1000)
	})
)

export const useFetchMembers = (projectId: string | number, config?: SWRConfiguration<Member[] | null, Error>) => (
	useSWR([ '/cpf/v1/members/', projectId + '' ], fetchMembers, config)
)
