import { useCallback, useState } from 'react'

export const useZoomController = (initialZoom: number) => {

	const [ controlZoomValue, setControlsZoomValue ] = useState(initialZoom)
	const [ mouseZoomValue, setMouseZoomValue ] = useState<[ number ]>([ initialZoom ])

	const resetZoom = useCallback(() => {
		setControlsZoomValue(initialZoom)
		setMouseZoomValue([ initialZoom ])
	}, [ initialZoom ])

	return {
		zoomAndSpan: {
			defaultZoom: mouseZoomValue,
			onZoom: (val: number) => setControlsZoomValue(Math.round(val * 100) / 100)
		},
		zoomController: {
			onZoom: (v: number) => setMouseZoomValue([ v ]),
			zoom: controlZoomValue
		},
		resetZoom
	}
}