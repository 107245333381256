import { ApiTag, ITags, Tags } from './../data/tag';
import useSWR, { SWRConfiguration } from 'swr'
import axios from 'axios'


export const fetchFileTags = (url: string) => (
    axios
        .get(url)
        .then(res => (Array.isArray(res.data) ? res.data : []) as ApiTag[])
        .then(data => Tags.fromApi(data))
)
export const useFetchProjectTags = (data: { project_id: string, file_id: string }, config?: SWRConfiguration<ITags, Error>) => (
    useSWR(`/cpf/v1/tag/?project_id=${data.project_id}&file_id=${data.file_id}`, fetchFileTags, config)
)

interface IPost { project_id: number, page: number,file_id: number | undefined, tag: string,  action:string, color?: string, oldtag?: string}
export const postFileTag = (payload: IPost ) => {
    const color = payload.color ? payload.color : colors[randomIndex];
    const data = {...payload, color}
    return (
      axios
        .post(url(data))
        .then(res => ({...res.data, data}))
    )
}

const colors = ['e751ea', 'e751ea','e751ea','eeb521', '6fea51']

const randomIndex = Math.floor(Math.random() * colors.length);

const url = (data:IPost) => {

    const params = new URLSearchParams();
    params.append('project_id', `${data.project_id}` );
    params.append('file_id', `${data.file_id}` );
    params.append('page', `${data.page}` );
    params.append('tag', `${data.tag}` );
    params.append('oldtag', data.oldtag || "" );
    params.append('color', `${data.color}` );
    params.append('action', `${data.action}` );

    return `/cpf/v1/tag/?${params.toString()}`
}
