import deepEqual from 'react-fast-compare'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { array } from 'src/helpers'
import { useUpdatedRef } from './refs'
import { useOnToggleKeyBinding } from './key-binding'

export enum UPDATE_REASON {
	UNDO = 'undo',
	REDO = 'redo',
	UNKNOWN = 'unknown',
}


interface Options<T extends unknown> {
	ignore?: (entry: T | undefined) => boolean,
	maxUndoCount?: number
	shortcuts?: boolean
}

export const useUndoRedo = <T extends unknown>(
	state: T,
	setState: ((newVal: T) => void) | ((newVal: T, revalidate: boolean) => void),
	options: Options<T> = {},
) => {
	const shortcuts = useMemo(() => options.shortcuts ?? true, [ options.shortcuts ])
	const maxUndoCount = useMemo(() => options.maxUndoCount ?? 10, [ options.maxUndoCount ])

	const ignoreRef = useUpdatedRef(options.ignore ?? (() => false))
	const updateReasonRef = useRef<UPDATE_REASON>(UPDATE_REASON.UNKNOWN)
	const historyRef = useRef<T[]>([])
	const redoListRef = useRef<T[]>([])


	useEffect(() => {
		if (updateReasonRef.current === UPDATE_REASON.UNKNOWN && !ignoreRef.current(state)) {
			if (!deepEqual(state, array.last(historyRef.current))) {
				if (Array.isArray(state)) {
					historyRef.current.push([ ...state ] as T)
				}
				else if ((state as any).clone && typeof (state as any).clone === 'function') {
					historyRef.current.push((state as any).clone())
				}
				else {
					historyRef.current.push(state)
				}
			}
			if (historyRef.current.length > (maxUndoCount) + 1)
				historyRef.current = historyRef.current.slice(1)
			redoListRef.current = []
		}
		updateReasonRef.current = UPDATE_REASON.UNKNOWN
	}, [ state, options.maxUndoCount, ignoreRef, maxUndoCount ])


	const undo = useCallback(() => {
		if (historyRef.current.length <= 0) return
		const undoneState = historyRef.current.pop() as T
		redoListRef.current.push(undoneState)
		const _state = array.last(historyRef.current)
		updateReasonRef.current = UPDATE_REASON.UNDO
		!!_state && setState(_state, false)
	}, [ setState ])

	const redo = useCallback(() => {
		if (!redoListRef.current.length) return
		const redoneState = redoListRef.current.pop() as T
		historyRef.current.push(redoneState)
		const _state = redoneState
		updateReasonRef.current = UPDATE_REASON.REDO
		!!_state && setState(_state, false)
	}, [ setState ])

	const clearHistory = useCallback(() => {
		updateReasonRef.current = UPDATE_REASON.UNKNOWN
		historyRef.current = []
		redoListRef.current = []
	}, [])

	const getUndoSnapshot = useCallback(() => historyRef.current.slice(-2, -1)[ 0 ] as T | undefined, [])


	useOnToggleKeyBinding([ 'Control', 'z' ], isActive => {
		isActive && shortcuts && getUndoSnapshot() && undo()
	})

	useOnToggleKeyBinding([ 'Control', 'y' ], isActive => {
		isActive && shortcuts && redo()
	})

	return {
		undo,
		redo,
		getUndoSnapshot,
		historyRef,
		redoListRef,
		clearHistory,
	}
}