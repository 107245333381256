import { useParams } from 'react-router';
import { useFetchHotspots } from 'src/API/hotspots';
import { LeftNav } from 'src/components/sidebar';
import { MainLayout } from 'src/lib/layout';
import View from './View'

interface Params {
	contractorDocumentId: string
	fileRecordId: string
	page: string
	projectId: string
	sheet: string
	sourcePath: string
}


const Setup = () => {

	const { contractorDocumentId, page, projectId, sheet, sourcePath } = useParams<Params>()

	const { data: hotspots = [] } = useFetchHotspots(projectId, contractorDocumentId, sheet)

	return (
		<MainLayout
			left={<LeftNav />}
			content={(
				<View
					contractorDocumentId={contractorDocumentId}
					sourcePath={decodeURIComponent(sourcePath)}
					fileName={decodeURIComponent(sourcePath).split('/').pop() || ''}
					page={parseInt(page, 10)}
					projectId={parseInt(projectId, 10)}
					sheet={parseInt(sheet, 10)}
					hotspots={hotspots}
				/>)
			}
		/>
	)
}

export default Setup;