// import './Left.css'
import css from './Sidebar.module.css'
import { useEffect } from 'react';
import { Children } from 'src/types';
import { Button } from 'src/lib/button';
import { useUserBookmark } from 'src/API/bookmark'
import { Icon } from 'src/lib/icons';


const noop = () => { }

interface Props {
	className?: string
	children?: Children
	onCollapse?: () => void
	onExpand?: () => void
}

const Left = ({
	className = '',
	onCollapse = noop,
	onExpand = noop,
	children
}: Props) => {

	const [ isCollapsed, setIsCollapsed ] = useUserBookmark('leftNav', false)

	useEffect(() => {
		isCollapsed
			? onCollapse()
			: onExpand()
	}, [ isCollapsed ]) //eslint-disable-line

	return (
		<div className={`${css.left} ${isCollapsed && `${css.collapse} collapse`} ${className}`}>
			<div>
				{children}

				<Button.Disc
					className={css.toggle_button}
					iconSize={24}
					onClick={() => setIsCollapsed(!isCollapsed)}>
					<Icon icon='chevron-left-circle' />
				</Button.Disc>
			</div>
		</div>
	)
}

export default Left;

// width: vars.$sidebar-width;
// 		position: relative;
// 		height: 100%;
// 		overflow: hidden;
// 		transition: width $transition-delay;