import axios from 'axios'
import { ApiToken, Credentials, Token } from 'src/data/Token'
import { CognitoRedirectData } from 'src/pages/login/WithCognito'


interface LoginResponseCZI extends ApiToken {
	two_factor_enabled: boolean
	user: {
		pk: number
		username: string
		email: string
		first_name: string
		last_name: string
	}
}

export const fetchToken = {
	cenozai({ emailOrUsername, password }: Credentials) {
		const isEmail = emailOrUsername.indexOf('@') > -1 && emailOrUsername.indexOf('.') > -1
		const username = isEmail ? '' : emailOrUsername
		const email = isEmail ? emailOrUsername : ''
		return () => axios
			.post('/cpf/v1/auth/login/', { username, email, password })
			.then(res => res.data as LoginResponseCZI)
			// .then(x => (console.log(x), x))
			.then(apiRes => Token.fromApi(apiRes))
	},
	cognito(redirectData: CognitoRedirectData) {
		return () => axios
			.post(`/cpf/v1/auth/cognito/login/`, redirectData)
			.then(res => res.data as LoginResponseCZI)
			// .then(x => (console.log(x), x))
			.then(apiRes => Token.fromApi(apiRes))
	},
}

export const refreshAccessToken = () => {
	const refreshToken = Token.getStore()?.refresh
	if (!refreshToken)
		return Promise.reject(new Error('No refresh token'))

	return axios
		.post('/token/refresh/', { refresh: refreshToken })
		.then(res => res.data as ApiToken)
		.then(apiToken => Token.fromApi(apiToken))
}

export const postBlacklistRefreshToken = (refresh: string) => {
	return axios
		.post('/cpf/v1/auth/logout/', { refresh })
		.catch(() => { })
}
