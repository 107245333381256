import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useFetchFileTreeNode } from 'src/API/files';
import { useUnreadNotifications } from 'src/API/notifications';
import { useActiveProjectCache } from 'src/API/projects';
import { useTasksCache } from 'src/API/tasks';
import { MODULE } from 'src/data/Modules';

interface Params {
	projectId?: string
	nodeId?: string
}

const NotificationGlobalAsyncEffects = () => {

	const params = useParams<Params>()

	const notifications = useUnreadNotifications()
	const { data: project, revalidate: revalidateProject } = useActiveProjectCache()
	const { revalidate: revalidateFiles } = useFetchFileTreeNode(project?.id, params.nodeId || 0, MODULE.NONE, { revalidateOnMount: false })
	const { revalidate: revalidateTasks } = useTasksCache()

	useEffect(() => {
		if (notifications.length) revalidateProject()

		notifications.forEach(n => {
			if (n.isFileSyncComplete) {
				return revalidateFiles()
			}
			if (n.isBomProcessDocsStarted) {
				return revalidateTasks()
			}
			if (n.isBomProcessDocsComplete) {
				return revalidateTasks()
			}
		})
	}, [ notifications ]) //eslint-disable-line

	return null
}

export default NotificationGlobalAsyncEffects;