import styles from './ExcelContextMenu.module.css'
import { Divisor } from 'src/components'
import { Icon } from '../icons'
import { Menu } from '../menu'
import { Alignment, Format } from './types'
import { selection } from './utils'

interface Selection {
	rows: ReturnType<typeof selection[ 'getRows' ]>
	cols: ReturnType<typeof selection[ 'getCols' ]>
	list: ReturnType<typeof selection[ 'getList' ]>
	object: ReturnType<typeof selection[ 'getObject' ]>
	tuple: ReturnType<typeof selection[ 'getTuple' ]>
	translate: typeof selection[ 'translate' ]
}

interface Props {
	mergeSelectedCells: () => void
	unmergeSelectedCells: () => void
	alignCells: (alignment: Alignment) => () => void
	formatCells: (format: Format) => () => void
	removeRows: () => void
	removeCols: () => void
	addRowAbove: () => void
	addRowBelow: () => void
	addColLeft: () => void
	addColRight: () => void
	undo: () => void
	redo: () => void
	selection: Selection
	close: () => void
	copy: () => void
	paste: () => void
	cut: () => void
}

export const ExcelContextMenu = ({
	mergeSelectedCells,
	unmergeSelectedCells,
	alignCells,
	formatCells,
	removeRows,
	removeCols,
	addRowAbove,
	addRowBelow,
	addColLeft,
	addColRight,
	undo,
	redo,
	selection,
	close,
	copy,
	paste,
	cut,
}: Props) => {

	const handleClick = (cb: () => void) => () => {
		close()
		cb()
	}

	return (
		<Menu.Root>
			<Menu.Button className='gap-3' onClick={handleClick(copy)}>
				<Icon icon='copy' className='opacity-80' iconSize={18} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Copy</div>
					<div className={styles.shortcut}>Ctrl+C</div>
				</div>
			</Menu.Button>
			<Menu.Button className='gap-3' onClick={handleClick(cut)}>
				<Icon icon='cut' className='opacity-80' iconSize={18} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Cut</div>
					<div className={styles.shortcut}>Ctrl+X</div>
				</div>
			</Menu.Button>
			<Menu.Button className='gap-3' onClick={handleClick(paste)}>
				<Icon icon='paste' className='opacity-80' iconSize={18} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Paste</div>
					<div className={styles.shortcut}>Ctrl+V</div>
				</div>
			</Menu.Button>
			<Divisor className='my-0.5' />
			<Menu.Button className='gap-3' onClick={handleClick(undo)}>
				<Icon icon='undo' className='opacity-80' iconSize={16} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Undo</div>
					<div className={styles.shortcut}>Ctrl+Z</div>
				</div>
			</Menu.Button>
			<Menu.Button className='gap-3' onClick={handleClick(redo)}>
				<Icon icon='redo' className='opacity-80' iconSize={16} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Redo</div>
					<div className={styles.shortcut}>Ctrl+Y</div>
				</div>
			</Menu.Button>
			<Divisor className='my-0.5' />
			<Menu.Button className='gap-3' onClick={handleClick(addRowAbove)}>
				<Icon icon='none' className='opacity-80' iconSize={18} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Insert row</div>
					<div className={styles.shortcut}></div>
				</div>
			</Menu.Button>
			<Menu.Button className='gap-3' onClick={handleClick(addColLeft)}>
				<Icon icon='none' className='opacity-80' iconSize={18} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Insert column</div>
					<div className={styles.shortcut}></div>
				</div>
			</Menu.Button>
			<Divisor className='my-0.5' />
			<Menu.Button className='gap-3' onClick={handleClick(removeRows)}>
				<Icon icon='none' className='opacity-80' iconSize={18} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Delete rows</div>
					<div className={styles.shortcut}></div>
				</div>
			</Menu.Button>
			<Menu.Button className='gap-3' onClick={handleClick(removeCols)}>
				<Icon icon='none' className='opacity-80' iconSize={18} style={{ width: '18px' }} />
				<div className={styles.content}>
					<div>Delete columns</div>
					<div className={styles.shortcut}></div>
				</div>
			</Menu.Button>
			{/* <Divisor className='my-0.5' />
			<Menu.Button className='gap-3' onClick={handleClick(alignCells('htLeft'))}>
				<Icon icon='align-left' className='opacity-80' iconSize={16} style={{ width: '18px' }} />
					<div>Align left</div>
					<div>Ctrl+C</div>
			</Menu.Button>
			<Menu.Button className='gap-3' onClick={handleClick(alignCells('htCenter'))}>
				<Icon icon='align-center' className='opacity-80' iconSize={16} style={{ width: '18px' }} />
					<div>Align center</div>
					<div>Ctrl+C</div>
			</Menu.Button>
			<Menu.Button className='gap-3' onClick={handleClick(alignCells('htRight'))}>
				<Icon icon='align-right' className='opacity-80' iconSize={16} style={{ width: '18px' }} />
					<div>Align right</div>
					<div>Ctrl+C</div>
			</Menu.Button> */}
		</Menu.Root>
	)
}