import { ApiCmsResponse } from './types'

export enum PAGE_CONTENT_ENTRY_ID {
	ARK = 'ARK',
	BOM_OVERVIEW = 'BOM overview',
}

export type ApiPageContentEntry = [ PAGE_CONTENT_ENTRY_ID, string ]

interface IPageContentEntry {
	id: PAGE_CONTENT_ENTRY_ID
	content: string
}

export class PageContentEntry implements IPageContentEntry {
	id: PAGE_CONTENT_ENTRY_ID
	content: string

	constructor(data: PageContentEntry | IPageContentEntry) {
		this.id = data.id
		this.content = data.content
	}

	static fromApi(apiPageContent: ApiPageContentEntry) {
		const data: IPageContentEntry = {
			id: apiPageContent[ 0 ],
			content: apiPageContent[ 1 ],
		}
		return new PageContentEntry(data)
	}
}

interface IPageContent {
	entries: PageContentEntry[]
}

export class PageContent implements IPageContent {
	entries: PageContentEntry[]

	constructor(pageContent: PageContent | IPageContent) {
		this.entries = pageContent.entries
	}

	static fromApi(apiResponse: ApiCmsResponse<ApiPageContentEntry>) {
		const data: IPageContent = {
			entries: apiResponse.values.slice(1).map(apiFaqEntry => PageContentEntry.fromApi(apiFaqEntry))
		}
		return new PageContent(data)
	}

	getEntry(id: PAGE_CONTENT_ENTRY_ID) {
		return this.entries.find(entry => entry.id === id)
	}
}
