import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useUpdateEffect, useUpdateLayoutEffect } from "src/hooks/effects";
import { useStateThrottled } from "src/hooks/state";
import { array } from "src/helpers";
import { Button } from "../button";
import PdfPageMapWrapper, { PdfPageMapWrapperProps } from "./PdfPageMapWrapper";
import { useFilteredPagesContext } from "src/contexts/filtered-pages-ctx";

const noop = () => {};

interface PdfPageMapWrapperWithMaxPagesProps
  extends Omit<PdfPageMapWrapperProps, "pages"> {
  maxPages: number;
  selectedPage?: number;
  width?: number | string;
  height?: number | string;
  marginTop?: number | string;
  setSelectedPage?: Dispatch<SetStateAction<number>>;
  filteredPages?: number[];
}

export const PdfPageMapWrapperWithMaxPages = ({
  width,
  height,
  marginTop,
  setSelectedPage,
  maxPages,
  scrollToPage,
  onPageChange = noop,
  numPages,
  intersectionObserverRootSelector: rootSelector,
  selectedPage,
  filteredPages = [],
  ...props
}: PdfPageMapWrapperWithMaxPagesProps) => {
  const currPageRef = useRef(1);
  const scrollPaddingTopRef = useRef(0);
  const scrollElRef = useRef<HTMLElement | null>(null);
  // Here the list of all displayed pages is being generated for our pdf file: PageGeneration
  const [pages, setPages] = useStateThrottled(
    filteredPages.length > 0
      ? [...filteredPages]
      : scrollToPage
      ? getNewPageRange(scrollToPage, maxPages, numPages)
      : array.range(1, maxPages),
    500
  );
  //   const [pages, setPages] = useStateThrottled([selectedPage], 500);
  const handlePageChange = (page: number) => {
    currPageRef.current = page;
    onPageChange(page);
    // const shouldLoadNextPages = page >= array.last(pages, 1) - 2 && array.last(pages) !== numPages;
    // const shouldLoadPrevPages = page <= pages[0] + 2 && pages[0] !== 1;
    const scrollData1 = getScrollData(
      `#pdf-page-container-${page}`,
      rootSelector
    );
    const scrollData2 = getScrollData(
      `#pdf-page-container-${page + 1}`,
      rootSelector
    );
    // if (!shouldLoadNextPages && !shouldLoadPrevPages) return;
    if (scrollData1?.scrollPaddingTop >= 0) {
      scrollPaddingTopRef.current = scrollData1.scrollPaddingTop;
      scrollElRef.current = scrollData1.scrollEl;
    } else {
      scrollPaddingTopRef.current = scrollData2.scrollPaddingTop;
      scrollElRef.current = scrollData2.scrollEl;
    }
    // setPages(getNewPageRange(page, maxPages, numPages));
  };
  useEffect(() => {
    console.log("filteredPages: ", filteredPages);
    setPages( filteredPages.length > 0
      ? [...filteredPages]
      : scrollToPage
      ? getNewPageRange(scrollToPage, maxPages, numPages)
      : array.range(1, maxPages))
  }, [filteredPages,maxPages,numPages]);
  useUpdateEffect(() => {
    scrollPaddingTopRef.current = 0;
    setScrollPaddingTop(rootSelector, 0);
    // if (filteredPages.length === 0)
    //   setPages(getNewPageRange(scrollToPage, maxPages, numPages));
    // else setPages([...filteredPages]);
  }, [scrollToPage, maxPages, numPages]);

  useUpdateLayoutEffect(() => {
    const scrollEl = scrollElRef.current;
    setScrollPaddingTop(rootSelector, scrollPaddingTopRef.current);
    scrollEl?.scrollIntoView();
    // }, [pages, rootSelector]);
  }, [rootSelector]);
  console.log("Not using anymore");
  return (
    <PdfPageMapWrapper
      width={width}
      height={height}
      selectedPage={selectedPage}
      marginTop={marginTop}
      setSelectedPage={setSelectedPage}
      onPageChange={handlePageChange}
      scrollToPage={scrollToPage}
      numPages={numPages}
      pages={pages}
      intersectionObserverRootSelector={rootSelector}
      /*beforePagesChildren={
        pages[0] > 1 && (
          <div className="py-12">
            <Button.Contained
              intent="primary"
              onClick={() => handlePageChange(currPageRef.current)}
            >
              Load more pages
            </Button.Contained>
          </div>
        )
      }
      afterPagesChildren={
        // array.last(pages) !== numPages && (
        array.last(pages) !== numPages && (
          <div className="py-12">
            <Button.Contained
              intent="primary"
              onClick={() => handlePageChange(currPageRef.current)}
            >
              Load more pages
            </Button.Contained>
          </div>
        )
      }*/
      {...props}
    />
  );
};

function getNewPageRange(
  scrollToPage: string | number | undefined,
  maxPages: number,
  numPages: number
) {
  if (!scrollToPage) scrollToPage = 1;
  const pagesBefore = Math.round(maxPages / 3);
  const start = Math.max(
    1,
    Math.min(numPages - maxPages, Number(scrollToPage) - pagesBefore)
  );
  const stop = Math.min(numPages, start + maxPages + 1);
  return array.range(start, stop);
}

function setScrollPaddingTop(selector: string, value: number): void {
  const container = document.querySelector(selector) as HTMLElement | null;
  if (container) (container.style as any).scrollPaddingTop = value + "px";
}

function getScrollData(targetSelector: string, rootSelector: string) {
  const target = document.querySelector(targetSelector);
  const root = document.querySelector(rootSelector);
  if (!target || !root)
    return {
      scrollPaddingTop: 0,
      scrollEl: null,
    };

  const pageTop = target.getBoundingClientRect().top;
  const containerTop = root.getBoundingClientRect().top;
  return {
    scrollPaddingTop: pageTop - containerTop,
    scrollEl: target as HTMLElement,
  };
}
