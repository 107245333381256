type Unit = 'bytes' | 'kb' | 'mb' | 'gb' | 'tb'

export function getFileSizeAndUnit(size: number) {
	size = removeDecimals(size)
	const unit = getUnit(size)
	size = convertSize(size, unit)
	size = roundToOneDecimal(size)
	return [ size, unit ] as const
}

function removeDecimals(size: number) {
	return Math.floor(size)
}

function getUnit(size: number): Unit {
	const numberOfDigits = `${size}`.length

	if (numberOfDigits <= 3)
		return 'bytes'
	if (numberOfDigits <= 6)
		return 'kb'
	if (numberOfDigits <= 9)
		return 'mb'
	if (numberOfDigits <= 12)
		return 'gb'
	return 'tb'
}

function convertSize(size: number, unit: Unit): number {
	if (unit === 'tb')
		return size / (10 ** 12)
	if (unit === 'gb')
		return size / (10 ** 9)
	if (unit === 'mb')
		return size / (10 ** 6)
	if (unit === 'kb')
		return size / (10 ** 3)
	return size
}

function roundToOneDecimal(size: number): number {
	return parseFloat(size.toFixed(1))
}
