import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { Grid } from 'src/lib/grid'
import { Text } from 'src/lib/text';

export interface Legend {
	col2?: {
		text: string,
		width: number
	},
	col3?: {
		text: string,
		width: number
	},
	col4?: {
		text: string,
		width: number
	},
	col5?: {
		text: string,
		width: number
	},

}

interface Props {
	legend: Legend
}

const InnerBoardLegend = ({
	legend
}: Props) => {



	return (
		<div className='flex items-center'>
			{((Object.keys(legend) as any).sort() as (keyof Legend)[]).map((colName) => (
				legend[ colName ]
					? (
						<div className='relative' style={{ width: legend[ colName ]?.width + 'px' }} key={`inner-legend-${colName}`}>
							<Text className='fadein absolute text-right px-4 right-0' style={{ marginTop: '-1.5rem' }}>{legend[ colName ]?.text}</Text>
						</div>
					) : null
			))}
		</div>
	)
}

interface GetterProps {
	onCol2Width?: (width: number) => void
	onCol3Width?: (width: number) => void
	onCol4Width?: (width: number) => void
}

export const GetInnerBoardLegendWidth = ({
	onCol2Width = () => { },
	onCol3Width = () => { },
	onCol4Width = () => { },
}: GetterProps) => {

	const location = useLocation()

	const col2Ref = useRef<HTMLTableDataCellElement | null>(null)
	const col3Ref = useRef<HTMLTableDataCellElement | null>(null)
	const col4Ref = useRef<HTMLTableDataCellElement | null>(null)

	useEffect(() => {
		if (col2Ref.current)
			onCol2Width(col2Ref.current.clientWidth)
		if (col3Ref.current)
			onCol3Width(col3Ref.current.clientWidth)
		if (col4Ref.current)
			onCol4Width(col4Ref.current.clientWidth)
	}, [ location.pathname ]) //eslint-disable-line

	return (
		<tr>
			<td>
			</td>

			<td ref={col2Ref}>
			</td>

			<td ref={col3Ref}>
			</td>

			<td ref={col4Ref}>
			</td>
		</tr>
	)
}

export default InnerBoardLegend;