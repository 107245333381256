import React from 'react';
import { cn } from 'src/helpers';

interface Props extends React.ComponentProps<'div'> {

}

const Line = ({
	className = '',
	style = {},
	...props
}: Props) => {

	style = {
		gridColumn: '1 / -1',
		...style,
	}

	return (
		<div
			className={cn`
				h-px
				min-h-0
				p-0
				m-0
				bg-current
				${className}
			`}
			style={style}
			{...props}
		/>
	)
}

export default Line;