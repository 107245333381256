import HotTable from '@handsontable/react'
import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react'
import { SetState } from 'src/hooks/state/useSetState'
import { array, isNullish } from 'src/helpers'
import { ExcelData, MergedCell } from '.'
import { Attribute } from './types'
import { selection } from './utils'

export const useAddOrRemove = (setState: Dispatch<SetStateAction<ExcelData>>, ref: MutableRefObject<HotTable | null>) => {

	const remove = (dir: 'row' | 'col', selected: number[]) => (target: Attribute[] | MergedCell[]) => {
		return (target as any[])
			.filter(x => !selected.includes(x[ dir ]))
			.map(x => {
				if (x[ dir ] > selected[ 0 ])
					x[ dir ] = x[ dir ] - selected.length
				return x
			})
	}
	const add = (dir: 'row' | 'col', index: number) => (target: Attribute[] | MergedCell[]) => {
		return (target as any[])
			.map(x => {
				if (x[ dir ] >= index)
					x[ dir ] = x[ dir ] + 1
				return x
			})
	}

	const removeRows = useCallback(() => {
		const selectedRows = selection.getRows(ref)
		if (!selectedRows) return
		setState(state => state.clone()
			.setData(state.data.filter((_, i) => !selectedRows.includes(i)))
			.setAttributes(remove('row', selectedRows)(state.attributes))
			.setMergedCells(remove('row', selectedRows)(state.mergedCells))
		)
	}, [ ref, setState ])

	const removeCols = useCallback(() => {
		const selectedCols = selection.getCols(ref)
		if (!selectedCols) return
		setState(state => state.clone()
			.setData(state.data.map(row => row.filter((_, colIndex) => !selectedCols.includes(colIndex))))
			.setAttributes(remove('col', selectedCols)(state.attributes))
			.setMergedCells(remove('col', selectedCols)(state.mergedCells))
		)
	}, [ ref, setState ])

	const addRowAbove = useCallback(() => {
		const rowIndex = selection.getRows(ref)?.[ 0 ]
		if (isNullish(rowIndex)) return
		setState(state => state.clone()
			.setData(array.insert(Array(state.data[ 0 ].length).fill(null), rowIndex)(state.data))
			.setAttributes(add('row', rowIndex)(state.attributes))
			.setMergedCells(add('row', rowIndex)(state.mergedCells))
		)
		selection.translate('y', 1, ref)
	}, [ ref, setState ])

	const addRowBelow = useCallback(() => {
		const rowIndex = array.last(selection.getRows(ref))
		if (isNullish(rowIndex)) return
		setState(state => state.clone()
			.setData(array.insert(Array(state.data[ 0 ].length).fill(null), rowIndex + 1)(state.data))
			.setAttributes(add('row', rowIndex + 1)(state.attributes))
			.setMergedCells(add('row', rowIndex + 1)(state.mergedCells))
		)
	}, [ ref, setState ])

	const addColLeft = useCallback(() => {
		const colIndex = selection.getCols(ref)?.[ 0 ]
		if (isNullish(colIndex)) return
		setState(state => state.clone()
			.setData(state.data.map(row => array.insert(null, colIndex)(row)))
			.setAttributes(add('col', colIndex)(state.attributes))
			.setMergedCells(add('col', colIndex)(state.mergedCells))
		)
		selection.translate('x', 1, ref)
	}, [ ref, setState ])

	const addColRight = useCallback(() => {
		const colIndex = array.last(selection.getCols(ref))
		if (isNullish(colIndex)) return
		setState(state => state.clone()
			.setData(state.data.map(row => array.insert(null, colIndex + 1)(row)))
			.setAttributes(add('col', colIndex + 1)(state.attributes))
			.setMergedCells(add('col', colIndex + 1)(state.mergedCells))
		)
	}, [ ref, setState ])

	return { removeRows, removeCols, addRowAbove, addRowBelow, addColLeft, addColRight }
}
