import { MainLayout } from 'src/lib/layout'
import { AsyncPage } from 'src/lib/page'
import { useFetchProjects } from 'src/API/projects'
import { Project } from 'src/data/Project';
import Page from './Page'


const Setup = () => {

	const { data: projects, error, isValidating, revalidate } = useFetchProjects()

	return (
		<AsyncPage
			errors={[ error ]}
			isLoading={!projects}
			retry={revalidate}
			isRetrying={isValidating}>

			<MainLayout
				content={<Page isValidating={isValidating} projects={(projects || []) as Project[]} />}
				bottom={''}
			/>

		</AsyncPage>
	)
}

export default Setup