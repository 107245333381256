import './index.css'
import { useParams } from 'react-router-dom';
import { useFetchProject, useFetchProjects } from 'src/API/projects'
import { AsyncPage } from 'src/lib/page'
import { MainLayout } from 'src/lib/layout'
import { Project } from 'src/data/Project';
import { View } from './View'
import { LeftNav } from 'src/components/sidebar';

interface Params {
	projectId: string
}

const Setup = () => {

	const { projectId } = useParams<Params>()

	const { data: projects } = useFetchProjects({
		revalidateOnMount: false
	})

	const { data: project, error, revalidate, isValidating } = useFetchProject(projectId, {
		initialData: projects?.find(p => p.id + '' === projectId),
		revalidateOnMount: true
	})

	return (
		<AsyncPage
			errors={[ error ]}
			retry={revalidate}
			isRetrying={isValidating}
			isLoading={!project}>

			<MainLayout
				left={<LeftNav />}
				content={<View project={project as Project} isValidatingProject={isValidating} />}
			/>

		</AsyncPage>
	)
}

export default Setup