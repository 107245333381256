import { usePageSrc } from 'src/API/_urls'
import { Children } from 'src/types'
import JsFileDownloader from 'js-file-downloader';
import { Tooltip } from 'src/lib/modals';


interface Props extends Omit<React.ComponentProps<'div'>, 'onClick' | 'title'> {
	children: Children
	page: string | number
	downloadName: string
	fileId?: string | number
	sourcePath: string
}

export const DownloadPdfPage = ({
	page,
	sourcePath,
	downloadName,
	children,
	...props
}: Props) => {

	const url = usePageSrc({
		sourcePath,
		page,
		format: getFormat(downloadName),
		scale: 3
	})

	function handleClick() {
		url && new JsFileDownloader({ url, filename: downloadName })
	}

	return (
		<Tooltip text={`Download:  ${downloadName}`} delay={400} padding={7} renderDivContainer={false}>
			<div
				onClick={handleClick}
				{...props}>
				{children}
			</div>
		</Tooltip>
	)
}

const getFormat = (downloadName: string) => {
	const ext = downloadName.split('.').pop() || ''

	if ([ 'jpg', 'jpeg' ].includes(ext))
		return 'jpg'
	if ([ 'png' ].includes(ext))
		return 'png'
	console.error('Invalid file extension')
	return
}
