import axios from 'axios';
import { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { ConnectivityStatus } from './components/utils';
import { ContextMenu, notify } from './lib/modals';
import { useFetchCurrentUser } from 'src/API/user';
import { Page } from './pages';
import { PrivateRoute } from './components/PrivateRoute';
import { useLogin } from './hooks/useLogin';
import { CONFIG } from './cp.config';
import { useAxiosInterceptor } from './hooks/useAxiosInterceptor';
import { FilteredPagesContext, FilteredPagesContextProvider } from './contexts/filtered-pages-ctx';

// axios.defaults.baseURL = "http://<domain>:8000".replace("<domain>", CONFIG.DOMAIN);
axios.defaults.baseURL = 'https://rest-demo.cenozai.io';

export const App = () => {
  const { isLogin, login, logout, token } = useLogin({
    onLogin: () => {},
    onLogout: () => {},
    onRefresh: () => {},
  });

  const { data: user, error: userError } = useFetchCurrentUser(isLogin);

  useEffect(() => {
    userError && handleUserError();
  }, [userError]); //eslint-disable-line

  useAxiosInterceptor({
    type: 'response',
    on401() {
      logout();
    },
  });

  function handleCredentialsError() {
    logout();
    notify('danger')('Incorrect username or password.');
  }

  function handleUserError() {
    logout();
    notify('danger')('Could not retrieve user profile.');
  }

  return (
    <Fragment>
      <FilteredPagesContextProvider>
        <ConnectivityStatus />
        <ContextMenu.Provider />
        <ToastContainer
          className="app_toast-container"
          toastClassName="app_toast"
          bodyClassName="app_toast-body"
          progressClassName="app_toast-progress"
          position="bottom-right"
          autoClose={CONFIG.NOTIFICATION_DEFAULT_AUTOCLOSE_DELAY}
          pauseOnFocusLoss
          limit={3}
          closeButton={false}
          draggable={false}
        />

        <Switch>
          <Route path="/login">
            {/* <Route path='/login/2FA'>
						<Page.Login2FA />
					</Route> */}
            <Route path="/login">
              <Page.Login
                onToken={login}
                onError={handleCredentialsError}
                user={user}
                // key={`login-page-key-${JSON.stringify(token || '')}`}
              />
            </Route>
          </Route>

          {user && (
            <Switch>
              <PrivateRoute path="/" exact onLogout={logout}>
                <Redirect to="/projects" />
              </PrivateRoute>

              <PrivateRoute path="/profile" onLogout={logout}>
                <Page.Profile />
              </PrivateRoute>
              {/* 
						<PrivateRoute path="/setup2fa" onLogout={logout}>
							<Page.Setup2FA />
						</PrivateRoute> */}

              <PrivateRoute path="/dashboard" onLogout={logout}>
                <Page.Dashboard />
              </PrivateRoute>

              <PrivateRoute path="/projects/new" onLogout={logout}>
                <Page.NewProject />
              </PrivateRoute>

              <PrivateRoute path="/projects/:projectId/ark" onLogout={logout}>
                <Page.Ark />
              </PrivateRoute>

              <PrivateRoute
                path="/projects/:projectId/page/:page/pnid/:contractorDocumentId/:sheet/:sourcePath"
                onLogout={logout}
              >
                <Page.Hotspotting />
              </PrivateRoute>

              <PrivateRoute
                path="/projects/:projectId/file/:fileId/page/:initialPage/:numPages/:sourcePath"
                onLogout={logout}
              >
                <Page.PdfFileView />
              </PrivateRoute>

              <PrivateRoute path="/projects/:projectId/bom/overview" exact onLogout={logout}>
                {/* <Page.GenerateBOM /> */}
                <Page.Bom.Overview />
              </PrivateRoute>
              <PrivateRoute path="/projects/:projectId/bom/process/:fileId?" exact onLogout={logout}>
                <Page.Bom.Process />
              </PrivateRoute>

              <PrivateRoute path="/projects/:projectId/bom/find/:fileId?/:totalPages?" exact onLogout={logout}>
                {/* <Page.GenerateBOMFind /> */}
                <Page.Bom.Find />
              </PrivateRoute>

              <PrivateRoute path="/projects/:projectId/bom/review/:fileId?/:tableId?" exact onLogout={logout}>
                {/* <Page.GenerateBOMReview /> */}
                <Page.Bom.Review />
              </PrivateRoute>

              <PrivateRoute path="/projects/:projectId" onLogout={logout}>
                <Page.SingleProject />
              </PrivateRoute>

              <PrivateRoute path="/projects" onLogout={logout}>
                <Page.Projects />
              </PrivateRoute>
            </Switch>
          )}
        </Switch>
      </FilteredPagesContextProvider>
    </Fragment>
  );
};
