import { useMemo, useState } from 'react'

export const usePageController = (initialPage: number) => {

	const [scrollToPage, setScrollToPage] = useState(initialPage)
	const [currentPage, setCurrentPage] = useState(initialPage)

	const pdfPageMapWrapper = useMemo(() => ({
		scrollToPage: scrollToPage,
		onPageChange: setCurrentPage,
	}), [scrollToPage])

	const pageController = useMemo(() => ({
		page: currentPage,
		onScrollToPage: setScrollToPage
	}), [currentPage])

	const res = useMemo(() => ({
		pdfPageMapWrapper,
		pageController,
	}), [pdfPageMapWrapper, pageController])

	return res
}