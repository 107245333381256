import { useProjectIdFromParams } from 'src/hooks/params'
import { Anchor } from 'src/lib/button'
import { Icon } from 'src/lib/icons'


export const BackToBomAnchor = () => {

	const projectId = useProjectIdFromParams()

	return (
		<Anchor.Icon to={`/projects/${projectId}/bom/overview`} title='Go back to BOM overview' className='bg-white' rounded>
			<Icon icon='chevron' className='transform rotate-180' />
		</Anchor.Icon>
	)
}