import { useEffect } from 'react'
import { useUpdatedRef } from 'src/hooks/refs'

export const useUnmountEffect = (cb: () => void) => {

	const cbRef = useUpdatedRef(cb)

	useEffect(() => {
		const callback = () => cbRef.current()
		window.addEventListener('beforeunload', callback)

		return () => {
			callback()
			window.removeEventListener('beforeunload', callback)
		}
	}, []) //eslint-disable-line
}
