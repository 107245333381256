import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { Hotspot } from 'src/data/module.ark';
import { Children } from 'src/types';
import { cn } from 'src/helpers';
import { BaseTooltip } from 'src/lib/modals'
import { getStyles, useStopNativeMouseDownPropaggation } from './utils';
import { usePnidLinkUrl } from 'src/API/_urls';


interface Props {
	hotspot: Hotspot
	children?: Children
	tooltip?: Children
	show?: boolean
}

const Spot = ({
	hotspot,
	children,
	tooltip,
	show = false
}: Props) => {

	const pnidLinkUrl = usePnidLinkUrl({
		contractorDocumentId: hotspot.pnid?.contractorDocumentId,
		pageNumber: hotspot.pnid?.pageNumber,
		sheet: hotspot.sheet,
		sourcePath: hotspot.pnid?.sourcePath,
	})

	if (!pnidLinkUrl) return null

	return (
		<BaseTooltip variant='light' content={tooltip} containerParentSelector='.zoom_and_span_root' renderDivContainer={false} delay={350}>
			<div
				style={getStyles(hotspot.bounds)}
				className='absolute flex justify-stretch'>
				<Link
					to={pnidLinkUrl}
					draggable={false}
					style={show ? { border: '1px solid #ff7b00' } : {}}
					className={cn`
						block
						w-full
						h-full
						border 
						border-transparent
						hover:border-orange
						transition-colors
						duration-100
						rounded-sm
					`}>
					{children}
				</Link>
			</div>
		</BaseTooltip>
	)
}

export default Spot;



const Link = (props: LinkProps) => {

	//Do not allow spanning from ZoomAndSpan
	const ref = useStopNativeMouseDownPropaggation()

	return (
		<RouterLink ref={ref} {...props} />
	)
}