import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CONFIG } from 'src/cp.config'


export interface CognitoRedirectData {
	access_token: string
	expires_in: string
	id_token: string
	token_type: string
}

interface Props {
	onCognitoRedirectOver: (data: CognitoRedirectData) => void
	disabled?: boolean
}

export const WithCognito = ({
	onCognitoRedirectOver,
	disabled = false
}: Props) => {

	const { hash } = useLocation()

	useEffect(() => {
		if (!hash) return
		const paramsString = hash.slice(1)
		const params = new URLSearchParams(paramsString)
		const jsonParams: any = {}

		for (var key of params.keys()) {
			jsonParams[ key ] = params.get(key) || ''
		}
		onCognitoRedirectOver(jsonParams as CognitoRedirectData)
	}, [ hash ]) //eslint-disable-line

	return (
		<a
			className={disabled ? 'disabled' : ''}
			href={`https://cenozai-platform.auth.ap-southeast-1.amazoncognito.com/login?client_id=6s1s7qasl351l0691c7njikgpv&response_type=token&scope=email+openid+profile&redirect_uri=${CONFIG.COGNITO.REDIRECT_URI.replace("<domain>", CONFIG.DOMAIN)}`}
			tabIndex={disabled ? -1 : 0}>
			Cognito
		</a>
	)
}
