import { useParams } from 'react-router';
import { LeftNav } from 'src/components/sidebar';
import { MainLayout } from 'src/lib/layout';
import View from './View'

export interface Params {
	projectId: string
	fileId: string
	numPages: string
	sourcePath: string
	initialPage: string
}

const Setup = () => {

	const { projectId, fileId, sourcePath, numPages, initialPage } = useParams<Params>()

	return (
		<MainLayout
			left={(
				<LeftNav />
			)}
			content={(
				<View
					projectId={parseInt(projectId)}
					fileId={parseInt(fileId)}
					sourcePath={decodeURIComponent(sourcePath)}
					fileName={decodeURIComponent(sourcePath).split('/').pop() || ''}
					numPages={parseInt(numPages)}
					initialPage={parseInt(initialPage)}
				/>
			)}
		/>
	)
}

export default Setup;