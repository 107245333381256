import useSWR, { SWRConfiguration } from 'swr'
import axios from 'axios'
import { Import, ApiImport } from 'src/data/Import'
import { useActiveProjectCache } from './projects'

export const postTriggerImport = (imp: Import) => {
    return axios
        .put('cpf/v1/import/', null, {
            params: {
                import_id: imp.id + ''
            }
        })
        .then(res => res.data as { code: 1 | 2 | 3 | 4, reason?: string })
        .then(res => {
            if (res.code === 2) {
                throw new Error('None selected')
            }
            if (res.code === 3) {
                throw new Error('Busy')
            }
            if (res.code === 4) {
                throw new Error(res.reason || 'An error has occured, please try again later.')
            }
            return res
        })

}

export const fetchImportList = (url: string, projectId: string | number): Promise<Import[]> => (
    axios
        .get(url, {
            params: {
                project_id: projectId
            }
        })
        .then(res => res.data as ApiImport[])
        // .then(t => (console.log('IMPORTS:', t), t))
        .then(apiImportList => apiImportList.map(apiImp => Import.fromApi(apiImp)))
)

export const useFetchImportList = (config?: SWRConfiguration<Import[], Error>) => {
    const { data: activeProject } = useActiveProjectCache()
    return useSWR(activeProject?.id ? [ '/cpf/v1/import/list/', activeProject.id + '' ] : null, fetchImportList, config)
}

