import { useRef, useEffect, useCallback } from 'react';

const useGetIsMounted = () => {

	const isMountedRef = useRef(false)

	useEffect(() => {
		isMountedRef.current = true

		return () => { isMountedRef.current = false }
	}, [])

	return useCallback(() => isMountedRef.current, [])
}

export default useGetIsMounted;