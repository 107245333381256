import { Nullish } from 'src/types'

export function last<T extends unknown>(array: Array<T> | Nullish): T | undefined
export function last<T extends unknown>(array: Array<T> | Nullish, defaultReturnValue: T): T

export function last<T extends unknown>(array: Array<T> | Nullish, defaultReturnValue?: T): T | undefined {
	if (defaultReturnValue === undefined) {
		return array?.slice(-1)[ 0 ]
	}
	return array?.slice(-1)[ 0 ] || defaultReturnValue
}
