import React, { useState } from 'react';
import { useOnToggleKeyBinding } from 'src/hooks/key-binding';
import { ZoomAndSpan } from 'src/lib/zoom-and-span';

interface Props extends Omit<React.ComponentProps<typeof ZoomAndSpan>, 'disableSpan'> {
  spanKey?: string;
}

const ZoomAndSpanWrapper = ({ spanKey, children, ...props }: Props) => {
  const [disableSpan, setDisableSpan] = useState(true);
  useOnToggleKeyBinding(spanKey || '', (isDown) => setDisableSpan(!isDown));

  return (
    <ZoomAndSpan disableSpan={spanKey ? disableSpan : false} {...props}>
      {children}
    </ZoomAndSpan>
  );
};

export default ZoomAndSpanWrapper;
