import React from 'react'
import { Dots as DefaultDots } from 'src/assets/preloaders'

interface Props extends React.ComponentProps<'svg'> {
	width?: number
	height?: number
	marginTop?: number
	marginLeft?: number
	marginRight?: number
	opacity?: number
}

export const Dots = ({
	width,
	height,
	marginTop = 0,
	marginLeft = 0,
	marginRight = 0,
	opacity = 0.85,
	style = {},
	...props
}: Props) => {

	if (height && !width) {
		width = height * 3
	}

	else if (width && !height) {
		height = width / 3
	}

	else if (!width || !height) {
		width = 18
		height = 6
	}

	style = {
		opacity,
		...style,
	}

	if (marginTop)
		style.marginTop = marginTop + 'px'
	if (marginLeft)
		style.marginLeft = marginLeft + 'px'
	if (marginRight)
		style.marginRight = marginRight + 'px'

	return (
		<DefaultDots
			width={width}
			height={height}
			{...props}
			style={style}
		/>
	)
}

export default Dots