import { useFetchPnidByPageRecordId } from 'src/API/ark_search';
import { usePnidLinkUrl } from 'src/API/_urls';
import { Result } from 'src/data/module.ark';
import { Anchor } from 'src/lib/button';

interface Props {
	projectId: number
	result: Result
	className?: string
}

const PnidLink = ({
	projectId,
	result,
	className = '',
}: Props) => {

	const { data: pnidRecord } = useFetchPnidByPageRecordId(projectId, result.pageRecordId)

	const pnidLinkUrl = usePnidLinkUrl({
		contractorDocumentId: pnidRecord?.contractorDocumentId,
		sheet: pnidRecord?.sheet,
		pageNumber: result.pageNumber,
		sourcePath: result.sourcePath,
	})

	if (!pnidLinkUrl) return null

	return (
		<Anchor.Disc to={pnidLinkUrl} className={`border-2 border-primary border-solid ${className}`}>
			<strong className='pr-1 pb-px'><small>View interactive P&ID</small></strong>
		</Anchor.Disc>
	)
}

export default PnidLink;