import { useCallback, useRef } from 'react'
import { SomeFunction } from 'src/types'
import { useUnmountEffect } from './effects'
import { useUpdatedRef } from './refs'

const noop = () => { }

type ID = string | number

let reference: Record<ID, {
	token: NodeJS.Timeout
	args: any
	fn: SomeFunction
} | undefined> = {}
/**
 * 
 * @returns [ returnedFn, cancelFn, callNow, callAllNow ] 
 */
export const useSchedule = <T extends SomeFunction>(fn: T, delay: number) => {
	const fnRef = useUpdatedRef(fn)
	const timeoutCbRef = useRef<() => void>(noop)

	const cancelFn = useCallback((id: ID) => {
		const referencedData = reference[ id ]
		if (!referencedData) return
		const { token } = referencedData
		delete reference[ id ]
		token && clearTimeout(token)
	}, [])

	const returnedFn = useCallback((id: ID) => (...args: Parameters<T>) => {
		const returnedPromise: Promise<ReturnType<T>> = new Promise((resolve, reject) => {
			cancelFn(id)

			timeoutCbRef.current = () => {
				const resolvedValue = fnRef.current(...args)
				if (resolvedValue.constructor.name === 'Promise') {
					(resolvedValue as Promise<any>)
						.then(x => resolve(x))
						.catch(x => reject(x))
				}
				else
					resolve(resolvedValue)
			}
			reference[ id ] = {
				token: setTimeout(timeoutCbRef.current, delay),
				args,
				fn: fnRef.current,
			}
		})
		return returnedPromise
	}, [ fnRef, delay, cancelFn ])


	const callNow = useCallback((id: ID): Promise<ReturnType<T>> | Promise<void> => {
		const referencedData = reference[ id ]

		const returnedPromise: Promise<ReturnType<T>> = new Promise((resolve, reject) => {

			if (referencedData) {
				cancelFn(id)
				const resolvedValue = referencedData.fn(...referencedData.args as Parameters<T>)
				if (resolvedValue.constructor.name === 'Promise') {
					(resolvedValue as Promise<any>)
						.then(x => resolve(x))
						.catch(x => reject(x))
				}
				else
					resolve(resolvedValue)
			}
			else {
				reject('no scheduled call for id: ' + id)
			}
		})
		return returnedPromise
	}, [ cancelFn ])

	const callAllNow = useCallback(() => {
		Object.keys(reference).forEach((id) => {
			callNow(id)
		})
	}, [ callNow ])

	return [ returnedFn, cancelFn, callNow, callAllNow ] as const
}