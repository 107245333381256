import { useFormikContext } from 'formik';
import React from 'react';
import { Children } from 'src/types';
import { cn } from 'src/helpers';
import ErrorMessage from './ErrorMessage';
import Input from './Input'
import Search from './Search';
import { Type } from './types';


interface Props extends Omit<React.ComponentProps<typeof Input>, 'type'> {
	showError?: boolean
	label: Children
	type: Type
	inputStyle?: React.CSSProperties
	inputClassName?: string
}

const LabelledInput = ({
	className = '',
	label,
	name,
	showError = true,
	style = {},
	type,
	disabled,
	inputStyle = {},
	inputClassName = '',
	...props
}: Props) => {

	const { errors, touched } = useFormikContext<any>()
	const hasErrors = !!errors[ name ] && touched[ name ]

	return (
		<label
			style={style}
			className={cn`
				relative
				inline-flex
				${[ 'search', 'email', 'password', 'text' ].includes(type) && 'flex-col'}
				${[ 'checkbox', 'radio', 'number' ].includes(type) && 'flex-row-reverse items-center justify-end'}
				${className}
			`}>

			<div className={cn`
				${disabled && 'disabled'}
				${[ 'search', 'email', 'password', 'text' ].includes(type) && 'mb-1.5'}
				${[ 'checkbox', 'radio' ].includes(type) && 'ml-2'}
				${[ 'number' ].includes(type) && 'ml-1'}
			`}>
				{label}
			</div>

			{type === 'search'
				? <Search disabled={disabled} name={name} {...props} />
				: <Input
					disabled={disabled}
					type={type}
					name={name}
					style={inputStyle}
					className={`${hasErrors ? 'border-red-500' : ''} ${inputClassName}`}
					{...props}
				/>
			}

			{showError && (
				<ErrorMessage
					name={name}
					className='absolute whitespace-nowrap leading-4'
					style={{ top: 'calc(2px + 100%' }}
				/>
			)}

		</label>
	)
}

export default LabelledInput;
