import { useLocation } from 'react-router';
import { useFetchActiveProject } from 'src/API/projects';
import { ErrorPage } from 'src/components';
import { LeftNav } from 'src/components/sidebar';
import { Project } from 'src/data/Project';
import { MainLayout } from 'src/lib/layout'
import RenderAsyncPage from 'src/lib/page/AsyncPage';
import View from './View'


const Setup = () => {

	const location = useLocation()
	const { data: project, error, revalidate, isValidating } = useFetchActiveProject()

	return (
		<RenderAsyncPage
			errors={[ error ]}
			isLoading={!project}
			retry={revalidate}
			isRetrying={isValidating}>
			<MainLayout
				left={<LeftNav />}
				content={project?.isEnabled.ark.module
					? <View project={project as Project} key={JSON.stringify(location.pathname)} />
					: <ErrorPage.ModuleNotActive />
				}
			/>
		</RenderAsyncPage>
	)
}

export default Setup;