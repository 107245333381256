import { useEffect, useRef, useState } from 'react'
import { Button } from 'src/lib/button'
import { Form, Formik } from 'src/lib/forms'
import { Icon } from 'src/lib/icons'
import { toFixed2 } from 'src/pages/pdf-file-view/utils'
import { Tooltip } from '../modals'


interface Props {
	zoom: number
	onZoom: (newZoom: number) => void
	zoomStrength: number
}

export const ZoomController = (props: Props) => {

	const tokenRef = useRef<NodeJS.Timeout>()
	const [ inputVal, setInputVal ] = useState(props.zoom + '% ')
	const [ zoom, setZoom ] = useState(props.zoom)

	useEffect(() => setZoom(props.zoom), [ props.zoom ])
	useEffect(() => setInputVal(Math.round(zoom * 100) + '%'), [ zoom ]) //eslint-disable-line


	function handleZoomOut() {
		const newVal = toFixed2(zoom / props.zoomStrength)
		setZoom(newVal)
		tokenRef.current && clearTimeout(tokenRef.current)
		tokenRef.current = setTimeout(() => props.onZoom(newVal), 350)
	}

	function handleZoomIn() {
		const newVal = toFixed2(zoom * props.zoomStrength)
		setZoom(newVal)
		tokenRef.current && clearTimeout(tokenRef.current)
		tokenRef.current = setTimeout(() => props.onZoom(newVal), 350)
	}

	function handleSubmit() {
		if (!inputVal) return
		const newVal = parseFloat(inputVal) / 100
		if (isNaN(newVal) || newVal > 20) return setInputVal(Math.round(zoom * 100) + '%')
		setZoom(newVal)
		tokenRef.current && clearTimeout(tokenRef.current)
		tokenRef.current = setTimeout(() => props.onZoom(newVal), 350)
	}


	return (
		<div className='flex items-center h-full'>
			<Tooltip text='Zoom out' shortcut='Ctrl+wheel' padding={8}>
				<Button.Disc onClick={handleZoomOut} className='-mr-1'><Icon icon='minus' /></Button.Disc>
			</Tooltip>
			<Tooltip text='Zoom in' shortcut='Ctrl+wheel' padding={8}>
				<Button.Disc onClick={handleZoomIn}><Icon icon='plus' /></Button.Disc>
			</Tooltip>
			<Formik initialValues={{ zoom }} onSubmit={handleSubmit}>
				<Form.Root className='flex items-center' noValidate={true}>
					<Form.Text
						name='zoom'
						value={inputVal + ''}
						onChange={e => setInputVal(e.target.value)}
						onFocus={e => e.target.select()}
						className='pl-0 pr-1.5 text-right leading-7 text-sm text-gray-800'
						maxDigits={5}
						autoComplete='off'
					/>
				</Form.Root>
			</Formik>
		</div>
	)
}
