import { Show } from 'src/lib/control-flow'
import { BaseTooltip, BaseTooltipProps } from './BaseTooltip'

interface Props extends Omit<BaseTooltipProps, 'content' | 'delay'> {
	shortcut?: string,
	text: string
	delay?: number
}

export const Tooltip = ({
	text,
	shortcut,
	delay = 300,
	...props
}: Props) => {



	return (
		<BaseTooltip content={tooltipContent(text)(shortcut)} delay={delay} {...props} />
	)
}

const tooltipContent = (text: string) => (shortcut?: string) => (
	<div className={`flex gap-2.5 items-center ${shortcut ? 'font-bold' : 'font-semibold'}`}>
		{text}
		<Show when={!!shortcut}>
			<div className='w-1 h-1 rounded bg-white' />
			<div className='opacity-70'>{shortcut}</div>
		</Show>
	</div>
)