import axios from 'axios';
import useSWR, { SWRConfiguration, cache } from 'swr';
import { Notification, ApiNotification } from 'src/data/Notification'
import { useEffect, useRef } from 'react';

const fetchAllNotifications = (url: string, limit: number): Promise<Notification[]> => (
	axios
		.get(url, {
			params: {
				type: "all",  // type are unread, read or all(unread first, then read until count is reached)
				count: limit  // Optional count, defaults to 10
			}
		})
		.then(({ data }) => data as ApiNotification[])
		.then(apiNotifs => apiNotifs.map(n => Notification.fromApi(n)))
	// .then(n => (console.log(n), n))
	// .then(notifs => notifs.filter(n => !!n.text))
)

export const useFetchAllNotifications = (limit: number = 5, config?: SWRConfiguration<Notification[], Error>) => {
	const prevLimitRef = useRef(limit)

	useEffect(() => {
		prevLimitRef.current = limit
	}, [ limit ])

	return useSWR([ '/cpf/v1/notification/', limit, 'all' ], fetchAllNotifications, {
		initialData: cache.get([ '/cpf/v1/notification/', prevLimitRef.current, 'all' ]),
		revalidateOnMount: true,
		errorRetryCount: 0,
		...config,
	})
}

const fetchUnreadNotifications = (url: string): Promise<Notification[]> => (
	axios
		.get(url, {
			params: {
				type: "unread",   // type are unread, read or all(unread first, then read until count is reached)
				count: 10         // Optional count, defaults to 10
			}
		})
		.then(({ data }) => data as ApiNotification[])
		.then(apiNotifs => apiNotifs.map(n => Notification.fromApi(n)))
	// .then(b => (console.log(b), b))
)

export const useFetchUnreadNotificationsWithInterval = (interval: number = 2000, config?: SWRConfiguration<Notification[], Error>) => {

	return useSWR([ '/cpf/v1/notification/', 'unread' ], fetchUnreadNotifications, {
		dedupingInterval: interval,
		refreshInterval: interval,
		...config,
	})
}

export const useUnreadNotifications = () => {
	const { data } = useSWR([ '/cpf/v1/notification/', 'unread' ], fetchUnreadNotifications, {
		revalidateOnMount: false
	})
	return data || []
}