import { useEffect, useState } from 'react';
import { Result } from 'src/data/module.ark';
import { useFetchFileRecord, useFetchPnidByPageRecordId } from 'src/API/ark_search';
import { Text } from 'src/lib/text';
import { Divisor } from 'src/components';
import { time } from 'src/helpers/time';
import { PdfPageImage } from 'src/components/PdfPageImage';


interface Props {
	className?: string
	result: Result
	projectId: number
}

const Thumbnail = ({
	className = '',
	result,
	projectId,
}: Props) => {

	const { data: pnidRecord } = useFetchPnidByPageRecordId(projectId, result.pageRecordId)
	const { data: fileRecord } = useFetchFileRecord(projectId, result.fileRecordId)

	return (
		<div className={className}>
			<Img fileId={result.fileId} pageNumber={result.pageNumber} scale={0.5} />

			<ul className='mt-4 mb-2 pl-0'>

				{pnidRecord?.title && (
					<li className='flex items-center py-1.5 animate-fadein-full-slow'>
						<Text smaller>
							<strong className='text-gray-800'>P&ID Title: </strong>
							{pnidRecord.title}
						</Text>
					</li>
				)}
				{pnidRecord?.contractorName && (
					<li className='flex items-center py-1.5 animate-fadein-full-slow'>
						<Text smaller>
							<strong className='text-gray-800'>Contractor: </strong>
							{pnidRecord.contractorName}
						</Text>
					</li>
				)}
				{pnidRecord?.contractorDocumentId && (
					<li className='flex items-center py-1.5 animate-fadein-full-slow'>
						<Text smaller>
							<strong className='text-gray-800'>Doc. ID: </strong>
							{pnidRecord.contractorDocumentId}
						</Text>
					</li>
				)}
				{pnidRecord?.facilityName && (
					<li className='flex items-center py-1.5 animate-fadein-full-slow'>
						<Text smaller>
							<strong className='text-gray-800'>Facility name: </strong>
							{pnidRecord.facilityName}
						</Text>
					</li>
				)}
				{pnidRecord?.revisionDate && (
					<li className='flex items-center py-1.5 animate-fadein-full-slow'>
						<Text smaller>
							<strong className='text-gray-800'>Revision date: </strong>
							{time.niceFormat(pnidRecord?.revisionDate)}
						</Text>
					</li>
				)}

				{pnidRecord && <Divisor className='my-2 animate-fadein-full-slow' />}

				{fileRecord?.createdOn && (
					<li className='flex items-center py-1.5 animate-fadein-full-slow'>
						<Text smaller>
							<strong className='text-gray-800'>Created on: </strong>
							{time.niceFormat(fileRecord?.createdOn)}
						</Text>
					</li>
				)}
				{fileRecord?.updatedOn && (
					<li className='flex items-center py-1.5 animate-fadein-full-slow'>
						<Text smaller>
							<strong className='text-gray-800'>Updated on: </strong>
							{time.niceFormat(fileRecord?.updatedOn)}
						</Text>
					</li>
				)}
			</ul>
		</div>
	)
}

export default Thumbnail;

interface ImgProps {
	fileId: number,
	pageNumber: number
	scale?: number
	transitionDuration?: number
}

const Img = ({
	fileId,
	pageNumber,
	scale = 0.5,
	transitionDuration = 300
}: ImgProps) => {

	const [ isLoading, setIsLoading ] = useState(true)

	useEffect(() => setIsLoading(true), [ fileId, pageNumber, scale ])

	const imgClassName = isLoading
		? 'absolute top-0 left-0 w-full opacity-0'
		: 'w-full'

	return (
		<div>
			<div className='relative shadow-sm'>
				<div style={{ paddingTop: isLoading ? '71%' : '0' }}></div>
				<PdfPageImage
					fileId={fileId}
					page={pageNumber}
					scale={scale}
					className={imgClassName}
					style={{ transition: isLoading ? '' : `opacity ${transitionDuration}ms` }}
					onLoad={() => setIsLoading(false)}
				/>
			</div>
		</div>
	)
}

