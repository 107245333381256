import { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { postProcessDocuments } from 'src/API/bom/mutations'
import { useUnreadNotifications } from 'src/API/notifications'
import { useTasksCache } from 'src/API/tasks'
import { Cms, FilePreview } from 'src/components'
import { SelectableFileTree } from 'src/components/file-tree/SelectableFileTree'
import { CONFIG } from 'src/cp.config'
import { File } from 'src/data'
import { MODULE } from 'src/data/Modules'
import { Project } from 'src/data/Project'
import { useUpdatedState } from 'src/hooks/state'
import { Button } from 'src/lib/button'
import { ContentWithCustomScrollbars, MainLayout, Top } from 'src/lib/layout'
import { notifyError } from 'src/lib/modals/notify'
import { Loader } from 'src/lib/loader'
import { BackToBomAnchor } from '../BackToBomAnchor'
import { StepTitle } from '../StepTitle'
import { FAQ_ENTRY_ID } from 'src/data/cms/Faq'

interface Props {
	project: Project
}

export const View = ({
	project
}: Props) => {

	const location = useLocation()
	const [ previewFile, setPreviewFile ] = useState<File | null>(null)
	const { data: tasks } = useTasksCache()
	const notifications = useUnreadNotifications()
	const isProcessDocsTaskInProgress = useMemo(() => !!tasks?.find(task => task.isBomProcessDocsStarted), [ tasks ])
	const [ isProcessingDocs, setIsProcessingDocs ] = useUpdatedState(isProcessDocsTaskInProgress)

	useEffect(() => {
		const hasFinishedProcessingDocs = notifications.find(n => n.isBomProcessDocsComplete)
		if (hasFinishedProcessingDocs)
			setIsProcessingDocs(false)
	}, [ notifications ]) //eslint-disable-line

	useEffect(() => setPreviewFile(null), [ location.pathname ])

	function handleProcessDocuments() {
		setIsProcessingDocs(true)
		postProcessDocuments(project.id)
			.catch(e => {
				notifyError(e)
				setIsProcessingDocs(false)
			})
	}


	return (
		<MainLayout
			top={(
				<Top
					left={(
						<div className='flex items-center max-w-full'>
							<BackToBomAnchor />
							<StepTitle>{CONFIG.BOM.PROCESS.TITLE}</StepTitle>
						</div>
					)}
					right={(
						<div className='flex items-center gap-6'>
							<Button.Contained
								intent='primary'
								onClick={handleProcessDocuments}
								className='disabled:bg-primary-darker'
								disabled={isProcessingDocs}>
								Process Selected Files
								{isProcessingDocs && <Loader.Circular width={16} strokeWidth={6} className='ml-1' />}
							</Button.Contained>
							<Cms.Faq id={FAQ_ENTRY_ID.BOM_PROCESS} />
						</div>
					)}
				/>
			)}
			content={(
				<ContentWithCustomScrollbars>
					<div className='pt-10'>
						<SelectableFileTree
							urlOnFileIdChange={(id, pid) => `/projects/${pid}/bom/process/${id}`}
							module={MODULE.BOM_GENERATION}
							onFileClick={setPreviewFile}
						/>
					</div>

					<div className='pt-6'>
						{previewFile && <FilePreview file={previewFile} />}
					</div>
				</ContentWithCustomScrollbars>
			)}
		/>
	)
}