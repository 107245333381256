import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFetchSelectedFiles } from "src/API/files";
import { useActiveProjectCache } from "src/API/projects";
import { ErrorPage } from "src/components";
import { FileLegend } from "src/components/file-legend";
import { LeftNav } from "src/components/sidebar";
import { useFilteredPagesContext } from "src/contexts/filtered-pages-ctx";
import { FileBase } from "src/data/File";
import { MODULE } from "src/data/Modules";
import { useFileIdFromParams } from "src/hooks/params";
import { MainLayout } from "src/lib/layout";
import { AsyncPage } from "src/lib/page";
import { usePageController } from "src/lib/render-pdf";
import { v4 } from "uuid";
import { RightBar } from "./RightBar";
import { View } from "./View";

const prefix = v4();

const Setup = () => {
	const { pdfPageMapWrapper, pageController } = usePageController(1);
	// const maxPages = 7;
	// const numPages = 50;
	// const scrollToPage = 1;
	const history = useHistory();
	const { fileId, totalPages } = useParams<{
		fileId?: string;
		totalPages?: string;
	}>();
	const {
		data: project,
		error: projectError,
		isValidating: isValidatingProject,
		revalidate: revalidateProject,
	} = useActiveProjectCache();
	const {
		filteredPages,
		setFilteredPages,
		selectedTags,
		setSelectedTags,
		selectedPage,
		setSelectedPage,
		pagesCtx,
		showLegend,
		setShowLegend,
	} = useFilteredPagesContext();
	const fileIdFromParams = useFileIdFromParams();
	const [pages, setPages] = useState(filteredPages)
	useEffect(()=>{
		setPages(filteredPages)
	},[filteredPages,selectedTags])
	const {
		data: selectedFiles = [],
		error: sfError,
		isValidating: isValidatingSf,
		revalidate: revalidateSf,
	} = useFetchSelectedFiles(project?.id, MODULE.BOM_GENERATION);
	const processedSelectedFiles = useMemo(() => selectedFiles.filter((file) => file.isBomProcessed), [selectedFiles]);

	const isModuleActive = !!project?.modules.active.includes(MODULE.BOM_GENERATION);

	const currentFile = useMemo(
		() => processedSelectedFiles?.find((file) => Number(fileId) === file.id && Number(totalPages) === file.totalPages),
		[fileId, processedSelectedFiles, totalPages]
	);
	console.log("CurrentFile: ", currentFile);
	// redirect to first selected file if not passed in URL params
	useEffect(() => {
		if (currentFile) return;
		if (!processedSelectedFiles?.length) return;
		history.replace(`${getBasePath(history)}/${processedSelectedFiles[0].id}/${processedSelectedFiles[0].totalPages}`);
	}, [processedSelectedFiles, currentFile, history]);
	if (!project) return null;
	return (
		<AsyncPage
			errors={[projectError, sfError]}
			retry={() => {
				revalidateProject();
				revalidateSf();
			}}
			isRetrying={isValidatingProject || isValidatingSf}
			isLoading={!project && !projectError}
		>
			<MainLayout
				left={<LeftNav />}
				right={<RightBar files={processedSelectedFiles} project={project} />}
				content={
					isModuleActive ? (
						<>
							{showLegend && (
								<FileLegend
									// maxPages={1}
									pdfPageMapWrapper={pdfPageMapWrapper}
									pageController={pageController}
									scrollToPage={selectedPage}
									onPageChange={pdfPageMapWrapper.onPageChange}
									file={currentFile}
									numPages={currentFile?.totalPages || 0}
									// pages={filteredPages.length > 0 ? filteredPages : pagesCtx}
									filteredPages={pages}
									fileId={fileIdFromParams}
									selectedPage={selectedPage}
									setSelectedPage={setSelectedPage}
									setShowLegend={setShowLegend}
								/>
							)}
							<View project={project} file={currentFile} key={prefix + currentFile?.id} />
						</>
					) : (
						<ErrorPage.ModuleNotActive />
					)
				}
			/>
		</AsyncPage>
	);
};

export default Setup;

function getBasePath(history: any) {
	const fullPath = history.location.pathname;
	const findI = fullPath.indexOf("find");
	return fullPath.substring(0, findI) + "find";
}
