import { useState, useEffect } from 'react';
import deepEqual from 'react-fast-compare'
import { useUpdatedRef } from '../refs';

export function useUpdatedState<T extends unknown>(stateFromProps: T, deepCompare: boolean = true) {

	const [ state, setState ] = useState<T>(stateFromProps)
	const stateRef = useUpdatedRef(state)

	useEffect(() => {
		if (deepCompare) {
			!deepEqual(stateRef.current, stateFromProps) && setState(stateFromProps)
		}
		else setState(stateFromProps)
	}, [ stateFromProps, deepCompare, stateRef ])

	return [ state, setState ] as const
}
