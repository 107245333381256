import { Anchor } from 'src/lib/button'
import { Cms, PageHead, ProjectIcon } from 'src/components'
import { Project } from 'src/data/Project'
import { Icon } from 'src/lib/icons'
import { ContentWithCustomScrollbars } from 'src/lib/layout'
import { Heading } from 'src/lib/text'
import { time } from 'src/helpers'
import { useProjectIdFromParams } from 'src/hooks/params'
import { CONFIG } from 'src/cp.config'
import { FileBase } from 'src/data/File'
import { FAQ_ENTRY_ID } from 'src/data/cms/Faq'
import { PAGE_CONTENT_ENTRY_ID } from 'src/data/cms/PageContent'
import { MODULE } from 'src/data/Modules'
import { Step } from './Step'
import { Steps } from './Steps'
import { Grid } from 'src/lib/grid'


interface Props {
	project: Project
	selectedFiles: FileBase[]
}

export const View = ({
	project,
	selectedFiles,
}: Props) => {

	const projectId = useProjectIdFromParams()


	return (
		<ContentWithCustomScrollbars>
			<PageHead
				title={(
					<>
						<ProjectIcon projectName={project.title} />
						<Heading tag='h2' className='whitespace-pre'>{project.title} / BOM / Overview</Heading>
						<Cms.Faq className='ml-auto' id={FAQ_ENTRY_ID.BOM_OVERVIEW} />
					</>
				)}
				metadata={[ {
					icon: <Icon icon='document' iconSize={14} />,
					count: project.metadata.bom.numberOfImportedFiles,
					text: `file${project.metadata.bom.numberOfImportedFiles > 1 ? 's' : ''}`
				}, {
					icon: <Icon icon='inbox-update' iconSize={14} />,
					count: project.bomFileSize.total,
					text: project.bomFileSize.unit

				}, {
					icon: <Icon icon='refresh' iconSize={14} />,
					text: time.ago(project.metadata.bom.importedOn)
				} ]}
				description={<Cms.PageContent id={PAGE_CONTENT_ENTRY_ID.BOM_OVERVIEW} />}
			/>

			<Steps className='gap-x-16 gap-y-7 mt-10'>
				<Grid.Line className='opacity-20' />

				<Step
					title={`1. ${CONFIG.BOM.PROCESS.TITLE}`}
					description={CONFIG.BOM.PROCESS.DESCRIPTION}
					stats={[
						{
							name: 'Selected files',
							value: `${selectedFiles.length}/${project.metadata.bom.numberOfImportedFiles}`
						}, {
							name: 'Processed files',
							value: `${project.metadata.bom.numberOfProcessedFilesWithinSelectedFiles}/${selectedFiles.length}`
						}
					]}
					link={{
						to: `/projects/${projectId}/bom/process/0`,
						disabled: !project.isEnabled.bom.process
					}}
				/>

				<Grid.Line className='opacity-20' />

				<Step
					title={`2. ${CONFIG.BOM.FIND.TITLE}`}
					description={CONFIG.BOM.FIND.DESCRIPTION}
					stats={[
						{
							name: 'Validated files',
							value: `${project.metadata.bom.numberOfValidatedTables}/${project.metadata.bom.numberOfTables}`
						}
					]}
					link={{
						to: `/projects/${projectId}/bom/find`,
						disabled: !project.isEnabled.bom.findTables
					}}
				/>

				<Grid.Line className='opacity-20' />

				<Step
					title={`3. ${CONFIG.BOM.REVIEW.TITLE}`}
					description={CONFIG.BOM.REVIEW.DESCRIPTION}
					stats={[
						{
							name: 'Reviewed tables',
							value: `${project.metadata.bom.numberOfReviewedTables}/${project.metadata.bom.numberOfValidatedTables}`
						}
					]}
					link={{
						to: `/projects/${projectId}/bom/review`,
						disabled: !project.isEnabled.bom.reviewTables
					}}
				/>


				{/* <div>
					<Heading tag='h3'>2. {CONFIG.BOM.FIND.TITLE}</Heading>
					<p>{CONFIG.BOM.FIND.DESCRIPTION.map(text => <div>{text}</div>)}</p>
					<div>Validated Tables: {project.metadata.bom.numberOfValidatedTables} / {project.metadata.bom.numberOfTables}</div>
					{selectedFiles.filter(file => file.isBomProcessed).map(file => (
						<div key={`file-${file.id}-${file.name}`}>
							{'=>'} <Anchor.Inline to={`/projects/${projectId}/bom/find/${file.id}/${file.totalPages}`}>{file.name} - {file.attributes.bomProcessedVersion}</Anchor.Inline>
						</div>
					))}
					<Anchor.Contained
						to={`/projects/${projectId}/bom/find`}
						disabled={!project.isEnabled.bom.findTables}>
						Start
					</Anchor.Contained>
				</div> */}

				{/* <div>
					<Heading tag='h3'>3. {CONFIG.BOM.REVIEW.TITLE}</Heading>
					<p>{CONFIG.BOM.REVIEW.DESCRIPTION.map(text => <div>{text}</div>)}</p>
					<div>Reviewed Tables: {project.metadata.bom.numberOfReviewedTables} / {project.metadata.bom.numberOfValidatedTables}</div>
					<Anchor.Contained
						to={`/projects/${projectId}/bom/review`}
						disabled={!project.isEnabled.bom.reviewTables}>
						Start
					</Anchor.Contained>
				</div> */}
			</Steps>
		</ContentWithCustomScrollbars>
	)
}

// [ {
// 	name: CONFIG.BOM.OVERVIEW.SHORT_TITLE,
// 	to: `/projects/${activeProject.id}/bom/overview`,
// 	disabled: !activeProject.modules.active.includes(MODULE.BOM_GENERATION)
// }, {
// 	name: CONFIG.BOM.PROCESS.SHORT_TITLE,
// 	to: `/projects/${activeProject.id}/bom/process/0`,
// 	disabled: !activeProject.modules.active.includes(MODULE.BOM_GENERATION)
// }, {
// 	name: CONFIG.BOM.FIND.SHORT_TITLE,
// 	to: `/projects/${activeProject.id}/bom/find`,
// 	disabled: !activeProject.metadata.bom.numberOfProcessedFiles
// }, {
// 	name: CONFIG.BOM.REVIEW.SHORT_TITLE,
// 	to: `/projects/${activeProject.id}/bom/review`,
// 	disabled: !activeProject.metadata.bom.numberOfValidatedTables
// } ]