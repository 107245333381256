import { Dispatch } from "react";
import { ITag } from "src/data/tag";
import { TagUpdateData } from "src/types/ui";
import TagEditItem from "./tag-edit-item";
import { FileBase } from "src/data/File";
import { Project } from "src/data/Project";

interface IProps {
  tags: ITag[];
  handleDeleteItem: Dispatch<ITag>;
  handleUpdateItem: Dispatch<TagUpdateData>;
  file: number | undefined
  page: number
  project: Project
}

const TagEditList = ({ tags, handleDeleteItem, handleUpdateItem, file, page, project}: IProps) => {
  return (
    <div className="flex-column">
      {tags.filter(_tag => _tag.pages.includes(page)).map((tag, idx) => (
        <TagEditItem
          key={idx}
          deleteItem={handleDeleteItem}
          tag={tag}
          updateItem={handleUpdateItem}
          className="mb-3"
          page={page}
          file={file}
          project={project}
        />
      ))}
    </div>
  );
};

export default TagEditList;
