import { MainLayout } from 'src/lib/layout';
import { LeftNav } from 'src/components/sidebar';
import { useActiveProjectCache } from 'src/API/projects';
import { ErrorPage } from 'src/components';
import { View } from './View';
import { AsyncPage } from 'src/lib/page';
import { MODULE } from 'src/data/Modules';

const Setup = () => {
  const { data: project, error, isValidating, revalidate } = useActiveProjectCache();

  const isModuleActive = !!project?.modules.active.includes(MODULE.BOM_GENERATION);

  if (!project) return null;

  return (
    <AsyncPage errors={[error]} retry={revalidate} isRetrying={isValidating} isLoading={!project}>
      <MainLayout
        left={<LeftNav />}
        content={isModuleActive ? <View project={project} /> : <ErrorPage.ModuleNotActive />}
      />
    </AsyncPage>
  );
};

export default Setup;
