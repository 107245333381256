import { cn } from 'src/helpers';
import { Icon } from 'src/lib/icons';
import { Text } from 'src/lib/text';
import { IAccordion } from 'src/types';

interface IProps extends IAccordion {
  expanded?: boolean;
  setExpend?: (e:any)=>void
}

const Header: React.FC<IProps> = ({ icon, iconColor, label, iconHeight, expanded, setExpend, className, iconWidth }) => {
  return (
    <div
      onClick={setExpend}
      className={cn`flex items-center justify-between w-full p-1 pl-4 pr-4 cursor-pointer bg-white-smoke ${className}`}
    >
      <div className="flex items-center justify-start">
        <Icon icon={icon} color={iconColor} width={iconWidth} height={iconHeight} />
        <Text className="ml-2 text-base font-normal text-slate-gray font-roboto" >
          {label}
        </Text>
      </div>
      <Icon icon='chevron-right' className={cn`
        ml-auto 
        w-4 
        transform
        transition-transform 
        ${expanded ? 'rotate-90' : ''}
      `} />
    </div>
  )
}

const Accordion = ({children, ...args }: IProps) => {
  return (
    <div className="flex flex-col flex-wrap w-full h-full">
      {/* header */}
      <Header {...args} />
      {/* content */}
      {children}
    </div>
  );
};

export default Accordion;
