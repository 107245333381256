import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ITag } from "src/data/tag";

interface IFilteredPagesContext {
  filteredPages: number[];
  setFilteredPages: Dispatch<SetStateAction<number[]>>;
  pagesCtx: number[];
  setPagesCtx: Dispatch<SetStateAction<number[]>>;
  selectedTags: ITag[];
  setSelectedTags: Dispatch<SetStateAction<ITag[]>>;
  selectedPage: number;
  setSelectedPage: Dispatch<SetStateAction<number>>;
  showLegend: boolean;
  setShowLegend: Dispatch<SetStateAction<boolean>>;
}

export const FilteredPagesContext = createContext<IFilteredPagesContext>({
  pagesCtx: [],
  setPagesCtx: () => null,
  filteredPages: [],
  setFilteredPages: () => null,
  selectedTags: [],
  setSelectedTags: () => null,
  selectedPage: 1,
  setSelectedPage: () => null,
  showLegend: false,
  setShowLegend: () => false,
});

export const FilteredPagesContextProvider = ({ children }: any) => {
  const [filteredPages, setFilteredPages] = useState<number[]>([]);
  const [pagesCtx, setPagesCtx] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(
    filteredPages.length > 0 ? filteredPages[0] : 1
  );
  const [showLegend, setShowLegend] = useState<boolean>(false);

  useEffect(()=>{
    if(filteredPages.length){
      setSelectedPage(filteredPages[0])
    }
  },[filteredPages])
  // memoized values
  const memoizedFilteredPages = useMemo(() => filteredPages, [filteredPages]);
  const memoizedPagesCtx = useMemo(() => pagesCtx, [pagesCtx]);
  const memoizedSelectedTags = useMemo(() => selectedTags, [selectedTags]);
  const memoizedSelectedPage = useMemo(() => selectedPage, [selectedPage]);
  const memoizedShowLegend = useMemo(() => showLegend, [showLegend]);
  return (
    <FilteredPagesContext.Provider
      value={{
        pagesCtx: memoizedPagesCtx,
        setPagesCtx,
        filteredPages: memoizedFilteredPages,
        setFilteredPages,
        selectedTags: memoizedSelectedTags,
        setSelectedTags,
        selectedPage: memoizedSelectedPage,
        setSelectedPage,
        showLegend: memoizedShowLegend,
        setShowLegend,
      }}
    >
      {children}
    </FilteredPagesContext.Provider>
  );
};

export function useFilteredPagesContext() {
  return useContext(FilteredPagesContext);
}
