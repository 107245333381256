import HotTable from '@handsontable/react'
import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react'
import { object } from 'src/helpers'
import { ExcelData } from '.'
import { Alignment, Format } from './types'
import { selection } from './utils'


function updateAlignment(attribute: { row: number; col: number; className?: string | string[] | undefined; }, alignment: Alignment) {
	let newCn = typeof attribute.className === 'string' ? attribute.className.split(' ') : (attribute.className || [])
	const horizontal = [ 'htCenter', 'htLeft', 'htRight', 'htJustify' ]
	const vertical = [ 'htTop', 'htBottom', 'htMiddle' ]
	if (horizontal.includes(alignment)) {
		newCn = newCn.filter(cn => !horizontal.includes(cn))
	}
	if (vertical.includes(alignment)) {
		newCn = newCn.filter(cn => !vertical.includes(cn))
	}
	newCn.push(alignment)
	return {
		...attribute,
		className: newCn.join(' '),
	}
}

function updateFormat(attribute: { row: number; col: number; className?: string | string[] | undefined; }, format: Format) {
	let newCn = typeof attribute.className === 'string' ? attribute.className.split(' ') : (attribute.className || [])
	if (newCn.includes(format)) {
		console.log('includes')
		newCn = newCn.filter(cn => cn !== format)
	}
	else
		newCn.push(format)
	return {
		...attribute,
		className: newCn.join(' '),
	}
}


export const useAttributes = (setState: Dispatch<SetStateAction<ExcelData>>, ref: MutableRefObject<HotTable | null>) => {

	const alignCells = useCallback((alignment: Alignment) => () => {
		const selectedCells = selection.getList(ref)
		if (!selectedCells) return
		setState(state => state.clone().setAttributes(
			state.attributes.map(attr => {
				const isSelected = !!selectedCells.find(object.match({ row: attr.row, col: attr.col }))
				return isSelected
					? { ...updateAlignment(attr, alignment) }
					: { ...attr }

			})
		))
	}, [ ref, setState ])

	const formatCells = useCallback((format: Format) => () => {
		const selectedCells = selection.getList(ref)
		if (!selectedCells) return
		setState(state => state.clone().setAttributes(
			state.attributes.map(attr => {
				const isSelected = !!selectedCells.find(object.match({ row: attr.row, col: attr.col }))
				return isSelected
					? { ...updateFormat(attr, format) }
					: { ...attr }

			})
		))
	}, [ ref, setState ])

	return { alignCells, formatCells }
}