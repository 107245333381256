import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useFetchTables } from "src/API/bom/fetchers";
import { postIsFindTableComplete } from "src/API/bom/mutations";
import { useSelectedFilesCache } from "src/API/files";
import { useActiveProjectCache } from "src/API/projects";
import { Divisor } from "src/components";
import { FileBase } from "src/data/File";
import { BOM_TABLE_STATUS } from "src/data/module.bom/BomTable";
import { MODULE } from "src/data/Modules";
import { Project } from "src/data/Project";
import { useUnmountEffect } from "src/hooks/effects";
import { useFileIdFromParams } from "src/hooks/params";
import { Anchor, Button } from "src/lib/button";
import { Show } from "src/lib/control-flow";
import { Form, Formik } from "src/lib/forms";
import { Grid } from "src/lib/grid";
import { Icon } from "src/lib/icons";
import { Right } from "src/lib/layout";
import { Loader } from "src/lib/loader";
import { notify, BaseTooltip } from "src/lib/modals";
import { Text } from "src/lib/text";
import { cn } from "src/helpers";
import { v4 } from "uuid";
import { postFileTag, useFetchProjectTags } from "src/API/tags";
import Tag from "src/components/tag/tag";
import { tagColorsMap, TagUpdateData } from "src/types/ui";
import { ITag, Tags } from "src/data/tag";
import { NewTag, TagEditList } from "src/components/tag";
import { Search } from "src/components/search";
import TagListView from "src/components/tag/tag-list-view";
import { FileLegend } from "src/components/file-legend";
import { useFilteredPagesContext } from "src/contexts/filtered-pages-ctx";
import { Resizable } from "re-resizable";
import rightStyles from "src/lib/layout/Right.module.css";
import { Children } from "src/types";
import ResizableAccordion from "src/components/resizable-accordion/resizable-accordion";
import { te } from "date-fns/locale";

const prefix = v4();

interface Props {
  project: Project;
  files: FileBase[];
}

export const RightBar = ({ project, files }: Props) => {
  const fileIdFromParams = useFileIdFromParams();
  const [, rerender] = useState({});
  const { revalidate, mutate } = useSelectedFilesCache(
    project.id,
    MODULE.BOM_GENERATION
  );
  const { revalidate: revalidateProject } = useActiveProjectCache();
  const [showCompletedFiles, setShowCompletedFiles] = useState(true);

  const { data, error, isValidating } = useFetchProjectTags({
    project_id: project.id.toString(),
    file_id: fileIdFromParams?.toString() || "",
  });
  const [appTags, setAppTags] = useState<ITag[]>([]);
  const [_selectedTags, _setSelectedTags] = useState<ITag[]>([]);
  const [search, setSearch] = useState<string>("");
  const [tagsEditView, setTagsEditView] = useState<boolean>(false);
  const {
    filteredPages,
    selectedTags,
    setSelectedTags,
    selectedPage,
    setSelectedPage,
    showLegend,
    setShowLegend,
  } = useFilteredPagesContext();

  useEffect(() => {
    if (!error && !isValidating) {
      setAppTags(data?.tags || []);
    }
  }, [
    data?.tags,
    error,
    isValidating
  ]);


  function handleStatusChange(file: FileBase, isComplete: boolean) {
    mutate(
      (files) =>
        files?.map((f) => {
          if (f.id !== file.id) return f;
          return new FileBase(file).setAttribute(
            "isFindTablesComplete",
            isComplete
          );
        }),
      false
    );
    postIsFindTableComplete(file.id, isComplete)
      .catch(() => {
        notify("danger")("Error: Failed to save the file status");
      })
      .finally(() => {
        revalidate();
        revalidateProject();
      });
  }

  useUnmountEffect(revalidateProject);
  const filteredFiles = useMemo(
    () =>
      files.filter((file) =>
        showCompletedFiles ? true : !file.attributes.isFindTablesComplete
      ),
    [files, showCompletedFiles]
  );

  const tagCloseHandler = async (tag: ITag) => {
    const addRequestResponse = await postFileTag({
      project_id: project.id,
      page:selectedPage,
      file_id: fileIdFromParams,
      tag: tag.tag,
      action:'delete'
    })
    if(addRequestResponse.code === 1){
      const _appTags = [...appTags];
      _appTags.filter((_tag) => _tag.tag === tag.tag);
      _appTags.forEach((_tag) => {
        if (_tag.tag === tag.tag) {
          _tag.pages = tag.pages.filter((page) => page !== selectedPage);
        }
      });
      setAppTags(_appTags);
      notify("success")(addRequestResponse.info);
    } 
    else {
      notify("danger")(addRequestResponse.reason);
    }
  };

  const addTagHandler = async (tag: string) => {
    const _appTags = [...appTags];
    const addRequestResponse = await postFileTag({
      project_id: project.id,
      page: selectedPage,
      file_id: fileIdFromParams,
      tag: tag,
      action: 'add',
    });
    if (addRequestResponse.code === 1) {
      const { data } = addRequestResponse;
      const hasTag: boolean = _appTags.some((_tag: ITag) => _tag.tag === addRequestResponse.new_tag);

      if (hasTag) {
        _appTags.forEach((_tag) => {
          if (_tag.tag === addRequestResponse.new_tag && !_tag.pages.includes(selectedPage)) {
            _tag.pages.push(selectedPage);
          }
        });
      } else {
        const newTag: ITag = { tag: `${addRequestResponse.new_tag}`, pages: [selectedPage], color: `#${data.color}` };
        _appTags.push(newTag);
      }

      setAppTags(_appTags);
      notify("success")(addRequestResponse.info);
    } else {
      notify("danger")(addRequestResponse.reason);
    }
  };

  const handleDeleteItem = (tag: ITag) => {
    const updatedTags = appTags.map((item) => {
      if (item.tag === tag.tag) {
        const updatedPages = item.pages.filter((page) => page !== selectedPage);
        return { ...item, pages: updatedPages };
      }
      return item;
    });

    const filteredTags = updatedTags.filter((item) => item.pages?.length);
    setAppTags(filteredTags);
  };

  const handleUpdateItem = async (updatedTag: TagUpdateData) => {
    const temp = [...appTags];
    const pages = updatedTag?.pages?.filter((p) => p === selectedPage) || [];
    if(pages.length) {
      const addRequestResponse = await postFileTag({ project_id: project.id, page: selectedPage, file_id: fileIdFromParams, tag: updatedTag.new, oldtag: updatedTag.old,  action:'edit'})
      if(addRequestResponse.code === 1){
        temp.forEach((tag) => {
          if (tag.tag === updatedTag.old) {
            tag.tag = updatedTag.new;
          }
        });
        setAppTags(temp);
        notify("success")(addRequestResponse.info);
      }
      else {
        notify("danger")(addRequestResponse.reason);
      }
    }
    else {
      notify("danger")(`Tag "${updatedTag?.old}" does not exist in current page!`);
    }
  };

  const filteredItems = appTags.filter((item) =>
    item.tag.toLowerCase().includes(search.toLowerCase())
  );

  filteredItems.sort((a, b) => {
    const aIndex = a.tag.toLowerCase().indexOf(search.toLowerCase());
    const bIndex = b.tag.toLowerCase().indexOf(search.toLowerCase());
    if (aIndex === bIndex) {
      return a.tag.localeCompare(b.tag);
    } else {
      return aIndex - bIndex;
    }
  });

  const selectedItems = appTags.map((tag) => {
      if ([...selectedTags].find((selectedTag) => selectedTag.tag === tag.tag && tag.pages.length))
        return { ...tag, selected: true };
      else return { ...tag, selected: false };
    }).sort((a: any, b: any) => b.selected - a.selected).filter(i=> i.pages.length)

  const selectedSearchItems =  filteredItems.filter((tag) =>
    tag.tag
      .toLowerCase()
      .includes(search.toLowerCase())
    )
    .map((tag) => {
      if ([...selectedTags].find((selectedTag) => selectedTag.tag === tag.tag )
      )
        return { ...tag, selected: true };
      else return { ...tag, selected: false };
    })
    .sort((a: any, b: any) => b.selected - a.selected).filter(i=> i.pages.length)

  return (
    <Right
      className="relative"
      onResizeStop={() => rerender({})}
      showToggleButton={false}
      resizable
    >
      <Scrollbars>
        <div className="px-3 py-3">
          <label className="flex items-center gap-2">
            <input
              className="custom_input-checkbox"
              type="checkbox"
              checked={showCompletedFiles}
              onChange={(e) => setShowCompletedFiles(e.target.checked)}
            />
            <small className="truncate">Show completed files</small>
          </label>
        </div>
        <div
          className="flex flex-wrap justify-between p-3 pb-0 overflow-hidden opacity-85"
          style={{ fontSize: "0.9375rem" }}
        >
          <div className="truncate">File</div>
          <div>Completed</div>
        </div>
        <Divisor className="mx-3 mt-1" />
        <ul className="flex flex-col py-3">
          {filteredFiles.map((file) => (
            <RenderFile
              isSelectedFile={file.id === fileIdFromParams}
              file={file}
              project={project}
              key={prefix + project.id + file.id}
              id={prefix + project.id + file.id}
              onStatusChange={handleStatusChange}
            />
          ))}
          {!filteredFiles.length && (
            <Text className="px-3" variant="info">
              <i>All files are completed.</i>
            </Text>
          )}
        </ul>
        <Divisor className="mx-3 mt-1" />
        {isValidating && appTags ? (
          <div className="p-3 animate-pulse">...Loading</div>
        ) : (
          <div className="flex-column">
              <ResizableAccordion icon="list" label="Explore files" >
                <div className="px-5 pt-4 pb-5">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Recusandae inventore id accusamus quod beatae molestiae,
                    voluptas soluta repudiandae
                  </div>
              </ResizableAccordion>
              <ResizableAccordion icon="list" label="Manage Tags" >
                <div className="px-5 pt-4">
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      lineHeight: "19px",
                    }}
                    className="flex items-center justify-between w-full mb-4"
                  >
                    Tags associated with current page:
                    {tagsEditView ? (
                      <span
                        className="cursor-pointer"
                        style={{
                          fontFamily: "'Open Sans'",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "22px",
                          color: "#63CAE1",
                        }}
                        onClick={(e) => setTagsEditView(!tagsEditView)}
                      >
                        CANCEL
                      </span>
                    ) : (
                      <Icon
                        className="cursor-pointer"
                        onClick={(e) => setTagsEditView(!tagsEditView)}
                        icon="edit"
                      />
                    )}
                  </p>
                  {tagsEditView ? (
                    <TagEditList
                      handleDeleteItem={handleDeleteItem}
                      handleUpdateItem={handleUpdateItem}
                      tags={appTags}
                      page={selectedPage}
                      project={project}
                      file={fileIdFromParams}
                    />
                  ) : (
                    <div className="flex flex-wrap">
                      {appTags
                        .filter((tag) =>
                          tag.pages.some((page) =>
                            [selectedPage].includes(page)
                          )
                        )
                        .map((tag) => (
                          <Tag
                            key={tag.tag}
                            tag={tag}
                            color={tag?.color || ''}
                            className="mb-3 ml-1"
                            close={tagCloseHandler}
                          />
                        ))}
                      {selectedPage && (
                        <NewTag
                          addHandler={addTagHandler}
                          className="mb-3 ml-1"
                        />
                      )}
                    </div>
                  )}
                </div>
                </ResizableAccordion>
                <ResizableAccordion icon="list" label="Filter Pages" >
                <div className="px-5 pt-4">
                  <Search setSearch={setSearch} />
                  <div className="w-full mt-4"></div>
                  <TagListView
                    className="mb-1"
                    tags={
                      search === ""
                        ? selectedItems
                        : selectedSearchItems
                    }
                    setSelected={setSelectedTags}
                  />
                </div>
              </ResizableAccordion>
          </div>
        )}
      </Scrollbars>
      {showLegend === false ? (
        <div className="flex items-center justify-center w-full p-2">
          <Icon
            className="cursor-pointer"
            icon="chevron-left-circle"
            width={30}
            height={30}
            color="#8B909A"
            onClick={(e) => setShowLegend(true)}
          />

          <span
            className="ml-2"
            style={{
              fontFamily: "'Open Sans'",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "19px",
              color: "#8B909A",
            }}
          >
            View page thumbnails
          </span>
        </div>
      ) : null}
    </Right>
  );
};

interface RenderFileProps {
  onStatusChange: (file: FileBase, isComplete: boolean) => void;
  project: Project;
  file: FileBase;
  id: string;
  className?: string;
  isSelectedFile: boolean;
}

const RenderFile = ({
  onStatusChange,
  project,
  file,
  id,
  className = "",
  isSelectedFile,
}: RenderFileProps) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [checked, setChecked] = useState(
    !!file.attributes.isFindTablesComplete
  );
  const fileIdFromParams = useFileIdFromParams();
  const { data: tables = [], isValidating } = useFetchTables(
    fileIdFromParams,
    project.id
  );

  useEffect(() => {
    setChecked(!!file.attributes.isFindTablesComplete);
  }, [file.attributes.isFindTablesComplete]);

  const verifiedTablesCount = useMemo(
    () =>
      tables.filter((t) => t.status.find === BOM_TABLE_STATUS.VERIFIED).length,
    [tables]
  );
  const nonRejectedTablesCount = useMemo(
    () =>
      tables.filter((t) => t.status.find !== BOM_TABLE_STATUS.REJECTED).length,
    [tables]
  );

  return (
    <li
      ref={ref}
      className={cn`flex items-center px-3 hover:bg-gray-100 relative max-w-full ${
        isSelectedFile && "bg-gray-100"
      } ${className}`}
    >
      <Show when={isSelectedFile}>
        <div className="absolute inset-0 w-1 bg-primary" />
      </Show>

      <BaseTooltip
        containerClassName="flex items-center grow overflow-hidden"
        content={
          <Show when={isSelectedFile} fallback={() => <div>{file.name}</div>}>
            <Grid.Root columns={2} growCols={[2]} className="gap-x-3">
              <strong className="whitespace-nowrap">File name</strong>
              <div>{file.name}</div>
              <strong className="whitespace-nowrap">Validated tables</strong>
              <div>{`${verifiedTablesCount}/${nonRejectedTablesCount}`}</div>
            </Grid.Root>
          </Show>
        }
      >
        <Anchor.Inline
          className={cn`
            justify-start
            flex-grow
            overflow-hidden
            no-underline
            ${isSelectedFile ? "text-primary-darker" : "text-default"}
        `}
          to={`/projects/${project.id}/bom/find/${file.id}/${file.totalPages}`}
        >
          <Icon icon="file-icon" />
          <div className="truncate">{file.name}</div>
          {isSelectedFile && (
            <div className="ml-auto mr-2 whitespace-nowrap">
              {isValidating && !tables.length ? (
                <Loader.Dots />
              ) : (
                `${verifiedTablesCount}/${nonRejectedTablesCount}`
              )}
            </div>
          )}
        </Anchor.Inline>
      </BaseTooltip>

      <Formik
        initialValues={{ isComplete: checked }}
        onSubmit={({ isComplete }) => onStatusChange(file, isComplete)}
      >
        {({ submitForm, setFieldValue }) => (
          <Form.Root className="py-2 group">
            <div className="relative flex-center ">
              <label className="absolute cursor-pointer h-7 w-7" htmlFor={id} />
              <Form.Checkbox
                className="z-10 group-hover:border-primary"
                id={id}
                name="isComplete"
                checked={checked}
                onChange={(e) => setIsConfirming(true)}
              />
            </div>
            {isConfirming && (
              <div
                className={cn`
                  fixed
                  text-sm
                  flex
                  items-center
                  justify-end
                  gap-2
                  pl-4
                  pr-1
                  right-0
                  z-20
                  bg-gray-100
                  shadow-medium
                  animate-pop-full-right
                  duration-1000
                  overflow-hidden
                `}
                style={{
                  top: ref.current?.getBoundingClientRect().top,
                  height: ref.current?.getBoundingClientRect().height,
                  minWidth: ref.current?.getBoundingClientRect().width,
                }}
              >
                <div className="absolute inset-y-0 left-0 w-1 bg-warning" />
                <div className="font-semibold opacity-75 whitespace-nowrap">
                  Mark file as {checked ? "not" : ""} done?
                </div>
                <div className="flex flex-row-reverse items-center">
                  <Button.Text
                    className="-mb-px px-1.5"
                    fontSize={12}
                    onClick={() => setIsConfirming(false)}
                  >
                    No
                  </Button.Text>
                  <Button.Text
                    className="-mb-px px-1.5 font-bold"
                    intent="warning"
                    fontSize={12}
                    onClick={() => {
                      setChecked(!checked);
                      setFieldValue("isComplete", !checked);
                      setIsConfirming(false);
                      submitForm();
                    }}
                  >
                    Yes
                  </Button.Text>
                </div>
              </div>
            )}
          </Form.Root>
        )}
      </Formik>
    </li>
  );
};
