import { MainLayout, Top } from 'src/lib/layout';
import { PageController, PdfPage, PdfPageMapWrapper, usePageController } from 'src/lib/render-pdf';
import { Divisor, Download, ZoomAndSpan } from 'src/components';
import { useZoomController, ZoomController } from 'src/lib/zoom-and-span';
import { Text } from 'src/lib/text';
import { Button } from 'src/lib/button';
import { useHistory } from 'react-router';
import { Icon } from 'src/lib/icons';
import { FilePath } from 'src/components/topbar/FilePath';
import { PdfPageMapWrapperWithMaxPages } from 'src/lib/render-pdf/PdfPageMapWrapperWithMaxPages';

const rootSelector = '.zoom_and_span_root > div'

interface Props {
	fileId: number
	fileName: string
	numPages: number
	projectId: number
	sourcePath: string
	initialPage: number
}

const View = ({
	fileId,
	fileName,
	numPages,
	projectId,
	sourcePath,
	initialPage,
}: Props) => {

	const history = useHistory()

	const { pdfPageMapWrapper, pageController } = usePageController(initialPage)
	const { zoomAndSpan, zoomController } = useZoomController(1)

	return (
		<MainLayout
			top={(
				<Top
					top={(
						<FilePath path={sourcePath} />
					)}
					left={(
						<div className='flex items-center max-w-full'>
							<Button.Icon onClick={history.goBack} title='Go back' className='bg-white' rounded><Icon icon='chevron' className='transform rotate-180' /></Button.Icon>
							<Text className='ml-3' title={fileName} truncate>{fileName}</Text>
						</div>
					)}
					right={(
						<div className='flex'>
							<ZoomController zoom={zoomController.zoom} onZoom={zoomController.onZoom} zoomStrength={1.1} />
							<Divisor className='self-center h-5 ml-8 mr-6 bg-gray-400 opacity-80' y />
							<PageController page={pageController.page} onScrollToPage={pageController.onScrollToPage} numPages={numPages} />
							<Divisor className='self-center h-5 ml-8 mr-5 bg-gray-400 opacity-80' y />
							<Download.PdfFile fileName={fileName} fileId={fileId}><Button.Disc><Icon icon='download' /></Button.Disc></Download.PdfFile>
						</div>
					)}
				/>
			)}
			content={(
				<ZoomAndSpan
					defaultZoom={zoomAndSpan.defaultZoom}
					onZoom={zoomAndSpan.onZoom}
					centerX={true}
					disableZoom={e => !e.ctrlKey}>

					<PdfPageMapWrapperWithMaxPages
						maxPages={18}
						onPageChange={pdfPageMapWrapper.onPageChange}
						fileId={fileId}
						numPages={numPages}
						intersectionObserverRootSelector={rootSelector}
						showPageNumber={true}
						scrollToPage={pdfPageMapWrapper.scrollToPage}
						map={(page, containerSize) => (
							<PdfPage fileId={fileId} page={page} containerSize={containerSize} />
						)}
					/>

				</ZoomAndSpan>
			)}
		/>
	)
}

export default View;