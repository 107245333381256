

const Setup = () => {



	return (
		<h2>No Dashboard yet...</h2>
	)
}

export default Setup;