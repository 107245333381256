import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Divisor, Download, ZoomAndSpan } from 'src/components';
import { Hotspot } from 'src/data/module.ark';
import { useZoomController, ZoomController } from 'src/lib/zoom-and-span';
import { Button } from 'src/lib/button';
import { Icon } from 'src/lib/icons';
import { MainLayout, Top } from 'src/lib/layout';
import { PdfPage, PdfSinglePageWrapper } from 'src/lib/render-pdf';
import { Text } from 'src/lib/text';
import Spot from './Spot';
import { Tooltip } from './Tooltip';
import { FilePath } from 'src/components/topbar/FilePath';
import { ShowAll } from './ShowAll';
import { ErrorPage } from 'src/lib/page';
import { getRotationClassName } from './utils'
import { Rotate } from './Rotate';


interface Props {
	contractorDocumentId: string
	fileName: string
	page: number
	projectId: number
	sheet: number
	sourcePath: string
	hotspots: Hotspot[]
}

const View = ({
	contractorDocumentId,
	fileName,
	page,
	projectId,
	sheet,
	sourcePath,
	hotspots
}: Props) => {

	const history = useHistory()
	const { zoomAndSpan, zoomController } = useZoomController(1)
	const [ showAll, setShowAll ] = useState(false)
	const [ error, setError ] = useState(false)
	const [ rotation, setRotation ] = useState(0)

	useEffect(() => setError(false), [ history.location ])


	return (
		<MainLayout
			top={(
				<Top
					top={(
						<FilePath path={sourcePath} />
					)}
					left={(
						<div className='flex items-center max-w-full'>
							<Button.Icon onClick={history.goBack} title='Go back' className='bg-white' rounded><Icon icon='chevron' className='transform rotate-180' /></Button.Icon>
							<Text className='ml-3' title={fileName} truncate>{contractorDocumentId}</Text>
						</div>
					)}
					right={(
						<div className='flex items-center flex-shrink-0'>
							<ShowAll label={'Show hotspots'} onToggle={setShowAll} hotkey='Control' />
							<Divisor className='h-5 self-center bg-gray-400 opacity-80 ml-8 mr-5' y />
							<Rotate onRotate={setRotation} defaultRotation={rotation} />
							<Divisor className='h-5 self-center bg-gray-400 opacity-80 ml-5 mr-5' y />
							<ZoomController onZoom={zoomController.onZoom} zoom={zoomController.zoom} zoomStrength={1.1} />
							<Divisor className='h-5 self-center bg-gray-400 opacity-80 ml-8 mr-5' y />
							<Download.PdfPage sourcePath={sourcePath} downloadName={`${contractorDocumentId}.sheet${sheet}.jpg`} page={page}><Button.Disc><Icon icon='download' /></Button.Disc></Download.PdfPage>
						</div>
					)}
				/>
			)}
			content={error
				? (
					<ErrorPage
						message='Document not found'
						backButton
					/>
				) : (
					<ZoomAndSpan
						defaultZoom={zoomAndSpan.defaultZoom}
						onZoom={zoomAndSpan.onZoom}
						centerX={true}
						centerY={true}
						key={`hotspot-${sourcePath}-${page}`}>

						<PdfSinglePageWrapper className={`p-12 ${getRotationClassName(rotation)}`}>
							<PdfPage sourcePath={sourcePath} page={page} onError={() => setError(true)}>
								{hotspots.map(hotspot => (
									<Spot
										show={showAll}
										hotspot={hotspot}
										key={hotspot.id + JSON.stringify(hotspot)}
										tooltip={<Tooltip hotspot={hotspot} />}
									/>
								))}
							</PdfPage>
						</PdfSinglePageWrapper>
					</ZoomAndSpan>
				)}
		/>
	)
}

export default View;

