import { AxiosError } from 'axios'
import { postDeselectFile, postSelectFile } from 'src/API/files'
import { File } from 'src/data'
import { MODULE } from 'src/data/Modules'
import { useProjectIdFromParams } from 'src/hooks/params'
import { useUpdatedState } from 'src/hooks/state'
import { notify } from 'src/lib/modals'




interface Props {
	file: File
	defaultChecked?: boolean
	onChange: (file: File, isChecked: boolean) => void
	module: MODULE
}

export const CheckboxSelectSingle = ({
	file,
	defaultChecked = false,
	onChange,
	module,
}: Props) => {

	const [ isChecked, setIsChecked ] = useUpdatedState(defaultChecked)

	const projectId = useProjectIdFromParams()

	function handleClick(e: React.MouseEvent<HTMLInputElement>) {
		const newIsChecked = e.currentTarget.checked
		setIsChecked(newIsChecked)
		onChange(file, newIsChecked)
		if (!projectId) return

		if (newIsChecked) {
			postSelectFile(projectId, file.id, module)
				.catch((e: AxiosError) => {
					setIsChecked(false)
					onChange(file, false)
					notify('danger')(e.response?.statusText || e.message)
				})
		}
		else {
			postDeselectFile(projectId, file.id, module)
				.catch((e: AxiosError) => {
					setIsChecked(true)
					onChange(file, true)
					notify('danger')(e.response?.statusText || e.message)
				})
		}
	}


	return (
		<label className='z-10 flex items-center px-3 cursor-pointer group' onClick={(e) => e.stopPropagation()} >
			<input
				type='checkbox'
				className='custom_input-checkbox cursor-pointer group-hover:border-primary-darker'
				onClick={handleClick}
				onChange={() => { }}
				checked={isChecked}
			/>
		</label>
	)
}