import * as Yup from 'yup'
import { Form as FormikForm } from 'formik'
import { ComponentProps } from 'react'
import Input from './Input'
import LabelledInput from './LabelledInput'
import Search from './Search'
import ErrorMessage from './ErrorMessage'
export { Yup }
export { Formik } from 'formik'

type Props = Omit<ComponentProps<typeof Input>, 'type'>
type LabelledProps = Omit<ComponentProps<typeof LabelledInput>, 'type'>

export const Form = {
	Root: FormikForm,
	Text: (props: Props) => <Input type='text' {...props} />,
	Email: (props: Props) => <Input type='email' {...props} />,
	Password: (props: Props) => <Input type='password' {...props} />,
	Number: (props: Props) => <Input type='number' {...props} />,
	Radio: (props: Props) => <Input type='radio' {...props} />,
	Checkbox: (props: Props) => <Input type='checkbox' {...props} />,
	Search: (props: ComponentProps<typeof Search>) => <Search {...props} />,
	Error: ErrorMessage,

	Labelled: {
		Text: (props: LabelledProps) => <LabelledInput type='text' {...props} />,
		Email: (props: LabelledProps) => <LabelledInput type='email' {...props} />,
		Password: (props: LabelledProps) => <LabelledInput type='password' {...props} />,
		Number: (props: LabelledProps) => <LabelledInput type='number' {...props} />,
		Radio: (props: LabelledProps) => <LabelledInput type='radio' {...props} />,
		Checkbox: (props: LabelledProps) => <LabelledInput type='checkbox' {...props} />,
		Search: (props: LabelledProps) => <LabelledInput type='search' {...props} />,
	}
}