import { tags } from "src/API/data";
export interface ApiTag {
  id: string;
  name: string;
  tags: ITag[];
}

export interface ITag {
  pages: number[];
  tag: string;
  color?: string;
}

export interface ITags {
  tags: ITag[];
}

export class Tags implements ITags {
  tags: ITag[];
  constructor(data: ITags | Tags) {
    this.tags = data.tags || [];
  }
  static fromApi(apiData: ApiTag[]): ITags {
    const data: ITags = {
      tags:apiData[0].tags,
    };
    return new Tags(data);
  }

  clone(): Tags {
    return new Tags(this);
  }
}
