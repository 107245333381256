import { useEffect, useState, useRef } from 'react';
import { useUpdatedRef } from 'src/hooks/refs';


const noop = () => { }

interface Config {
	updateOnly?: boolean
	onOnline?: () => any
	onOffline?: () => any
}

export function useOnlineStatus({
	updateOnly = true,
	onOnline = noop,
	onOffline = noop,
}: Config = {}) {

	const isFirstRenderRef = useRef(true)
	const onOnlineRef = useUpdatedRef(onOnline)
	const onOfflineRef = useUpdatedRef(onOffline)
	const [ onlineStatus, setOnlineStatus ] = useState(getOnlineStatus());

	useEffect(() => {
		const _onOnline = onOnlineRef.current
		const _onOffline = onOfflineRef.current

		const goOnline = () => {
			setOnlineStatus(true)
			if (updateOnly && isFirstRenderRef.current) return
			_onOnline()
		};
		const goOffline = () => {
			setOnlineStatus(false)
			_onOffline()
		};

		isFirstRenderRef.current = false

		window.addEventListener("online", goOnline);
		window.addEventListener("offline", goOffline);

		return () => {
			window.removeEventListener("online", goOnline);
			window.removeEventListener("offline", goOffline);
		};
	}, [ onOnlineRef, onOfflineRef, updateOnly ]);

	return onlineStatus;
}

function getOnlineStatus() {
	return typeof navigator !== "undefined" &&
		typeof navigator.onLine === "boolean"
		? navigator.onLine
		: true;
}


