import { default as Root } from './Menu'
import { default as MenuLink } from './Link'
import { default as MenuItem } from './Item'
import { default as MenuButton } from './Button'

export const Menu = {
	Root,
	Link: MenuLink,
	Item: MenuItem,
	Button: MenuButton,
}