import React from 'react';
import { Loader } from 'src/lib/loader'


interface FallbackProps {
	error: Error
	resetErrorBoundary: (...args: Array<unknown>) => void
	isRetrying?: boolean
}

const btnStyle = { marginRight: '20px' }

const ErrorFallback = ({
	error,
	resetErrorBoundary,
	isRetrying = false
}: FallbackProps) => {

	return (
		<div role="alert">
			<p>Something went wrong:</p>

			<pre>{error.message}</pre>

			<div className='flex items-center'>
				<button onClick={resetErrorBoundary} style={btnStyle}>Reload</button>

				{isRetrying && <Loader.Circular width={20} strokeWidth={5} />}
			</div>
		</div>
	)
}

export default ErrorFallback;