import { Dispatch, useEffect, useRef, useState } from "react";
import { ITag } from "src/data/tag";
import { Icon } from "src/lib/icons";
import { ContentWithCustomScrollbars } from "src/lib/layout";
import { TagUI, TagUpdateData, TagViewMode } from "src/types/ui";
import { useOnClickOutside, useInterval } from "usehooks-ts";
import { tagSuggestions } from "src/API/data";
import "./new-tag.css";
import { Button } from "src/lib/button";
import { notify } from "src/lib/modals";
import { cn } from "src/helpers";

interface IProps {
  addHandler?: (tag: string) => void;
  updateHandler?: (updatedTag: TagUpdateData) => void;
  className?: string;
  tagValue?: string;
  viewMode?: TagViewMode;
  viewModeEmitter?: Dispatch<TagViewMode>;
  color?: string
}

export const NewTag = ({
  className,
  addHandler,
  updateHandler,
  tagValue,
  viewMode,
  viewModeEmitter,
  color=''
}: IProps) => {
  const [mode, setMode] = useState<TagViewMode>(viewMode || "placeholder");
  const ref = useRef<HTMLInputElement>(null);
  const [coords, setCoords] = useState<{ x: number; y: number } | null>(null);
  const [isDetectingCoords, setIsDetectingCoords] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(tagValue || "");
  const handleClickOutside = () => {
    setIsDetectingCoords(false);
    setMode("placeholder");
  };
  const handleClickInside = (e: any) => {
    setMode("edit");
    setIsDetectingCoords(true);
  };
  useOnClickOutside(ref, handleClickOutside);
  const handleSearchKeysEvents = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      addTag();
      handleClickOutside();
    }
  };
  const addTag = (value: string | null = null) => {
    if (search.length === 0) {
      notify("danger")(`Tag name can't be empty!`);
      return;
    }
    if (addHandler) addHandler(!value ? search : value);
    if (updateHandler)
      updateHandler(
        !value
          ? { old: tagValue || "", new: search, color }
          : { old: tagValue || "", new: value, color }
      );
    setSearch("");
  };
  const updateCoords = () => {
    setCoords({
      x: ref.current?.getBoundingClientRect().x as number,
      y: ref.current?.getBoundingClientRect().y as number,
    });
  };
  const handleClose = (e: any) => {
    setSearch("");
    handleClickOutside();
    if (viewModeEmitter) viewModeEmitter("placeholder");
  };
  const handleCheck = (e: any) => {
    addTag();
    handleClickOutside();
    if (viewModeEmitter) viewModeEmitter("placeholder");
  };
  useInterval(() => updateCoords(), isDetectingCoords ? 10 : null);
  const filteredItems = tagSuggestions.filter((item) =>
    item.toLowerCase().includes(search.toLowerCase())
  );

  filteredItems.sort((a, b) => {
    const aIndex = a.toLowerCase().indexOf(search.toLowerCase());
    const bIndex = b.toLowerCase().indexOf(search.toLowerCase());
    if (aIndex === bIndex) {
      return a.localeCompare(b);
    } else {
      return aIndex - bIndex;
    }
  });
  return (
    <div className="inline-flex items-center justify-between" ref={ref}>
      <div
        onClick={handleClickInside}
        className={`relative max-w-fit flex justify-center items-center rounded-3xl pl-3 pr-3 pb-1 pt-1 ${className}`}
        style={{
          border: "1px dashed #CACACA",
          borderRadius: "50px",
        }}
      >
        {mode === "edit" ? (
          <div>
            <input
              autoFocus
              className="w-full h-full bg-transparent border-none focus:ring-transparent"
              type="text"
              style={{
                fontFamily: "'Roboto'",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16px",
                letterSpacing: "0.01em",
              }}
              onKeyDown={handleSearchKeysEvents}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
            />
            {search.length > 0 &&
              filteredItems
                .length > 0 && (
                <div
                  className="fixed z-50 w-full h-40 overflow-auto bg-white border rounded-lg tag-dropdown border-light-blue-border"
                  style={{
                    left: coords?.x,
                    right: coords?.x,
                    maxWidth: "13em",
                  }}
                >
                  {filteredItems.map((result) => (
                      <div
                        style={{
                          fontFamily: "'Roboto'",
                        }}
                        onClick={(e) => {
                          setSearch(result);
                        }}
                        key={result}
                        className="p-2 text-sm leading-4 tracking-widest cursor-pointer hover:font-weight-extrabold max-w-fit tag-dropdown-item"
                      >
                        {result} 
                      </div>
                    ))}
                </div>
              )}
          </div>
        ) : (
          <div
            style={{
              fontFamily: "'Roboto'",
            }}
            className="text-sm italic leading-4 tracking-widest text-tag-gray"
          >
            Add tag to current page
          </div>
        )}
      </div>
      {mode === "edit" && (
        <div
          className={cn`inline-flex p-1 ml-1 h-9 ${className} `}
          style={{
            background: "#D9D9D9",
            borderRadius: "5px",
          }}
        >
          {/* Icons */}
          <button
            className="px-1 py-1 mr-1 bg-white button"
            onClick={handleCheck}
          >
            <Icon icon="check-green" width={16} height={16} />
          </button>
          <button className="px-1 py-1 bg-white button" onClick={handleClose}>
            <Icon icon="close-red" width={16} height={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default NewTag;
