const abbreviationsDictionary = {
	"AN": "Audit Notification",
	"BG": "Budget",
	"CA": "Corrective Action",
	"CH": "Change Request",
	"CK": "Checklist",
	"CL": "Calculation",
	"CR": "Certificate",
	"CS": "Concession Request",
	"AR": "Agreement‐Contract",
	"DN": "Design Change Note",
	"DR": "Deviation Request",
	"DS": "Datasheet",
	"DW": "Drawing",
	"ET": "Estimate",
	"FC": "Flowchart",
	"FM": "Form/Template",
	"GN": "General",
	"LS": "Lists",
	"MN": "Manual",
	"MP": "Map",
	"ML": "Multimedia",
	"MM": "Minute of Meeting",
	"MO": "Model",
	"MT": "Material Take Off",
	"MX": "Matrix",
	"NC": "Non Conformance",
	"NI": "Notification of Inspection",
	"PL": "Plan",
	"PR": "Procedure",
	"PO": "Proposal",
	"RC": "Record",
	"RQ": "Requisition",
	"RL": "Register",
	"RP": "Report Philosophy Basis of Design",
	"SC": "Site Technical Query",
	"SH": "Schedule Planning",
	"SI": "Site Instruction",
	"SK": "Sketches",
	"SP": "Specification",
	"SQ": "Site Query",
	"ST": "Study",
	"TC": "Technical Clarification",
	"TN": "Technical Note",
	"TQ": "Technical Query Request",
	"TB": "Technical Bid Evaluation"
} as const

export type DocumentTypeAbbreviation = keyof typeof abbreviationsDictionary | null

const all: DocumentTypeAbbreviation[] = Object.keys(abbreviationsDictionary) as DocumentTypeAbbreviation[]
const manuals: DocumentTypeAbbreviation[] = [ 'FM', 'LS', 'MN', 'MT', 'PR', 'SI' ]
const reports: DocumentTypeAbbreviation[] = [ 'BG', 'CL', 'AR', 'ET', 'FC', 'MM', 'MO', 'NC', 'PL', 'PO', 'RP', 'SC', 'SH', 'SQ', 'ST', 'TC', 'TN', 'TB' ]
const documents: DocumentTypeAbbreviation[] = [ 'AN', 'CA', 'CH', 'CK', 'CR', 'CS', 'DN', 'DR', 'FM', 'GN', 'MP', 'MX', 'NC', 'NI', 'PR', 'PO', 'RC', 'RQ', 'RL', 'SC', 'SI', 'SQ', 'TQ' ]
const specifications: DocumentTypeAbbreviation[] = [ 'CL', 'DN', 'DS', 'ET', 'FC', 'FM', 'LS', 'MP', 'MO', 'MT', 'NC', 'PL', 'PR', 'RP', 'SH', 'SK', 'SP', 'ST', 'TC', 'TN' ]
const drawings: DocumentTypeAbbreviation[] = [ 'CL', 'DW', 'SK' ]
const photos: DocumentTypeAbbreviation[] = [ 'FC', 'ML' ]

export const docTypesDictionary = {
	all,
	manuals,
	reports,
	documents,
	specifications,
	drawings,
	photos,
}

export type DocType = keyof typeof docTypesDictionary

export const docTypes: DocType[] = Object.keys(docTypesDictionary) as DocType[]