import { Icon } from 'src/lib/icons';
import { PageHead } from 'src/components';
import { Project } from 'src/data/Project';
import { Anchor } from 'src/lib/button';
import { ContentWithCustomScrollbars } from 'src/lib/layout';
import { Heading } from 'src/lib/text';
import { getFileSizeAndUnit } from 'src/helpers';
import NoProject from './NoProject';
import ProjectCard from './ProjectCard';

interface Props {
  projects: Project[];
  isValidating: boolean;
}

const Page = ({ projects, isValidating }: Props) => {
  const totalNumberOfImportedFiles = projects.reduce((count, p) => count + p.metadata.numberOfImportedFiles, 0);

  const totalFileSizeInBytes = projects.reduce((count, p) => count + p.metadata.totalFileSize, 0);
  const [totalFileSize, unit] = getFileSizeAndUnit(totalFileSizeInBytes);

  return (
    <ContentWithCustomScrollbars>
      <PageHead
        title={
          <Heading tag="h1" className="whitespace-pre">
            Projects
          </Heading>
        }
        action={
          <Anchor.Contained to="/projects/new" intent="primary">
            New Project
          </Anchor.Contained>
        }
        metadata={[
          {
            icon: <Icon icon="layers" iconSize={14} />,
            count: projects.length,
            text: projects.length > 1 ? 'projects' : 'project',
          },
          {
            icon: <Icon icon="document" iconSize={14} />,
            count: totalNumberOfImportedFiles,
            text: 1521 > 1 ? 'files' : 'file',
          },
          {
            icon: <Icon icon="inbox-update" iconSize={14} />,
            count: totalFileSize,
            text: unit,
          },
        ]}
        description={
          <div className="col-6 pt-1 pb-2">
            All the projects your are a member of.
            <br />
            Projects contain files, modules and members.
          </div>
        }
      />

      {projects.map((project) => (
        <ProjectCard project={project} key={`project-card-${project.id}`} />
      ))}

      {!isValidating && !projects.length && <NoProject />}
    </ContentWithCustomScrollbars>
  );
};

export default Page;
