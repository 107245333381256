
interface BaseApiPnid {
	contractorDocumentID: string
	contractorName: string | null
	drawingRecord_id: number | null
	drawingTitle: string
	facilityName: string | null
	fileRecord_id: number
	pageNumber: number
	pageRecord_id: number | null
	revision: string | null
	revisionDate: string | null
	sheet: number | undefined | null
}

interface ApiPnidEquipmentsAndInstruments {
	tag: string,
	equipmentRecord_id: unknown,
	pnidRecord_id: unknown,
	str_box: unknown
}

/************************************************************************************ */


export interface ApiPnidByPageRecordId extends BaseApiPnid {
	"equipments": ApiPnidEquipmentsAndInstruments[],
	"instruments": ApiPnidEquipmentsAndInstruments[],
	"related": any[]
}

interface IPnidByPageRecordId {
	title: ApiPnidByPageRecordId[ 'drawingTitle' ]
	contractorDocumentId: ApiPnidByPageRecordId[ 'contractorDocumentID' ]
	facilityName: ApiPnidByPageRecordId[ 'facilityName' ]
	contractorName: ApiPnidByPageRecordId[ 'contractorName' ]
	revisionDate: ApiPnidByPageRecordId[ 'revisionDate' ]
	sheet: number
	pageNumber: number
	fileRecordId: number
}

export class PnidByPageRecordId implements IPnidByPageRecordId {
	title: ApiPnidByPageRecordId[ 'drawingTitle' ]
	contractorDocumentId: ApiPnidByPageRecordId[ 'contractorDocumentID' ]
	facilityName: ApiPnidByPageRecordId[ 'facilityName' ]
	contractorName: ApiPnidByPageRecordId[ 'contractorName' ]
	revisionDate: ApiPnidByPageRecordId[ 'revisionDate' ]
	sheet: number
	pageNumber: number
	fileRecordId: number


	constructor(data: IPnidByPageRecordId | PnidByPageRecordId) {
		this.title = data.title
		this.contractorDocumentId = data.contractorDocumentId
		this.facilityName = data.facilityName
		this.contractorName = data.contractorName
		this.revisionDate = data.revisionDate
		this.sheet = data.sheet
		this.pageNumber = data.pageNumber
		this.fileRecordId = data.fileRecordId
	}

	static fromApi(apiData: ApiPnidByPageRecordId | BaseApiPnid): PnidByPageRecordId {
		const data: IPnidByPageRecordId = {
			title: apiData[ 'drawingTitle' ],
			contractorDocumentId: apiData[ 'contractorDocumentID' ] || '',
			facilityName: apiData[ 'facilityName' ],
			contractorName: apiData[ 'contractorName' ],
			revisionDate: apiData[ 'revisionDate' ],
			sheet: apiData[ 'sheet' ] || 1,
			fileRecordId: apiData[ 'fileRecord_id' ],
			pageNumber: apiData[ 'pageNumber' ],
		}
		return new PnidByPageRecordId(data)
	}
}

/************************************************************************************ */

export interface ApiPnidByOpc extends BaseApiPnid {
	createDate: string
	modifyDate: string
	sourcePath: string
}

interface IPnidByOpc {
	contractorDocumentId: string
	contractorName: string
	createDate: string
	drawingRecordId: number
	drawingTitle: string
	facilityName: string
	fileRecordId: number
	modifyDate: string
	pageNumber: number
	pageRecordId: number
	revision: string
	revisionDate: string
	sheet: number
	sourcePath: string
}

export class PnidByOpc implements IPnidByOpc {
	contractorDocumentId: string
	contractorName: string
	createDate: string
	drawingRecordId: number
	drawingTitle: string
	facilityName: string
	fileRecordId: number
	modifyDate: string
	pageNumber: number
	pageRecordId: number
	revision: string
	revisionDate: string
	sheet: number
	sourcePath: string

	constructor(data: IPnidByOpc | PnidByOpc) {
		this.contractorDocumentId = data.contractorDocumentId
		this.contractorName = data.contractorName
		this.createDate = data.createDate
		this.drawingRecordId = data.drawingRecordId
		this.drawingTitle = data.drawingTitle
		this.facilityName = data.facilityName
		this.fileRecordId = data.fileRecordId
		this.modifyDate = data.modifyDate
		this.pageNumber = data.pageNumber
		this.pageRecordId = data.pageRecordId
		this.revision = data.revision
		this.revisionDate = data.revisionDate
		this.sheet = data.sheet
		this.sourcePath = data.sourcePath
	}

	static fromApi(apiData: ApiPnidByOpc): PnidByOpc {
		const data: IPnidByOpc = {
			contractorDocumentId: apiData.contractorDocumentID,
			contractorName: apiData.contractorName || '',
			createDate: apiData.createDate,
			drawingRecordId: apiData.drawingRecord_id || 0,
			drawingTitle: apiData.drawingTitle,
			facilityName: apiData.facilityName || '',
			fileRecordId: apiData.fileRecord_id,
			modifyDate: apiData.modifyDate,
			pageNumber: apiData.pageNumber,
			pageRecordId: apiData.pageRecord_id || 0,
			revision: apiData.revision || '',
			revisionDate: apiData.revisionDate || '',
			sheet: apiData.sheet || 1,
			sourcePath: apiData.sourcePath,
		}
		return new PnidByOpc(data)
	}
}
