import { MutableRefObject, useEffect, useRef } from 'react';
import { NormalizedBounds } from 'src/types';
import { dom } from 'src/helpers';

export function reorderBounds(bounds: NormalizedBounds): NormalizedBounds {
	return {
		minX: Math.min(bounds.minX, bounds.maxX),
		minY: Math.min(bounds.minY, bounds.maxY),
		maxX: Math.max(bounds.minX, bounds.maxX),
		maxY: Math.max(bounds.minY, bounds.maxY),
	}
}

export const useParentRef = (ref: MutableRefObject<HTMLDivElement | null>) => {
	const parentRef = useRef<HTMLElement | null>(null)

	useEffect(() => {
		if (!ref.current) return
		parentRef.current = dom.getClosestPositionnedParent(ref.current)
		if (parentRef.current)
			parentRef.current.draggable = false
	})

	return parentRef
}