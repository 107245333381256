import style from './View.module.css'
import { useEffect, useState } from 'react';
import { useFetchArkSearch } from 'src/API/ark_search';
import { Project } from 'src/data/Project';
import { ArkSearchRequest, Result as ArkSearchResult, docTypes } from 'src/data/module.ark';
import { Button } from 'src/lib/button';
import { Form, Formik } from 'src/lib/forms'
import { useSetStateInUrl } from 'src/hooks/state';
import { ContentWithCustomScrollbars } from 'src/lib/layout';
import { Heading, Text } from 'src/lib/text';
import { Divisor, PageHead, ProjectIcon, Cms } from 'src/components';
import useIsHeaderScrolled from './useIsHeaderScrolled'
import Pager from './Pager'
import Thumbnail from './Thumbnail';
import { Icon } from 'src/lib/icons';
import ResultView from './Result';
import { ErrorPage } from 'src/components';
import { Loader } from 'src/lib/loader';
import { cn, time } from 'src/helpers';
import { CONFIG } from 'src/cp.config';
import { FAQ_ENTRY_ID } from 'src/data/cms/Faq';
import { PAGE_CONTENT_ENTRY_ID } from 'src/data/cms/PageContent';


const maxPageSize = CONFIG.ARK_SEARCH_RESULTS_PER_PAGE

const GRID_CN = {
	ROOT: 'grid grid-cols-12 gap-x-12',
	LEFT: 'col-span-7',
	RIGHT: 'col-span-5',
}


const defaultSearch = (pid: number): ArkSearchRequest => new ArkSearchRequest({
	keyword: '',
	pid,
	pageIndex: 0,
	pageSize: maxPageSize,
	fragmentSize: 47,
	docType: 'all',
	latest: true
})

interface Props {
	project: Project
}

const Page = ({
	project
}: Props) => {

	const [ thumbPreviewResult, setThumbPreviewResult ] = useState<ArkSearchResult | undefined>()
	const [ isScrolled, headerRef ] = useIsHeaderScrolled()
	const [ search, setSearch ] = useSetStateInUrl(defaultSearch(project.id))
	const { data: response, isValidating, error } = useFetchArkSearch(search)

	useEffect(reset, [ response ])

	function showDocType(docType: ArkSearchRequest[ 'docType' ]) {
		return () => setSearch({ docType, pageIndex: 0 })
	}

	function gotToPageIndex(pageIndex: number) {
		return () => setSearch({ pageIndex })
	}

	function reset() {
		setThumbPreviewResult(response?.results[ 0 ])
		document.querySelector('.main_layout-content > div')?.scrollTo({ top: 0 })
	}

	const hasSearchBeenPerformed = () => response || isValidating || search?.keyword

	if (!project.metadata.ark.numberOfImportedFiles)
		return <ErrorPage.NoImportedFile />

	return (
		<ContentWithCustomScrollbars className='flex-col' xlarge>

			<div className={cn`${style.header} pb-4 ${GRID_CN.ROOT} ${isScrolled && style.scrolled_header}`} ref={headerRef}>
				<div className={`${GRID_CN.LEFT} flex-col`}>

					<PageHead
						title={(
							<>
								<ProjectIcon projectName={project.title} />
								<Heading tag='h2' className='whitespace-pre'>{project.title} / ARK Search</Heading>
							</>
						)}
						metadata={!hasSearchBeenPerformed() && [ {
							icon: <Icon icon='document' iconSize={14} />,
							count: project.metadata.ark.numberOfImportedFiles,
							text: `file${project.metadata.ark.numberOfImportedFiles > 1 ? 's' : ''}`
						}, {
							icon: <Icon icon='inbox-update' iconSize={14} />,
							count: project.arkFileSize.total,
							text: project.arkFileSize.unit

						}, {
							icon: <Icon icon='refresh' iconSize={14} />,
							text: time.ago(project.metadata.ark.importedOn)
						} ]}
						description={!hasSearchBeenPerformed() && <Cms.PageContent id={PAGE_CONTENT_ENTRY_ID.ARK} />}
					/>

					<Formik
						//the page will remount on URL change, 
						//this allows PREV page from the browser to change the initialValues
						initialValues={{
							keyword: search.keyword,
							latestOnly: true
						}}
						onSubmit={({ keyword, latestOnly }) => {
							setSearch({
								keyword,
								latest: latestOnly,
								pageIndex: 0
							})
						}}>

						{({ submitForm }) =>
							<Form.Root className={cn`w-full ${hasSearchBeenPerformed() && 'mt-8'}`}>
								<Form.Search wrapperClassName='w-full' inputClassName='leading-10 pl-4' iconMarginRight={2} iconSize={18} name='keyword' autoComplete='off' autoFocus rounded />
								{/* latest only is always true */}
								{/* this element is therefore hidden */}
								<Form.Labelled.Checkbox label='Latest only' name='latestOnly' onChangeCapture={submitForm} checked style={{ position: 'absolute', pointerEvents: 'none', opacity: 0 }} />
							</Form.Root>
						}
					</Formik>


					<div className="flex items-center mt-4">
						{docTypes.map(_docType => (
							<Button.Inline
								className={`${style.filter_button} capitalize hover:no-underline ${search.docType === _docType && style.active_filter_button}`}
								onClick={showDocType(_docType)}
								fontSize={14}
								key={`ark-filter-${_docType}`}>
								{_docType}
							</Button.Inline>
						))}
					</div>
				</div>
				<div className={`${GRID_CN.RIGHT} flex-col`}>
					<div className='flex justify-end'><Cms.Faq id={FAQ_ENTRY_ID.ARK} /></div>
				</div>

				<Divisor className={`my-0 ${GRID_CN.LEFT}`} />

				{hasSearchBeenPerformed() && (<>


					<div className={`${GRID_CN.ROOT} absolute top-full pointer-events-none w-full`}>
						<div className={GRID_CN.LEFT} />
						<div className={`${GRID_CN.RIGHT} flex-col mt-9`}>
							{thumbPreviewResult && (
								<Thumbnail
									className='bg-light1 w-full shadow rad-5 px-4 py-4 pointer-events-auto'
									result={thumbPreviewResult}
									projectId={project.id}
								/>
							)}
						</div>
					</div>
				</>)}
			</div>

			{hasSearchBeenPerformed() && (
				<div className='fadein-300'>
					{isValidating && (
						<div className='flex items-center' style={{ height: '20px' }}>
							<Loader.Dots width={27} />
						</div>
					)}

					{error && <Text variant='error' smaller>
						{error.response?.status || error.name}: {error.response?.data.reason || error.message}
					</Text>}

					{response && (
						<>
							{!isValidating && (
								<Text variant='info' className='' smaller>
									{!!search.pageIndex && `Page ${search.pageIndex + 1} of `}{response?.totalHits} results ({response?.elapsed.toFixed(2)} seconds)
								</Text>
							)}

							<div className={GRID_CN.ROOT}>
								<div className={`${GRID_CN.LEFT} flex-col`}>

									{response.results.map((res, i) => (
										<ResultView
											className='my-4 w-full'
											isPreview={thumbPreviewResult === res}
											onClickPreview={setThumbPreviewResult}
											result={res}
											resultNumber={search.pageIndex * search.pageSize + i + 1}
											key={`${res.latest}${res.fileRecordId}${i}`}
										/>
									))}

									<Pager
										currentPageIndex={search.pageIndex}
										maxPageSize={maxPageSize}
										results={response.results}
										totalHits={response.totalHits}
										onClickPrev={gotToPageIndex(search.pageIndex - 1)}
										onClickNext={gotToPageIndex(search.pageIndex + 1)}
										onClickPage={pageNumber => gotToPageIndex(pageNumber - 1)()}
									/>
								</div>

								<div className={`${GRID_CN.RIGHT} flex-col mt-4`} /** placeholder for the thumbnail */ />
							</div>
						</>
					)}


				</div>
			)}

		</ContentWithCustomScrollbars >
	)
}

export default Page;

