import './ProjectCard.css'
import React from 'react';
import { Project } from 'src/data/Project';
import { ProjectIcon } from 'src/components';
import { Heading, Text } from 'src/lib/text';
import { Link } from 'react-router-dom';
import { cn } from 'src/helpers';
import { Grid } from 'src/lib/grid';
import { Modules } from 'src/data/Modules';

interface Props extends React.ComponentProps<'div'> {
	project: Project
}

const ProjectCard = ({
	project,
	className = ''
}: Props) => {


	return (
		<Grid.Root
			columns={3}
			growCols={[ 2 ]}
			className={cn`
				bg-white
				shadow
				//border
				//border-border
				rounded
				pt-2.5
				pr-6
				pb-2.5
				pl-3.5
				mb-4
				${className}
			`}>
			<Grid.Col className='flex items-center'>
				<Link to={`/projects/${project.id}/files/0`}>
					<ProjectIcon projectName={project.title} />
				</Link>
			</Grid.Col>

			<Grid.Col className='flex flex-col justify-center pr-6'>
				<Link className='hover:underline' to={`/projects/${project.id}/files/0`}><Heading>{project.title}</Heading></Link>
				<Text opacity={0.9}>{project.description}</Text>
			</Grid.Col>

			<Grid.Col className='flex items-center justify-end flex-wrap gap-1.5' maxWidth={200}>
				{project.modules.active
					.map(module => (
						<div className='project_card-active_module' title={Modules.getName(module)} key={`${project.id}-am-${Modules.getNickname(module)}`}>
							<Text opacity={0.7} smaller inline>
								<strong className='whitespace-nowrap'>{Modules.getNickname(module)}</strong>
							</Text>
						</div>
					))}
			</Grid.Col>
		</Grid.Root>
	)
}

export default ProjectCard;
