import { useEffect, useRef } from 'react'

export function usePrevious<T extends unknown>(state: T): T | undefined
export function usePrevious<T extends unknown>(state: T, defaultState: T): T

export function usePrevious<T extends unknown>(state: T, defaultState?: T): T | undefined {

	const previousRef = useRef(defaultState)

	useEffect(() => {
		previousRef.current = state
	}, [ state ])

	return previousRef.current
}