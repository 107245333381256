import { range } from './range'
import { last } from './last'
import { sortBy } from './sortBy'
import { replace } from './replace'
import { insert } from './insert'

export const array = {
	range,
	last,
	sortBy,
	replace,
	insert,
}