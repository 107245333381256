import styles from './styles.module.css'
import React from 'react';
import { Link } from 'react-router-dom';
import { Children } from 'src/types';
import { cn } from 'src/helpers';


interface Props {
	children?: Children
	className?: string
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	style?: React.CSSProperties
	to: string
	disabled?: boolean
}

const MenuLink = ({
	children = null,
	className = '',
	style = {},
	disabled = false,
	...props
}: Props) => {



	return (
		<li>
			<Link className={cn`${styles.link} ${className} ${disabled && 'disabled'}`} style={style} {...props} tabIndex={disabled ? -1 : 0}>
				{children}
			</Link>
		</li>
	)
}

export default MenuLink;