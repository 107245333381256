export function getFormData<T extends {}>(form: HTMLFormElement | undefined) {
	if (!form || !form.elements) return

	let res: any = {}

	const elements = Array.from(form.elements)

	elements.forEach((el: any) => {
		let { type, name, value, checked } = el

		if (type === 'checkbox') {
			res[ name ] = checked
		}
		else if (type === 'radio') {
			if (checked)
				res[ name ] = value
		}
		else {
			res[ name ] = checked = value
		}
	})

	return res as T
}
