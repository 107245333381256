import axios from 'axios'
import useSWR, { Key, cache } from 'swr'
import { SWRConfiguration, Fetcher } from 'swr/dist/types'


export const useSwrCache = <Data = any, Error = any>(key: Key, fn?: Fetcher<Data>, config: Omit<SWRConfiguration<Data, Error>, 'initialData'> = {}) => {

	const _config = {
		...config,
		initialData: cache.get(key)
	}

	return useSWR(key, fn || null, _config)
}

export const swrKey = {

	fetchTableData(tableId: string | number) {
		return [ 'cpf/v1/generate_bom/table/review/data/', tableId + '' ]
	}

}