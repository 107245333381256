import { useEffect, useRef, useState } from 'react'
import { useUpdatedRef } from 'src/hooks/refs'
import useGetIsMounted from '../useGetIsMounted'

/**
 * returns a boolean indicating whether the delay has passed or not
 * the timeout is triggered only if ALL deps are truthy
 * 
 * @param delay: number
 * @param deps: DependencyList
 * @returns isTimeoutElapsed: boolean
 */
export function useTimeout(delay: number, deps: React.DependencyList = []) {

	const getIsMounted = useGetIsMounted()
	const tokenRef = useRef<NodeJS.Timeout>()
	const delayRef = useUpdatedRef(delay)
	const [ isTimeoutElapsed, setIsTimeoutElapsed ] = useState(delay === 0)

	useEffect(() => {
		//All deps should evalute to true, otherwise clearTimeout and exit
		if (!deps.every(dep => !!dep)) {
			tokenRef.current && clearTimeout(tokenRef.current)
			return
		}
		if (!delayRef.current) return
		setIsTimeoutElapsed(false)
		tokenRef.current = setTimeout(() => getIsMounted() && setIsTimeoutElapsed(true), delayRef.current)
	}, deps) //eslint-disable-line

	return isTimeoutElapsed
}