import { CSSProperties, useEffect, useRef, useState } from 'react'
import { useGlobalMouseMove, useGlobalMouseUp } from 'src/hooks/listeners'
import { useUpdatedState } from 'src/hooks/state'
import { Children, Coordinates, NormalizedBounds } from 'src/types'
import { cn, dom } from 'src/helpers'
import { useParentRef } from './utils'


interface Props {
	children: Children
	className: string
	bounds: NormalizedBounds
	borderWidth: number
	borderColor: string
	backgroundColor: string
	disabled: boolean
	onMoveStart: () => void
	onMoveEnd: (bounds: NormalizedBounds) => void
}

export const Rect = ({
	children = null,
	className = '',
	bounds: boundsFromProps,
	borderWidth,
	borderColor,
	backgroundColor,
	disabled,
	onMoveStart,
	onMoveEnd,
}: Props) => {

	const [ bounds, setBounds ] = useUpdatedState(boundsFromProps)
	const [ canMove, setCanMove ] = useState(false)
	const ref = useRef<HTMLDivElement | null>(null)
	const parentRef = useParentRef(ref)
	const startCoordRef = useRef<Coordinates | null>(null) //normalized coordinates
	const hasMoveStartedRef = useRef(false)

	const style: CSSProperties = {
		top: `calc(${bounds.minY * 100 + '%'} - ${borderWidth}px)`,
		left: `calc(${bounds.minX * 100 + '%'} - ${borderWidth}px)`,
		right: `calc(${100 - bounds.maxX * 100 + '%'} - ${borderWidth}px)`,
		bottom: `calc(${100 - bounds.maxY * 100 + '%'} - ${borderWidth}px)`,
		borderStyle: 'solid',
		boxSizing: 'border-box',
		borderWidth,
		borderColor,
		backgroundColor,
	}

	useEffect(() => {
		disabled && setCanMove(false)
	}, [ disabled ])

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		const parent = parentRef.current
		if (disabled || !parent) return
		setCanMove(true)
		startCoordRef.current = {
			x: dom.getRelativeMousePosition(parent)(e).x / parent.clientWidth,
			y: dom.getRelativeMousePosition(parent)(e).y / parent.clientHeight,
		}
	}

	useGlobalMouseMove(e => {
		const rect = ref.current
		const parent = parentRef.current
		const startCoord = startCoordRef.current
		if (!rect || !parent || !canMove || !startCoord) return
		if (!hasMoveStartedRef.current) onMoveStart()
		hasMoveStartedRef.current = true

		const currentCoord = {
			x: dom.getRelativeMousePosition(parent)(e).x / parent.clientWidth,
			y: dom.getRelativeMousePosition(parent)(e).y / parent.clientHeight,
		}

		const translateX = currentCoord.x - startCoord.x
		const minTranslateX = -boundsFromProps.minX
		const maxTranslateX = 1 - boundsFromProps.maxX
		const translateY = currentCoord.y - startCoord.y
		const minTranslateY = -boundsFromProps.minY
		const maxTranslateY = 1 - boundsFromProps.maxY

		setBounds({
			minX: boundsFromProps.minX + Math.min(Math.max(translateX, minTranslateX), maxTranslateX),
			maxX: boundsFromProps.maxX + Math.min(Math.max(translateX, minTranslateX), maxTranslateX),
			minY: boundsFromProps.minY + Math.min(Math.max(translateY, minTranslateY), maxTranslateY),
			maxY: boundsFromProps.maxY + Math.min(Math.max(translateY, minTranslateY), maxTranslateY),
		})
	})

	useGlobalMouseUp(() => {
		if (!canMove) return
		setCanMove(false)
		hasMoveStartedRef.current = false
		onMoveEnd(bounds)
		startCoordRef.current = null
	})

	return (
		<div
			ref={ref}
			onMouseDown={handleMouseDown}
			className={cn`absolute ${!disabled && 'cursor-move'} ${className}`}
			style={style}
			draggable={false}>
			{children}
		</div>
	)
}