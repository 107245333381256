import { useRef } from 'react'
import { useGlobalResize } from '.'

export const useGlobalResizeDebounced = (listener: (e: UIEvent) => void, delay: number = 200) => {

	const tokenRef = useRef<NodeJS.Timeout>()

	useGlobalResize(e => {
		tokenRef.current && clearTimeout(tokenRef.current)
		tokenRef.current = setTimeout(() => listener(e), delay)
	})
}