import { CSSProperties, ReactEventHandler, useState } from 'react'
import { usePageSrc } from 'src/API/_urls'
import { useUpdateEffect } from 'src/hooks/effects'
import { Show } from 'src/lib/control-flow'
import { Loader } from 'src/lib/loader'
import { cn } from 'src/helpers'


const noop = () => { }

interface Props {
	className?: string
	fileId: string | number | undefined
	onError?: ReactEventHandler<HTMLImageElement>
	onLoad?: ReactEventHandler<HTMLImageElement>
	page: number
	scale?: number
	style?: CSSProperties
	hidePreloader?: boolean
}

export const PdfPageImage = ({
	className = '',
	fileId,
	onError = noop,
	onLoad = noop,
	page,
	scale,
	style = {},
	hidePreloader = false
}: Props) => {

	const [ isLoading, setIsLoading ] = useState(true)
	const [ isError, setIsError ] = useState<boolean>(false)
	const src = usePageSrc({ fileId, page, scale })

	useUpdateEffect(() => {
		setIsLoading(true)
		setIsError(false)
	}, [ fileId, page ])

	if (!src) return null

	if (isError)
		return <em>Error loading the page. Please try again later</em>

	return (
		<>
			<img
				src={src}
				alt={`Page ${page}`}
				className={cn`${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity ${className}`}
				style={style}
				onLoad={(...args) => {
					setIsLoading(false)
					onLoad(...args)
					setIsError(false)
				}}
				onError={(...args) => {
					setIsLoading(false)
					onError(...args)
					setIsError(true)
				}}
			/>
			<Show when={isLoading && !hidePreloader}>
				<div style={{ animationDelay: '150ms', animationDuration: '1000ms' }} className='inline-block text-primary absolute-center opacity-0 animate-fadein-full-slow'>
					<Loader.Cool />
				</div>
			</Show>
		</>
	)
}
