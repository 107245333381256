import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { postBlacklistRefreshToken, refreshAccessToken } from 'src/API/token'
import { Token } from 'src/data/Token'
import { cache } from 'swr'
import { useUpdatedRef } from './refs'
import { useOnlineStatus } from 'src/hooks/listeners';
import { useIdleTimer } from 'react-idle-timer/dist/modern'
import { CONFIG } from 'src/cp.config'


interface Config {
	onLogin: (token: Token) => void
	onLogout: () => void
	onRefresh: () => void
}

export const useLogin = ({
	onLogin,
	onLogout,
	onRefresh,
}: Config) => {
	const onLoginRef = useUpdatedRef(onLogin)
	const onLogoutRef = useUpdatedRef(onLogout)

	const history = useHistory()
	const [ isLogin, setIsLogin ] = useState(false)
	const [ token, setToken ] = useState<Token | null>(Token.getStore())
	const isLoggedIn = useMemo(() => !!token, [ token ])


	const login = useCallback((token: Token) => {
		setToken(token)
		Token.setStore(token)
	}, [])

	const logout = useCallback(() => {
		const token = Token.getStore()
		token && postBlacklistRefreshToken(token.refresh)
		setToken(null)
		Token.setStore(null)
		cache.clear()
		if (!history.location.pathname.startsWith('/login'))
			history.push('/login')
	}, [ history ])

	useRefreshAccessToken({
		token,
		login,
		logout,
		onRefresh
	})

	useEffect(() => {
		const token = Token.getStore()
		if (isLoggedIn && token) {
			onLoginRef.current(token)
			setIsLogin(true)
		}
		else {
			onLogoutRef.current()
			setIsLogin(false)
		}
	}, [ isLoggedIn, onLoginRef, onLogoutRef ])

	return { isLogin, login, logout, token }
}


interface RefreshConfig {
	token: Token | null
	login: Config[ 'onLogin' ]
	logout: Config[ 'onLogout' ]
	onRefresh: Config[ 'onRefresh' ]
}

function useRefreshAccessToken({
	token,
	login,
	logout,
	onRefresh
}: RefreshConfig) {

	const { refreshNow, cancelNextRefresh, refreshInMs, logoutRef } = useRefreshAccessTokenUtils(login, logout, onRefresh)

	useOnlineStatus({
		onOnline: refreshNow,
		onOffline: cancelNextRefresh,
	});

	useIdleTimer({
		timeout: CONFIG.IDLE_TIME,
		onActive: refreshNow,
		onIdle: cancelNextRefresh,
	})

	useEffect(() => {
		if (token) {
			refreshInMs(token.accessExpiresInMs - CONFIG.REFRESH_ACCESS_BEFORE_EXPIRATION_TIME)
		}
		else {
			cancelNextRefresh()
			logoutRef.current()
		}
	}, [ token, refreshNow, logoutRef, cancelNextRefresh, refreshInMs ])
}


function useRefreshAccessTokenUtils(
	login: RefreshConfig[ 'login' ],
	logout: RefreshConfig[ 'logout' ],
	onRefresh: RefreshConfig[ 'onRefresh' ],
) {
	const cancelTokenRef = useRef<NodeJS.Timeout>()
	const loginRef = useUpdatedRef(login)
	const logoutRef = useUpdatedRef(logout)
	const onRefreshRef = useUpdatedRef(onRefresh)


	const refreshNow = useCallback(() => {
		console.log('REFRESH NOW')
		refreshAccessToken()
			.then(token => {
				loginRef.current(token)
				onRefreshRef.current()
			})
			.catch((e: AxiosError) => {
				if (!e.isAxiosError)
					return
				logoutRef.current()
			})
	}, [ loginRef, logoutRef, onRefreshRef ])


	const cancelNextRefresh = useCallback(() => {
		cancelTokenRef.current && clearTimeout(cancelTokenRef.current)
	}, [])


	const refreshInMs = useCallback((ms: number) => {
		cancelNextRefresh()
		cancelTokenRef.current = setTimeout(refreshNow, ms)
	}, [ refreshNow, cancelNextRefresh ])

	return { refreshNow, cancelNextRefresh, refreshInMs, logoutRef }
}
