import { iconMap } from 'src/lib/icons/iconMap'
import { time } from 'src/helpers';

export type NotificationType = 'info' | 'warning' | 'error' | 'success'

enum SYSTEM {
	UNDEFINED = 0,
	FILE_SYNC = 1,
	BOM_TAG_SPIR = 2,
	BOM_FIND_TABLE = 3,
	BOM_PROCESS_DOCUMENTS = 4
}

enum STATUS {
	INACTIVE = 0,
	STARTED = 1,
	COMPLETE = 2,
	INPROGRESS = 3,
	CANCEL = 4
}

export interface ApiNotification {
	project: string;
	system: SYSTEM;
	status: STATUS;
	task_user: string;
	level: NotificationType;
	timestamp: string;
	// message: string;
}

interface INotification {
	projectName: string;
	system: SYSTEM;
	status: STATUS;
	triggeredByUsername: string;
	type: NotificationType;
	timestamp: string;
	// message: string;
}

export class Notification implements INotification {
	projectName: string;
	system: SYSTEM;
	status: STATUS;
	triggeredByUsername: string;
	type: NotificationType;
	timestamp: string;
	// message: string;

	constructor(data: INotification | Notification) {
		this.projectName = data.projectName
		this.system = data.system
		this.status = data.status
		this.triggeredByUsername = data.triggeredByUsername
		this.type = data.type
		this.timestamp = data.timestamp
		// this.message = data.message
	}

	static fromApi(apiData: ApiNotification): Notification {
		const data: INotification = {
			projectName: apiData.project,
			system: apiData.system,
			status: apiData.status,
			triggeredByUsername: apiData.task_user,
			type: apiData.level,
			timestamp: apiData.timestamp,
			// message: apiData.message
		}

		return new Notification(data)
	}

	get icon() {
		const dictionary: Record<NotificationType, keyof typeof iconMap> = {
			error: 'error',
			info: 'info-sign',
			success: 'tick-circle-contained',
			warning: 'warning-sign',
		}
		return dictionary[ this.type ] as keyof typeof iconMap
	}

	get iconClassName(): string {
		const dictionary: Record<NotificationType, string> = {
			success: 'text-success',
			error: 'text-danger',
			info: 'text-primary',
			warning: 'text-warning',
		}
		return dictionary[ this.type ]
	}

	get background(): string {
		const dictionary: Record<NotificationType, string> = {
			success: '#B8F6B9',
			error: '#F9E1E1',
			info: '#C7F0F8',
			warning: '#FEEFCB',
		}
		return dictionary[ this.type ]
	}

	get age(): string {
		return time.ago(this.timestamp)
	}

	get text(): string | null {
		if (this.system === SYSTEM.BOM_PROCESS_DOCUMENTS)
			return this._bomProcessDocumentText
		if (this.system === SYSTEM.FILE_SYNC)
			return this._fileSyncText
		return null
	}

	get isBomProcessDocsComplete() {
		return this.system === SYSTEM.BOM_PROCESS_DOCUMENTS && this.status === STATUS.COMPLETE
	}

	get isBomProcessDocsStarted() {
		return this.system === SYSTEM.BOM_PROCESS_DOCUMENTS && this.status === STATUS.STARTED
	}

	get isFileSyncStarted() {
		return this.system === SYSTEM.FILE_SYNC && this.status === STATUS.STARTED
	}

	get isFileSyncComplete() {
		return this.system === SYSTEM.FILE_SYNC && this.status === STATUS.COMPLETE
	}

	private get _bomProcessDocumentText(): string | null {
		switch (this.status) {
			case STATUS.STARTED:
				return `Process Document task was started by ${this.triggeredByUsername}.`
			case STATUS.COMPLETE:
				return 'Process Document task has been completed.'
			case STATUS.CANCEL:
				return 'Process Document task was cancelled.'
			default:
				return null
		}
	}

	private get _fileSyncText(): string | null {
		switch (this.status) {
			case STATUS.STARTED:
				return `File Sync task was started by ${this.triggeredByUsername}.`
			case STATUS.COMPLETE:
				return 'File Sync task has been completed'
			default:
				return null
		}
	}
}
