import { useEffect } from 'react';
import { useUpdatedRef } from 'src/hooks/refs';

type Cb = (e: WheelEvent) => void

const options: AddEventListenerOptions = { passive: false }

export const useGlobalWheel = (cb: Cb) => {

	const cbRef = useUpdatedRef(cb)

	useEffect(() => {
		const callback = (e: WheelEvent) => cbRef.current(e)

		window.addEventListener('wheel', callback, options)

		return () => window.removeEventListener('wheel', callback, options)
	}, [ cbRef ])
}

export default useGlobalWheel;