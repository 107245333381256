import { useEffect, useRef, useState } from 'react'
import { useOnToggleKeyBinding } from 'src/hooks/key-binding'
import { Button } from 'src/lib/button'
import { Form, Formik } from 'src/lib/forms'
import { Icon } from 'src/lib/icons'
import { math } from 'src/helpers'
import { Tooltip } from '../modals'


interface Props {
	page: number
	onScrollToPage: (newZoom: number) => void
	numPages: number
}

export const PageController = (props: Props) => {

	const tokenRef = useRef<NodeJS.Timeout>()
	const [ inputVal, setInputVal ] = useState(props.page + '')
	const [ page, setPage ] = useState(props.page)

	useEffect(() => setPage(props.page), [ props.page ])
	useEffect(() => setInputVal(page + ''), [ page ]) //eslint-disable-line


	function handleSubmit() {
		if (!inputVal) return
		const _page = parseFloat(inputVal)
		if (_page > props.numPages) return setInputVal(page + '') //do nothing 
		setPage(_page)
		tokenRef.current && clearTimeout(tokenRef.current)
		tokenRef.current = setTimeout(() => props.onScrollToPage(_page), 350)
	}

	function handleNextPage() {
		const _page = page + 1
		if (_page > props.numPages) return
		setPage(_page)
		tokenRef.current && clearTimeout(tokenRef.current)
		tokenRef.current = setTimeout(() => props.onScrollToPage(_page), 350)
	}

	function handlePrevPage() {
		const _page = page - 1
		if (_page <= 0) return
		setPage(_page)
		tokenRef.current && clearTimeout(tokenRef.current)
		tokenRef.current = setTimeout(() => props.onScrollToPage(_page), 350)
	}

	useOnToggleKeyBinding('ArrowUp', isDown => isDown && handlePrevPage())
	useOnToggleKeyBinding('ArrowDown', isDown => isDown && handleNextPage())

	useEffect(() => {
		const listener = (e: KeyboardEvent) => [ 'ArrowUp', 'ArrowDown' ].includes(e.key) && e.preventDefault()
		document.addEventListener('keydown', listener)
		return () => document.removeEventListener('keydown', listener)
	}, [])

	return (
		<div className='flex h-full'>
			<Tooltip text='Previous page' shortcut='Arrow up' padding={8}>
				<Button.Disc onClick={handlePrevPage} className='-mr-1' rounded><Icon icon='arrow-up' /></Button.Disc>
			</Tooltip>
			<Tooltip text='Next page' shortcut='Arrow down' padding={8}>
				<Button.Disc onClick={handleNextPage} rounded><Icon icon='arrow-up' className='transform rotate-180' /></Button.Disc>
			</Tooltip>
			<Formik initialValues={{ page }} onSubmit={handleSubmit}>
				<Form.Root className='flex items-center' noValidate={true}>
					<Form.Labelled.Number
						name='page'
						label={<div className='whitespace-nowrap'>{`/ ${props.numPages}`}</div>}
						value={inputVal + ''}
						onChange={e => setInputVal(e.target.value)}
						onFocus={e => e.target.select()}
						className='leading-7 text-sm text-gray-800'
						inputStyle={{ fontSize: 'inherit', lineHeight: 'inherit' }}
						maxDigits={math.clamp<1 | 2 | 3 | 4 | 5>(2, props.numPages.toString().length, 5)}
					/>
				</Form.Root>
			</Formik>
		</div>
	)
}