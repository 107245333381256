import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Children, Size } from "src/types";
import { array, dom } from "src/helpers";
import { v4 } from "uuid";
import { useScrollToPage } from "./useScrollToPage";
import { useStateDebounced } from "src/hooks/state";

const keyPrefix = v4();
const optimalWidth = 1500;

const noop = () => {};

export interface PdfPageMapWrapperProps {
  fileId: number;
  numPages: number;
  pages?: number[];
  containerSize?: Size | null;
  map: (page: number, pageContainerSize: Size | null) => Children;
  intersectionObserverRootSelector: string;
  rootMargin?: string;
  showPageNumber?: boolean;
  loadDelay?: number;
  slotAspectRatio?: [number, number];
  onVisiblePagesChange?: (visiblePages: Set<number>) => void;
  onPageChange?: (page: number) => void;
  scrollToPage?: string | number;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  beforePagesChildren?: Children;
  afterPagesChildren?: Children;
  selectedPage?: number | undefined;
  width?: number | string;
  height?: number | string;
  marginTop?: number | string;
  setSelectedPage?: Dispatch<SetStateAction<number>>;
}

const PdfPageMapWrapper = ({
  width,
  height,
  marginTop,
  setSelectedPage,
  fileId,
  numPages,
  pages,
  map,
  intersectionObserverRootSelector: rootSelector,
  rootMargin = "400px 9999px",
  showPageNumber = true,
  loadDelay = 100,
  slotAspectRatio = [3, 2],
  onVisiblePagesChange = noop,
  onPageChange = noop,
  onClick = noop,
  onMouseDown = noop,
  scrollToPage,
  beforePagesChildren = null,
  afterPagesChildren = null,
  selectedPage,
}: PdfPageMapWrapperProps) => {
  const defaultPages = useMemo(() => {
    return array.range(1, numPages);
  }, [numPages]); //eslint-disable-line

  useScrollToPage(scrollToPage, pages);
  useCurrentPage(fileId, pages, (page) => onPageChange(page));
  const visiblePages = useVisiblePages(
    fileId,
    rootSelector,
    rootMargin,
    loadDelay,
    pages
  );
  const slotPaddingTop = `${(slotAspectRatio[1] / slotAspectRatio[0]) * 100}%`;

  useEffect(() => onVisiblePagesChange?.(visiblePages), [visiblePages]); //eslint-disable-line

  return (
    <div
      className="max-w-full flex flex-col items-center"
      style={{
        width: `${optimalWidth}px`,
        transform: "translateZ(0px)",
      }}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {beforePagesChildren}
      {(pages || defaultPages).map((page) => (
        <div
          className="pdf-page-wrapper relative w-full mb-4"
          id={`pdf-page-${page}`}
          data-page={page}
          style={{ paddingTop: slotPaddingTop, transform: "translateZ(0px)", marginTop: marginTop}}
          key={`${keyPrefix}-${page}-${fileId}`}
        >
          {visiblePages.has(page) && (
            <PageContainer
              width={width}
              height={height}
              marginTop={marginTop}
              setSelectedPage={setSelectedPage}
              page={page}
              showPageNumber={showPageNumber}
              renderFn={map}
              selectedPage={selectedPage as number}
            />
          )}
        </div>
      ))}

      {afterPagesChildren}
    </div>
  );
};

export default PdfPageMapWrapper;

interface PageContainerProps {
  width?: number | string;
  height?: number | string;
  marginTop?: number | string;
  setSelectedPage?: Dispatch<SetStateAction<number>>;
  showPageNumber: boolean;
  page: number;
  renderFn: PdfPageMapWrapperProps["map"];
  selectedPage: number;
}

function PageContainer({
  width,
  height,
  marginTop,
  setSelectedPage,
  showPageNumber,
  page,
  selectedPage,
  renderFn,
}: PageContainerProps) {
  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerSize, setContainerSize] = useStateDebounced<Size | null>(
    null,
    100
  );

  useEffect(() => {
    const target = pageContainerRef.current;
    if (!target) return;
    const resizeObs = new ResizeObserver(() => {
      setContainerSize(getPageContainerSize(pageContainerRef));
    });
    resizeObs.observe(target);
    return () => {
      resizeObs.unobserve(target);
    };
  }, [page, showPageNumber, setContainerSize]);

  return (
    <div
      ref={pageContainerRef}
      id={`pdf-page-container-${page}`}
      className="absolute inset-0 flex flex-col items-center pt-4"
    >
      <div
        style={{
          border: page === selectedPage ? "4px solid #3CABC4" : "none",
          width: width,
          height: height,
        }}
        className="flex items-end flex-grow flex-shrink h-px w-full"
        onClick={() => (setSelectedPage ? setSelectedPage(page) : null)}
      >
        {renderFn(page, containerSize)}
      </div>

      {showPageNumber && (
        <div className="flex-shrink-0 py-3 text-lg">{page}</div>
      )}
    </div>
  );
}

function useCurrentPage(
  fileId: number,
  pages: number[] | undefined,
  onPageChange?: (page: number) => void
) {
  const threshold = [0.2, 0.4, 0.6, 0.8, 1];
  const intersectionRatiosRef = useRef<{ page: number; ratio: number }[]>([]);
  const currentPageRef = useRef<number>(1);

  useEffect(() => {
    intersectionRatiosRef.current = [];
    const observer = new IntersectionObserver(listener, { threshold });

    function listener(entries: IntersectionObserverEntry[]) {
      entries.forEach((e) => {
        const target = dom.getEventTarget(e);
        const page = Number(target.dataset.page);

        updateintersectionRatios(page, e.intersectionRatio);
        handleCurrentPageChange();
      });
    }

    const elements = document.querySelectorAll(".pdf-page-wrapper");

    elements.forEach((el) => observer.observe(el));

    return () => elements.forEach((el) => observer.unobserve(el));
  }, [fileId, pages]); //eslint-disable-line

  function updateintersectionRatios(page: number, ratio: number) {
    intersectionRatiosRef.current = intersectionRatiosRef.current.filter(
      (entry) => entry.page !== page
    );
    if (ratio >= threshold[0])
      intersectionRatiosRef.current = [
        ...intersectionRatiosRef.current,
        { page, ratio },
      ];
  }

  function handleCurrentPageChange() {
    const currentPage = findCurrentScrolledPage();
    if (currentPage && currentPage !== currentPageRef.current) {
      currentPageRef.current = currentPage;
      onPageChange?.(currentPageRef.current);
    }
  }

  function findCurrentScrolledPage(): number | undefined {
    const maxEntry = intersectionRatiosRef.current.reduce(
      (currentMaxRatioEntry, entry) => {
        if (entry.ratio < currentMaxRatioEntry.ratio)
          return currentMaxRatioEntry;
        if (entry.ratio > currentMaxRatioEntry.ratio) return entry;
        return entry.page < currentMaxRatioEntry.page
          ? entry
          : currentMaxRatioEntry;
      },
      intersectionRatiosRef.current[0]
    );

    return maxEntry?.page;
  }

  return currentPageRef;
}

function useVisiblePages(
  fileId: number,
  rootSelector: string,
  rootMargin: string,
  loadDelay: number,
  pages?: number[]
) {
  const vpRef = useRef(new Set<number>());
  const [visiblePages, setVisiblePages] = useState(new Set<number>());
  const tokenRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    vpRef.current = new Set<number>();

    const threshold = 0.01;
    const root = document.querySelector(rootSelector);
    const observer = new IntersectionObserver(listener, {
      root,
      threshold,
      rootMargin,
    });

    function listener(entries: IntersectionObserverEntry[]) {
      entries.forEach((e) => {
        const target = dom.getEventTarget(e);
        const page = Number(target.dataset.page);
        tokenRef.current && clearTimeout(tokenRef.current);

        e.intersectionRatio > threshold
          ? vpRef.current.add(page)
          : vpRef.current.size > 1 && vpRef.current.delete(page); //Check if size > 1 => prevents single image removal (not intersecting) on huge zoom
        tokenRef.current = setTimeout(updateVisiblePages, loadDelay);
      });
    }

    const elements = document.querySelectorAll(".pdf-page-wrapper");

    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, [fileId, rootMargin, rootSelector, loadDelay, pages]); //eslint-disable-line

  function updateVisiblePages() {
    vpRef.current = removeIsolatedEntries(vpRef.current);
    vpRef.current.size && setVisiblePages(vpRef.current);
  }
  return visiblePages;
}

function removeIsolatedEntries(set: Set<number>) {
  if (set.size === 1) return new Set(set);

  const newSet = new Set<number>();

  set.forEach((n) => {
    if (set.has(n + 1) || set.has(n - 1)) newSet.add(n);
  });
  return newSet;
}

function getPageContainerSize(
  pageContainerRef: React.MutableRefObject<HTMLDivElement | null>
): Size | null {
  if (!pageContainerRef.current) return null;
  const bounds = pageContainerRef.current.getBoundingClientRect();
  return {
    width: bounds.width,
    height: bounds.height,
  };
}
