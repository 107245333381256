
import rightStyles from './Right.module.css'
import css from './Sidebar.module.css'
import { useEffect } from 'react';
import { Children } from 'src/types';
import { Button } from 'src/lib/button';
import { useUserBookmark } from 'src/API/bookmark'
import { Icon } from 'src/lib/icons';
import { cn, dom } from 'src/helpers';
import { Resizable } from 're-resizable';


const noop = () => { }

interface Props {
	className?: string
	children?: Children
	onCollapse?: () => void
	onExpand?: () => void
	showToggleButton?: boolean
	resizable?: boolean
	onResizeStop?: () => void
}

export const Right = ({
	className = '',
	onCollapse = noop,
	onExpand = noop,
	children,
	showToggleButton = true,
	resizable = false,
	onResizeStop = noop,
}: Props) => {

	const [ isCollapsed, setIsCollapsed ] = useUserBookmark('rightNav', false)

	useEffect(() => {
		isCollapsed
			? onCollapse()
			: onExpand()
	}, [ isCollapsed ]) //eslint-disable-line

	const content = <>
		{children}

		{showToggleButton && (
			<Button.Disc
				className={css.toggle_button}
				iconSize={24}
				onClick={() => setIsCollapsed(!isCollapsed)}>
				<Icon icon='chevron-left-circle' />
			</Button.Disc>
		)}
	</>

	return (
		<div className={cn`${css.right} ${isCollapsed && css.collapse} ${className}`}>
			{resizable
				? <Resizable
					onResizeStop={onResizeStop}
					handleStyles={{ left: { width: '10px', left: 0 } }}
					handleClasses={{ left: resizable ? rightStyles.leftResizable : '' }}
					enable={{ left: true }}
					className={css.wrapper}
					maxWidth={'50vw'}
					defaultSize={{ height: '100%', width: dom.getCssVariable('--sidebar-width')(document.documentElement) }}>
					{content}
				</Resizable>
				: <div>
					{content}
				</div>
			}
		</div>
	)
}
