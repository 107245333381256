import { useState } from 'react'
import { useFetchFaq } from 'src/API/cms'
import { Icon } from 'src/lib/icons'
import { Button } from 'src/lib/button'
import { Dialog } from 'src/lib/modals'
import { Text } from 'src/lib/text'
import { Loader } from 'src/lib/loader'
import { FAQ_ENTRY_ID } from 'src/data/cms/Faq'


interface Props {
	id: FAQ_ENTRY_ID
	className?: string
}

export const Faq = ({
	id,
	className = '',
}: Props) => {

	const [ isOpen, setIsOpen ] = useState(false)
	const { data, error, isValidating } = useFetchFaq()

	return (
		<div className={className}>
			<Button.Disc className='opacity-70 hover:opacity-100' onClick={() => setIsOpen(v => !v)}>
				<Icon icon='help' />
			</Button.Disc>

			{isValidating && !error && !data && (
				<Dialog
					content={<div className='flex items-end justify-center h-36 opacity-60'><Loader.Cool /></div>}
					isOpen={isOpen}
					onClickOutside={() => setIsOpen(false)}
					minWidth={440}
					minHeight={300}
					footer={<div className='flex justify-end'><Button.Text onClick={() => setIsOpen(false)}>CLOSE</Button.Text></div>}
				/>
			)}

			{data && (
				<Dialog
					header={<div className='pt-4'>{data.getEntry(id)?.title || id}</div>}
					content={(
						<Text className='px-8 py-4'>
							<span dangerouslySetInnerHTML={{ __html: data.getEntry(id)?.html || 'No content found' }} />
						</Text>
					)}
					isOpen={isOpen}
					onClickOutside={() => setIsOpen(false)}
					minWidth={440}
					minHeight={300}
					footer={<div className='flex justify-end'><Button.Text onClick={() => setIsOpen(false)}>CLOSE</Button.Text></div>}
				/>
			)}
			{error && (
				<Dialog
					header={'FAQ'}
					content={<em>An error has occurred, please try again later.</em>}
					isOpen={isOpen}
					onClickOutside={() => setIsOpen(false)}
					footer={<div className='flex justify-end'><Button.Text onClick={() => setIsOpen(false)}>CLOSE</Button.Text></div>}
				/>
			)}
		</div>
	)
}

