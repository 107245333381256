import style from './Circular.module.css'
import React from 'react';
import { Intent } from 'src/types'
import { cn } from 'src/helpers';

interface Props extends React.ComponentProps<'svg'> {
	width?: string | number,
	strokeWidth?: string | number,
	intent?: Intent
	className?: string
	dark?: boolean
}

const Circular = ({
	width = 140,
	strokeWidth = 2,
	intent,
	className = '',
	dark = false,
	...props
}: Props) => {



	return (
		<svg
			className={cn`${style.svg} ${intent && style[ intent ]} ${dark && intent === 'none' && style.none_dark} ${className}`}
			width={width}
			viewBox="0 0 38 38"
			xmlns="http://www.w3.org/2000/svg"
			aria-label={'loading'}
			{...props}>

			<g fill="none" fillRule="evenodd">
				<g transform="translate(1 1)" strokeWidth={strokeWidth}>
					<circle strokeOpacity=".4" cx="18" cy="18" r={18} />
					<path d="M36 18c0-9.94-8.06-18-18-18" >
						<animateTransform
							attributeName="transform"
							type="rotate"
							from="0 18 18"
							to="360 18 18"
							dur="1s"
							repeatCount="indefinite"
						/>
					</path>
				</g>
			</g>
		</svg>
	)
}

export default Circular;