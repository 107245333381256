import { Modules, MODULE } from './Modules'

export enum FILE_SYNC {
	Local,
	RClone,
	SYMLINK
}

interface IAttributes {
	numberOfTables: number
	bomProcessedVersion: number
	isFindTablesComplete?: boolean
}
export interface ApiFileBase {
	file_id: number
	file_name: string
	attributes: IAttributes
	page_count: number
}

export interface ApiFile extends ApiFileBase {
	is_directory: boolean
	children_files_count: number
	selected_in_modules: number[]
	selected: boolean
}

interface IFileBase {
	id: number
	name: string
	attributes: IAttributes
	totalPages: number
}

interface IFile {
	id: number
	name: string
	isDirectory: boolean
	childrenCount: number
	selectedInModules: MODULE[]
	totalPages: number
	isSelected: boolean
	attributes: IAttributes
}

export class FileBase implements IFileBase {
	id: number
	name: string
	attributes: IAttributes
	totalPages: number

	constructor(file: FileBase | IFileBase) {
		this.id = file.id
		this.name = file.name
		this.attributes = { ...file.attributes }
		this.totalPages = file.totalPages
	}

	static fromApi(apiFileBase: ApiFileBase): FileBase {
		const fileBase: IFileBase = {
			id: apiFileBase.file_id,
			name: apiFileBase.file_name,
			attributes: { ...apiFileBase.attributes },
			totalPages: apiFileBase.page_count,
		}
		return new FileBase(fileBase)
	}

	setAttribute<A extends keyof IAttributes>(attr: A, value: IAttributes[A]): FileBase {
		this.attributes[attr] = value
		return this
	}

	get isBomProcessed(): boolean {
		return !!this.attributes.bomProcessedVersion
	}
}

export class File implements IFile {
	id: number
	name: string
	isDirectory: boolean
	childrenCount: number
	selectedInModules: MODULE[]
	totalPages: number
	isSelected: boolean
	attributes: IAttributes

	constructor(file: File | IFile) {
		this.id = file.id
		this.name = file.name
		this.isDirectory = file.isDirectory
		this.childrenCount = file.childrenCount
		this.selectedInModules = [...file.selectedInModules]
		this.isSelected = file.isSelected
		this.attributes = { ...file.attributes }
		this.totalPages = file.totalPages
	}

	get usedInModules() {
		return new Modules({ active: this.selectedInModules }).active
	}

	static fromApi(apiFile: ApiFile): File {
		const file: IFile = {
			id: apiFile.file_id,
			name: apiFile.file_name,
			isDirectory: apiFile.is_directory,
			childrenCount: apiFile.children_files_count,
			selectedInModules: apiFile.selected_in_modules.map(n => (n + '') as MODULE),
			isSelected: apiFile.selected,
			attributes: apiFile.attributes,
			totalPages: apiFile.page_count,
		}

		return new File(file)
	}
}
