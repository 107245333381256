import { User } from 'src/data/User'
import { IPasswordConfig, PasswordConfig } from 'src/data/Password'
import { ContentWithCustomScrollbars } from 'src/lib/layout'
import { Formik, Form } from 'src/lib/forms'
import { updatePwd } from 'src/API/user'
import { Divisor } from 'src/components'
import { Heading, Text } from 'src/lib/text'
import { Button } from 'src/lib/button'
import { FormikHelpers } from 'formik'
import { AxiosError } from 'axios'
import { notify } from 'src/lib/modals/notify'
import { useState } from 'react'


interface Props {
	user: User
}

export const View = ({
	user
}: Props) => {

	const [ show1 ] = useState(false)
	const [ show2 ] = useState(false)
	const [ show3 ] = useState(false)
	const [ isSuccess, setSuccess ] = useState(false)

	function handleSubmit(values: IPasswordConfig, { setSubmitting, setFieldError, resetForm }: FormikHelpers<IPasswordConfig>) {
		updatePwd(new PasswordConfig(values))
			.then(() => {
				setSuccess(true);
				(document.activeElement as HTMLElement)?.blur()
				notify('success')('Password updated with success.')
				resetForm()
			})
			.catch((e: AxiosError) => {
				if (!e.response) {
					notify('danger')('An error has occured, please try again later.')
					return
				}
				const { status } = e.response

				if (status === 409) {
					setFieldError('oldPwd', 'Incorrect password.')
					notify('danger')('Incorrect password.')
					return
				}

				if (status === 406) {
					const errorMessage = Object.values(e.response.data).join('\n')
					notify('danger')(errorMessage)
					return
				}
			})
			.finally(() => setSubmitting(false))
	}

	const Field1 = Form.Labelled[ show1 ? 'Text' : 'Password' ]
	const Field2 = Form.Labelled[ show2 ? 'Text' : 'Password' ]
	const Field3 = Form.Labelled[ show3 ? 'Text' : 'Password' ]

	return (
		<ContentWithCustomScrollbars>

			<section className='mb-12'>
				<Heading tag='h2'>Profile</Heading>
				<Divisor className='mb-4 mt-2' />
				<div className='flex flex-col gap-4'>
					<label className='flex flex-col w-1/2'>
						<Text className='mb-1' stronger>Username</Text>
						<input name='username' type='text' className='custom_input-text text-black' value={user.username} disabled />
					</label>
					<label className='flex flex-col w-1/2'>
						<Text className='mb-1' stronger>Email</Text>
						<input name='username' type='text' className='custom_input-text text-black' value={user.email} disabled />
					</label>
				</div>
			</section>

			<section className='mb-4'>
				<Heading tag='h3' opacity={1}>Change password</Heading>
				<Divisor className='mb-4 mt-2' />
				<Formik initialValues={PasswordConfig.defaults()} validationSchema={PasswordConfig.schema()} onSubmit={handleSubmit}>
					{({ isSubmitting }) => (
						<Form.Root className='flex flex-col gap-4'>
							<div>
								<Field1
									name='oldPwd'
									className='w-1/2'
									autoComplete='off'
									label={(
										<Text><strong>Old password <em>*</em></strong></Text>
									)}
								/>
								{/* <button onClick={() => setShow1(x => !x)}>Show1</button> */}
							</div>
							<div>
								<Field2
									name='newPwd1'
									className='w-1/2'
									autoComplete='off'
									label={(
										<Text><strong>New password <em>*</em></strong></Text>
									)}
								/>
								{/* <button onClick={() => setShow2(x => !x)}>Show2</button> */}
							</div>
							<div>
								<Field3
									name='newPwd2'
									className='w-1/2'
									autoComplete='off'
									label={(
										<Text><strong>Confirm new password <em>*</em></strong></Text>
									)}
								/>
								{/* <button onClick={() => setShow3(x => !x)}>Show3</button> */}
							</div>
							<div className='flex items-center mt-3 gap-3'>
								<Button.Contained type='submit' intent='primary' disabled={isSubmitting}>
									Submit
								</Button.Contained>
								{isSuccess && <Text variant='success' stronger>Password updated with success.</Text>}
							</div>
						</Form.Root>
					)}
				</Formik>
			</section>
		</ContentWithCustomScrollbars>
	)
}