import useSWR, { SWRConfiguration } from 'swr'
import axios from 'axios'
import { ApiHotspot, Hotspot } from 'src/data/module.ark'

const fetchHotspots = (url: string, projectId: string, contractorDocumentId: string, sheet: string): Promise<Hotspot[]> => (
	axios
		.get(url, {
			params: {
				project_id: projectId,
				draw_id: contractorDocumentId,
				sheet: sheet
			}
		})
		.then(res => res.data as ApiHotspot[])
		// .then(x => (console.log('API HOTSPOTS:', x), x))
		.then(apiData => apiData.map(data => Hotspot.fromApi(data)))
	// .then(x => (console.log('HOTSPOTS:', x), x))
)

export const useFetchHotspots = (projectId: string | number, contractorDocumentId: string, sheet: string | number, config?: SWRConfiguration<Hotspot[], Error>) => {
	return useSWR([ 'cpf/v1/ark_graph/query/opc/pnid/', projectId + '', contractorDocumentId, sheet + '' ], fetchHotspots, config)
}
