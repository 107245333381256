export const getTemplateCols = (columns: number, growCols: number[]) => {

	if (!growCols.length) {
		return `repeat(${columns}, minmax(0, 1fr))`
	}

	let gridTemplateCols = ''

	for (let col = 1; col <= columns; col++) {
		// gridTemplateCols += ' 1fr '
		if (growCols.includes(col)) {
			gridTemplateCols += ` minmax(0, 1fr)`
		}
		else {
			gridTemplateCols += ` fit-content(${100 / columns}%)`
		}
	}

	return gridTemplateCols
}