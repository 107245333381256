import { useState } from 'react'
import { BomTable, BOM_TABLE_STATUS } from 'src/data/module.bom/BomTable'
import { useUpdateEffect } from 'src/hooks/effects'
import { usePrevious, useUpdatedRef } from 'src/hooks/refs'
import { Box } from 'src/lib/box'
import { Icon } from 'src/lib/icons'
import { Menu } from 'src/lib/menu'
import { ContextMenu } from 'src/lib/modals'
import { NormalizedBounds } from 'src/types'
import { tableColors } from '../tableColors'
import deepEqual from 'react-fast-compare'
import { object } from 'src/helpers'
import { Show } from 'src/lib/control-flow'
import { Button } from 'src/lib/button'


interface Props {
	table: BomTable
	onTableValidate: (table: BomTable) => void
	onTableInvalidate: (table: BomTable) => void
	onTableDelete: (table: BomTable) => void
	onTableBoundsChange: (table: BomTable) => void
	onClick: (table: BomTable) => void
	readonly: boolean
}

export const Table = ({
	table: tableFromProps,
	onTableValidate,
	onTableInvalidate,
	onTableDelete,
	onTableBoundsChange,
	onClick,
	readonly,
}: Props) => {

	const onTableValidateRef = useUpdatedRef(onTableValidate)
	const onTableInvalidateRef = useUpdatedRef(onTableInvalidate)
	const onTableDeleteRef = useUpdatedRef(onTableDelete)
	const onTableBoundsChangeRef = useUpdatedRef(onTableBoundsChange)
	const [ table, setTable ] = useState(tableFromProps)
	const prevTable = usePrevious(table)
	const [ isMovingOrResizing, setIsMovingOrResizing ] = useState(false)

	const handleValidate = () => {
		setTable(table => table.clone().setStatus('find', BOM_TABLE_STATUS.VERIFIED))
	}
	const handleInvalidate = () => {
		setTable(table => table.clone().setStatus('find', BOM_TABLE_STATUS.NOT_VERIFIED))
	}
	const handleDelete = () => {
		setTable(table => table.clone().setStatus('find', BOM_TABLE_STATUS.REJECTED))
	}
	const handleBoundsChange = (bounds: NormalizedBounds) => {
		setIsMovingOrResizing(false)
		setTable(table => table.clone().setBounds(bounds))
	}

	useUpdateEffect(() => {
		if (!deepEqual(table, tableFromProps))
			setTable(tableFromProps)
	}, [ tableFromProps ])

	useUpdateEffect(() => {
		if (!prevTable || deepEqual(table, prevTable)) return

		const prevBounds: NormalizedBounds = {
			minX: prevTable.minX,
			maxX: prevTable.maxX,
			minY: prevTable.minY,
			maxY: prevTable.maxY,
		}
		if (prevTable.status.find !== table.status.find) {
			if (table.status.find === BOM_TABLE_STATUS.VERIFIED)
				onTableValidateRef.current(table)
			if (table.status.find === BOM_TABLE_STATUS.NOT_VERIFIED)
				onTableInvalidateRef.current(table)
			if (table.status.find === BOM_TABLE_STATUS.REJECTED)
				onTableDeleteRef.current(table)
		}
		if (!object.match(prevBounds)(table))
			onTableBoundsChangeRef.current(table)
	}, [ table ])

	const handleContextMenu = (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()

		ContextMenu.show((close) => (
			<Menu.Root>
				{table.status.find === BOM_TABLE_STATUS.VERIFIED
					? <Menu.Button className='gap-3' onClick={() => { handleInvalidate(); close() }}>
						<Icon icon='cross' opacity={0.7} />
						<div className='pr-4'>Invalidate table</div>
					</Menu.Button>
					: <Menu.Button className='gap-3' onClick={() => { handleValidate(); close() }}>
						<Icon icon='tick' opacity={0.7} />
						<div className='pr-4'>Validate table</div>
					</Menu.Button>
				}
				<Menu.Button className='gap-3' onClick={() => { handleDelete(); close() }}>
					<Icon icon='trash' opacity={0.7} />
					<div className='pr-4'>Delete table</div>
				</Menu.Button>
			</Menu.Root>
		), { x: e.clientX, y: e.clientY })
	}

	if (table.status.find === BOM_TABLE_STATUS.REJECTED)
		return null

	const isTableVerified = table.status.find === BOM_TABLE_STATUS.VERIFIED

	return (
		<Box
			onContextMenu={handleContextMenu}
			anchorSize={12}
			borderWidth={3}
			readonly={readonly}
			borderColor={table.status.find === BOM_TABLE_STATUS.NOT_VERIFIED ? tableColors.notVerified : tableColors.verified}
			minX={table.minX}
			maxX={table.maxX}
			minY={table.minY}
			maxY={table.maxY}
			onResizeStart={() => setIsMovingOrResizing(true)}
			onResizeEnd={handleBoundsChange}
			onMoveStart={() => setIsMovingOrResizing(true)}
			onMoveEnd={handleBoundsChange}
			onClick={() => onClick(table)}>
			<Show when={!isMovingOrResizing && !readonly}>
				<div className='absolute left-full top-1/2 transform -translate-y-1/2 flex flex-col ml-3 gap-1 p-1 z-10 bg-gray-400 bg-opacity-50 rounded animate-fadein-full-fast'>
					<Button.Icon className='h-7 w-7' onClick={handleDelete}><Icon icon='trash' /></Button.Icon>
					<Button.Icon className='h-7 w-7' onClick={isTableVerified ? handleInvalidate : handleValidate}><Icon icon={isTableVerified ? 'minus' : 'tick'} /></Button.Icon>
				</div>
			</Show>
		</Box>
	)
}