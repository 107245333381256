
export const insert = <T extends unknown>(
	item: T,
	index: number
) => <A>(arr: A[]): (A | T)[] => {

	const newArr = [ ...arr ]
	newArr.splice(index, 0, item as any)
	return newArr as (A | T)[]
}
