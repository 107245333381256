enum ROLE {
	OWNER = 'owner',
}

const arkDefaultData: ArkMetadata = {
	importedOn: '',
	numberOfImportedFiles: 0,
	totalFileSize: 0,
}

const bomDefaultData: BomMetadata = {
	importedOn: '',
	numberOfImportedFiles: 0,
	numberOfProcessedFiles: 0,
	numberOfReviewedTables: 0,
	numberOfTables: 0,
	numberOfValidatedTables: 0,
	numberOfProcessedFilesWithinSelectedFiles: 0,
	numberOfExtractedTables: 0,
	totalFileSize: 0,
}

interface ArkMetadata {
	importedOn: string
	numberOfImportedFiles: number
	totalFileSize: number
}

interface BomMetadata {
	importedOn: string
	numberOfImportedFiles: number
	numberOfProcessedFiles: number
	numberOfReviewedTables: number
	numberOfTables: number
	numberOfValidatedTables: number
	numberOfProcessedFilesWithinSelectedFiles: number
	numberOfExtractedTables: number
	totalFileSize: number
}

export interface ApiMetadata {
	numberOfImportedFiles: number
	totalFileSize: number
	numberOfProjectMembers: number
	ark: {} | ArkMetadata
	bom: {} | BomMetadata
	roles: ROLE[]
}

export interface IMetadata {
	numberOfImportedFiles: number
	totalFileSize: number
	numberOfProjectMembers: number
	ark: ArkMetadata
	bom: BomMetadata
	roles: ROLE[]
}

export class Metadata implements IMetadata {
	readonly numberOfImportedFiles: number
	readonly totalFileSize: number
	readonly numberOfProjectMembers: number
	readonly roles: ROLE[]
	readonly ark: {
		importedOn: string
		numberOfImportedFiles: number
		totalFileSize: number
	}
	readonly bom: {
		importedOn: string
		numberOfImportedFiles: number
		numberOfProcessedFiles: number
		numberOfReviewedTables: number
		numberOfTables: number
		numberOfValidatedTables: number
		numberOfProcessedFilesWithinSelectedFiles: number
		numberOfExtractedTables: number
		totalFileSize: number
	}

	constructor(data: IMetadata) {
		this.numberOfImportedFiles = data.numberOfImportedFiles
		this.totalFileSize = data.totalFileSize
		this.numberOfProjectMembers = data.numberOfProjectMembers
		this.ark = { ...data.ark }
		this.bom = { ...data.bom }
		this.roles = data.roles
	}

	static fromApi(apiData: ApiMetadata): Metadata {
		const data: IMetadata = {
			numberOfImportedFiles: apiData.numberOfImportedFiles,
			totalFileSize: apiData.totalFileSize,
			numberOfProjectMembers: apiData.numberOfProjectMembers,
			ark: { ...arkDefaultData, ...apiData.ark },
			bom: { ...bomDefaultData, ...apiData.bom },
			roles: apiData.roles,
		}
		return new Metadata(data)
	}

	static createInstance(data: Metadata | IMetadata): Metadata {
		const _data: IMetadata = {
			numberOfImportedFiles: data.numberOfImportedFiles,
			totalFileSize: data.totalFileSize,
			numberOfProjectMembers: data.numberOfProjectMembers,
			ark: { ...data.ark },
			bom: { ...data.bom },
			roles: data.roles,
		}
		return new Metadata(_data)
	}

	static createDefaults(): Metadata {
		const data: IMetadata = {
			numberOfImportedFiles: 0,
			totalFileSize: 0,
			numberOfProjectMembers: 0,
			ark: arkDefaultData,
			bom: bomDefaultData,
			roles: []
		}
		return new Metadata(data)
	}

	static toApi(data: IMetadata | Metadata): ApiMetadata {
		const _data: ApiMetadata = {
			numberOfImportedFiles: data.numberOfImportedFiles,
			totalFileSize: data.totalFileSize,
			numberOfProjectMembers: data.numberOfProjectMembers,
			ark: { ...data.ark },
			bom: { ...data.bom },
			roles: data.roles,
		}
		return _data
	}
}