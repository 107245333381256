import { useState } from 'react'
import { useOnToggleKeyBinding } from './useOnToggleKeyBinding'

export const useIsKeyBindingActive = (...keys: string[]) => {

	const [ isActive, setIsActive ] = useState(false)

	useOnToggleKeyBinding(keys, setIsActive)

	return isActive
}