import axios from 'axios'
import useSWR, { SWRConfiguration } from 'swr'
import { ApiTask, Task } from 'src/data/Task'
import { useParams } from 'react-router-dom'

const fetchTasks = (url: string, projectId: string | number): Promise<Task[] | null> => (
	axios
		.get(url, {
			params: {
				project_id: projectId
			}
		})
		.then(res => res.data as ApiTask[])
		.then(apiTasks => apiTasks.map(apiTask => Task.fromApi(apiTask)))
		.then(tasks => tasks.filter(t => !t.isCancelled))
	// .then(v => (console.log('tasks:', v), v))
)

export const deleteTask = (task: Task) => (
	axios
		.put("cpf/v1/task/cancel/", null, {
			params: {
				id: task.id
			}
		})
)

export const useFetchTasks = (projectId: string | number | undefined, config?: SWRConfiguration<Task[] | null, Error>) => (
	useSWR(projectId ? ['/cpf/v1/task/', projectId + ''] : null, fetchTasks, config)
)

export function useTasksCache() {
	const { projectId } = useParams<{ projectId?: string }>()
	return useFetchTasks(projectId, { revalidateOnMount: false })
}

export const useFetchTasksWithInterval = (projectId: string | number, interval: number = 2000, config?: SWRConfiguration<Task[] | null, Error>) => (
	useSWR(['/cpf/v1/task/', projectId + ''], fetchTasks, {
		refreshInterval: interval,
		dedupingInterval: interval,
		...config
	})
)




