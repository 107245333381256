import { ValueOf } from 'src/types'

export enum MODULE {
	NONE = '-1',
	SITE_REGISTER = '0',
	PNID_ANALYSIS = '1',
	BOM_GENERATION = '2',
	ARK_SEARCH = '3'
}

const nicknames = {
	[MODULE.NONE]: '',
	[MODULE.SITE_REGISTER]: 'Site reg.',
	[MODULE.PNID_ANALYSIS]: 'P&ID',
	[MODULE.BOM_GENERATION]: 'BOM',
	[MODULE.ARK_SEARCH]: 'ARK',
} as const

const names = {
	[MODULE.NONE]: '',
	[MODULE.SITE_REGISTER]: 'Site register',
	[MODULE.PNID_ANALYSIS]: 'P&ID Analysis',
	[MODULE.BOM_GENERATION]: 'BOM Generation',
	[MODULE.ARK_SEARCH]: 'ARK Search',
} as const


export interface ApiModules {
	analyze_pnid: boolean
	generate_bom: boolean
	site_register: boolean
	ark_graph: boolean
}

interface IModules {
	active: MODULE[]
}


export class Modules implements IModules {
	active: MODULE[]

	constructor(data: Modules | IModules) {
		this.active = data.active
	}

	static fromApi(apiModules: ApiModules): Modules {
		const data: IModules = {
			active: []
		}
		apiModules.ark_graph && data.active.push(MODULE.ARK_SEARCH)
		apiModules.generate_bom && data.active.push(MODULE.BOM_GENERATION)
		apiModules.site_register && data.active.push(MODULE.SITE_REGISTER)
		apiModules.analyze_pnid && data.active.push(MODULE.PNID_ANALYSIS)
		return new Modules(data)
	}

	static toApi(modules: IModules | Modules): ApiModules {
		const data: ApiModules = {
			analyze_pnid: modules.active.includes(MODULE.PNID_ANALYSIS),
			generate_bom: modules.active.includes(MODULE.BOM_GENERATION),
			site_register: modules.active.includes(MODULE.SITE_REGISTER),
			ark_graph: modules.active.includes(MODULE.ARK_SEARCH),
		}
		return data
	}

	static getNickname(module: MODULE): ValueOf<typeof nicknames> {
		return nicknames[module]
	}

	static getName(module: MODULE): ValueOf<typeof names> {
		return names[module]
	}
}
