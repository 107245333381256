import { ReactComponent as ArrowUp } from "./16px/arrow-up.svg";
import { ReactComponent as BomIcon } from "./16px/bom.svg";
import { ReactComponent as Cross } from "./16px/cross.svg";
import { ReactComponent as DocumentIcon } from "./16px/document.svg";
import { ReactComponent as ErrorSign } from "./16px/error.svg";
import { ReactComponent as EyeOpen } from "./16px/eye-open.svg";
import { ReactComponent as Layers } from "./16px/layers.svg";
import { ReactComponent as InboxUpdate } from "./16px/inbox-update.svg";
import { ReactComponent as InfoSign } from "./16px/info-sign.svg";
import { ReactComponent as MinusIcon } from "./16px/minus.svg";
import { ReactComponent as PlusIcon } from "./16px/plus.svg";
import { ReactComponent as Refresh } from "./16px/refresh.svg";
import { ReactComponent as SearchIcon } from "./16px/search.svg";
import { ReactComponent as Share } from "./16px/share.svg";
import { ReactComponent as Tick } from "./16px/tick.svg";
import { ReactComponent as TickCircleContained } from "./16px/tick-circle-contained.svg";
import { ReactComponent as Trash } from "./16px/trash.svg";
import { ReactComponent as WarningSign } from "./16px/warning-sign.svg";

import { ReactComponent as AddTable } from "./18px/add-table.svg";
import { ReactComponent as AlignCenter } from "./18px/xlsx/align-center.svg";
import { ReactComponent as AlignLeft } from "./18px/xlsx/align-left.svg";
import { ReactComponent as AlignRight } from "./18px/xlsx/align-right.svg";
import { ReactComponent as Bold } from "./18px/xlsx/bold.svg";
import { ReactComponent as Chevron } from "./18px/chevron.svg";
import { ReactComponent as Cog } from "./18px/cog.svg";
import { ReactComponent as Copy } from "./18px/xlsx/copy.svg";
import { ReactComponent as Cut } from "./18px/xlsx/cut.svg";
import { ReactComponent as Download } from "./18px/download.svg";
import { ReactComponent as FileIcon } from "./18px/files/file-icon.svg";
import { ReactComponent as FolderIcon } from "./18px/files/folder-icon.svg";
import { ReactComponent as FolderOutlinedIcon } from "./18px/files/folder-outlined-icon.svg";
import { ReactComponent as Italic } from "./18px/xlsx/italic.svg";
import { ReactComponent as MembersIcon } from "./18px/members.svg";
import { ReactComponent as Merge } from "./18px/xlsx/merge.svg";
import { ReactComponent as None } from "./18px/none.svg";
import { ReactComponent as Paste } from "./18px/xlsx/paste.svg";
import { ReactComponent as Redo } from "./18px/xlsx/redo.svg";
import { ReactComponent as Split } from "./18px/xlsx/split.svg";
import { ReactComponent as Undo } from "./18px/xlsx/undo.svg";

import { ReactComponent as ApplicationsIcon } from "./20px/applications.svg";
import { ReactComponent as ChevronRight } from "./20px/chevron-right.svg";
import { ReactComponent as DocumentShare } from "./20px/document-share.svg";
import { ReactComponent as DoubleChevronRight } from "./20px/double-chevron-right.svg";
import { ReactComponent as FilterIcon } from "./20px/filter.svg";
import { ReactComponent as HelpIcon } from "./20px/help.svg";
import { ReactComponent as MoreIcon } from "./20px/more.svg";
import { ReactComponent as NotificationsIcon } from "./20px/notifications.svg";
import { ReactComponent as RotateLeft } from "./20px/rotate-left.svg";
import { ReactComponent as RotateRight } from "./20px/rotate-right.svg";
import { ReactComponent as TickCircle } from "./20px/tick-circle.svg";
import { ReactComponent as ToggleChevronDown } from "./mixed-sizes/toggle-chevron-down.svg";
import { ReactComponent as UserIcon } from "./20px/user.svg";

import { ReactComponent as ChevronLeftCircle } from "./mixed-sizes/chevron-left-circle.svg";
import { ReactComponent as GrabToReoderIcon } from "./mixed-sizes/grab-to-reorder.svg";
import { ReactComponent as ListIcon } from "./mixed-sizes/list.svg";
import { ReactComponent as MenuIcon } from "./mixed-sizes/menu-icon.svg";
import { ReactComponent as TagIcon } from "./mixed-sizes/tag-icon.svg";
import { ReactComponent as Close } from "./mixed-sizes/close.svg";
import { ReactComponent as Edit } from "./mixed-sizes/edit.svg";
import { ReactComponent as CheckGreen } from "./mixed-sizes/check-green.svg";
import { ReactComponent as CloseRed } from "./mixed-sizes/close-red.svg";

export const iconMap = {
  "add-table": AddTable,
  "align-center": AlignCenter,
  "align-left": AlignLeft,
  "align-right": AlignRight,
  applications: ApplicationsIcon,
  "arrow-up": ArrowUp,
  bold: Bold,
  bom: BomIcon,
  cross: Cross,
  chevron: Chevron,
  "chevron-left-circle": ChevronLeftCircle,
  "chevron-right": ChevronRight,
  cog: Cog,
  copy: Copy,
  cut: Cut,
  document: DocumentIcon,
  "document-share": DocumentShare,
  "double-chevron-right": DoubleChevronRight,
  download: Download,
  error: ErrorSign,
  "eye-open": EyeOpen,
  "file-icon": FileIcon,
  filter: FilterIcon,
  "folder-icon": FolderIcon,
  "folder-outlined-icon": FolderOutlinedIcon,
  "grab-to-reorder": GrabToReoderIcon,
  help: HelpIcon,
  "inbox-update": InboxUpdate,
  italic: Italic,
  "info-sign": InfoSign,
  layers: Layers,
  list: ListIcon,
  members: MembersIcon,
  "menu-icon": MenuIcon,
  merge: Merge,
  minus: MinusIcon,
  more: MoreIcon,
  none: None,
  notifications: NotificationsIcon,
  paste: Paste,
  plus: PlusIcon,
  redo: Redo,
  "rotate-left": RotateLeft,
  "rotate-right": RotateRight,
  refresh: Refresh,
  search: SearchIcon,
  share: Share,
  split: Split,
  tick: Tick,
  "tick-circle": TickCircle,
  "tick-circle-contained": TickCircleContained,
  trash: Trash,
  "toggle-chevron-down": ToggleChevronDown,
  undo: Undo,
  user: UserIcon,
  "warning-sign": WarningSign,
  "tag-icon": TagIcon,
  close: Close,
  edit: Edit,
  "check-green": CheckGreen,
  "close-red": CloseRed,
};
