import React from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';
import { cn } from 'src/helpers';

interface Props extends ScrollbarProps {
	large?: boolean
	xlarge?: boolean
}

const Content = ({
	className = '',
	large = false,
	xlarge = false,
	children,
	...props
}: Props) => {

	const style: React.CSSProperties = {
		maxWidth: xlarge ? '1500px' : large ? '1300px' : '940px',
	}


	return (
		<Scrollbars className='main_layout-content custom_scrollbars scrollbars-bigger h-full w-full' {...props}>
			<div className={cn`main_layout-content-container py-7 pl-9 pr-11 my-0 mx-auto ${className}`} style={style}>
				{children}
			</div>
		</Scrollbars>
	)
}

export default Content;