import { Route } from 'react-router'
import { CONFIG } from 'src/cp.config'
import { Children } from 'src/types'
import { Navbar } from './navbar'
import { FetchNotifications, NotificationGlobalAsyncEffects, SwrPrefetcher } from './utils'


interface Props {
	onLogout: () => void
	children: Children
	path: string
	exact?: boolean
}

export const PrivateRoute = ({
	onLogout,
	children,
	path,
	exact = false
}: Props) => {



	return (
		<Route path={path} exact={exact}>

			<SwrPrefetcher />
			<FetchNotifications interval={CONFIG.FETCH_NOTIFICATIONS_INTERVAL} />
			<NotificationGlobalAsyncEffects />

			<div className="h-screen flex flex-col max-w-full overflow-hidden">
				<Navbar onLogout={onLogout} />

				<div className="relative flex-grow max-w-full">
					{children}
				</div>
			</div>
		</Route>
	)
}