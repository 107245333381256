export const getClosestPositionnedParent = (el: HTMLElement) => {
	while (el && el.parentElement) {
		el = el.parentElement;

		const { position } = getComputedStyle(el)

		if (
			position === 'absolute' ||
			position === 'relative' ||
			position === 'sticky'
		) {
			return el;
		}
	}
	return null;
}
