import React, { useState } from 'react';
import { Menu } from 'src/lib/menu'
import { useFetchAllNotifications } from 'src/API/notifications';
import { Text } from 'src/lib/text';
import { Loader } from 'src/lib/loader';
import { Notification } from 'src/data/Notification';
import { Grid } from 'src/lib/grid';
import { Icon } from 'src/lib/icons';

const NotificationsDropdown = () => {

	const [ limit, setLimit ] = useState(5)
	const { data: notifications, isValidating, error, revalidate } = useFetchAllNotifications(limit)

	if (!notifications || !notifications.length)
		return (
			<Menu.Root className='gap-1'>
				<Menu.Item>
					<Text variant='info' className='flex-center mx-auto pr-1'>
						<i>No notification</i>
					</Text>
				</Menu.Item>
			</Menu.Root>
		)

	return (
		<Menu.Root className='gap-1'>
			{notifications.filter(n => !!n.text).map(data => (
				<RenderNotification data={data} key={data.timestamp + data.text} />
			))}
			{error
				? (
					<Menu.Item onClick={() => revalidate()}>
						<Text className='flex items-center mx-auto' style={{ height: '30px' }}>
							<em>{error.message}</em>
							{isValidating && <Loader.Dots height={7} marginTop={4} marginLeft={6} />}
						</Text>
					</Menu.Item>
				)
				: (
					<Menu.Button className='bg-gray-100' onClick={() => setLimit(v => v + 5)}>
						<div className='flex-center py-1 mx-auto'>
							{isValidating
								? <Loader.Dots height={8} />
								: <strong className='animate-fadein-full text-info'>Show more</strong>}
						</div>
					</Menu.Button>
				)}
		</Menu.Root>
	)
}

export default NotificationsDropdown;


interface RenderNotificationProps {
	data: Notification
}

const RenderNotification = ({
	data
}: RenderNotificationProps) => {


	return (
		<Menu.Item style={{ background: data.background }}>
			<Grid.Root columns={2} growCols={[ 2 ]} className='w-full text-sm'>
				<Grid.Col className={`px-1 flex-center ${data.iconClassName}`}>
					<Icon icon={data.icon} />
				</Grid.Col>

				<Grid.Col className='p-2'>
					<div className='pb-0.5 flex items-center justify-between'>
						<div className='font-bolder capitalize'>{data.type}</div>
						<div className='ml-8 text-info'><i>{data.age}</i></div>
					</div>
					<div className='pr-4 opacity-90'>{data.text}</div>
				</Grid.Col>
			</Grid.Root>
		</Menu.Item>
	)
}

