import { useLayoutEffect, useRef } from 'react'

export const useUpdateLayoutEffect = (effect: React.EffectCallback, deps?: React.DependencyList | undefined): void => {

	const isMountedRef = useRef(false)

	useLayoutEffect(() => {
		isMountedRef.current && effect()
		isMountedRef.current = true
	}, deps)//eslint-disable-line

	useLayoutEffect(() => {
		return () => {
			isMountedRef.current = false
		}
	}, [])
}
