import { MainLayout } from 'src/lib/layout';
import Page from './Page'


const Setup = () => {

	return (
		<MainLayout
			content={<Page />}
		/>
	)
}

export default Setup