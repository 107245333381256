import { ApiCmsResponse } from './types'


export enum FAQ_ENTRY_ID {
	ARK = 'ARK',
	BOM_OVERVIEW = 'BOM overview',
	BOM_PROCESS = 'BOM process',
	BOM_FIND = 'BOM find',
	BOM_REVIEW = 'BOM review',
}

export type ApiFaqEntry = [ FAQ_ENTRY_ID, string, string, string ]

interface IFaqEntry {
	id: FAQ_ENTRY_ID
	title: string
	html: string
}

export class FaqEntry implements IFaqEntry {
	id: FAQ_ENTRY_ID
	title: string
	html: string

	constructor(data: FaqEntry | IFaqEntry) {
		this.id = data.id
		this.title = data.title
		this.html = data.html
	}

	static fromApi(apiFaq: ApiFaqEntry) {
		const data: IFaqEntry = {
			id: apiFaq[ 0 ],
			title: apiFaq[ 1 ],
			html: apiFaq[ 3 ],
		}
		return new FaqEntry(data)
	}
}

interface IFaq {
	entries: FaqEntry[]
}

export class Faq implements IFaq {
	entries: FaqEntry[]

	constructor(faq: Faq | IFaq) {
		this.entries = faq.entries
	}

	static fromApi(apiFaqResponse: ApiCmsResponse<ApiFaqEntry>) {
		const data: IFaq = {
			entries: apiFaqResponse.values.slice(1).map(apiFaqEntry => FaqEntry.fromApi(apiFaqEntry))
		}
		return new Faq(data)
	}

	getEntry(id: FAQ_ENTRY_ID) {
		return this.entries.find(entry => entry.id === id)
	}
}