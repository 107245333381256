import { useState } from 'react'
import { useUpdateEffect } from 'src/hooks/effects'
import { Button } from 'src/lib/button'
import { Icon } from 'src/lib/icons'
import { Tooltip } from 'src/lib/modals'


interface Props {
	className?: string
	defaultRotation: number
	onRotate: (rotation: number) => void
}

export const Rotate = ({
	className = '',
	defaultRotation,
	onRotate,
}: Props) => {

	const [ rotation, setRotation ] = useState(defaultRotation)

	function rotateRight() {
		setRotation(r => (r + 90) % 360)
	}
	function rotateLeft() {
		setRotation(r => (r + 270) % 360)
	}

	useUpdateEffect(() => onRotate(rotation), [ rotation ])

	return (
		<div className={`flex items-center ${className}`}>
			<Tooltip text='Rotate left' delay={400} padding={6}>
				<Button.Disc onClick={rotateLeft}><Icon icon='rotate-left' /></Button.Disc>
			</Tooltip>
			<Tooltip text='Rotate right' delay={400} padding={6}>
				<Button.Disc onClick={rotateRight}><Icon icon='rotate-right' /></Button.Disc>
			</Tooltip>
		</div>
	)
}