export function getHexFromWord(word: string) {
	if (!word)
		return '#000000'

	word = word.toLowerCase()

	let hexColor = '#'

	let totalI = 0
	let i = 0

	while (hexColor.length < 7) {
		if (totalI > 2 * word.length || totalI > 50) {
			hexColor = '#000000'
			break;
		}
		if (i >= word.length) {
			i = 0
			continue
		}
		const val = word.charCodeAt(i) - 97

		if (val >= 0 && val <= 26) {
			const hexVal = (val < 16 ? val : val - 10).toString(16);
			hexColor += hexVal
		}
		i++
		totalI++
	}

	return hexColor
}
