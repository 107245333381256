import { Size } from 'src/types';
import { dom } from 'src/helpers';
import { DisableZoom, Coordinates } from './types';

export function handleDisableZoom(disableZoom: DisableZoom, e: WheelEvent): boolean {
	const disabled = typeof disableZoom === 'function'
		? disableZoom(e)
		: disableZoom

	if (!disabled) e.preventDefault()

	return disabled
}

export function getNextScale(currentScale: number, e: WheelEvent, strength: number): number {
	const direction = e.deltaY < 0 ? -1 : 1
	return currentScale * (1 - direction / 1500 * strength)
}

export function setScaleOnTarget(value: number) {
	return (target: HTMLElement) => (
		dom.setCssVariable('--target-scale', value)(target)
	)
}

export function getScrollRatio(target: HTMLDivElement, e: WheelEvent): Coordinates {
	const bounds = target.getBoundingClientRect()

	return {
		x: (e.clientX - bounds.left) / bounds.width,
		y: (e.clientY - bounds.top) / bounds.height
	}
}

export function getScrollOffset(root: HTMLDivElement, e: WheelEvent): Coordinates {
	const bounds = root.getBoundingClientRect()

	return {
		x: e.clientX - bounds.left,
		y: e.clientY - bounds.top
	}
}

export function getDefaultScaleScrollRatio(target: HTMLDivElement, root: HTMLDivElement, centerX: boolean, centerY: boolean): Coordinates {
	const bounds = target.getBoundingClientRect()
	const { top: rootTop, height: rootHeight, left: rootLeft, width: rootWidth } = root.getBoundingClientRect()

	const offset = {
		x: centerX ? rootLeft + rootWidth / 2 : rootLeft,
		y: centerY ? rootTop + rootHeight / 2 : rootTop,
	}

	return {
		x: (offset.x - bounds.left) / bounds.width,
		y: (offset.y - bounds.top) / bounds.height
	}
}

export function getDefaultScaleScrollOffset(root: HTMLDivElement, centerX: boolean, centerY: boolean): Coordinates {
	const { width, height } = root.getBoundingClientRect()

	return {
		x: centerX ? width / 2 : 0,
		y: centerY ? height / 2 : 0
	}
}


export function getNewTargetSize(target: HTMLDivElement): Size {
	const { width, height } = target.getBoundingClientRect()

	return { width, height }
}

export function scroll(root: HTMLDivElement, targetSize: Size, scrollRatio: Coordinates, scrollOffset: Coordinates) {
	root.scrollTo({
		left: targetSize.width * scrollRatio.x - scrollOffset.x,
		top: targetSize.height * scrollRatio.y - scrollOffset.y,
	})
}

export function setTransformOrigin(target: HTMLDivElement, targetSize: Size, root: HTMLDivElement, centerX: boolean, centerY: boolean) {
	targetSize.width > root.clientWidth || !centerX
		? dom.setCssVariable('--target-origin-x', '0')(target)
		: dom.setCssVariable('--target-origin-x', 'center')(target)
	targetSize.height > root.clientHeight || !centerY
		? dom.setCssVariable('--target-origin-y', '0')(target)
		: dom.setCssVariable('--target-origin-y', 'center')(target)
}