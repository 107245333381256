import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { Token } from 'src/data/Token'
import { useGetIsMounted } from 'src/hooks'
import { useProjectIdFromParams } from 'src/hooks/params'


interface UsePageSrcConfig {
	fileId?: string | number
	page: string | number
	sourcePath?: string
	scale?: number
	format?: 'png' | 'jpg'
	onError?: (err: Error) => void
}

export function usePageSrc({
	fileId, // either this
	page,
	sourcePath, // or this
	scale,
	format,
	onError = () => { }
}: UsePageSrcConfig) {

	const defaultRetryCount = 3
	const getIsMounted = useGetIsMounted()
	const projectId = useProjectIdFromParams()

	const url = useMemo(() => {
		if (!projectId || !page || (!fileId && !sourcePath)) return null
		const baseURL = `${axios.defaults.baseURL}/cpf/v1/file/`
		const requiredQuerySegment = `?project_id=${projectId}&page=${page}`
		const idOrPathRequiredSegment = fileId ? `&id=${fileId}` : sourcePath ? `&path=${encodeURIComponent(sourcePath)}` : console.warn('[ usePageSrc ] You need to provide either a "fileId" or a "sourcePath"')
		const scaleQuerySegment = scale ? `&scale=${scale}` : ''
		const formatQuerySegment = `&png=${format === 'png'}`
		return baseURL + requiredQuerySegment + idOrPathRequiredSegment + scaleQuerySegment + formatQuerySegment
	}, [ fileId, format, page, projectId, scale, sourcePath ])

	const [ src, setSrc ] = useState<string | null>(null)
	const [ retryCountDown, setRetryCountDown ] = useState(defaultRetryCount)

	useEffect(() => {
		if (!url) return

		fetch(url, {
			headers: {
				Authorization: `Token ${Token.getStore()?.access}`
			},
			cache: retryCountDown === defaultRetryCount ? 'force-cache' : 'default'
		})
			.then((res: any) => {
				if (res.ok)
					return res.blob()
				else
					throw new Error(res.statusText)
			})
			.then(image => URL.createObjectURL(image))
			.then(src => {
				if (!getIsMounted()) return
				setRetryCountDown(3)
				setSrc(src)
			})
			.catch(() => {
				if (!getIsMounted()) return
				setSrc(null)
				if (retryCountDown !== 0) {
					// incremental retry delay
					const delay = 3000 * (defaultRetryCount - retryCountDown + 1)
					setTimeout(() => {
						if (!getIsMounted()) return
						setRetryCountDown(c => c - 1)
					}, delay)
				}
				else {
					onError(new Error('Document not found'))
				}
			})
	}, [ url, retryCountDown ]) // eslint-disable-line

	return src
}


interface UseViewFileLinkUrlConfig {
	fileId: string | number
	pageNumber: string | number
	numPages: string | number
	sourcePath: string
}

export const useViewFileLinkUrl = ({
	fileId,
	pageNumber,
	numPages,
	sourcePath,
}: UseViewFileLinkUrlConfig) => {

	const projectId = useProjectIdFromParams()


	return `/projects/${projectId}/file/${fileId}/page/${pageNumber}/${numPages}/${encodeURIComponent(sourcePath)}`
}


interface UsePnidRouteUrlConfig {
	contractorDocumentId: string | undefined
	pageNumber: string | number | undefined
	sheet: string | number | undefined
	sourcePath: string | undefined
}

export const usePnidLinkUrl = ({
	contractorDocumentId,
	pageNumber,
	sheet,
	sourcePath,
}: UsePnidRouteUrlConfig) => {
	const projectId = useProjectIdFromParams()

	if (!contractorDocumentId || !pageNumber || !sourcePath) return null

	return `/projects/${projectId}/page/${pageNumber}/pnid/${contractorDocumentId}/${sheet || 1}/${encodeURIComponent(sourcePath)}`
}

export const useDownloadPdfFileUrl = (fileId: string | number) => {
	const projectId = useProjectIdFromParams()


	const url = `/cpf/v1/file/?project_id=${projectId}&id=${fileId}`
	return axios.defaults.baseURL + url
}