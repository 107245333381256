import { useCallback, useEffect, useRef, useState } from "react";
import { useFetchTables } from "src/API/bom/fetchers";
import { postNewTable, postUpdateTable } from "src/API/bom/mutations";
import { Cms, Divisor, ZoomAndSpan } from "src/components";
import { CONFIG } from "src/cp.config";
import { FileBase } from "src/data/File";
import { BomTable, BOM_TABLE_STATUS } from "src/data/module.bom/BomTable";
import { Project } from "src/data/Project";
import { MainLayout, Top } from "src/lib/layout";
import { usePageController, PageController, PdfPage } from "src/lib/render-pdf";
import { useZoomController, ZoomController } from "src/lib/zoom-and-span";
import { BackToBomAnchor } from "../BackToBomAnchor";
import { StepTitle } from "../StepTitle";
import { useIsKeyBindingActive } from "src/hooks/key-binding";
import { Button } from "src/lib/button";
import { ContextMenu, Tooltip } from "src/lib/modals";
import { dom, object } from "src/helpers";
import { useUndoRedo } from "src/hooks/useUndoRedo";
import { Icon } from "src/lib/icons";
import { FAQ_ENTRY_ID } from "src/data/cms/Faq";
import { PdfDocument } from "./PdfDocument";
import { useSchedule } from "src/hooks/useSchedule";
import { useUnmountEffect } from "src/hooks/effects";
import { useFilteredPagesContext } from "src/contexts/filtered-pages-ctx";
import { Table } from "./Table";
import { v4 } from "uuid";
import { AreaSelect } from "src/lib/box";
import { NormalizedBounds } from "src/types";
import { Menu } from "src/lib/menu";

interface Props {
	project: Project;
	file: FileBase | undefined;
}
const prefix = v4();
const noop = () => {};
const onTableBoundsChange = noop;
const onSelectTable = (table: BomTable) => {};
const onDrawTable = (bounds: NormalizedBounds, page: number) => {};
const onClickAddNewTable = noop;
export const View = ({ project, file }: Props) => {
	const { selectedPage,setSelectedPage } = useFilteredPagesContext();
	const scaleRef = useRef(1);
	const [selectedTable, setSelectedTable] = useState<BomTable | null>(null);
	const { pdfPageMapWrapper, pageController } = usePageController(1);
	const { zoomAndSpan, zoomController } = useZoomController(1);
	const [canDraw, setCanDraw] = useCanDraw("Control");
	const { data: tables = [], mutate, actions } = useFetchTables(file?.id, project.id);
	const [schedulePostUpdateTable, , , postAllPendingUpdates] = useSchedule(
		postUpdateTable,
		CONFIG.BOM.FIND.AUTO_SAVE_DELAY
	);
	const { undo, getUndoSnapshot, redo } = useUndoRedo(tables, mutate, {
		ignore: (tables) => !tables?.length,
		shortcuts: true,
	});

	const handleTableChange = useCallback(
		(table: BomTable) => {
			schedulePostUpdateTable(`table-${table.id}`)(table, "find");
			mutate(actions.replaceTableById(table), false);
		},
		[mutate, actions, schedulePostUpdateTable]
	);

	const handleClickAddNewTable = useCallback(() => {
		setCanDraw(true);
	}, [setCanDraw]);

	const handleDrawTable = useCallback(
		(bounds, page) => {
			setCanDraw(false);
			if (!file) return;
			let table: BomTable;
			mutate(actions.addTable(bounds, page), false)
				.then((tables) => tables?.find(object.match({ ...bounds, page })) as BomTable)
				.then((t) => {
					setSelectedTable(t);
					table = t;
				});
			postNewTable(bounds, page, file.id)
				.then(({ id }) => {
					table.id = id;
				})
				.catch(() => mutate((tables) => tables?.filter((t) => t.id !== table.id)));
		},
		[setCanDraw, file, mutate, actions]
	);

	useUnmountEffect(postAllPendingUpdates);
	// new logic for single page start
	const handleClickTable = (table: BomTable) => {
		if (table.id !== selectedTable?.id) setSelectedTable(table);
	};
	// new logic for single page end
	if (!file) return null;
	return (
		<MainLayout
			top={
				<Top
					left={
						<div className="flex items-center max-w-full">
							<BackToBomAnchor />
							<StepTitle>{CONFIG.BOM.FIND.TITLE}</StepTitle>
						</div>
					}
					right={
						<div className="flex items-center gap-4">
							<Tooltip text="Add table" shortcut="Ctrl" padding={10}>
								<Button.Contained
									intent="primary"
									fontSize={14}
									className={`whitespace-nowrap ${canDraw ? "shadow-inner" : ""}`}
									onClick={() => setCanDraw((v) => !v)}
								>
									Add table
								</Button.Contained>
							</Tooltip>
							<Divisor className="self-center h-5 ml-2 bg-gray-400 opacity-80" y />
							<div className="flex items-center">
								<Tooltip text="Undo" shortcut="Ctrl+Z" padding={10}>
									<Button.Disc onClick={() => getUndoSnapshot()?.length && undo()}>
										<Icon icon="undo" iconSize={16} />
									</Button.Disc>
								</Tooltip>
								<Tooltip text="Redo" shortcut="Ctrl+Y" padding={10}>
									<Button.Disc onClick={redo}>
										<Icon icon="redo" iconSize={16} />
									</Button.Disc>
								</Tooltip>
							</div>
							<Divisor className="self-center h-5 ml-1 bg-gray-400 opacity-80" y />
							<ZoomController onZoom={zoomController.onZoom} zoom={zoomController.zoom} zoomStrength={1.15} />
							<Divisor className="self-center h-5 ml-2 bg-gray-400 opacity-80" y />
							<PageController
								numPages={file.totalPages}
								onScrollToPage={setSelectedPage}
								page={selectedPage}
							/>
							<Divisor className="self-center h-5 ml-2 bg-gray-400 opacity-80" y />
							<Cms.Faq id={FAQ_ENTRY_ID.BOM_FIND} />
						</div>
					}
				/>
			}
			content={
				<ZoomAndSpan
					defaultZoom={zoomAndSpan.defaultZoom}
					onZoom={(scale) => {
						dom.setCssVariable("--konva-child-scale", 1 / scale)(document.documentElement); //keeps tables buttons at scale 1
						zoomAndSpan.onZoom(scale);
						scaleRef.current = scale;
					}}
					centerY
					centerX
					spanKey=" "
					disableZoom={(e) => !e.ctrlKey}
				>
					{/* <PdfDocument
            canDraw={canDraw}
            file={file}
            tables={tables}
            selectedTable={selectedTable}
            // Those are the functions that handle the page changes [onPageChange, scrollToPage]
            onPageChange={pdfPageMapWrapper.onPageChange}
            scrollToPage={pdfPageMapWrapper.scrollToPage}
            onClickAddNewTable={handleClickAddNewTable}
            onDrawTable={handleDrawTable}
            onSelectTable={setSelectedTable}
            onTableValidate={handleTableChange}
            onTableInvalidate={handleTableChange}
            onTableDelete={handleTableChange}
            onTableBoundsChange={handleTableChange}
          /> */}
					<PdfPage
						onPageChange={setSelectedPage}
						scrollToPage={setSelectedPage}
						fileId={file.id}
						page={(selectedPage as number) || 1}
						containerSize={null}
						onContextMenu={(e) => {
							e.preventDefault();
							ContextMenu.show(
								(close) => (
									<Menu.Root>
										<Menu.Button
											className="gap-3"
											onClick={() => {
												handleClickAddNewTable();
												close();
											}}
										>
											<Icon icon="plus" opacity={0.7} />
											<div className="pr-4">Add new table</div>
										</Menu.Button>
									</Menu.Root>
								),
								{ x: e.clientX, y: e.clientY }
							);
						}}
					>
						{tables
							.filter((t) => t.page === selectedPage && t.status.find !== BOM_TABLE_STATUS.REJECTED)
							.map((table) => (
								<Table
									table={table}
									onTableValidate={handleTableChange}
									onTableInvalidate={handleTableChange}
									onTableDelete={handleTableChange}
									onTableBoundsChange={onTableBoundsChange}
									onClick={handleClickTable}
									readonly={selectedTable?.id !== table.id}
									key={prefix + table.id}
								/>
							))}
						<AreaSelect onSelectEnd={(bounds) => handleDrawTable(bounds, selectedPage as number)} disabled={!canDraw} />
					</PdfPage>
				</ZoomAndSpan>
			}
		/>
	);
};

function useCanDraw(...keys: string[]) {
	const isCtrlKeyDown = useIsKeyBindingActive(...keys);
	const [isAddTableClicked, setIsAddTableClicked] = useState(false);

	useEffect(() => {
		!isCtrlKeyDown && setIsAddTableClicked(false);
	}, [isCtrlKeyDown]);

	return [isCtrlKeyDown || isAddTableClicked, setIsAddTableClicked] as const;
}
