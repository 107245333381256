import { time } from "./helpers";

export const CONFIG = {
	DOMAIN: "cp-demo.cenozai.io", // previous dev didn't cleanup his own config...
	COGNITO: {
		REDIRECT_URI: "https://<domain>/login/cognito/",
	},
	FETCH_NOTIFICATIONS_INTERVAL: time.seconds(4),
	NOTIFICATION_DEFAULT_AUTOCLOSE_DELAY: time.seconds(8),
	ARK_SEARCH_RESULTS_PER_PAGE: 5,
	IDLE_TIME: time.minutes(6),
	REFRESH_ACCESS_BEFORE_EXPIRATION_TIME: time.seconds(20),
	KONVA: {
		PIXEL_RATIO: 10,
	},
	CMS: {
		GOOGLE_SHEETS_API_KEY: "AIzaSyBk0xL6WVnl016Wu4BB6Rqi1etvImp_0MQ",
		DOC_ID: "1UoOoo3DrjHGRhCUKOvaOeeSGYTVRnxkSNL3tvWQ-gh0",
		FAQ_SHEET_NAME: "FAQ",
		PAGE_CONTENT_SHEET_NAME: "Page Content",
	},
	DIALOG: {
		MAX_WIDTH: "min(1000px,60vw)",
		MAX_HEIGHT: "80vh",
	},
	MODULES: {
		AVAILABLE: {
			ARK_SEARCH: true,
			BOM_GENERATION: true,
			PNID_ANALYSIS: false,
			SITE_REGISTER: false,
		},
	},
	BOM: {
		OVERVIEW: {
			TITLE: "Overview",
			SHORT_TITLE: "Overview",
		},
		PROCESS: {
			TITLE: "Select & Process files",
			SHORT_TITLE: "Process files",
			DESCRIPTION: ["Select files to process and automatically identify pages containing spare parts information."],
		},
		FIND: {
			TITLE: "Find Parts Tables",
			SHORT_TITLE: "Find Tables",
			DESCRIPTION: [
				"Our algorithms have found tables containing parts information.",
				"Remove false positives, as well as check for and add important tables that have irregular formats.",
			],
			AUTO_SAVE_DELAY: time.seconds(5),
		},
		REVIEW: {
			TITLE: "Review Extracted Parts Tables",
			SHORT_TITLE: "Review Tables",
			DESCRIPTION: ["Dive into your newly extracted tables.", "Review and remediate extracted tablular information."],
		},
		EXCEL: {
			MIN_ROWS: 40,
			MIN_COLS: 26,
			AUTO_SAVE_DELAY: time.seconds(10),
		},
	},
};
