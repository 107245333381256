import { Dispatch } from 'react';
import { Icon } from 'src/lib/icons';
import { TagColor, TagUI } from 'src/types/ui';

interface IProps {
  tag: TagUI;
  color: TagColor;
  textColor?: string;
  className?: string;
  close?: (tag: TagUI) => void;
  size?: string
}

export const Tag = ({ tag, color, textColor, className='', close, size="sm" }: IProps) => {
  return (
    <div
      className={`${
        close ? 'flex justify-between items-center ' : ' '
      }inline-block rounded-3xl pl-3 pr-3 pt-0.5 pb-0.5 text-${size} ${className} `}
      style={{
        backgroundColor: color ,
        color: textColor ? textColor : 'white',
      }}
    >
      {tag.tag}
      {close && (
        <Icon className="ml-3 cursor-pointer" width={10} height={10} icon="close" onClick={(e) => close(tag)} />
      )}
    </div>
  );
};

export default Tag;
    