import { ExtractArrayType } from 'src/types'
import { object } from '..'


export const replace = <T extends {}, U extends T>(
	partial: Partial<T>,
	by: U
) => <A extends T[]>(arr: A): (ExtractArrayType<A> | U)[] => {

	return arr.map(entry => {
		if (object.match(partial)(entry))
			return by
		return entry
	}) as (ExtractArrayType<A> | U)[]
}