import axios from 'axios'

export interface Credentials {
	emailOrUsername: string
	password: string
}

export interface ApiToken {
	access: string
	refresh: string
}

export interface IToken {
	access: string
	refresh: string
}

interface ParsedToken {
	exp: number
	jti: string
	token_type: string
	user_id: number
}

export class Token implements IToken {
	access: string
	refresh: string

	constructor(data: Token | IToken) {
		this.access = data.access
		this.refresh = data.refresh
	}

	static fromApi(apiToken: ApiToken): Token {
		const data: IToken = {
			access: apiToken.access,
			refresh: apiToken.refresh
		}
		return new Token(data)
	}

	static getStore(): Token | null {
		const stringifiedToken = localStorage.getItem('token')
		if (!stringifiedToken) {
			Token.setHeader(null)
			return null
		}
		const token: Token = JSON.parse(stringifiedToken)

		Token.setHeader(token.access)
		return new Token(token)
	}

	static setStore(token: IToken | Token | null) {
		Token.setHeader(token?.access)

		if (!token) {
			localStorage.removeItem('token')
		}
		else {
			localStorage.setItem('token', JSON.stringify(token))
		}
	}

	static setHeader(token: string | null | undefined) {
		if (token)
			axios.defaults.headers.common['Authorization'] = "Token " + token;
		else
			delete axios.defaults.headers.common['Authorization'];
	}

	private get accessTokenExpirationDate(): number {
		const accessToken = this.access

		const parsedToken = Token.parseJwt(accessToken)
		return parsedToken.exp
	}

	private static parseJwt(token: string): ParsedToken {
		try {
			const base64Url = token.split('.')[1] || ''
			const base64 = base64Url.replace('-', '+').replace('_', '/')
			return JSON.parse(window.atob(base64));
		}
		catch (e) {
			const defaultParsed: ParsedToken = {
				exp: 0,
				jti: '',
				token_type: '',
				user_id: -1,
			}
			return defaultParsed
		}
	}

	get accessExpiresInMs(): number {
		const exp = this.accessTokenExpirationDate
		return exp * 1000 - Date.now()
	}
}
