import { NormalizedBounds } from 'src/types'
import { ExcelData } from 'src/lib/excel'

export enum BOM_TABLE_STATUS {
	NOT_VERIFIED = 0,
	VERIFIED = 1,
	REJECTED = 2,
}

export interface ApiBomTable extends NormalizedBounds {
	id: number
	page: number
	reviewStatus: BOM_TABLE_STATUS
	status: BOM_TABLE_STATUS
	userDefined: boolean
}

interface IBomTable extends NormalizedBounds {
	id: number
	page: number
	userDefined: boolean
	status: {
		find: BOM_TABLE_STATUS
		review: BOM_TABLE_STATUS
	}
}

export class BomTable implements IBomTable {
	id: number
	maxX: number
	maxY: number
	minX: number
	minY: number
	page: number
	userDefined: boolean
	status: {
		find: BOM_TABLE_STATUS
		review: BOM_TABLE_STATUS
	}

	constructor(data: BomTable | IBomTable) {
		this.id = data.id
		this.maxX = data.maxX
		this.maxY = data.maxY
		this.minX = data.minX
		this.minY = data.minY
		this.page = data.page
		this.userDefined = data.userDefined
		this.status = { ...data.status }
	}

	setId(id: number): BomTable {
		this.id = id
		return this
	}

	setPage(page: number): BomTable {
		this.page = page
		return this
	}

	setStatus(key: keyof BomTable[ 'status' ], status: BOM_TABLE_STATUS): BomTable {
		this.status = {
			...this.status,
			[ key ]: status
		}
		return this
	}

	setBounds(bounds: NormalizedBounds): BomTable {
		this.maxX = bounds.maxX
		this.maxY = bounds.maxY
		this.minX = bounds.minX
		this.minY = bounds.minY
		return this
	}

	clone(): BomTable {
		return new BomTable(this)
	}


	static toApi(table: BomTable | IBomTable): ApiBomTable {
		const data: ApiBomTable = {
			id: table.id,
			maxX: table.maxX,
			maxY: table.maxY,
			minX: table.minX,
			minY: table.minY,
			page: table.page,
			status: table.status.find,
			reviewStatus: table.status.review,
			userDefined: table.userDefined,
		}
		return data
	}

	static fromApi(apiTable: ApiBomTable): BomTable {
		const data: IBomTable = {
			id: apiTable.id,
			maxX: apiTable.maxX,
			maxY: apiTable.maxY,
			minX: apiTable.minX,
			minY: apiTable.minY,
			page: apiTable.page,
			userDefined: apiTable.userDefined,
			status: {
				find: apiTable.status,
				review: apiTable.status,
			},
		}
		return new BomTable(data)
	}

	static default(): BomTable {
		return new BomTable({
			id: -1,
			maxX: 0,
			maxY: 0,
			minX: 0,
			minY: 0,
			page: 0,
			userDefined: true,
			status: {
				find: BOM_TABLE_STATUS.NOT_VERIFIED,
				review: BOM_TABLE_STATUS.NOT_VERIFIED
			}
		})
	}
}


enum TABLE_EXTRACTION_STATUS {
	NOT_STARTED = 0,
	STARTED = 1,
	DONE = 2
}

export interface ApiReviewTablesListEntry {
	document: { id: number, document_path: string }
	extraction_date: string // "2021-09-17T08:35:27.142940Z"
	extraction_status: TABLE_EXTRACTION_STATUS
	table_index: number
	id: number
	page: number
	review_status: BOM_TABLE_STATUS
}

interface IReviewTablesListEntry {
	id: number
	page: number
	file: { id: number, sourcePath: string }
	tableIndex: number
	extractionDate: string
	extractionStatus: TABLE_EXTRACTION_STATUS
	status: BOM_TABLE_STATUS
}

export class ReviewTablesListEntry implements IReviewTablesListEntry {
	id: number
	page: number
	file: { id: number, sourcePath: string }
	tableIndex: number
	extractionDate: string
	extractionStatus: TABLE_EXTRACTION_STATUS
	status: BOM_TABLE_STATUS

	constructor(data: ReviewTablesListEntry | IReviewTablesListEntry) {
		this.id = data.id
		this.page = data.page
		this.file = { ...data.file }
		this.tableIndex = data.tableIndex
		this.extractionDate = data.extractionDate
		this.extractionStatus = data.extractionStatus
		this.status = data.status
	}

	get fileName(): string {
		return this.file.sourcePath.split(/[\\/]/).pop() || ''
	}

	static fromApi(apiData: ApiReviewTablesListEntry): ReviewTablesListEntry {
		const data: IReviewTablesListEntry = {
			id: apiData.id,
			page: apiData.page,
			file: {
				id: apiData.document.id,
				sourcePath: apiData.document.document_path
			},
			tableIndex: apiData.table_index,
			extractionDate: apiData.extraction_date,
			extractionStatus: apiData.extraction_status,
			status: apiData.review_status,
		}
		return new ReviewTablesListEntry(data)
	}

	clone(): ReviewTablesListEntry {
		return new ReviewTablesListEntry(this)
	}

	setStatus(status: BOM_TABLE_STATUS): ReviewTablesListEntry {
		this.status = status
		return this
	}

	get isExtracted(): boolean {
		return this.extractionStatus === TABLE_EXTRACTION_STATUS.DONE
	}

	get isReviewed(): boolean {
		return this.status === BOM_TABLE_STATUS.VERIFIED
	}
}


export interface ApiBomTableData {
	id: number
	tags: any[]
	bounding_box: NormalizedBounds
	excel: ExcelData
}

interface IBomTableData {
	id: number
	tags: any[]
	bounds: NormalizedBounds
	excelData: ExcelData
}

export class BomTableData implements IBomTableData {
	id: number
	tags: any[]
	bounds: NormalizedBounds
	excelData: ExcelData

	constructor(data: IBomTableData | BomTableData) {
		this.id = data.id
		this.tags = [ ...data.tags ]
		this.bounds = { ...data.bounds }
		this.excelData = data.excelData.clone()
	}

	static fromApi(apiData: ApiBomTableData): BomTableData {
		const data: IBomTableData = {
			id: apiData.id,
			tags: [ ...apiData.tags ],
			bounds: { ...apiData.bounding_box },
			excelData: new ExcelData({ ...apiData.excel, id: apiData.id }),
		}
		return new BomTableData(data)
	}

	setExcelMinSize(size: { rows: number, cols: number }): BomTableData {
		this.excelData = this.excelData
			.clone()
			.setMinSize(size)
		return this
	}

	clone(): BomTableData {
		return new BomTableData(this)
	}

	setExcelData(excelData: ExcelData): BomTableData {
		this.excelData = excelData.clone()
		return this
	}
}