import "src/styles/variables/index.css"; //css variables
import "src/styles/base/index.css";
import 'react-toastify/dist/ReactToastify.min.css';
import "src/styles/components/index.css";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { App } from './AppRouter'
import * as serviceWorker from './serviceWorker';
import "src/styles/utilities/index.css";
import { SWRConfig } from 'swr';



ReactDOM.render(
  // <React.StrictMode>
  <SWRConfig value={{ revalidateOnFocus: false, revalidateOnReconnect: false, dedupingInterval: 1000 }}>
    <BrowserRouter>
      <App key='cenozai-platform' />
    </BrowserRouter>
  </SWRConfig>,
  // </React.StrictMode>
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
