import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from 'src/helpers'
import { LinkOrButton, LinkOrButtonProps } from './types';
import { useSetIconSize, useSetFontSize, getIntentClassName } from './utils';

// PostCSS WHITELIST
// DO NOT REMOVE THIS COMMENT
// 
// intent_primary
// intent_danger
// intent_warning
// intent_success
// intent_none


type Props<T extends LinkOrButton> = LinkOrButtonProps<T> & {
	as: T
}


const Base = <T extends LinkOrButton>({
	iconSize,
	fontSize,
	as: As,
	className = '',
	disabled = false,
	intent = 'none',
	rounded = false,
	...props
}: Props<T>) => {

	const btnRef = useRef<HTMLElement | null>(null)
	const pathname = usePathname()

	useSetIconSize(iconSize, btnRef)
	useSetFontSize(fontSize, btnRef)

	const TagName = As === Link ? Link : 'button'

	const isActiveLink = () => {
		if (TagName !== Link) return false
		const to = (props as any).to
		return pathname.startsWith(to)
	}

	return (
		<TagName
			tabIndex={disabled ? -1 : 0}
			ref={btnRef}
			className={cn`
				button
				${getIntentClassName(intent)}
				${disabled && 'disabled'}
				${rounded && 'round'}
				${isActiveLink() && 'active'}
				${className}`}
			{...props}
		/>
	)
}

export default Base

// This hooks avoids errors in Portals
function usePathname() {
	let pathname = ''
	try {
		pathname = useLocation().pathname //eslint-disable-line
	}
	catch (e) { }

	return pathname
}