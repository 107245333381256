import style from './Container.module.css'
import React from 'react';
import { cn } from 'src/helpers';

interface Props extends React.ComponentProps<'div'> {
}

function Container({
	className = '',
	children = '',
	...props
}: Props) {



	return (
		<div className={cn`${style.div} ${className}`} {...props}>
			{children}
		</div>
	)
}

export default Container;