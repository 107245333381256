import { BomTable, BOM_TABLE_STATUS } from 'src/data/module.bom/BomTable'
import { NormalizedBounds } from 'src/types'

let tempId = -1

export const tableActions = {
	replaceTableById: (table: BomTable) => (tables: BomTable[] | undefined) => {
		return tables?.map(t => {
			if (t.id === table.id)
				return table.clone()
			return t
		})
	},

	addTable: (bounds: NormalizedBounds, page: number) => (tables: BomTable[] | undefined) => {
		if (!tables) return tables
		const table = new BomTable({
			...bounds,
			page,
			id: --tempId,
			userDefined: true,
			status: {
				find: BOM_TABLE_STATUS.VERIFIED,
				review: BOM_TABLE_STATUS.NOT_VERIFIED
			},
		})
		return [ ...tables, table ]
	}
}