import { createGlobalListener } from './createGlobalListener'
export const useGlobalClick = createGlobalListener('click')
export const useGlobalContextMenu = createGlobalListener('contextmenu')
export const useGlobalKeyDown = createGlobalListener('keydown')
export const useGlobalKeyUp = createGlobalListener('keyup')
export const useGlobalMouseDown = createGlobalListener('mousedown')
export const useGlobalMouseMove = createGlobalListener('mousemove')
export const useGlobalMouseUp = createGlobalListener('mouseup')
export const useGlobalResize = createGlobalListener('resize', 'window')
export const useGlobalBlur = createGlobalListener('blur', 'window')
export { useGlobalWheel } from './useGlobalWheel'
export { useEventListener } from './useEventListener-UNUSED'
export { useOnClickOutside } from './useOnClickOutside'
export { useGlobalResizeDebounced } from './useGlobalResizeDebounced'
export { useOnlineStatus } from './useOnlineStatus'