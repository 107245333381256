import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NormalizedBounds } from 'src/types'
import { dom } from 'src/helpers'
import { Show } from 'src/lib/control-flow'
import { Handler } from './Handler'
import { BoxProps } from './types'
import useMergedRef from '@react-hook/merged-ref'
import { reorderBounds, useParentRef } from './utils'
import { Rect } from './Rect'


const noop = () => { }

export const Box = forwardRef<HTMLDivElement, BoxProps>(({
	minX,
	minY,
	maxX,
	maxY,
	className = '',
	children = null,
	borderWidth,
	borderColor,
	backgroundColor = `${borderColor}25`,
	onResizeStart = noop,
	onResizeEnd = noop,
	onMoveEnd = noop,
	onMoveStart = noop,
	anchorSize,
	readonly,
	onClick = noop,
	onContextMenu = noop,
}, forwardedRef) => {

	const ref = useRef<HTMLDivElement | null>(null)
	const parentRef = useParentRef(ref)
	const [ bounds, setBounds, unorderedBounds ] = useBounds({ minX, maxX, minY, maxY })
	const [ disableHandlers, setDisableHandlers ] = useState(false)

	function handleResizeEnd(partialBounds: Partial<NormalizedBounds>) {
		setBounds(partialBounds);
		const reorderedBounds = reorderBounds({ ...unorderedBounds, ...partialBounds })
		onResizeEnd(reorderedBounds)
		dom.setStyle('cursor', '')(parentRef.current)
	}

	return (
		<div onClick={onClick} onContextMenu={onContextMenu} ref={useMergedRef(forwardedRef, ref)} draggable={false}>
			<Rect
				className={className}
				bounds={bounds}
				borderWidth={borderWidth}
				borderColor={borderColor}
				backgroundColor={backgroundColor}
				onMoveStart={() => {
					setDisableHandlers(true)
					onMoveStart()
				}}
				onMoveEnd={bounds => {
					setBounds(bounds)
					setDisableHandlers(false)
					onMoveEnd(bounds)
				}}
				disabled={readonly}>
				{children}
			</Rect>

			<Show when={!disableHandlers && !readonly}>
				<Handler.Top
					onResizeStart={() => {
						dom.setStyle('cursor', 'n-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>

				<Handler.Bottom
					onResizeStart={() => {
						dom.setStyle('cursor', 's-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>

				<Handler.Left
					onResizeStart={() => {
						dom.setStyle('cursor', 'w-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>

				<Handler.Right
					onResizeStart={() => {
						dom.setStyle('cursor', 'e-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>

				<Handler.TopLeft
					onResizeStart={() => {
						dom.setStyle('cursor', 'nw-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>

				<Handler.TopRight
					onResizeStart={() => {
						dom.setStyle('cursor', 'ne-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>

				<Handler.BottomLeft
					onResizeStart={() => {
						dom.setStyle('cursor', 'sw-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>

				<Handler.BottomRight
					onResizeStart={() => {
						dom.setStyle('cursor', 'se-resize')(parentRef.current)
						onResizeStart()
					}}
					onResize={setBounds}
					onResizeEnd={handleResizeEnd}
					borderWidth={borderWidth}
					anchorSize={anchorSize}
					color={borderColor}
					{...bounds}
				/>
			</Show>
		</div>
	)
})


function useBounds({ minX, maxX, minY, maxY }: NormalizedBounds) {
	const [ _bounds, setBounds ] = useState<NormalizedBounds>({ minX, maxX, minY, maxY })

	const set = useCallback((partialBounds: Partial<NormalizedBounds>) => {
		setBounds(_bounds => ({ ..._bounds, ...partialBounds }))
	}, [])

	const bounds = useMemo(() => reorderBounds(_bounds), [ _bounds ])

	useEffect(() => {
		set({ minX, maxX, minY, maxY })
	}, [ minX, maxX, minY, maxY ]) //eslint-disable-line

	return [ bounds, set, _bounds ] as const
}