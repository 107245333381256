import { useState, useCallback } from 'react'

export function useToggle(defaultValue: boolean = false) {

	const [ state, setState ] = useState(defaultValue)

	const toggle = useCallback(() => setState(state => !state), [])

	return [ state, toggle ] as const
}
