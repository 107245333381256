import { Field } from "formik";
import {
  CSSProperties,
  Dispatch,
  ReactEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FileBase } from "src/data/File";
import { array } from "src/helpers/array";
import PageHandler from "src/helpers/page";
import { useStateThrottled } from "src/hooks/state";
import { Icon } from "src/lib/icons";
import {
  PdfPage,
  PdfPageMapWrapperWithMaxPages,
  usePageController,
} from "src/lib/render-pdf";
import { PdfPageMapWrapperProps } from "src/lib/render-pdf/PdfPageMapWrapper";

const noop = () => {};
const rootSelector = ".file_legend > div";
interface Props {
  file: FileBase | undefined;
  fileId: number | undefined;
  numPages: number;
  filteredPages: number[];
  pdfPageMapWrapper: any;
  pageController: any;
  onPageChange: PdfPageMapWrapperProps["onPageChange"];
  scrollToPage: PdfPageMapWrapperProps["scrollToPage"];
  className?: string;
  style?: CSSProperties;
  onLoad?: () => void;
  onError?: ReactEventHandler<HTMLImageElement>;
  setSelectedPage: Dispatch<SetStateAction<number>>;
  selectedPage: number;
  setShowLegend: Dispatch<SetStateAction<boolean>>;
}

const FileLegend = ({
  pdfPageMapWrapper,
  pageController,
  scrollToPage,
  onPageChange,
  file,
  filteredPages,
  fileId,
  selectedPage,
  className = "",
  style = {},
  onError,
  setSelectedPage,
  onLoad = noop,
  setShowLegend,
  numPages = 0,
}: Props) => {
  const maxPages: number = 1;

  const currPageRef = useRef(1);
  const scrollPaddingTopRef = useRef(0);
  const scrollElRef = useRef<HTMLElement | null>(null);
  const handlePageChange = (page: number) => {
    currPageRef.current = page;
    if (onPageChange) onPageChange(page);
    // const shouldLoadNextPages = page >= array.last(pages, 1) - 2 && array.last(pages) !== numPages;
    // const shouldLoadPrevPages = page <= pages[0] + 2 && pages[0] !== 1;
    const scrollData1 = getScrollData(
      `#pdf-page-container-${page}`,
      rootSelector
    );
    const scrollData2 = getScrollData(
      `#pdf-page-container-${page + 1}`,
      rootSelector
    );
    // if (!shouldLoadNextPages && !shouldLoadPrevPages) return;
    if (scrollData1?.scrollPaddingTop >= 0) {
      scrollPaddingTopRef.current = scrollData1.scrollPaddingTop;
      scrollElRef.current = scrollData1.scrollEl;
    } else {
      scrollPaddingTopRef.current = scrollData2.scrollPaddingTop;
      scrollElRef.current = scrollData2.scrollEl;
    }
    //setPages(getNewPageRange(page, maxPages, numPages));
  };
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [_selectedPage, _setSelectedPage] = useState<number | null>(
    selectedPage
  );
  const handlePageClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    page: number
  ) => {
    _setSelectedPage(page);
    setSelectedPage(page);
  };
  // To show the number of pages below in legend (current/total pages)
  const getLegendPages = (selectedPage: number, pages: number) => {
    if (pages > 0)
      return `${
        selectedPage
      }/ ${pages}`;
    else return `No pages`;
  };
  useEffect(() => {
    if(!selectedPage){
      setSelectedPage(1);
    }
  }, [setSelectedPage, filteredPages, file, fileId]);
  // console.log("numPages: ", numPages);
  // if (numPages === 0) return null;
  // else
  
  return (
    <div
      className={`file_legend overflow-hidden w-52 z-10 absolute ${className}`}
      style={{
        backgroundColor: "#D9D9D9",
        border: "1px solid #5C6069",
        right: "0px",
        top: "3.1em",
        height: "calc(100vh - 6.2rem)",
      }}
    >
      <div
        className="overflow-auto"
        style={{
          height: "90%",
        }}
      >
        {file && (
          <PdfPageMapWrapperWithMaxPages
            width={"6.2em"}
            height={"8.3em"}
            marginTop={"2.5em"}
            filteredPages={filteredPages}
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            maxPages={100}
            onPageChange={onPageChange}
            fileId={file.id}
            numPages={file.totalPages}
            intersectionObserverRootSelector={rootSelector}
            showPageNumber={true}
            scrollToPage={scrollToPage}
            map={(page, containerSize) => {
              return (
                <PdfPage
                  fileId={file.id}
                  page={page}
                  containerSize={containerSize}
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                ></PdfPage>
              );
            }}
          />
        )}
        {/* <ul className="list-none">
          {allPages
            .sort((a: number, b: number) => a - b)
            .map((page) => (
              <li key={page} className="p-4">
                <span onClick={(e) => handlePageClick(e, page)}>
                  <PdfPageImage
                    style={{
                      border:
                        page === selectedPage ? "4px solid #3CABC4" : "none",
                    }}
                    fileId={fileId}
                    page={page}
                    className="w-full cursor-pointer max-w-none shadow-medium"
                    scale={3}
                    onLoad={() => {
                      setIsLoading(false);
                      onLoad();
                      setIsError(false);
                    }}
                    onError={(...args) => {
                      setIsLoading(false);
                      if (onError) onError(...args);
                      setIsError(true);
                    }}
                    hidePreloader
                  />
                </span>
                <span
                  className="flex justify-center w-full mt-1"
                  style={{
                    fontFamily: "'Open Sans'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "19px",
                    color: "#8B909A",
                  }}
                >
                  {page}
                </span>
              </li>
            ))}
        </ul> */}
      </div>
      <div
        className="flex flex-col items-center justify-center w-full"
        style={{ height: "10%" }}
      >
        <span
          className="flex justify-center w-full mb-2"
          style={{
            fontFamily: "'Open Sans'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "19px",
            color: "#8B909A",
          }}
        >
          {getLegendPages(selectedPage, numPages)}
        </span>
        <Icon
          className="cursor-pointer"
          icon="chevron-left-circle"
          width={30}
          height={30}
          color="#8B909A"
          style={{
            transform: "rotate(180deg)",
          }}
          onClick={(e) => setShowLegend(false)}
        />
      </div>
    </div>
  );
};

export default FileLegend;

function getScrollData(targetSelector: string, rootSelector: string) {
  const target = document.querySelector(targetSelector);
  const root = document.querySelector(rootSelector);
  if (!target || !root)
    return {
      scrollPaddingTop: 0, 
      scrollEl: null,
    };

  const pageTop = target.getBoundingClientRect().top;
  const containerTop = root.getBoundingClientRect().top;
  return {
    scrollPaddingTop: pageTop - containerTop,
    scrollEl: target as HTMLElement,
  };
}
