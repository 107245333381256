import { ITag } from "./../data/tag";
// This is the type of data a tag will hold
export interface TagUI extends ITag {
  selected?: boolean;
}

// type of color with colors that can only be used as tag colors
// export type TagColor = '#F12688' | '#51C5EA' | '#6FEA51' | '#EEB521' | '#E751EA' | '#A426F1' | '#2690F1';
export type TagColor = string;

export const tagColorsMap: TagColor[] = [
  "#F12688",
  "#51C5EA",
  "#6FEA51",
  "#EEB521",
  "#E751EA",
  "#A426F1",
  "#2690F1",
];

export type TagViewMode = "edit" | "placeholder";
export type TagUpdateData = { old: string; new: string, color: string, pages?: number[] };
