

export const sortBy = <T extends string>(order: 'ascending' | 'descending', ...keys: T[]) => <O extends Record<T, unknown>>(o1: O, o2: O) => {

	for (let key of keys) {
		if (typeof o1[ key ] !== 'string' && typeof o1[ key ] !== 'number') continue

		if (o1[ key ] > o2[ key ])
			return order === 'ascending' ? 1 : -1
		if (o1[ key ] < o2[ key ])
			return order === 'ascending' ? -1 : 1
	}
	return 0
}