import {
  CSSProperties,
  DependencyList,
  useEffect,
  useRef,
  useState,
} from "react";
import { usePageSrc } from "src/API/_urls";
import { useInterval } from "src/hooks/timers";
import { Children, Size } from "src/types";
import { getDynamicInitialScale } from "./utils";
import { Placeholder } from "./Placeholder";
import { cn } from "src/helpers";
import { useCallbackDebounce } from "src/hooks/callbacks";

const noop = () => {};

interface Props {
  onPageChange?: any;
  scrollToPage?: any;
  fileId?: number;
  page: number;
  containerSize?: Size | null;
  children?: ((size: Size) => Children) | Children;
  format?: "png" | "jpg";
  sourcePath?: string;
  fadeIn?: boolean;
  onError?: (err: Error) => void;
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
  style?: CSSProperties;
  className?: string;
}

const PdfPage = ({
  onPageChange = noop,
  scrollToPage = noop,
  fileId,
  page,
  containerSize,
  children = null,
  format = "jpg",
  sourcePath,
  fadeIn = true,
  onContextMenu = noop,
  style = {},
  className = "",
  ...props
}: Props) => {
  const { url, imgRef, containerRef, size } = usePdfPage({
    fileId,
    page,
    containerSize,
    format,
    sourcePath,
    onError(e) {
      props.onError?.(e);
      setError(e);
    },
  });

  const [isLoaded, setIsLoaded, prevIsLoaded] = useIsLoaded([fileId, page]);
  const [error, setError] = useState<Error | null>(null);
  return (
    <div
      ref={containerRef}
      className={`max-w-full max-h-full w-full h-full inline-flex justify-center animate-fadein-full ${
        className ? className : ""
      }`}
      style={{ writingMode: "vertical-lr", ...style }}
    >
      {!isLoaded && !error && <Placeholder animate={true} />}
      {error && (
        <em className="p-6 flex-center">
          An error has occurred: this page cannot be displayed.
          <br /> Please try again later.
        </em>
      )}

      <div
        onContextMenu={onContextMenu}
        className={`relative max-w-full max-h-full h-full inline-flex items-center justify-center ${
          !isLoaded && "hidden"
        }`}
      >
        {url && (
          <img
            className={`max-w-full max-h-full block shadow-medium ${
              fadeIn && !prevIsLoaded && "animate-fadein-full"
            }`}
            ref={imgRef}
            src={url}
            alt=""
            draggable={false}
            onLoad={() => setIsLoaded(true)}
            onError={(e) => console.log("onError")}
            onContextMenu={(e) => e.preventDefault()}
          />
        )}
        {isLoaded && size && rendersSomething(children) && (
          <PdfPageChildren
            className={cn`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 ${
              fadeIn && !prevIsLoaded && "animate-fadein-full"
            }`}
            pageSize={size}
            children={children}
          />
        )}
      </div>
    </div>
  );
};

export default PdfPage;

function rendersSomething(children: Children): boolean {
  if (!children) return false;
  if (typeof children === "function") return true; //cannot know the args, so it returns true to be safe
  if (Array.isArray(children)) {
    if (!children.length) return false;
    return !children.every(
      (child) => !child || (Array.isArray(child) && !child.length)
    );
  }
  return true;
}

interface UsePdfPageConfig {
  fileId?: number;
  page: number;
  containerSize: Size | null | undefined;
  format: "png" | "jpg";
  sourcePath?: string;
  onError: (err: Error) => void;
}

const usePdfPage = ({
  fileId,
  page,
  containerSize,
  format,
  sourcePath,
  onError,
}: UsePdfPageConfig) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [scale, setScale] = useState(getDynamicInitialScale(containerSize));
  const [size, setSize] = useState<Size | null>(null);
  const url = usePageSrc({ fileId, page, sourcePath, scale, format, onError });

  const handleSetSize = useCallbackDebounce(
    () => {
      if (!imgRef.current) return;
      setSize({
        width: imgRef.current.clientWidth,
        height: imgRef.current.clientHeight,
      });
    },
    100,
    false
  );

  function setupOnLoadListener() {
    if (!imgRef.current) return;
    imgRef.current.onload = handleSetSize;
  }

  function checkIfImageIsTooSmallAndLoadNewImageIfNecessary() {
    if (!imgRef.current || scale === 3) return;
    const bounds = imgRef.current.getBoundingClientRect();

    if (bounds.width > imgRef.current.naturalWidth) {
      setScale(3);
    }
  }

  useEffect(() => {
    const target = containerRef.current;
    if (!target || containerSize) return;
    const resizeObs = new ResizeObserver(handleSetSize);

    resizeObs.observe(target);
    return () => {
      resizeObs.unobserve(target);
    };
  }, [page, containerSize, handleSetSize]);

  useInterval(checkIfImageIsTooSmallAndLoadNewImageIfNecessary, 1000);
  useEffect(setupOnLoadListener, [handleSetSize, url]);

  useEffect(handleSetSize, [
    containerSize?.width,
    containerSize?.height,
    handleSetSize,
  ]);

  return { url, imgRef, size, containerRef };
};

const useIsLoaded = (deps: DependencyList) => {
  const isLoadedRef = useRef(false);
  const prevIsLoadedRef = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    prevIsLoadedRef.current = isLoadedRef.current;
    isLoadedRef.current = isLoaded;
  }, [isLoaded]);

  useEffect(() => {
    setIsLoaded(false);
    isLoadedRef.current = false;
    prevIsLoadedRef.current = false;
  }, deps); //eslint-disable-line

  return [isLoaded, setIsLoaded, prevIsLoadedRef.current] as const;
};

interface PdfPageChildrenProps {
  className: string;
  pageSize: Size | null | undefined;
  children?: ((size: Size) => Children) | Children;
}

const PdfPageChildren = ({
  className,
  pageSize,
  children,
}: PdfPageChildrenProps) => {
  if (!pageSize) return null;

  return (
    <div
      className={className}
      style={{
        height: pageSize.height + "px",
        width: pageSize.width + "px",
        writingMode: "initial",
      }}
    >
      {typeof children === "function" ? children(pageSize) : children}
    </div>
  );
};
