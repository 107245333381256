import HotTable from '@handsontable/react'
import Handsontable from 'handsontable'
import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react'
import { ExcelData } from '.'


export const useData = (setState: Dispatch<SetStateAction<ExcelData>>, ref: MutableRefObject<HotTable | null>) => {

	const handleChange = useCallback((changes: Handsontable.CellChange[] | null, source: Handsontable.ChangeSource) => {
		if (source === 'loadData' || source === 'populateFromArray') return
		setState(state => state.clone().setData(
			state.data.map((row, i) => {
				const newRow = [ ...row ]
				for (let [ rowI, colI, , val ] of (changes || [])) {
					if (rowI !== i) continue
					newRow[ +colI ] = val
				}
				return newRow
			})
		))
	}, [ setState ])

	return { handleChange } as const
}