import { useState, useEffect, useRef } from "react";
import Accordion from "./accordion";
import { useAnimateHeightFromZeroToAuto } from "src/hooks";
import { IAccordion } from "src/types";
import { cn } from "src/helpers";

interface Props extends IAccordion {
}

const ResizableAccordion: React.FC<Props> = ({ icon, iconColor="#3CABC4", label="title", children, className=''}) => {
  const [height, setHeight] = useState<number>(200);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [initialY, setInitialY] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement | null>(null)

    const handleExpend = () => {
      setExpanded(!expanded)
    }
    useEffect(() => {
      const handleMouseMove = (e: MouseEvent) => {
        if (!isDragging) return;
        
        const newHeight = height - (initialY - e.clientY);
        setExpanded(newHeight > 0)
        setHeight(newHeight > 0 ? newHeight : 0);
        setInitialY(e.clientY);
      };

      const handleMouseUp = () => {
        setIsDragging(false);
        document.body.style.userSelect = "auto";
      };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [height, initialY, isDragging]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    let h = 0;
        if(ref?.current){
          h = ref?.current?.offsetHeight;
        }
    setHeight(h)
    setInitialY(e.clientY);
    document.body.style.userSelect = "none";
  };
  useAnimateHeightFromZeroToAuto(ref, 200, expanded)
  return (
    <Accordion 
        className={cn`py-2 ${className} `}
        icon={icon}
        iconColor={iconColor}
        label={label}
        iconWidth={18}
        iconHeight={18}
        expanded={expanded}
        setExpend={handleExpend}
    >
      <div
        ref={ref}
        className="relative grid overflow-auto bg-white rounded-md grid-rows-between "
        style={{ height: `${height}px` }}
      >
        {children}
        <div
            className={`sticky z-[2] w-full h-1 bottom-0 cursor-row-r `}
            onMouseDown={handleMouseDown}
        />
      </div>
    </Accordion>
  );
};

export default ResizableAccordion;