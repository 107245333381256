import React, { ReactChild, ReactChildren, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary'
import { Loader } from 'src/lib/loader'
import ErrorBoundaryFallback from './ErrorBoundaryFallback'
import ErrorPage from './ErrorPage'


interface Props {
	errors: Array<Error | string | null | undefined | false>
	isLoading: boolean
	children: ReactNode | ReactChild | ReactChildren | null
	isRetrying?: boolean
	retry?: () => void
}

const AsyncPage: React.FC<Props> = ({
	errors,
	isLoading,
	isRetrying = false,
	retry,
	children
}: Props) => {

	const error = errors.find(error => !!error)

	if (!!error) {
		const _error = typeof error === 'string' ? new Error(error) : error
		return (
			<ErrorPage
				message={_error.message}
				retry={retry}
				isRetrying={isRetrying}
				backButton
			/>
		)
	}

	if (isLoading)
		return (
			<Loader.Container role='img' >
				<Loader.Cool className='text-primary' />
			</Loader.Container>
		)


	return (
		<ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={window.location.reload}>
			{children}
		</ErrorBoundary>
	)

}

export default AsyncPage;