import styles from './styles.module.css'
import React from 'react';
import { Children } from 'src/types';


interface Props {
	children?: Children
	className?: string
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
	style?: React.CSSProperties
}

const MenuLink = ({
	children = null,
	className = '',
	style = {},
	...props
}: Props) => {



	return (
		<li>
			<button className={`${styles.button} ${className}`} style={style} {...props}>
				{children}
			</button>
		</li>
	)
}

export default MenuLink;