import { useEffect, useRef } from 'react'
import { useUpdatedRef } from 'src/hooks/refs'
import { useGlobalMouseMove, useGlobalMouseUp } from 'src/hooks/listeners'
import { dom } from 'src/helpers'


interface Config {
	rootRef: React.MutableRefObject<HTMLDivElement | null>
	disableSpan: boolean
	onSpanStart?: (e: MouseEvent) => void,
	onSpan?: (e: MouseEvent) => void,
	onSpanEnd?: () => void
}

export const useSpan = ({
	rootRef,
	disableSpan,
	...props
}: Config) => {

	const prevSpanCoordsRef = useRef({ x: 0, y: 0 })

	useEffect(updateMouseCursor, [ disableSpan ]) //eslint-disable-line

	useSpanListeners({
		rootRef,
		disableSpan,
		onSpanStart(e) {
			updatePrevCoords(e)
			props.onSpanStart?.(e)
		},
		onSpan(e) {
			scroll(e)
			updatePrevCoords(e)
			props.onSpan?.(e)
		},
		onSpanEnd() {
			props.onSpanEnd?.()
		},
	})


	function updatePrevCoords(e: MouseEvent) {
		prevSpanCoordsRef.current = {
			x: e.clientX,
			y: e.clientY
		}
	}

	function scroll(e: MouseEvent) {
		rootRef.current?.scrollBy({
			left: prevSpanCoordsRef.current.x - e.clientX,
			top: prevSpanCoordsRef.current.y - e.clientY,
		})
	}

	function updateMouseCursor() {
		if (!rootRef.current) return
		dom.toggleClass('grab', !disableSpan)(rootRef.current)
	}
}


interface ListenersConfig {
	rootRef: React.MutableRefObject<HTMLDivElement | null>
	disableSpan: boolean
	onSpan: (e: MouseEvent) => void
	onSpanStart: (e: MouseEvent) => void
	onSpanEnd?: () => void
}



const useSpanListeners = ({
	rootRef,
	disableSpan,
	onSpan,
	onSpanStart,
	onSpanEnd = () => { }
}: ListenersConfig) => {

	const mouseDownRef = useRef<boolean>(false)
	const hasSpanStartedRef = useRef<boolean>(false)
	const disableSpanRef = useUpdatedRef(disableSpan)

	useEffect(listenMouseDown, []) //eslint-disable-line
	useEffect(handleSpanEnd, [ disableSpan ]) //eslint-disable-line
	useGlobalMouseUp(listenMouseUp)
	useGlobalMouseMove(handleSpan)


	function listenMouseDown() {
		const root = rootRef.current
		if (!root) return

		const listener = (e: MouseEvent) => {
			mouseDownRef.current = true
			handleSpanStart(e)
		}

		root.addEventListener('mousedown', listener)
		return () => root.removeEventListener('mousedown', listener)
	}

	function listenMouseUp() {
		mouseDownRef.current = false
		hasSpanStartedRef.current && onSpanEnd()
		hasSpanStartedRef.current = false
	}

	function handleSpanStart(e: MouseEvent) {
		if (!hasSpanStartedRef.current && mouseDownRef.current && !disableSpanRef.current) {
			hasSpanStartedRef.current = true
			onSpanStart(e)
			return
		}
	}

	function handleSpanEnd() {
		if (disableSpan && hasSpanStartedRef.current) {
			hasSpanStartedRef.current = false
			onSpanEnd()
			return
		}
	}

	function handleSpan(e: MouseEvent) {
		if (disableSpan || !mouseDownRef.current || !hasSpanStartedRef.current) return
		onSpan(e)
	}
}