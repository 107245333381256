import { useEffect, useRef } from 'react'
import { useGlobalWheel } from 'src/hooks/listeners'
import { DisableZoom } from './types'
import { getNewTargetSize, getNextScale, getScrollOffset, getScrollRatio, handleDisableZoom, scroll, setScaleOnTarget, setTransformOrigin, getDefaultScaleScrollRatio, getDefaultScaleScrollOffset } from './utils_scale-wheel'


const noop = () => { }


interface Config {
	centerX: boolean,
	centerY: boolean
	defaultScale: [ number ]
	disableZoom: DisableZoom
	onZoom?: (scale: number) => void
	rootRef: React.MutableRefObject<HTMLDivElement | null>
	strength: number
	targetRef: React.MutableRefObject<HTMLDivElement | null>
}

export const useScale = ({
	centerX,
	centerY,
	defaultScale = [ 1 ],
	disableZoom,
	onZoom = noop,
	rootRef,
	strength,
	targetRef,
}: Config) => {

	const scaleRef = useRef<number>(defaultScale[ 0 ])

	useWheelScale({ centerX, centerY, disableZoom, onZoom, rootRef, scaleRef, setScale, strength, targetRef })
	useDefaultScale({ centerX, centerY, defaultScale, onZoom, rootRef, setScale, targetRef, })

	function setScale(scale: number, target: HTMLDivElement) {
		setScaleOnTarget(scale)(target)
		scaleRef.current = scale
	}
}


interface WheelScaleConfig extends Omit<Config, 'defaultScale'> {
	scaleRef: React.MutableRefObject<number>
	setScale: (scale: number, target: HTMLDivElement) => void
}

const useWheelScale = ({
	centerX,
	centerY,
	disableZoom,
	onZoom = () => { },
	rootRef,
	scaleRef,
	setScale,
	strength,
	targetRef,
}: WheelScaleConfig) => {

	useGlobalWheel(e => {
		const isDisabled = handleDisableZoom(disableZoom, e)
		if (!targetRef.current || !rootRef.current || isDisabled) return

		const newScale = getNextScale(scaleRef.current, e, strength)
		const scrollRatio = getScrollRatio(targetRef.current, e)
		const scrollOffset = getScrollOffset(rootRef.current, e)
		setScale(newScale, targetRef.current)
		const targetSize = getNewTargetSize(targetRef.current)
		setTransformOrigin(targetRef.current, targetSize, rootRef.current, centerX, centerY) //center when no overflow, 0 when overflow
		scroll(rootRef.current, targetSize, scrollRatio, scrollOffset)
		onZoom(newScale)
	})
}

interface DefaultScaleConfig {
	centerX: boolean,
	centerY: boolean
	defaultScale: [ number ]
	onZoom: (scale: number) => void
	rootRef: React.MutableRefObject<HTMLDivElement | null>
	setScale: (scale: number, target: HTMLDivElement) => void
	targetRef: React.MutableRefObject<HTMLDivElement | null>

}

function useDefaultScale({
	centerX,
	centerY,
	defaultScale,
	onZoom,
	rootRef,
	setScale,
	targetRef
}: DefaultScaleConfig) {

	function handleChangeDefaultScale() {
		if (!targetRef.current || !rootRef.current) return

		const newScale = defaultScale[ 0 ]
		const scrollRatio = getDefaultScaleScrollRatio(targetRef.current, rootRef.current, centerX, centerY)
		const scrollOffset = getDefaultScaleScrollOffset(rootRef.current, centerX, centerY)
		setScale(newScale, targetRef.current)
		const targetSize = getNewTargetSize(targetRef.current)
		setTransformOrigin(targetRef.current, targetSize, rootRef.current, centerX, centerY)
		scroll(rootRef.current, targetSize, scrollRatio, scrollOffset)
		onZoom(newScale)

	}

	useEffect(handleChangeDefaultScale, [ defaultScale ]) //eslint-disable-line
}