import HotTable from '@handsontable/react'
import { MutableRefObject } from 'react'
import { ExcelData, MergedCell } from '.'



export const selection = {

	/**
	 * 
	 * @param ref 
	 * @returns [ rowStart, colStart, rowEnd, colEnd ]
	 */
	getTuple(ref: MutableRefObject<HotTable | null>): [ number, number, number, number ] | null {
		const hot = ref.current?.hotInstance
		const selected = hot?.getSelected()?.[ 0 ]
		if (!hot || !selected) return null
		return selected
	},

	getObject(ref: MutableRefObject<HotTable | null>): MergedCell | null {
		const selected = selection.getTuple(ref)
		if (!selected) return null
		const [ rowStart, colStart, rowEnd, colEnd ] = selected
		return {
			row: rowStart,
			col: colStart,
			rowspan: rowEnd - rowStart + 1,
			colspan: colEnd - colStart + 1,
		}
	},

	getList(ref: MutableRefObject<HotTable | null>): { row: number, col: number }[] | null {
		const selected = selection.getTuple(ref)
		if (!selected) return null
		const [ rowStart, colStart, rowEnd, colEnd ] = selected
		const res: { row: number, col: number }[] = []

		for (let row = rowStart; row <= rowEnd; row++)
			for (let col = colStart; col <= colEnd; col++)
				res.push({ row, col })
		return res
	},

	getRows(ref: MutableRefObject<HotTable | null>): number[] | null {
		const selected = selection.getTuple(ref)
		if (!selected) return null
		const [ rowStart, , rowEnd ] = selected
		let res: number[] = []
		for (let i = rowStart; i <= rowEnd; i++)
			res.push(i)
		return res
	},

	getCols(ref: MutableRefObject<HotTable | null>): number[] | null {
		const selected = selection.getTuple(ref)
		if (!selected) return null
		const [ , colStart, , colEnd ] = selected
		let res: number[] = []
		for (let i = colStart; i <= colEnd; i++)
			res.push(i)
		return res
	},

	translate(axis: 'x' | 'y', by: number, ref: MutableRefObject<HotTable | null>) {
		const selectedCells = selection.getTuple(ref)
		const hot = ref.current?.hotInstance
		if (!selectedCells || !hot) return
		hot.selectCells([ [
			selectedCells[ 0 ] + (axis === 'y' ? by : 0),
			selectedCells[ 1 ] + (axis === 'x' ? by : 0),
			selectedCells[ 2 ] + (axis === 'y' ? by : 0),
			selectedCells[ 3 ] + (axis === 'x' ? by : 0),
		] ])
	},

	getText(ref: MutableRefObject<HotTable | null>, state: ExcelData): string {
		const selected = selection.getList(ref)
		if (!selected) return ''

		let res = ''

		for (let i = 0; i < selected.length; i++) {
			const prev = selected[ i - 1 ]
			const curr = selected[ i ]

			if (prev && curr.row !== prev.row) {
				res += '\n'
			}
			else if (prev && curr.col !== prev.col) {
				res += '\t'
			}
			res += state.data[ curr.row ][ curr.col ] || ''
		}
		return res
	}
}
