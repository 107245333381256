import React from 'react';
import { Grid } from 'src/lib/grid';
import { Children } from 'src/types';
import { PageMetadata } from '.';
import { MetadataSnippet } from './PageMetadata';


interface Props {
	title: Children
	action?: Children
	metadata?: MetadataSnippet[] | false
	description?: Children
}

const PageHead = ({
	title,
	action,
	metadata,
	description
}: Props) => {



	return (
		<>
			<Grid.Root columns={2} growCols={[ 1 ]}>
				<Grid.Col className='flex items-center'>
					{title}
				</Grid.Col>

				<Grid.Col className='flex items-center justify-end'>
					{action}
				</Grid.Col>
			</Grid.Root>

			{metadata && <PageMetadata className='mt-1' data={metadata} />}

			{description && <div className='mb-6 opacity-85'>{description}</div>}
		</>
	)
}

export default PageHead;