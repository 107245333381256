import useSWR, { SWRConfiguration } from 'swr'
import axios from 'axios'
import { MODULE } from 'src/data/Modules'
import { FileTreeNode, ApiFileTreeNode } from 'src/data/FileTreeNode'
import { useEffect, useRef } from 'react'
import { ApiFileBase, FileBase } from 'src/data/File'
import { isNullish, array } from 'src/helpers'
import { useSwrCache } from './utils'

export function postSelectFile(projectId: number, fileId: number, module: MODULE) {
	return axios
		.put("cpf/v1/file/select/", null, {
			params: {
				project_id: projectId,
				file_id: fileId,
				module_type: module
			}
		})
		.then(res => res.data)
}

export function postDeselectFile(projectId: number, fileId: number, module: MODULE) {
	return axios
		.delete("cpf/v1/file/select/", {
			params: {
				project_id: projectId,
				file_id: fileId,
				module_type: module
			}
		})
}


export const fetchFileTreeNode = (url: string, projectId: string | number, nodeId: number, module: MODULE): Promise<FileTreeNode | null> => (
	axios
		.get(url, {
			params: {
				project_id: projectId,
				file_id: nodeId,
				module_type: module,
			}
		})
		.then(res => res.data as ApiFileTreeNode)
		// .then(apiNode => (console.log(apiNode), apiNode))
		.then(apiNode => FileTreeNode.fromApi(apiNode))
)

export const useFetchFileTreeNode = (projectId: string | number | undefined, nodeId: string | number | undefined, module: MODULE, config?: SWRConfiguration<FileTreeNode | null, Error>) => (
	useSWR(
		!isNullish(projectId) && !isNullish(nodeId)
			? [ '/cpf/v1/file/list/', projectId + '', nodeId + '', module ]
			: null,
		fetchFileTreeNode,
		config
	)
)

export const useFetchFileTreeNodeWithPrevData = (projectId: number | undefined, nodeId: number | undefined, module: MODULE = MODULE.NONE) => {
	const prevProjectIdRef = useRef<number>()
	const prevFileTreeNodeRef = useRef<FileTreeNode | null>(null)

	const { data, ...rest } = useFetchFileTreeNode(projectId, nodeId, module, {
		//keep displaying old values while fetching new nodeId
		//set to no data if project changes
		initialData: prevProjectIdRef.current === projectId ? prevFileTreeNodeRef.current : null,
		revalidateOnMount: true
	})


	useEffect(() => {
		if (data)
			prevFileTreeNodeRef.current = data
	}, [ data ])


	useEffect(() => {
		prevProjectIdRef.current = projectId
	}, [ projectId ])

	return { data, ...rest }
}


function fetchSelectedFiles(url: string, projectId: string | number, module: MODULE): Promise<FileBase[]> {
	return axios
		.get(url, {
			params: {
				project_id: projectId,
				module_type: module
			}
		})
		.then(res => res.data as ApiFileBase[])
		.then(apiFileBaseArray => apiFileBaseArray.map(apiFileBase => FileBase.fromApi(apiFileBase)))
		.then(array.sortBy('ascending', 'name'))
}

export function useFetchSelectedFiles(projectId: string | number | undefined, module: MODULE, config?: SWRConfiguration<FileBase[], Error>) {
	return useSWR(projectId && module ? [ '/cpf/v1/file/selection/', projectId, module ] : null, fetchSelectedFiles, config)
}

export function useSelectedFilesCache(projectId: string | number | undefined, module: MODULE, config?: SWRConfiguration<FileBase[], Error>) {
	return useSwrCache(projectId && module ? [ '/cpf/v1/file/selection/', projectId, module ] : null, fetchSelectedFiles, config)
}

