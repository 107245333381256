import './LeftNav.css'
import { useEffect, useRef, useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom'
import { Divisor, ProjectIcon } from 'src/components';
import { Heading, Text } from 'src/lib/text';
import { useActiveProjectCache } from 'src/API/projects';
import { Left } from 'src/lib/layout'
import { Children } from 'src/types';
import { useAnimateHeightFromZeroToAuto } from 'src/hooks';
import { cn } from 'src/helpers';
import { Anchor } from 'src/lib/button';
import { Icon } from 'src/lib/icons';
import { MODULE } from 'src/data/Modules';
import { CONFIG } from 'src/cp.config';
import { useUserBookmark } from 'src/API/bookmark';

const leftNavLinkCn = cn`
	relative 
	flex 
	items-center 
	text-default 
	py-2 
	px-3
	hover:text-primary-xdarker
	hover:bg-gray-100 
	transition-colors 
	bg-transparent 
	border-none 
	w-full 
	active:font-bolder 
	active:text-primary-darker
	group-collapse:py-2.5
`

const LeftNav = () => {

	const { data: activeProject } = useActiveProjectCache()

	if (!activeProject)
		return (
			<Left className='left_nav' />
		)

	return (
		<Left className='left_nav group'>
			<div className={cn`
				flex 
				items-center 
				p-3
				group-collapse:p-0 
				group-collapse:mb-3
			`}>
				<div className='max-w-full'>
					<Link to={`/projects/${activeProject.id}/files/0`} className='flex items-center hover:underline'>
						<ProjectIcon
							className={cn`
							transform 
							transition-transform 
							hover:scale-110
							group-collapse:mr-0
						`}
							projectName={activeProject.title}
							small
						/>
						<Heading tag='h3' className='truncate group-collapse:hidden' title={activeProject.title}>{activeProject.title}</Heading>
					</Link>
				</div>
			</div>

			<Divisor className='mt-0 mb-2 group-collapse:hidden' />

			<ModuleMenu
				structure={{
					icon: <Icon icon='search' />,
					name: 'ARK Search',
					to: `/projects/${activeProject.id}/ark`
				}}
			/>

			<ModuleMenu
				structure={{
					icon: <Icon icon='bom' />,
					name: 'BOM Generation',
					submenu: [ {
						name: CONFIG.BOM.OVERVIEW.SHORT_TITLE,
						to: `/projects/${activeProject.id}/bom/overview`,
						disabled: !activeProject.isEnabled.bom.overview
					}, {
						name: CONFIG.BOM.PROCESS.SHORT_TITLE,
						to: `/projects/${activeProject.id}/bom/process/0`,
						disabled: !activeProject.isEnabled.bom.process
					}, {
						name: CONFIG.BOM.FIND.SHORT_TITLE,
						to: `/projects/${activeProject.id}/bom/find`,
						disabled: !activeProject.isEnabled.bom.findTables
					}, {
						name: CONFIG.BOM.REVIEW.SHORT_TITLE,
						to: `/projects/${activeProject.id}/bom/review`,
						disabled: !activeProject.isEnabled.bom.reviewTables
					} ]
				}}
			/>
		</Left>
	)
}

export default LeftNav;



interface SubMenuEntry {
	name: string,
	to: string
	disabled?: boolean
}

interface ModuleMenuStructure {
	icon: Children
	name: string,
	to?: string
	submenu?: SubMenuEntry[]
}

interface ModuleSubmenuStructure {
	icon: Children
	name: string,
	submenu: SubMenuEntry[]
}

interface ModuleMenuProps {
	structure: ModuleMenuStructure
}

const ModuleMenu = ({
	structure
}: ModuleMenuProps) => {

	const [ isCollapsed ] = useUserBookmark('leftNav', false)

	if (structure.to) {
		return (
			<NavLink className={`${leftNavLinkCn} bigger_icon_when_collapse`} to={structure.to} title={structure.name}>
				<div className='flex-center icon_container'>{structure.icon}</div>
				<div className='ml-2 group-collapse:hidden'>{structure.name}</div>
			</NavLink>
		)
	}

	if (!structure.submenu)
		throw new Error('structure requires "to" or "submenu"')

	return isCollapsed
		? <CollapsedNavbarModuleMenu structure={structure as ModuleSubmenuStructure} />
		: <ExpandedNavbarModuleMenu structure={structure as ModuleSubmenuStructure} />
}


const ExpandedNavbarModuleMenu = ({
	structure
}: { structure: ModuleSubmenuStructure }) => {

	const location = useLocation()
	const submenuRef = useRef<HTMLUListElement | null>(null)
	const [ isExpanded, setIsExpanded ] = useState(getIsAnyLinkActive())
	useAnimateHeightFromZeroToAuto(submenuRef, 200, isExpanded)


	useEffect(() => {
		setIsExpanded(getIsAnyLinkActive())
	}, [ location ])//eslint-disable-line

	function getIsAnyLinkActive() {
		return !!structure.submenu?.some(entry => location.pathname.startsWith(entry.to))
	}


	return (
		<>
			<button className={leftNavLinkCn} onClick={() => setIsExpanded(v => !v)}>
				<div className='flex-center'>{structure.icon}</div>

				<div className='ml-2'>{structure.name}</div>

				<Icon icon='chevron-right' className={cn`
					ml-auto 
					w-4 
					transform 
					transition-transform 
					${isExpanded ? 'rotate-90' : ''}
				`} />
			</button >

			{/* DROPDOWN */}
			<ul ref={submenuRef}
				className={cn`
					text-left 
					my-0 
					shadow-inner-soft 
					bg-light2 
					overflow-hidden 
					list-disc list-inside
				`}>
				<div className='py-1'>
					{structure.submenu.map((entry, i) => (
						<Anchor.Inline
							className={cn`
								relative 
								w-full
								flex 
								items-center 
								justify-start
								h-8 
								text-default 
								transition-colors 
								active:text-primary-darker
								group 
								hover:text-primary-xdarker 
								active:font-semibold 
								no-underline
							`}
							to={entry.to}
							key={`left_nav-subentry-${entry.to}${i}`}
							disabled={entry.disabled}>
							{/* active blue left bar when expanded */}
							<div
								style={{ left: '0', width: '4px' }}
								className={cn`
									absolute 
									-inset-y-1 
									bg-gray-300 
									transition-colors 
									group-active:z-10 
									group-active:bg-primary-darker
								`}
							/>
							<li className='pl-6 '>{entry.name}</li>
						</Anchor.Inline>
					))}
				</div>
			</ul>
		</>
	)
}

const CollapsedNavbarModuleMenu = ({
	structure
}: { structure: ModuleSubmenuStructure }) => {

	const location = useLocation()

	const [ isExpanded, setIsExpanded ] = useState(false)
	const [ isHover, setIsHover ] = useState(false)
	const [ isActive, setIsActive ] = useState(getIsAnyLinkActive())


	useEffect(() => {
		setIsActive(getIsAnyLinkActive())
	}, [ location ])//eslint-disable-line

	function getIsAnyLinkActive() {
		return !!structure.submenu?.some(entry => location.pathname.startsWith(entry.to))
	}

	const shouldDisplaySubmenu = isExpanded || isHover

	return (
		<button
			className={cn`
				bigger_icon_when_collapse
				${leftNavLinkCn}
				${shouldDisplaySubmenu && 'bg-gray-100'}
				${(isActive || isHover || isExpanded) && 'text-primary-darker'}
			`}
			onClick={() => setIsExpanded(v => !v)}
			onBlur={() => setIsExpanded(false)}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}>

			<div className='flex-center icon_container'>{structure.icon}</div>

			{/* DROPDOWN */}
			<ul className={cn`
				absolute 
				left-full
				top-0
				mt-0
				ml-1
				pl-0
				bg-white
				text-left
				-opacity-0
				animate-fadein-full-left
				shadow-md
				${(true && !shouldDisplaySubmenu) && 'hidden'}
			`}>
				{structure.submenu.map((entry, i) => (
					<Anchor.Inline
						className='justify-start w-full no-underline'
						to={entry.to}
						key={`left_nav-subentry-${entry.to}${i}`}
						disabled={entry.disabled}>

						<li className={`
							w-full
							list-none 
							hover:bg-gray-100 
							hover:text-black
						`}>
							<Text className={`
								whitespace-pre
								pl-4 
								py-2
								pr-10 
								transform 
								transition-transform
								hover:translate-x-0.5
								hover:text-primary-xdarker
							`}>{entry.name}</Text>
						</li>
					</Anchor.Inline>
				))}

				{/* Invisible div a bit bigger than the submenu in order to trigger hover state */}
				<div className='absolute bg-opacity-50 -inset-3 z-m1' />
			</ul>
			{/* Invisible div a bit bigger than the submenu in order to trigger hover state */}
			{isHover && <div className='absolute transform rotate-45 bg-opacity-50 left-1/4 -right-1/4 -bottom-1 top-1/2' />}
		</button >
	)
}