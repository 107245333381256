import axios, { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useUpdatedRef } from './refs';


const noop = (err: AxiosError) => Promise.reject(err)

interface Config {
	type: 'response' | 'request',
	on401?: (err: AxiosError) => void
}

export const useAxiosInterceptor = ({
	type,
	on401 = noop
}: Config) => {
	const on401Ref = useUpdatedRef(on401)

	useEffect(() => {
		axios.interceptors[ type ].use(
			res => {
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				return res;
			},
			(err: AxiosError) => {
				// Any status codes that falls outside the range of 2xx cause this function to trigger
				// Do something with response error
				if (err.response?.status === 401) on401Ref.current(err)
				return Promise.reject(err);
			}
		);
	}, [ type, on401Ref ])

}