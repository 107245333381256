import { niceFormat } from './niceFormat'
import { ago } from './ago'
import { minutes } from './minutes'
import { seconds } from './seconds'
import { today } from './today'

export const time = {
	minutes,
	seconds,
	niceFormat,
	ago,
	today
}