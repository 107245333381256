import { Size } from 'src/types'
import { ApiPnidByOpc, PnidByOpc } from './PnidRecord'

interface Bounds {
	minX: number
	minY: number
	maxX: number
	maxY: number
}

interface KonvaBounds {
	x: number
	y: number
	width: number
	height: number
}

export interface ApiHotspot {
	tag: string
	sheet: number
	str_box: [ number, number, number, number ]
	pnids: ApiPnidByOpc[]
}

interface IHotspot {
	id: string
	sheet: number
	bounds: Bounds
	pnid: PnidByOpc | null
}

export class Hotspot implements IHotspot {
	id: string
	sheet: number
	bounds: Bounds
	pnid: PnidByOpc | null

	constructor(data: IHotspot | Hotspot) {
		this.id = data.id
		this.sheet = data.sheet
		this.bounds = data.bounds
		this.pnid = data.pnid
	}

	static fromApi(apiData: ApiHotspot): Hotspot {
		const data: IHotspot = {
			id: apiData.tag,
			sheet: apiData.sheet,
			bounds: {
				minX: apiData.str_box[ 0 ],
				minY: apiData.str_box[ 1 ],
				maxX: apiData.str_box[ 2 ],
				maxY: apiData.str_box[ 3 ],
			},
			pnid: apiData.pnids.length
				? PnidByOpc.fromApi(apiData.pnids[ 0 ])
				: null
		}
		return new Hotspot(data)
	}

	static convertBoundsToKonva(pageSize: Size): (data: Hotspot[ 'bounds' ]) => KonvaBounds {
		return (data: Hotspot[ 'bounds' ]) => ({
			x: Math.round(pageSize.width * data.minX),
			y: Math.round(pageSize.height * data.minY),
			width: Math.round(pageSize.width * (data.maxX - data.minX)),
			height: Math.round(pageSize.height * (data.maxY - data.minY)),
		})
	}
}
