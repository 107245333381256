import { createAnchor, createButton } from './utils';

export const Anchor = {
	Contained: createAnchor('contained'),
	Text: createAnchor('upper_text'),
	Inline: createAnchor('inline_text'),
	Disc: createAnchor('disc'),
	Icon: createAnchor('icon'),
}

export const Button = {
	Contained: createButton('contained'),
	Text: createButton('upper_text'),
	Inline: createButton('inline_text'),
	Disc: createButton('disc'),
	Icon: createButton('icon'),
}
