import { useEffect, useRef, useState } from 'react'

const useIsHeaderScrolled = () => {
	const headerRef = useRef<HTMLDivElement>(null)
	const [ isScrolled, setIsScrolled ] = useState(false)

	useEffect(() => {
		const header = headerRef.current
		if (!header) return
		const threshold = 0.8
		const observer = new IntersectionObserver(
			([ e ]) => setIsScrolled(e.intersectionRatio < threshold),
			{ threshold }
		);
		observer.observe(header)
		return () => observer.unobserve(header)
	}, [])

	return [ isScrolled, headerRef ] as const
}

export default useIsHeaderScrolled