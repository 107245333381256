import { useEffect, useRef } from 'react';
import { postTriggerImport, useFetchImportList } from 'src/API/imports';
import { Import } from 'src/data/Import';
import { Button } from 'src/lib/button';
import { Loader } from 'src/lib/loader';
import { Dialog } from 'src/lib/modals';
import { Text } from 'src/lib/text';
import { useIsImportListBusy } from './utils';
import { array } from 'src/helpers';
import { Icon } from 'src/lib/icons';


interface Props {
	close: () => void
	isOpen: boolean
	onImportSuccess: () => void
}

export const ImportFilesDialog = ({
	close,
	isOpen,
	onImportSuccess,
}: Props) => {


	return (
		<Dialog
			isOpen={isOpen}
			header='Import Files'
			content={<Content onImportSuccess={onImportSuccess} />}
			footer={<Footer close={close} />}
			onClickOutside={close}
			minWidth={500}
			minHeight={300}
		/>
	)
}


const Content = ({
	onImportSuccess
}: { onImportSuccess: Props[ 'onImportSuccess' ] }) => {

	const someImportIsBusy = useIsImportListBusy()
	const someImportWasBusyRef = useRef(someImportIsBusy)

	const { data: importList, error, mutate } = useFetchImportList({
		refreshInterval: someImportIsBusy ? 2000 : undefined,
		dedupingInterval: 2000
	})

	function handleImportStart(imp: Import) {
		postTriggerImport(imp)
		mutate((
			importList => importList
				? array.replace(imp, imp.clone().setToBusy())(importList)
				: importList
		), false
		)
	}

	useEffect(() => {
		if (someImportWasBusyRef.current && !someImportIsBusy)
			onImportSuccess()
		someImportWasBusyRef.current = someImportIsBusy
	}, [ someImportIsBusy ]) //eslint-disable-line

	if (error)
		return (
			<em className='flex-center'>{error.message}</em>
		)
	if (!importList)
		return (
			<div className='flex-center py-2'><Loader.Cool width={40} /></div>
		)
	return (
		<ul className='flex flex-col gap-y-4 mt-5 pb-2'>
			{importList.map((data, i) => (
				<li className='inline-flex items-center gap-4' key={`import-${data.id}`}>
					<div className='rounded-full h-1.5 w-1.5 bg-current' />
					<div>
						<Text stronger>{data.title}</Text>
						<Text opacity={0.8}>{data.description}</Text>
					</div>

					<div className='ml-auto flex items-center justify-end'>
						<ImportButton
							isBusy={data.isBusy}
							isComplete={data.isImported}
							isDisabled={someImportIsBusy}
							onClick={() => handleImportStart(data)}
						/>
					</div>
				</li>
			))}
		</ul>
	)
}

const ImportButton = ({
	isBusy,
	isComplete,
	isDisabled,
	onClick,
}: { isBusy: boolean, isComplete: boolean, isDisabled: boolean, onClick: () => void }) => {

	if (isComplete)
		return (
			<Icon icon='tick' className='text-success' />
		)

	if (isBusy)
		return (
			<Loader.Circular width={16} strokeWidth={6} className='text-disabled' />
		)

	return (
		<Button.Inline
			intent='primary'
			onClick={onClick}
			disabled={isDisabled}>
			<strong>import</strong>
		</Button.Inline>
	)
}

const Footer = ({
	close
}: { close: () => void }) => {

	return (
		<div className='flex justify-end'>
			<Button.Text onClick={close}>CLOSE</Button.Text>
		</div>
	)
}

