import style from './Navbar.module.css'
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { Anchor, Button } from 'src/lib/button';
import ProjectsDropdown from './ProjectsDropdown'
import ProfileDropdown from './ProfileDropdown'
import TasksDropdown from './TasksDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import { cn } from 'src/helpers';
import { Icon } from 'src/lib/icons';
import { Tooltip, Popover } from 'src/lib/modals'
import { useFetchTasks } from 'src/API/tasks';


interface Props {
	onLogout: () => void
}

const Navbar = ({
	onLogout
}: Props) => {

	const { projectId } = useParams<{ projectId?: string }>()
	const [ isProjectsOpen, setIsProjectsOpen ] = useState(false)
	const [ isProfileOpen, setIsProfileOpen ] = useState(false)
	const [ isNotificationsOpen, setIsNotificationsOpen ] = useState(false)
	const [ isTasksOpen, setIsTasksOpen ] = useState(false)
	const { data: tasks } = useFetchTasks(projectId)

	return (
		<nav className={style.nav}>
			<ul className={`${style.ul} ${style.left}`}>
				<li>
					<Link to='/' className={style.logo} />
				</li>

				<li>
					<Tooltip text="Not available yet" delay={400}>
						<Anchor.Inline to='/dashboard'>
							Dashboard
						</Anchor.Inline>
					</Tooltip>		
				</li>

				<li>
					<Popover
						content={<ProjectsDropdown />}
						positions={[ 'bottom' ]}
						nudgeLeft={40}
						padding={4}
						onToggle={setIsProjectsOpen}>
						<Button.Inline
							className={cn`hover:no-underline gap-0.5 ${isProjectsOpen && 'active'}`}
							iconSize={12}>
							Projects
							<Icon icon='chevron-right' strokeWidth={4} style={{ transform: 'rotate(90deg)', margin: '2px 0 0 6px' }} />
						</Button.Inline>
					</Popover>
				</li>
			</ul>

			<ul className={`${style.ul} ${style.right}`}>
				<li>
					<Tooltip
						text="Notifications"
						padding={8}
						delay={400}
						disabled={isProfileOpen || isNotificationsOpen || isTasksOpen}>
						<Popover
							content={<NotificationsDropdown />}
							positions={[ 'bottom' ]}
							padding={8}
							onToggle={setIsNotificationsOpen}>
							<Button.Disc
								className={`light ${isNotificationsOpen ? 'hover' : ''}`}
								iconSize={20}>
								<Icon icon='notifications' />
							</Button.Disc>
						</Popover>
					</Tooltip>
				</li>

				{projectId && (
					<li>
						<Tooltip
							text="Tasks"
							padding={8}
							delay={400}
							disabled={isProfileOpen || isNotificationsOpen || isTasksOpen}>
							<Popover
								content={<TasksDropdown projectId={projectId} />}
								positions={[ 'bottom' ]}
								padding={8}
								onToggle={setIsTasksOpen}>
								<Button.Disc
									className={`light relative ${isTasksOpen ? 'hover' : ''}`}
									iconSize={20}>
									<Icon icon='applications' />
									{!!tasks?.length && (
										<div className='absolute w-4 h-4 text-xs font-bold bg-red-500 rounded-full left-7 bottom-6 flex-center' style={{ fontSize: '10px' }}>
											{tasks?.length}
										</div>
									)}
								</Button.Disc>
							</Popover>
						</Tooltip>
					</li>
				)}

				<li>
					<Tooltip
						text="Manage Account"
						padding={8}
						delay={400}
						disabled={isProfileOpen || isNotificationsOpen || isTasksOpen}>
						<Popover
							content={<ProfileDropdown onLogout={onLogout} />}
							positions={[ 'bottom' ]}
							padding={8}
							onToggle={setIsProfileOpen}>
							<Button.Disc
								className={`light ${isProfileOpen ? 'hover' : ''}`}
								iconSize={20}>
								<Icon icon='user' />
							</Button.Disc>
						</Popover>
					</Tooltip>
				</li>
			</ul>
		</nav>
	)
}

export default Navbar;