import { useState, Dispatch, useEffect } from 'react';
import { Tag } from './tag';
import { tagColorsMap, TagUI } from 'src/types/ui';
import { CheckBox } from 'src/components/checkbox';
import { useFilteredPagesContext } from 'src/contexts/filtered-pages-ctx';
import { ITag } from 'src/data/tag';
import { cn } from 'src/helpers';

interface IProps {
  tags: TagUI[];
  className?:string;
  setSelected: Dispatch<TagUI[]>;
  valueEmitter?: Dispatch<TagUI[]>;
}

const TagListView = ({ tags, setSelected, valueEmitter, className='' }: IProps) => {
  const { filteredPages, setFilteredPages } = useFilteredPagesContext();
  const getSelectedTagsPages = (): number[] => {
    return tags
      .filter((tag: TagUI) => tag.selected === true)
      .reduce((acc, tag: ITag) => acc.concat(tag.pages), [] as number[]);
  };
  /**
   * Handles the update functionality of tags
   * It also emits the updated tags list to parent component
   */
  const handleUpdateTag = (e: any, data: TagUI) => {
    data.selected = !data.selected;
    setSelected([...tags.filter((tag) => tag.selected === true)]);
    if (setSelected.length === 0) {
      setFilteredPages([]);
    } else {
      setFilteredPages([...new Set(getSelectedTagsPages())]);
    }
  };
  useEffect(() => {}, [filteredPages, tags]);
  return (
    <div className="flex-wrap justify-start flex-column items-between">
      {tags.map((tag, idx) => (
        <div key={`${tag.tag}-${idx}`} className={cn`flex items-center justify-start p-1 ${className}`}>
          <CheckBox name={`${tag}`} onChange={(e) => handleUpdateTag(e, tag)} checked={tag.selected || false} />
          <Tag tag={tag} color={tag.color || tagColorsMap[Math.floor(Math.random() * 5)]} className="ml-2" />
        </div>
      ))}
    </div>
  );
};

export default TagListView;
