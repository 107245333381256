import React, { useLayoutEffect } from 'react'
import { Intent, IntentClassName, LinkOrButtonProps, Variant } from './types'
import PolymorphicBase from './PolymorphicBase'
import { Link } from 'react-router-dom'
import { dom } from 'src/helpers'


export const useSetIconSize = (iconSize: string | number | undefined, btnRef: React.MutableRefObject<HTMLElement | null>) => {

	useLayoutEffect(() => {
		if (!btnRef.current || !iconSize) return
		const _iconSize = typeof iconSize === 'number'
			? `${iconSize}px`
			: iconSize
		dom.setCssVariable('--icon-size', _iconSize)(btnRef.current)
	}, [ iconSize, btnRef ])
}

export const useSetFontSize = (fontSize: string | number | undefined, btnRef: React.MutableRefObject<HTMLElement | null>) => {

	useLayoutEffect(() => {
		if (!btnRef.current || !fontSize) return
		const _fontSize = typeof fontSize === 'number'
			? `${fontSize}px`
			: fontSize
		dom.setCssVariable('--font-size', _fontSize)(btnRef.current)
	}, [ fontSize, btnRef ])
}


export const getIntentClassName = (intent: Intent): IntentClassName => {
	if (intent === 'none')
		return '' as IntentClassName
	return `intent_${intent}` as IntentClassName
}

export const createButton = (variant: Variant) => {

	return ({
		className = '',
		...props
	}: LinkOrButtonProps<'button'>) => (
		<PolymorphicBase<any>
			className={`${variant} ${className}`}
			as={'button'}
			{...props}
		/>
	)
}

export const createAnchor = (variant: Variant) => {

	return ({
		className = '',
		...props
	}: LinkOrButtonProps<typeof Link>) => (
		<PolymorphicBase
			className={`${variant} ${className}`}
			as={Link}
			{...props}
		/>
	)
}

