import { useState } from 'react'
import { deleteProject } from 'src/API/projects'
import { Project } from 'src/data/Project'
import { Button } from 'src/lib/button'
import { Dialog, notify } from 'src/lib/modals'
import { Text } from 'src/lib/text'


interface Props {
	isOpen: boolean
	close: () => void
	onDeleteSuccess: () => void
	project: Project
}

export const DeleteProjectDialog = ({
	isOpen,
	close,
	onDeleteSuccess,
	project,
}: Props) => {

	const [ isPending, setIsPending ] = useState(false)

	const handleConfirm = () => {
		setIsPending(true)
		close()

		deleteProject(project)
			.then(() => {
				setIsPending(false)
				onDeleteSuccess()
			})
			.catch(() => {
				setIsPending(false)
				notify('danger')('Could not delete the Project.')
			})
	}

	return (
		<Dialog
			isOpen={isOpen}
			onClickOutside={close}
			header='Delete Project'
			content={(
				<div className='mr-8'>
					<Text>Are you sure you want to delete this project?</Text>
					<Text>This action cannot be undone.</Text>
				</div>
			)}
			footer={(
				<div className='flex justify-end'>
					<Button.Text
						onClick={close}>
						CLOSE
					</Button.Text>

					<Button.Text
						intent='danger'
						className={isPending ? 'animate-pulse' : ''}
						onClick={handleConfirm}
						tabIndex={isPending ? -1 : 0}>
						DELETE
						 </Button.Text>
				</div>
			)}
		/>
	)
}