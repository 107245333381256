import axios from 'axios';
import { CONFIG } from 'src/cp.config';
import { ApiFaqEntry, Faq } from 'src/data/cms/Faq';
import { ApiPageContentEntry, PageContent } from 'src/data/cms/PageContent';
import { ApiCmsResponse, SHEETNAME } from 'src/data/cms/types';
import { SWRConfiguration } from 'swr';
import { useSwrCache } from './utils';

const getUrl = (sheetName: SHEETNAME): string => {
	return `https://sheets.googleapis.com/v4/spreadsheets/${CONFIG.CMS.DOC_ID}/values/${sheetName}?key=${CONFIG.CMS.GOOGLE_SHEETS_API_KEY}`;
}

const faqUrl = getUrl(SHEETNAME.FAQ)

const fetchFaq = (url: string) => (
	axios
		.get(url)
		.then(res => res.data as ApiCmsResponse<ApiFaqEntry>)
		.then(apiFaq => Faq.fromApi(apiFaq))
)

export const useFetchFaq = (config?: SWRConfiguration<Faq | null, Error>) => {
	return useSwrCache(faqUrl, fetchFaq, config)
}

const pageContentUrl = getUrl(SHEETNAME.PAGE_CONTENT)

const fetchPageContent = (url: string) => (
	axios
		.get(url)
		.then(res => res.data as ApiCmsResponse<ApiPageContentEntry>)
		.then(apiPageContent => PageContent.fromApi(apiPageContent))
)

export const useFetchPageContent = (config?: SWRConfiguration<PageContent | null, Error>) => {
	return useSwrCache(pageContentUrl, fetchPageContent, config)
}


