import React from 'react';

interface Props extends React.ComponentProps<'div'> {
	span?: number
	maxWidth?: string | number
	minWidth?: string | number
	minHeight?: string | number
}

const Col = React.forwardRef<HTMLDivElement, Props>(({
	span = 1,
	className = '',
	maxWidth = 'auto',
	minWidth = 'auto',
	minHeight = 'auto',
	style = {},
	...props
}, ref) => {

	minHeight = typeof minHeight === 'number'
		? `${minHeight}px`
		: minHeight

	maxWidth = typeof maxWidth === 'number'
		? `${maxWidth}px`
		: maxWidth

	minWidth = typeof minWidth === 'number'
		? `${minWidth}px`
		: minWidth

	style = {
		minWidth,
		maxWidth,
		minHeight,
		gridColumn: `span ${span} / span ${span}`,
		...style
	}

	return (
		<div
			ref={ref}
			className={className}
			style={style}
			{...props}
		/>
	)
})

export default Col as typeof Col;