import { Size } from 'src/types'

export function getDynamicInitialScale(containerSize: Size | null | undefined): number {
	if (!containerSize)
		return 2
	if (containerSize.height < 840)
		return 1.2
	if (containerSize.height < 1600)
		return 2
	return 3
}
