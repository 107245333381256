import { useEffect } from 'react';
import { File } from 'src/data';
import { PdfPageMapWrapper, PdfPage } from 'src/lib/render-pdf';
import { Text } from 'src/lib/text';
import { Divisor, InnerBoard } from 'src/components';
import { useStateDebounced } from 'src/hooks/state';
import { Loader } from 'src/lib/loader';

interface Props {
  file: File;
  className?: string;
}

export const FilePreview = (props: Props) => {
  //renders the file after 50ms to avoid freezing the FilePreview visual updates
  const [file, setFile] = useStateDebounced<File | null>(null, 50);
  useEffect(() => setFile(props.file), [props.file]); //eslint-disable-line

  return (
    <div className={props.className}>
      <InnerBoard className="inner_board file_preview" minHeight={700}>
        <div className="flex items-center pl-3 pr-4 py-0.5" style={{ minHeight: '36px' }}>
          <Text stronger>{props.file.name}</Text>
          {props.file !== file && <Loader.Dots marginTop={3} marginLeft={6} />}
          <Text className="entry ml-auto">{renderNumberOfPages(props.file?.totalPages)}</Text>
        </div>

        <Divisor className="my-0 ml-px bg-gray-150" />

        <div className="p-12">
          {props.file === file && (
            <PdfPageMapWrapper
              fileId={file.id}
              intersectionObserverRootSelector={'.main_layout-content.custom_scrollbars > div'}
              numPages={file.totalPages}
              slotAspectRatio={[6, 5]}
              map={(page, containerSize) => <PdfPage fileId={file.id} page={page} containerSize={containerSize} />}
            />
          )}
        </div>
      </InnerBoard>
    </div>
  );
};

function renderNumberOfPages(numberOfPages: number | undefined): string {
  switch (numberOfPages) {
    case 0 || undefined:
      return '-';
    case 1:
      return '1 page';
    default:
      return `${numberOfPages} pages`;
  }
}
