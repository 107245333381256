import axios from 'axios'
import useSWR, { cache } from 'swr'
import deepEqual from 'react-fast-compare'

const createBookmarkHook = (url: string) => {

	return function useBookmarkHook<T extends unknown>(key: string, defaultBookmark?: T) {
		const { data: bookmark, mutate } = useSWR([ url, key ], fetchUserBookmark, {
			initialData: defaultBookmark,
			revalidateOnMount: cache.get([ url, key ]) === undefined
		})

		function fetchUserBookmark(url: string, key: string) {
			return axios
				.get(url, {
					params: { key }
				})
				.then(res => deepEqual(res.data, {}) //default response is {}
					? { value: defaultBookmark } ?? res.data
					: res.data
				)
				.then(data => data.value as T)
		}

		function postUserBookmark(newValue: T) {
			mutate(newValue, false)
			axios.put(url, { value: newValue }, {
				params: { key }
			})
		}

		function deleteUserBookmark() {
			mutate(undefined, false)
			axios.delete(url, {
				params: { key }
			})
		}
		return [ bookmark, postUserBookmark, deleteUserBookmark ] as const
	}
}

export const useUserBookmark = createBookmarkHook('/cpf/v1/bookmark/user/')
