

import { getAutoHeight } from './getAutoHeight'
import { getClientSize } from './getClientSize'
import { getClosestPositionnedParent } from './getClosestPositionnedParent'
import { getComputedSize } from './getComputedSize'
import { getCssVariable } from './getCssVariable'
import { getEventTarget } from './getEventTarget'
import { getEventCurrentTarget } from './getEventCurrentTarget'
import { getForm } from './getForm'
import { getFormData } from './getFormData'
import { getOverflowYParent } from './getOverflowYParent'
import { getRelativeMousePosition } from './getRelativeMousePosition'
import { isParent } from './isParent'
import { isTargetParent } from './isTargetParent'
import { preventDefault } from './preventDefault'
import { setCssVariable } from './setCssVariable'
import { setStyle } from './setStyle'
import { tagNames } from './tagNames'
import { toggleClass } from './toggleClass'

export const dom = {
	getAutoHeight,
	getClientSize,
	getClosestPositionnedParent,
	getComputedSize,
	getCssVariable,
	getEventTarget,
	getEventCurrentTarget,
	getForm,
	getFormData,
	getOverflowYParent,
	getRelativeMousePosition,
	isParent,
	isTargetParent,
	preventDefault,
	setCssVariable,
	setStyle,
	tagNames,
	toggleClass,
}