import React, { useCallback, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Popover as TinyPopover, PopoverProps as TinyPopoverProps, ArrowContainer } from 'react-tiny-popover'
import { useGetIsMounted } from 'src/hooks';
import { Children } from 'src/types';
import { dom } from 'src/helpers';
import { getContainerParent } from './utils';


const noop = () => { }

interface Props extends Omit<TinyPopoverProps, 'content' | 'isOpen'> {
	content: Children | ((close: () => void) => Children)
	className?: string
	onToggle?: (isOpen: boolean) => void
	containerParentSelector?: string
	renderDivContainer?: boolean
	nudgeLeft?: number
	isOpen?: boolean
	maxHeight?: string | number
}

const Popover = ({
	content,
	className = '',
	children,
	containerParent,
	containerParentSelector,
	renderDivContainer = true,
	nudgeLeft = 0,
	isOpen: isOpenProp = false,
	onToggle = noop,
	maxHeight = 'calc(100vh - 100px)',
	...props
}: Props) => {

	const { childRef, isOpen, close } = useIsOpen(isOpenProp, onToggle)

	function handleClickContent(e: React.MouseEvent<HTMLElement>) {
		const target = dom.getEventTarget(e)
		if (target.closest('a'))
			close()
	}

	return (
		<TinyPopover
			containerParent={getContainerParent(containerParent, containerParentSelector)}
			isOpen={isOpen}
			onClickOutside={close}
			ref={childRef}
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor='#ffffff'
					arrowSize={4}
					arrowStyle={{ transform: `scale(2)`, zIndex: 1 }}>
					<div
						className={`text-default rounded-sm ${className}`}
						style={{ boxShadow: '0 2px 12px 0 #bbb', background: '#ffffff', transform: `translateX(${nudgeLeft}px)` }}>

						<Scrollbars autoHeight={true} autoHeightMax={maxHeight} hideTracksWhenNotNeeded={true} >
							<div className='px-1 py-1' onClick={handleClickContent}>
								{typeof content === 'function'
									? content(close)
									: content
								}
							</div>
						</Scrollbars>
					</div>
				</ArrowContainer>
			)}
			{...props}>

			{renderDivContainer
				? <div className='inline-flex'>{children}</div>
				: children
			}

		</TinyPopover>
	)
}

export default Popover;

function useIsOpen(initialState: boolean, onToggle: (isOpen: boolean) => void) {

	const getIsMounted = useGetIsMounted()
	const childRef = useRef<any>(null)
	const [ isOpen, setIsOpen ] = useState(initialState)

	const close = useCallback(() => {
		if (!getIsMounted()) return
		onToggle(false)
		setIsOpen(false)
	}, [])//eslint-disable-line

	useEffect(() => {
		const child = childRef.current
		if (!child) return

		function toggle() {
			if (!getIsMounted()) return
			onToggle(!isOpen)
			setIsOpen(!isOpen)
		}

		child.addEventListener('click', toggle)

		return () => child.removeEventListener('click', toggle)
	}, [])//eslint-disable-line

	return { childRef, isOpen, close }
}