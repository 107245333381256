import { useRef } from 'react';

interface IProps {
  name: string;
  checked: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const CheckBox = ({ name, checked, ...props }: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = (e: any) => {
    inputRef?.current?.focus();
    inputRef?.current?.click();
  };
  return (
    <div className="relative">
      {checked ? (
        <div
          onClick={handleClick}
          style={{
            background: '#63CAE1',
            boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            height: '18px',
            width: '18px',
            padding: '4px',
          }}
          className="flex justify-center items-center cursor-pointer"
        >
          <div
            style={{
              background: '#F0F0F0',
              opacity: '0.9',
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
              borderRadius: '2px',
              width: '8px',
              height: '8px',
            }}
          ></div>
        </div>
      ) : (
        <div
          onClick={handleClick}
          className="cursor-pointer"
          style={{
            background: '#FFFFFF',
            border: '1px solid #817F7F',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            height: '18px',
            width: '18px',
          }}
        ></div>
      )}
      <input
        ref={inputRef}
        className="absolute opacity-0"
        style={{ zIndex: -1 }}
        type="checkbox"
        name={name}
        checked={checked}
        {...props}
      />
    </div>
  );
};

export default CheckBox;
