import styles from './styles.module.css'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { Redirect } from 'react-router'
import { fetchToken } from 'src/API/token'
import { Token } from 'src/data/Token'
import { User } from 'src/data/User'
import { useGetIsMounted } from 'src/hooks'
import { Heading, Text } from 'src/lib/text'
import { cache, mutate } from 'swr'
import { WithCenozai } from './WithCenozai'
import { WithCognito } from './WithCognito'
import { ReactComponent as Logo } from 'src/assets/logo_egg.svg'


interface Props {
	onToken: (token: Token) => void
	onError: (axiosError: AxiosError) => void
	user: User | null | undefined
}

const Login = ({
	onToken,
	onError,
	user,
}: Props) => {


	const { isBusy, tokenFetcher } = useTokenFetcher({
		onToken,
		onError,
		user,
	})

	// if (Token.getStore()?.is2FaOn) {
	// 	return (
	// 		<Redirect to='/login/2FA' />
	// 	)
	// }

	if (user) {
		return (
			<Redirect to='/' />
		)
	}

	return (
		<div className="flex-col h-full pt-5 pb-24 flex-center">
			<div>
				<div className={styles.logo}>
					<Logo />
				</div>
			</div>
			<Heading tag='h1' className='flex-shrink-0 my-5'>Cenozai Platform</Heading>

			<WithCenozai
				onSubmit={({ emailOrUsername, password }) => {
					tokenFetcher(fetchToken.cenozai({ emailOrUsername, password }))
				}}
				disabled={isBusy}
			/>

			<Text className='my-4 text-center'>or</Text>
			<WithCognito onCognitoRedirectOver={(redirectData) => { tokenFetcher(fetchToken.cognito(redirectData)) }} disabled={isBusy} />
		</div >
	)
}

export default Login

interface UserFetcherConfig {
	onToken: (token: Token) => void
	onError: (axiosError: AxiosError) => void
	user: User | undefined | null
}

const useTokenFetcher = ({
	onToken,
	onError,
	user,
}: UserFetcherConfig) => {

	const [ token, setToken ] = useState<Token | null>(null)
	const [ isFetching, setIsFetching ] = useState(false)

	const getIsMounted = useGetIsMounted()

	function tokenFetcher(fetcher: () => Promise<Token>) {
		cache.clear()
		mutate('/cpf/v1/profile/', null, false)
		setIsFetching(true)

		fetcher()
			.then((auth) => {
				if (!getIsMounted()) return
				setToken(auth)
				onToken(auth)
			})
			.catch(e => {
				if (!getIsMounted()) return
				setToken(null)
				onError(e)
			})
			.finally(() => {
				if (!getIsMounted()) return
				setIsFetching(false)
			})
	}

	return {
		isBusy: isFetching || (!!token && !user),
		tokenFetcher
	}
}
