import useSWR, { SWRConfiguration } from 'swr'
import axios from 'axios'
import { array } from 'src/helpers'
import { ApiProject, Project } from 'src/data/Project'
import { useSwrCache } from './utils'
import { useParams } from 'react-router'


export const postNewProject = (project: Project) => (
	axios
		.post('/cpf/v1/projects/', Project.toApi(project))
		.then(res => res.data)
)

export const updateProject = (project: Project) => (
	axios
		.put(`/cpf/v1/projects/${project.id}/`, Project.toApi(project))
		.then(res => res.data)
)

export const deleteProject = (project: Project) => (
	axios.delete("cpf/v1/projects/" + project.id + "/")
)

const fetchProjects = (url: string) => (
	axios
		.get(url)
		.then(res => (Array.isArray(res.data) ? res.data : []) as ApiProject[])
		// .then(n => (console.log('ALL API PROJECTS:', n), n))
		.then(apiProjects => apiProjects.map(project => Project.fromApi(project)))
		// .then(n => (console.log('ALL Int. PROJECTS:', n), n))
		.then(array.sortBy('ascending', 'title'))
)

export const fetchProject = (url: string, id: string | number) => (
	axios
		.get(url + id)
		.then(res => res.data)
		// .then(n => (console.log('RAW PROJECT', n), n))
		.then(data => Project.fromApi(data as ApiProject))
)

export const useFetchProjects = (config?: SWRConfiguration<Project[], Error>) => (
	useSWR('/cpf/v1/projects/', fetchProjects, config)
)

export const useFetchProject = (id: string | number, config?: SWRConfiguration<Project, Error>) => (
	useSWR(id ? ['/cpf/v1/projects/', id + ''] : null, fetchProject, config)
)

export const useFetchActiveProject = (config?: SWRConfiguration<Project, Error>) => {
	const { projectId = '' } = useParams<{ projectId?: string }>()

	return useFetchProject(projectId, config)
}

export const useActiveProjectCache = (config?: Omit<SWRConfiguration<Project, Error>, 'initialData'>) => {
	const { projectId = '' } = useParams<{ projectId?: string }>()
	return useSwrCache(projectId ? ['/cpf/v1/projects/', projectId] : null, fetchProject, config)
}