import styles from './styles.module.css'
import React from 'react';
import { Children } from 'src/types';


interface Props {
	children?: Children
	className?: string
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
	onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void
	onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void
	style?: React.CSSProperties
}

const MenuItem = ({
	children = null,
	className = '',
	style = {},
	...props
}: Props) => {



	return (
		<li>
			<div className={`${styles.item} ${className}`} style={style} {...props}>
				{children}
			</div>
		</li>
	)
}

export default MenuItem;