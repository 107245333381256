import { default as Container } from './Container'
import { default as Circular } from './Circular'
import { default as Dots } from './Dots'
import { default as Cool } from './Cool'

export const Loader = {
	Container,
	Circular,
	Cool,
	Dots,
}