export function getOverflowYParent(node: any | null): Element | null {
	if (node == null) {
		return null;
	}

	if (node.scrollHeight > node.clientHeight) {
		return node;
	} else {
		return getOverflowYParent(node?.parentNode || null);
	}
}
