import React, { useState } from 'react';
import { Menu } from 'src/lib/menu'
import { Icon } from 'src/lib/icons'
import { useFetchTasksWithInterval, deleteTask } from 'src/API/tasks';
import { Text } from 'src/lib/text';
import { Task as TaskData } from 'src/data/Task'
import { Loader } from 'src/lib/loader';
import { Button } from 'src/lib/button';
import { pure } from 'src/hoc';
import { cn } from 'src/helpers';
import ProgressBar from '../ProgressBar';

interface TaskDropdownProps {
	projectId: string | number
}

const TasksDropdown = ({
	projectId
}: TaskDropdownProps) => {
	const { data: tasks } = useFetchTasksWithInterval(projectId)

	if (!tasks || !tasks.length)
		return (
			<Menu.Root>
				<Menu.Item>
					<Text variant='info' className='flex-center mx-auto pr-1'><i>No Task</i></Text>
				</Menu.Item>
			</Menu.Root>
		)

	return (
		<Menu.Root className='dropdown_list gap-1'>
			{tasks.map(task => (
				<Task
					task={task}
					key={`taskId-${task.id}`}
				/>
			))}
		</Menu.Root>
	)
}

export default TasksDropdown;


interface TaskProps {
	task: TaskData,
}

const Task = pure(({
	task,
}: TaskProps) => {

	const [ isHover, setIsHover ] = useState(false)
	const [ isConfirming, setIsConfirming ] = useState(false)
	const [ isCancelling, setIsCancelling ] = useState(false)

	function handleCancelTask() {
		setIsConfirming(false)
		setIsCancelling(true)
		deleteTask(task).catch(() => setIsCancelling(false))
	}

	return (
		<Menu.Item
			className={`relative flex flex-col py-1 text-sm bg-gray-150 hover:bg-gray-200 ${isCancelling ? 'opacity-50' : ''}`}
			style={{ width: '420px' }}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}>

			<header className='w-full flex items-center justify-between'>
				<Text truncate stronger>{task.description}</Text>
				<Text className='ml-auto' variant='info'><i>{task.createdOn}</i></Text>
			</header>

			<div className='flex items-center w-full mt-2'>

				<ProgressBar progress={task.progress.current / task.progress.total * 100} />

				<Text className='ml-4 mr-2 flex-shrink-0'>
					{task.progress.current}/{task.progress.total}
				</Text>

				{isHover
					? <Button.Inline intent='danger' onClick={() => setIsConfirming(true)} iconSize={14} style={{ transform: 'scale(1.2)' }}> <Icon icon='plus' className='transform rotate-45' /> </Button.Inline>
					: <Loader.Circular opacity={0.8} dark intent='none' width='14px' strokeWidth={5} />
				}
			</div>

			<div className={cn`absolute top-0 left-0 w-full h-full bg-gray-150 rounded-sm flex-center flex-col ${!isConfirming && 'invisible'}`}>
				<Text className='pt-1' stronger>
					Cancel Task?
				</Text>
				<div className='flex items-center gap-2 mt-1'>
					<Button.Text fontSize={14} intent='primary' onClick={handleCancelTask}>Yes</Button.Text>
					<Button.Text fontSize={14} onClick={() => setIsConfirming(false)}>No</Button.Text>
				</div>
			</div>
		</Menu.Item>
	)
})