import React from 'react';
import { Children } from 'src/types';
import { HeadingTag, Variant } from './types';
import { getClassName } from './utils';

interface Props extends React.ComponentProps<'h1'> {
	children?: Children
	opacity?: number
	tag?: HeadingTag
	className?: string
	variant?: Variant
	inline?: boolean
	truncate?: boolean
}

const Heading = ({
	children = null,
	opacity = 1,
	tag: TagName = 'h4',
	className = '',
	variant = 'dark',
	inline = false,
	truncate = false,
	style = {},
	...props
}: Props) => {

	style = {
		...style,
		opacity
	}

	const cnConfig = { variant, inline, className, smaller: false, stronger: false, truncate }

	return (
		<TagName style={style} className={`${getClassName(cnConfig)}`} {...props}>
			{children}
		</TagName>
	)
}

export default Heading;