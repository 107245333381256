import { MouseEvent, useRef } from 'react'
import { useToggle } from 'src/hooks/state'
import { Text } from 'src/lib/text'
import { cn } from 'src/helpers'


interface Props {
	className?: string
	path: string
}

export const FilePath = ({
	className = '',
	path,
}: Props) => {

	const mouseDownTimestampRef = useRef(0)
	const [ collapse, toggle ] = useToggle(true)

	function updateMouseDownTimestamp(e: MouseEvent) {
		mouseDownTimestampRef.current = e.timeStamp
	}

	function handleClick(e: MouseEvent) {
		if (e.timeStamp - mouseDownTimestampRef.current > 350) return //invalid click
		toggle()
	}

	return (
		<Text
			className={cn`cursor-pointer px-2 bg-gray-200 text-xs py-px collapse:text-center ${collapse && 'collapse'} ${className}`}
			title={path}
			truncate={collapse}
			onMouseDown={updateMouseDownTimestamp}
			onClick={handleClick}>
			{path}
		</Text>
	)
}