import { Yup } from 'src/lib/forms'
import { ResolveOptions } from 'yup/lib/Condition'

export interface ApiPasswordConfig {
	old_password: string
	new_password1: string
	new_password2: string
}

export interface IPasswordConfig {
	oldPwd: string
	newPwd1: string
	newPwd2: string
}

export class PasswordConfig implements IPasswordConfig {
	oldPwd: string
	newPwd1: string
	newPwd2: string

	constructor(config: IPasswordConfig | PasswordConfig) {
		this.oldPwd = config.oldPwd
		this.newPwd1 = config.newPwd1
		this.newPwd2 = config.newPwd2
	}

	static toApi(config: IPasswordConfig | PasswordConfig): ApiPasswordConfig {
		const apiConfig: ApiPasswordConfig = {
			old_password: config.oldPwd,
			new_password1: config.newPwd1,
			new_password2: config.newPwd2,
		}
		return apiConfig
	}

	static schema() {
		return Yup.object({
			oldPwd: Yup
				.string()
				.required('Required'),
			newPwd1: Yup
				.string()
				.required('Required')
				.min(8, 'Minimun characters: 8')
				.max(24, 'Maximun characters: 24')
				.matches(/[A-Z]/, 'Needs at least 1 uppercase character.')
				.matches(/\d/, 'Needs at least 1 number.'),
			newPwd2: Yup.lazy((value: string = '', ctx: ResolveOptions) => {
				const newPwd1: string = ctx.parent?.newPwd1 || ''
				return Yup
					.string()
					.required('Required')
					.equals([ newPwd1 ], 'The passwords do not match.')
			})
		})
	}

	static defaults(): IPasswordConfig {
		return {
			oldPwd: '',
			newPwd1: '',
			newPwd2: '',
		}
	}
}