import { Icon } from 'src/lib/icons';
import { Dispatch } from 'react';

interface IProps {
  setSearch: Dispatch<string>;
}

const Search = ({ setSearch }: IProps) => {
  return (
    <div
      className="flex justify-around items-center m-0 p-0"
      style={{
        height: '32px',
        border: '1px solid #2B333E',
        boxShadow: '1px 4px 6px 1px rgba(0, 0, 0, 0.08)',
        borderRadius: '50px',
      }}
    >
      <input
        type="search"
        className="w-full h-full p-0 m-0 pl-3 border-none bg-transparent focus:ring-transparent"
        name=""
        id=""
        onChange={(e) => setSearch(e.target.value)}
      />
      <div
        style={{
          borderRightStyle: 'solid',
          borderRightColor: '#969EAA',
          borderRightWidth: '1px',
          width: '0px',
          height: '20px',
        }}
      ></div>
      <Icon icon="search" iconSize={35} color="#63CAE1" className="pl-2 pr-2" />
    </div>
  );
};

export default Search;
