import { useEffect, useMemo, lazy, Suspense } from 'react';
import { useHistory, useParams } from 'react-router';
import { useFetchReviewTablesList } from 'src/API/bom/fetchers';
import { useFetchSelectedFiles } from 'src/API/files';
import { useActiveProjectCache } from 'src/API/projects';
import { ErrorPage } from 'src/components';
import { LeftNav } from 'src/components/sidebar';
import { ReviewTablesListEntry } from 'src/data/module.bom/BomTable';
import { MODULE } from 'src/data/Modules';
import { Project } from 'src/data/Project';
import { useFileIdFromParams } from 'src/hooks/params';
import { MainLayout } from 'src/lib/layout';
import { Loader } from 'src/lib/loader';
import { AsyncPage } from 'src/lib/page';
import { RightBar } from './RightBar';
// import View from './View';

const View = lazy(() => import('./View'));


const Setup = () => {

	const history = useHistory()
	const { data: project, error: projectError, isValidating: isValidatingProject, revalidate: revalidateProject } = useActiveProjectCache()
	const isModuleActive = !!project?.modules.active.includes(MODULE.BOM_GENERATION)
	const { data: selectedFiles, error: selectedFilesError, revalidate: revalidateSelectedFiles, isValidating: isValidatingSelectedFiles } = useFetchSelectedFiles(project?.id, MODULE.BOM_GENERATION)
	const { data: tableList, error: tableListError, isValidating: isValidatingTableList, revalidate: revalidateTableList } = useFetchReviewTablesList(selectedFiles)
	const fileIdFromParams = useFileIdFromParams()
	const { tableId: tableIdFromParams } = useParams<{ tableId: string }>()

	const extractedTablesList = useMemo(() => (
		tableList?.filter(table => table.isExtracted)
	), [ tableList ])

	const currentTable = useMemo(() => (
		extractedTablesList?.find(table => Number(fileIdFromParams) === table.file.id && Number(tableIdFromParams) === table.id)
	), [ fileIdFromParams, tableIdFromParams, extractedTablesList ])

	// redirect to first table file if not passed in URL params
	useEffect(() => {
		if (fileIdFromParams && tableIdFromParams && currentTable) return
		if (!extractedTablesList?.length) return
		history.replace(`${getBasePath(history)}/${extractedTablesList[ 0 ].file.id}/${extractedTablesList[ 0 ].id}`)
	}, [ fileIdFromParams, history, tableIdFromParams, extractedTablesList, currentTable ])


	return (
		<AsyncPage
			errors={[ projectError, tableListError, selectedFilesError ]}
			retry={() => { revalidateProject(); revalidateTableList(); revalidateSelectedFiles() }}
			isRetrying={isValidatingProject || isValidatingTableList || !isValidatingSelectedFiles}
			isLoading={(!project && !projectError) || (!tableListError && !tableList)}>

			<MainLayout
				left={<LeftNav />}
				right={<RightBar tableList={extractedTablesList as ReviewTablesListEntry[]} project={project as Project} />}
				content={isModuleActive
					? <Suspense fallback={<Loader.Container><Loader.Cool className='text-primary' /></Loader.Container>}>
						<View table={currentTable} project={project as Project} />
					</Suspense>
					: <ErrorPage.ModuleNotActive />
				}
			/>
		</AsyncPage>
	)
}

export default Setup

function getBasePath(history: any) {
	const fullPath = history.location.pathname
	const reviewI = fullPath.indexOf('review')
	return fullPath.substring(0, reviewI) + 'review'
}