import { DocumentTypeAbbreviation } from './documentTypes'

export interface ApiArkSearchResponse {
	totalHits: number
	elapsed: number
	search: ApiResult[]
}


export class ArkSearchResponse {
	totalHits: number
	elapsed: number
	results: Result[]

	constructor(res: ApiArkSearchResponse) {
		this.totalHits = res.totalHits
		this.elapsed = res.elapsed
		this.results = res.search.map(sr => Result.fromApi(sr))
	}
}

interface ApiResult {
	documentType: DocumentTypeAbbreviation
	fileId: number
	fileSize: string
	isPnid: 0 | 1
	latest: number
	numPages: number
	pageIndex: number
	pageRecord_id: number
	parent: number
	fileRecord_id: number
	sourcePath: string
	textSnippet: string[]
}

interface IResult {
	documentTypeAbbr: DocumentTypeAbbreviation
	fileId: number
	fileSize: string
	latest: number
	isPnid: boolean
	numPages: number
	pageNumber: number
	pageRecordId: number
	parentFileRecordId: number
	fileRecordId: number
	sourcePath: string
	textSnippets: string[]
}

export class Result implements IResult {
	documentTypeAbbr: DocumentTypeAbbreviation
	fileId: number
	fileSize: string
	latest: number
	isPnid: boolean
	numPages: number
	pageNumber: number
	pageRecordId: number
	parentFileRecordId: number
	fileRecordId: number
	sourcePath: string
	textSnippets: string[]

	private pathDelimiter: string = '/'

	constructor(result: IResult | Result) {
		this.documentTypeAbbr = result.documentTypeAbbr
		this.fileId = result.fileId
		this.fileSize = result.fileSize
		this.latest = result.latest
		this.isPnid = result.isPnid
		this.numPages = result.numPages
		this.pageNumber = result.pageNumber
		this.pageRecordId = result.pageRecordId
		this.parentFileRecordId = result.parentFileRecordId
		this.fileRecordId = result.fileRecordId
		this.sourcePath = result.sourcePath
		this.textSnippets = result.textSnippets
	}

	static fromApi(apiData: ApiResult): Result {
		const data: IResult = {
			documentTypeAbbr: apiData.documentType,
			fileId: apiData.fileId,
			fileSize: apiData.fileSize,
			latest: apiData.latest,
			isPnid: !!apiData.isPnid,
			numPages: apiData.numPages,
			pageNumber: apiData.pageIndex + 1,
			pageRecordId: apiData.pageRecord_id,
			parentFileRecordId: apiData.parent,
			fileRecordId: apiData.fileRecord_id, //fileId? //pdfRecord_id //fileRecord_id
			sourcePath: apiData.sourcePath,
			textSnippets: apiData.textSnippet,
		}
		return Result.createNewInstance(data)
	}

	static toApi(data: Result | IResult): ApiResult {
		const apiData: ApiResult = {
			documentType: data.documentTypeAbbr,
			fileId: data.fileId,
			fileSize: data.fileSize,
			latest: data.latest,
			isPnid: data.isPnid ? 1 : 0,
			numPages: data.numPages,
			pageIndex: data.pageNumber - 1,
			pageRecord_id: data.pageRecordId,
			parent: data.parentFileRecordId,
			fileRecord_id: data.fileRecordId,
			sourcePath: data.sourcePath,
			textSnippet: data.textSnippets,
		}
		return apiData
	}

	static createNewInstance(instanceOrObject: IResult | Result): Result {
		return new Result(instanceOrObject)
	}

	get pathWithoutFileName() {
		return this.sourcePath
			.split(/[\\/]/)
			.slice(0, -1)
			.filter(str => str !== '')
			.join(this.pathDelimiter)

	}

	get fileName() {
		return this.pathDelimiter + this.sourcePath.split(/[\\/]/).pop()
	}
}