import { useActiveProjectCache } from 'src/API/projects';
import { ErrorPage } from 'src/lib/page';

export const ModuleNotActive = () => {

	const { data: activeProject } = useActiveProjectCache()

	return (
		<ErrorPage
			message='This module is not active.'
			link={{ message: 'Go to project settings', to: `/projects/${activeProject?.id}/settings/` }}
		/>
	)
}
