import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';
import fileDownload from "js-file-download";
import { FileBase } from 'src/data/File';
import { ApiBomTableData, ApiBomTable, ApiReviewTablesListEntry, BomTable, ReviewTablesListEntry, BomTableData } from 'src/data/module.bom/BomTable';
import { isNullish, array } from 'src/helpers';
import useSWR, { SWRConfiguration } from 'swr';
import { useSwrCache } from '../utils';
import { tableActions } from './actions';
import { swrKey } from '../utils';


function fetchTables(url: string, projectId: string | undefined, fileId: string | undefined): Promise<BomTable[]> {
	return axios
		.get(url, {
			params: {
				proj_id: projectId,
				file_id: fileId,
			}
		})
		.then(res => res.data.current_document.tables as ApiBomTable[])
		.then(apiTables => apiTables.map(apiTable => BomTable.fromApi(apiTable)))
}



const createFetchTablesHook = (useSwrHook: typeof useSWR | typeof useSwrCache) => (fileId: string | number | undefined, projectId: string | number | undefined, config?: SWRConfiguration<BomTable[], Error>) => {

	const swrHook = useSwrHook(
		!isNullish(projectId) && !isNullish(fileId)
			? [ '/cpf/v1/generate_bom/find_table/pdf', projectId + '', fileId + '' ]
			: null,
		fetchTables,
		config
	)

	return useMemo(() => ({
		...swrHook,
		actions: tableActions,
	}), [ swrHook ])
}

export const useFetchTables = createFetchTablesHook(useSWR)
export const useFetchTablesCache = createFetchTablesHook(useSwrCache)


const fetchReviewTablesList = (url: string, fileIds: string): Promise<ReviewTablesListEntry[]> => (
	axios
		.get(url, { params: { documents: fileIds } })
		.then(res => res.data.data as ApiReviewTablesListEntry[])
		.then(apiData => apiData.map(data => ReviewTablesListEntry.fromApi(data)))
		.then(array.sortBy('ascending', 'fileName', 'page'))
	// .then(x => (console.log(x), x))
)

export const useFetchReviewTablesList = (selectedFiles: FileBase[] | undefined, config: SWRConfiguration<ReviewTablesListEntry[], AxiosError> = {}) => {
	const fileIds = useMemo(() => selectedFiles
		? JSON.stringify(selectedFiles.map(file => file.id))
		: null,
		[ selectedFiles ]
	)

	return useSWR(
		fileIds
			? [ "cpf/v1/generate_bom/table/review", fileIds ]
			: null,
		fetchReviewTablesList,
		config
	)
}

export const useFetchReviewTablesCache = (selectedFiles: FileBase[] | undefined, config: SWRConfiguration<ReviewTablesListEntry[], AxiosError> = {}) => {
	const fileIds = useMemo(() => selectedFiles
		? JSON.stringify(selectedFiles.map(file => file.id))
		: null,
		[ selectedFiles ]
	)

	return useSwrCache(
		fileIds
			? [ "cpf/v1/generate_bom/table/review", fileIds ]
			: null,
		fetchReviewTablesList,
		config
	)
}

const fetchTableData = (url: string, tableId: string | number): Promise<BomTableData> => (
	axios
		.get(url, {
			params: {
				id: tableId
			}
		})
		.then(res => res.data as ApiBomTableData)
		.then(apiData => BomTableData.fromApi(apiData))
	// .then(data => data.setExcelMinSize({ cols: CONFIG.BOM.EXCEL.MIN_COLS, rows: CONFIG.BOM.EXCEL.MIN_ROWS }))
)

export const useFetchTableData = (tableId: string | number | undefined, config: SWRConfiguration<BomTableData, AxiosError> = {}) => (
	useSWR(
		tableId
			? swrKey.fetchTableData(tableId)
			: null,
		fetchTableData,
		config
	)
)

export const downloadExcelFile = (tableId: number, fileName: string) => (
	axios
		.get("cpf/v1/generate_bom/table/review/download/", {
			params: {
				id: tableId
			},
			responseType: 'blob',
		})
		.then(res => {
			fileDownload(res.data, fileName)
		})
)