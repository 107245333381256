import { Field } from 'formik';
import React from 'react';
import { cn } from 'src/helpers';
import { Icon } from '../icons';

interface Props extends Omit<React.ComponentProps<typeof Field>, 'type' | 'className'> {
	inputClassName?: string
	wrapperClassName?: string
	rounded?: boolean
	name: string
	iconMarginRight?: number
	iconSize?: number
}

const Search = ({
	inputClassName = '',
	wrapperClassName = '',
	name,
	rounded = false,
	iconMarginRight = 0,
	iconSize = 16,
	...props
}: Props) => {



	return (
		<div className={`relative inline-flex ${wrapperClassName}`}>
			<Field
				type='search'
				name={name}
				className={cn`
					custom_input-search
					${rounded && 'round'}
					${inputClassName}
				`}
				{...props}
			/>

			<button
				className={cn`
					absolute
					right-2.5
					transform
					top-1/2
					-translate-y-1/2
				`}
				style={{ marginRight: iconMarginRight }}>
				<Icon icon='search' iconSize={iconSize} />
			</button>
		</div>
	)
}

export default Search;