import { useEffect, useMemo } from 'react'
import { useUpdatedRef } from 'src/hooks/refs'

export function createGlobalListener<T extends keyof (WindowEventMap | DocumentEventMap)>(eventType: T, root: 'document' | 'window' = 'document') {

	const callbacks: { [ key: number ]: (e: (WindowEventMap | DocumentEventMap)[ T ]) => void } = {}
	let nextID: number = 0

	if (root === 'document') {
		document.addEventListener(eventType, (e: DocumentEventMap[ T ]) => {
			Object.values(callbacks).forEach(cb => {
				cb(e)
			})
		})
	}
	else {
		window.addEventListener(eventType, (e: WindowEventMap[ T ]) => {
			Object.values(callbacks).forEach(cb => {
				cb(e)
			})
		})
	}

	return function (callback: (e: (WindowEventMap | DocumentEventMap)[ T ]) => void) {

		const id = useMemo(() => (nextID++), [])
		const cbRef = useUpdatedRef(callback)

		useEffect(() => {
			callbacks[ id ] = (e) => cbRef.current(e)
			return () => { delete callbacks[ id ] }
		}, [ cbRef, id ])
	}
}
