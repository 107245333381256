import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BOM_TABLE_STATUS, ReviewTablesListEntry } from 'src/data/module.bom/BomTable'
import { Project } from 'src/data/Project'
import { Right } from 'src/lib/layout'
import Scrollbars from 'react-custom-scrollbars'
import { Divisor } from 'src/components'
import { Text } from 'src/lib/text'
import { v4 } from 'uuid'
import { cn, object } from 'src/helpers'
import { useFileIdFromParams } from 'src/hooks/params'
import { useParams } from 'react-router'
import { Show } from 'src/lib/control-flow'
import { Form, Formik } from 'src/lib/forms'
import { notify, BaseTooltip } from 'src/lib/modals'
import { Grid } from 'src/lib/grid'
import { Icon } from 'src/lib/icons'
import { Anchor, Button } from 'src/lib/button'
import { useActiveProjectCache } from 'src/API/projects'
import { useUnmountEffect } from 'src/hooks/effects'
import { useFetchReviewTablesCache } from 'src/API/bom/fetchers'
import { useSelectedFilesCache } from 'src/API/files'
import { MODULE } from 'src/data/Modules'
import { postTableReviewStatus } from 'src/API/bom/mutations'
import { Loader } from 'src/lib/loader'


const prefix = v4()

interface Props {
	project: Project
	tableList: ReviewTablesListEntry[]
}

export const RightBar = ({
	project,
	tableList,
}: Props) => {

	const [ , rerender ] = useState({})
	const fileIdFromParams = useFileIdFromParams()
	const { tableId: tableIdFromParams } = useParams<{ tableId: string }>()
	const [ showReviewedTables, setShowReviewedTables ] = useState(true)
	const { revalidate: revalidateProject } = useActiveProjectCache()
	const { data: selectedFiles } = useSelectedFilesCache(project?.id, MODULE.BOM_GENERATION)
	const { mutate: mutateTableList, revalidate: revalidateTableList } = useFetchReviewTablesCache(selectedFiles)
	const [ isSavingTableIds, setIsSavingTableIds ] = useState(new Set<number>())


	const handleStatusChange = useCallback((table: ReviewTablesListEntry, isComplete: boolean) => {
		setIsSavingTableIds(tableIds => new Set(tableIds).add(table.id))
		const status = isComplete ? BOM_TABLE_STATUS.VERIFIED : BOM_TABLE_STATUS.NOT_VERIFIED
		mutateTableList(tableList => tableList?.map(t =>
			object.match(t)(table)
				? t.clone().setStatus(status)
				: t.clone()
		), false)

		postTableReviewStatus(table.id, status)
			.catch(() => {
				notify('danger')('Error: Failed to save the file status')
			})
			.finally(() => {
				setIsSavingTableIds(tableIds => {
					const newSet = new Set(tableIds)
					newSet.delete(table.id)
					return newSet
				})
				revalidateTableList()
				revalidateProject()
			})
	}, [ mutateTableList, revalidateProject, revalidateTableList ])


	useUnmountEffect(revalidateProject)


	const filteredTableList = useMemo(() => tableList.filter(table => showReviewedTables ? true : table.isReviewed), [ showReviewedTables, tableList ])

	return (
		<Right onResizeStop={() => rerender({})} showToggleButton={false} resizable>
			<Scrollbars>
				<div className='py-3 px-3'>
					<label className='flex items-center gap-2'>
						<input className='custom_input-checkbox' type='checkbox' checked={showReviewedTables} onChange={e => setShowReviewedTables(e.target.checked)} />
						<small className='truncate'>Show reviewed tables</small>
					</label>
				</div>
				<div className='flex flex-wrap p-3 pb-0 justify-between overflow-hidden opacity-85' style={{ fontSize: '0.9375rem' }}>
					<div className='truncate'>File</div>
					<div>Reviewed</div>
				</div>
				<Divisor className='mt-1 mx-3' />
				<ul className='py-3 flex flex-col'>
					{filteredTableList
						.filter(table => table.isExtracted)
						.sort(object.sortBy('ascending', 'fileName', 'page'))
						.map(table => (
							<RenderTable
								table={table}
								isSelected={table.file.id === fileIdFromParams && `${table.id}` === tableIdFromParams}
								project={project}
								key={prefix + project.id + table.id}
								isSavingTable={isSavingTableIds.has(table.id)}
								onStatusChange={handleStatusChange}
							/>
						))}
					{!filteredTableList.length && (
						<Text className='px-3' variant='info'><i>All tabled are reviewed.</i></Text>
					)}
				</ul>
			</Scrollbars>
		</Right>
	)
}

interface RenderTableProps {
	project: Project
	table: ReviewTablesListEntry
	isSelected: boolean
	className?: string
	onStatusChange: (table: ReviewTablesListEntry, isComplete: boolean) => void
	isSavingTable: boolean
}

function RenderTable({
	project,
	table,
	isSelected,
	className = '',
	onStatusChange,
	isSavingTable,
}: RenderTableProps) {

	const ref = useRef<HTMLLIElement | null>(null)
	const [ checked, setChecked ] = useState(table.status === BOM_TABLE_STATUS.VERIFIED)
	const [ isConfirming, setIsConfirming ] = useState(false)

	useEffect(() => {
		setChecked(table.status === BOM_TABLE_STATUS.VERIFIED)
	}, [ table ])

	return (
		<li ref={ref} className={cn`flex items-center px-3 hover:bg-gray-100 relative max-w-full ${isSelected && 'bg-gray-100'} ${className}`}>
			<Show when={isSelected}>
				<div className='absolute inset-0 w-1 bg-primary' />
			</Show>

			<BaseTooltip
				containerClassName='flex items-center grow overflow-hidden'
				content={(
					<Show when={isSelected} fallback={() => <div>{table.fileName}</div>}>
						<Grid.Root columns={2} growCols={[ 2 ]} className='gap-x-3'>
							<strong className='whitespace-nowrap'>File name</strong>
							<div>{table.fileName}</div>
							<strong className='whitespace-nowrap'>Validated tables</strong>
							<div>{`${table.page}`}</div>
						</Grid.Root>
					</Show>
				)}>
				<Anchor.Inline
					className={cn`
						justify-start
						flex-grow
						overflow-hidden
						no-underline
						${isSelected ? 'text-primary-darker' : 'text-default'}
					`}
					to={`/projects/${project.id}/bom/review/${table.file.id}/${table.id}`}>
					<Icon icon='file-icon' />
					<div className='truncate'>{table.fileName}</div>
					<div className='ml-auto mr-2 whitespace-nowrap'>
						{isSavingTable
							? <Loader.Dots />
							: <div>p.{table.page}</div>
						}
					</div>
				</Anchor.Inline>
			</BaseTooltip>

			<Formik initialValues={{ isComplete: checked }} onSubmit={({ isComplete }) => onStatusChange(table, isComplete)}>
				{({ submitForm, setFieldValue, }) => (
					<Form.Root className='py-2 group'>
						<div className='flex-center relative '>
							<label className='absolute h-7 w-7 cursor-pointer' htmlFor={`${table.file.id}-${table.id}`} />
							<Form.Checkbox
								className='z-10 group-hover:border-primary'
								id={`${table.file.id}-${table.id}`}
								name='isComplete'
								checked={checked}
								onChange={(e) => setIsConfirming(true)}
							/>
						</div>
						{isConfirming && (
							<div
								className={cn`
									fixed
									text-sm
									flex
									items-center
									justify-end
									gap-2
									pl-4
									pr-1
									right-0
									z-20
									bg-gray-100
									shadow-medium
									animate-pop-full-right
									duration-1000
									overflow-hidden
								`}
								style={{
									top: ref.current?.getBoundingClientRect().top,
									height: ref.current?.getBoundingClientRect().height,
									minWidth: ref.current?.getBoundingClientRect().width,
								}}>
								<div className='absolute left-0 inset-y-0 w-1 bg-warning' />
								<div className='font-semibold opacity-75 whitespace-nowrap'>
									Mark file as {checked ? 'not' : ''} done?
								</div>
								<div className='flex items-center flex-row-reverse'>
									<Button.Text
										className='-mb-px px-1.5'
										fontSize={12}
										onClick={() => setIsConfirming(false)}>
										No
									</Button.Text>
									<Button.Text
										className='-mb-px px-1.5 font-bold'
										intent='warning'
										fontSize={12}
										onClick={() => {
											setChecked(!checked)
											setFieldValue('isComplete', !checked)
											setIsConfirming(false)
											submitForm()
										}}>
										Yes
									</Button.Text>
								</div>
							</div>
						)}
					</Form.Root>
				)}
			</Formik>
		</li>
	)
}
