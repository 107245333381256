import { Credentials } from 'src/data/Token'
import { useUpdateEffect } from 'src/hooks/effects'
import { Button } from 'src/lib/button'
import { Form, Formik } from 'src/lib/forms'
import { Text } from 'src/lib/text'


const initialValues: Credentials = {
	emailOrUsername: '',
	password: '',
}


interface Props {
	onSubmit: (values: Credentials) => void
	disabled: boolean
}

export const WithCenozai = ({
	onSubmit,
	disabled,
}: Props) => {

	useUpdateEffect(() => {
		if (disabled) return
		const pwdInput: HTMLInputElement | null = document.querySelector('input[type=password]')
		if (!pwdInput) return
		pwdInput.focus()
	}, [ disabled ])

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			<Form.Root className='flex flex-col gap-4'>
				<Form.Labelled.Text
					name='emailOrUsername'
					label={<Text opacity={0.7} stronger>Username or email</Text>}
					disabled={disabled}
				/>
				<Form.Labelled.Password
					name='password'
					label={<Text opacity={0.7} stronger>Password</Text>}
					disabled={disabled}
				/>

				<Button.Contained intent='primary' className='w-full mt-3.5 py-1.5' type="submit" disabled={disabled} >
					Sign in
				</Button.Contained>
			</Form.Root>
		</Formik>

	)
}
