import { array } from 'src/helpers'
import { Attribute, MergedCell, Row } from './types'


interface IExcelData {
	id: number
	attributes: Attribute[]
	data: Row[]
	mergedCells: MergedCell[]
}

export class ExcelData implements IExcelData {
	id: number
	attributes: Attribute[]
	data: Row[]
	mergedCells: MergedCell[]

	constructor(data: ExcelData | IExcelData) {
		this.id = data.id
		this.attributes = data.attributes.map(attr => ({ row: attr.row, col: attr.col, className: attr.className || '' }))
		this.data = data.data.map(row => [ ...row ])
		this.mergedCells = data.mergedCells.map(x => ({ ...x }))
	}

	clone(): ExcelData {
		return new ExcelData(this)
	}

	setData(data: Row[] | ((data: Row[]) => Row[])) {
		this.data = typeof data === 'function'
			? data(this.data)
			: data
		return this
	}

	setAttributes(attributes: Attribute[] | ((attrs: Attribute[]) => Attribute[])) {
		this.attributes = typeof attributes === 'function'
			? attributes(this.attributes)
			: attributes
		return this
	}

	setMergedCells(mergedCells: MergedCell[] | ((mc: MergedCell[]) => MergedCell[])) {
		this.mergedCells = typeof mergedCells === 'function'
			? mergedCells(this.mergedCells)
			: mergedCells
		return this
	}

	setMinSize(size: { rows: number, cols: number }): ExcelData {
		this.data = this.data.map(row => {
			if (row.length >= size.cols)
				return [ ...row ]
			return [ ...row, ...Array(size.cols - row.length).fill(null) ]
		})
		while (this.data.length < size.rows)
			this.data.push(Array(size.cols).fill(null))

		const newAttributes: Attribute[] = []

		for (let i = 0; i < this.attributes.length; i++) {
			const currAttr = this.attributes[ i ]
			const nextAttr = this.attributes[ i + 1 ]

			newAttributes.push(currAttr)
			if (!nextAttr) break

			if (nextAttr.col !== currAttr.col + 1) {
				for (let j = 1; currAttr.col + j < size.cols; j++)
					newAttributes.push({
						row: currAttr.row,
						col: currAttr.col + j,
						className: ''
					})
			}
		}
		const newAttributesHighestRow = array.last(newAttributes)?.row || 0
		for (let row = newAttributesHighestRow + 1; row < size.rows; row++) {
			for (let col = 0; col < size.cols; col++) {
				newAttributes.push({ row, col, className: '' })
			}
		}
		this.attributes = newAttributes
		return this
	}

	static defaultData(): ExcelData {
		return new ExcelData({
			id: -1,
			attributes: [],
			data: [],
			mergedCells: [],
		})
	}
}