import useLatest from '@react-hook/latest'
import { useCallback, useEffect, useRef } from 'react'

export const useCallbackDebounce = <CallbackArgs extends any[]>(
	callback: (...args: CallbackArgs) => void,
	wait = 100,
	leading = false
): ((...args: CallbackArgs) => void) => {
	const storedCallback = useLatest(callback)
	const timeout = useRef<ReturnType<typeof setTimeout>>()
	// Cleans up pending timeouts when the deps change
	useEffect(() => () => {
		timeout.current && clearTimeout(timeout.current)
		timeout.current = void 0
	}, [ wait, leading, storedCallback ])

	return useCallback(function () {
		// eslint-disable-next-line prefer-rest-params
		const args = arguments
		const { current } = timeout
		// Calls on leading edge
		if (current === void 0 && leading) {
			timeout.current = setTimeout(() => {
				timeout.current = void 0
			}, wait)
			// eslint-disable-next-line prefer-spread
			return storedCallback.current.apply(null, args as any)
		}
		// Clear the timeout every call and start waiting again
		current && clearTimeout(current)
		// Waits for `wait` before invoking the callback
		timeout.current = setTimeout(() => {
			timeout.current = void 0
			storedCallback.current.apply(null, args as any)
		}, wait)
	}, [ wait, leading, storedCallback ])
}
