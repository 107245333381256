import { Text } from 'src/lib/text'


interface Props {
	children: string
}

export const StepTitle = ({
	children
}: Props) => {

	return <Text className='ml-3 truncate' title={children} stronger opacity={0.8}>{children}</Text>

}