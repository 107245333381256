import { useUpdatedState } from 'src/hooks/state'


interface Props {
	onChange: (isChecked: boolean) => void
	defaultChecked: boolean
}

export const CheckboxSelectAll = ({
	onChange,
	defaultChecked,
}: Props) => {

	const [ isChecked, setIsChecked ] = useUpdatedState(defaultChecked)

	return (
		<label className='z-10 flex items-center px-3 cursor-pointer group' onClick={(e) => e.stopPropagation()}>
			<span className='pr-2.5'>Select all</span>
			<input
				type='checkbox'
				className='custom_input-checkbox cursor-pointer group-hover:border-primary-darker'
				onClick={() => setIsChecked(v => !v)}
				onChange={(e) => onChange(e.currentTarget.checked)}
				checked={isChecked}
			/>
		</label>
	)
}