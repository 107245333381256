import style from './NoProject.module.css'
import { Anchor } from 'src/lib/button';

interface Props {

}

const NoProject = ({
	...props
}: Props) => {



	return (
		<div className={style.div}>
			<h3 className={style.h3}> No Project Yet </h3>
			<Anchor.Contained
				className={style.a}
				intent='primary'
				to='/projects/new'>
				Get Started
			</Anchor.Contained>
		</div>
	)
}

export default NoProject;