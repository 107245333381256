import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Loader } from 'src/lib/loader';
import { Children } from 'src/types';
import { cn } from 'src/helpers';

interface Props {
	error?: Error
	isLoading?: boolean
	children?: Children
	className?: string
	height?: number
	minHeight?: number
	style?: React.CSSProperties
}

const InnerBoard = ({
	error,
	isLoading = false,
	children = null,
	...props
}: Props) => {



	if (error) {
		return (
			<Wrapper {...props}>
				<div className="flex-center grow h-full">
					<em className='pb-4'>{error.message}</em>
				</div>
			</Wrapper>
		)
	}

	if (isLoading) {
		return (
			<Wrapper {...props}>
				<div className="flex-center grow h-full">
					<Loader.Circular
						data-testid='loader'
						className='pb-4'
						width={50}
						strokeWidth={3}
					/>
				</div>
			</Wrapper>
		)
	}

	return (
		<Wrapper {...props}>
			{children}
		</Wrapper>
	)
}

export default InnerBoard;



const Wrapper: React.FC = ({
	className = '',
	style = {},
	height,
	minHeight,
	children = null
}: Props) => {

	const classNames = cn`
		flex 
		flex-col 
		relative 
		pb-0.5 
		border 
		border-border 
		shadow-sm 
		bg-white 
		rounded 
		${className}
	`

	if (!!minHeight)
		return (
			<div className={classNames} style={{ ...style, minHeight: minHeight + 'px' }}>
				{children}
			</div>
		)

	else
		return (
			<Scrollbars className={classNames} style={{ ...style, height: height + 'px' }} renderTrackHorizontal={() => <div />}>
				{children}
			</Scrollbars>
		)
}