import { useCallback, useEffect, useRef } from 'react'
import useLatest from '@react-hook/latest'

const perf = typeof performance !== 'undefined' ? performance : Date
const now = () => perf.now()

export function useCallbackThrottle<CallbackArguments extends any[]>(
	callback: (...args: CallbackArguments) => void,
	ms = 1000,
	leading = true,
	trailing = false,
): (...args: CallbackArguments) => void {
	const storedCallback = useLatest(callback)
	const prev = useRef(0)
	const trailingTimeout = useRef<ReturnType<typeof setTimeout>>()
	const clearTrailing = () =>
		trailingTimeout.current && clearTimeout(trailingTimeout.current)

	// Reset any time the deps change
	useEffect(() => {
		return () => {
			prev.current = 0
			clearTrailing()
		}
	}, [ ms, leading, storedCallback, trailing ])

	return useCallback((...args: any) => {
		const rightNow = now()
		const call = () => {
			prev.current = rightNow
			clearTrailing()
			storedCallback.current.apply(null, args as any)
		}
		const current = prev.current
		// leading
		if (leading && current === 0) return call()
		// body
		if (rightNow - current > ms) {
			if (current > 0) return call()
			prev.current = rightNow
		}
		// trailing
		clearTrailing()
		trailingTimeout.current = setTimeout(() => {
			trailing && call()
			prev.current = 0
		}, ms)
	}, [ ms, leading, storedCallback, trailing ]
	)
}

