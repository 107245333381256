import { useRef, useState } from 'react'
import { useGlobalMouseMove, useGlobalMouseUp } from 'src/hooks/listeners'
import { dom, math } from 'src/helpers'
import { } from 'src/helpers'
import { NormalizedBounds } from 'src/types'
import { HandlerProps } from './types'

type Side = 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

const useResize = ({
	onResizeStart,
	onResizeEnd,
	onResize,
	side
}: Pick<HandlerProps, 'onResizeEnd' | 'onResize' | 'onResizeStart'> & { side: Side }) => {

	const ref = useRef<HTMLDivElement | null>(null)
	const [ isActive, setIsActive ] = useState(false)

	useGlobalMouseUp((e) => {
		const handler = ref.current
		const container = handler?.offsetParent
		if (isActive && handler && container) {
			const { x, y } = dom.getRelativeMousePosition(container)(e)
			const { height: containerHeight, width: containerWidth } = dom.getComputedSize(container)
			const partialBounds: Partial<NormalizedBounds> = {}
			if (side.includes('top'))
				partialBounds.minY = math.clamp(0, y / containerHeight, 1)
			if (side.includes('bottom'))
				partialBounds.maxY = math.clamp(0, y / containerHeight, 1)
			if (side.includes('left'))
				partialBounds.minX = math.clamp(0, x / containerWidth, 1)
			if (side.includes('right'))
				partialBounds.maxX = math.clamp(0, x / containerWidth, 1)
			onResizeEnd(partialBounds)
		}
		setIsActive(false)
	})

	useGlobalMouseMove((e) => {
		const handler = ref.current
		const container = handler?.offsetParent
		if (isActive && handler && container) {
			const { x, y } = dom.getRelativeMousePosition(container)(e)
			const { height: containerHeight, width: containerWidth } = dom.getComputedSize(container)
			const partialBounds: Partial<NormalizedBounds> = {}
			if (side.includes('top'))
				partialBounds.minY = math.clamp(0, y / containerHeight, 1)
			if (side.includes('bottom'))
				partialBounds.maxY = math.clamp(0, y / containerHeight, 1)
			if (side.includes('left'))
				partialBounds.minX = math.clamp(0, x / containerWidth, 1)
			if (side.includes('right'))
				partialBounds.maxX = math.clamp(0, x / containerWidth, 1)
			onResize(partialBounds)
		}
	})

	function onMouseDown() {
		setIsActive(true)
		onResizeStart()
	}

	return { ref, isActive, setIsActive, onMouseDown }
}


function TopLeft(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'top-left',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})


	return (
		<div
			ref={ref}
			className='absolute rounded-full'
			onMouseDown={onMouseDown}
			style={{
				top: `calc(${props.minY * 100}% - ${props.borderWidth + props.anchorSize / 2}px)`,
				left: `calc(${props.minX * 100}%  - ${props.borderWidth + props.anchorSize / 2}px)`,
				height: `${props.borderWidth + props.anchorSize}px`,
				width: `${props.borderWidth + props.anchorSize}px`,
				cursor: 'nw-resize',
				background: 'white',
				border: `${props.borderWidth}px solid ${props.color}`
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

function TopRight(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'top-right',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})


	return (
		<div
			ref={ref}
			className='absolute rounded-full'
			onMouseDown={onMouseDown}
			style={{
				top: `calc(${props.minY * 100}% - ${props.borderWidth + props.anchorSize / 2}px)`,
				right: `calc(${100 - props.maxX * 100}%  - ${props.borderWidth + props.anchorSize / 2}px)`,
				height: `${props.borderWidth + props.anchorSize}px`,
				width: `${props.borderWidth + props.anchorSize}px`,
				cursor: 'ne-resize',
				background: 'white',
				border: `${props.borderWidth}px solid ${props.color}`
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

function BottomLeft(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'bottom-left',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})


	return (
		<div
			ref={ref}
			className='absolute rounded-full'
			onMouseDown={onMouseDown}
			style={{
				bottom: `calc(${100 - props.maxY * 100}% - ${props.borderWidth + props.anchorSize / 2}px)`,
				left: `calc(${props.minX * 100}%  - ${props.borderWidth + props.anchorSize / 2}px)`,
				height: `${props.borderWidth + props.anchorSize}px`,
				width: `${props.borderWidth + props.anchorSize}px`,
				cursor: 'sw-resize',
				background: 'white',
				border: `${props.borderWidth}px solid ${props.color}`
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

function BottomRight(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'bottom-right',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})


	return (
		<div
			ref={ref}
			className='absolute rounded-full'
			onMouseDown={onMouseDown}
			style={{
				bottom: `calc(${100 - props.maxY * 100}% - ${props.borderWidth + props.anchorSize / 2}px)`,
				right: `calc(${100 - props.maxX * 100}%  - ${props.borderWidth + props.anchorSize / 2}px)`,
				height: `${props.borderWidth + props.anchorSize}px`,
				width: `${props.borderWidth + props.anchorSize}px`,
				cursor: 'se-resize',
				background: 'white',
				border: `${props.borderWidth}px solid ${props.color}`
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

function Top(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'top',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})

	return (
		<div
			ref={ref}
			className='absolute'
			onMouseDown={onMouseDown}
			style={{
				top: `calc(${props.minY * 100}% - ${props.borderWidth + 2}px)`,
				left: `${props.minX * 100}%`,
				right: `${100 - props.maxX * 100}%`,
				height: `${props.borderWidth + 4}px`,
				cursor: 'n-resize',
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

function Bottom(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'bottom',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})

	return (
		<div
			ref={ref}
			className='absolute'
			onMouseDown={onMouseDown}
			style={{
				bottom: `calc(${100 - props.maxY * 100}% - ${props.borderWidth + 2}px)`,
				left: `${props.minX * 100}%`,
				right: `${100 - props.maxX * 100}%`,
				height: `${props.borderWidth + 4}px`,
				cursor: 's-resize',
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

function Left(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'left',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})

	return (
		<div
			ref={ref}
			className='absolute'
			onMouseDown={onMouseDown}
			style={{
				left: `calc(${props.minX * 100}% - ${props.borderWidth + 2}px)`,
				top: `${props.minY * 100}%`,
				bottom: `${100 - props.maxY * 100}%`,
				width: `${props.borderWidth + 4}px`,
				cursor: 'w-resize',
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

function Right(props: HandlerProps) {

	const { ref, onMouseDown } = useResize({
		side: 'right',
		onResizeStart: props.onResizeStart,
		onResizeEnd: props.onResizeEnd,
		onResize: props.onResize,
	})

	return (
		<div
			ref={ref}
			className='absolute'
			onMouseDown={onMouseDown}
			style={{
				right: `calc(${100 - props.maxX * 100}% - ${props.borderWidth + 2}px)`,
				top: `${props.minY * 100}%`,
				bottom: `${100 - props.maxY * 100}%`,
				width: `${props.borderWidth + 4}px`,
				cursor: 'e-resize',
			}}
			onClick={props.onClick}
			draggable={false}
		/>
	)
}

export const Handler = {
	Top,
	Bottom,
	Left,
	Right,
	TopLeft,
	TopRight,
	BottomLeft,
	BottomRight,
}