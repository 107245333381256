export enum ROLE {
	ADMIN = 'admin',
	REVIEWER = 'reviewer',
	SUPERVISOR = 'supervisor',
}

interface ApiMember {
	id: string
	name: string
	role: ROLE
}

export class Member {
	id: string
	name: string
	role: ROLE

	constructor(member: ApiMember) {
		this.id = member.id
		this.name = member.name
		this.role = member.role
	}
}