import './Excel.css'
import 'handsontable/dist/handsontable.full.css';
import HotTable from '@handsontable/react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { pure } from 'src/hoc';
import { ExcelData } from '.';
import { useUndoRedo } from 'src/hooks/useUndoRedo';
import { useData } from './useData';
import { useAddOrRemove } from './useAddOrRemove';
import { useMergedCells } from './useMergedCells';
import { useAttributes } from './useAttributes';
import { Button } from '../button';
import { Icon } from '../icons';
import { Divisor } from 'src/components';
import { ContextMenu, Tooltip } from '../modals';
import { selection } from './utils';
import { ExcelContextMenu } from './ExcelContextMenu';
import { useCopyPaste } from './useCopyPaste';
import { useCallbackDebounce } from 'src/hooks/callbacks';
import useResizeObserver from '@react-hook/resize-observer';
import { v4 } from 'uuid';
import { useUpdatedRef } from 'src/hooks/refs';
import { useUpdateEffect } from 'src/hooks/effects';

const TOOLBAR_HEIGHT = '3rem'
const TOOLTIP_DELAY = 300
const prefix = v4()
const noop = () => { }

export interface ExcelProps {
	defaultData: ExcelData | undefined | null
	minRows?: number
	minCols?: number
	onChange?: (excelData: ExcelData) => void
}

export const Excel = ({
	defaultData,
	minRows = 5,
	minCols = 5,
	onChange = noop,
}: ExcelProps) => {

	const onChangeRef = useUpdatedRef(onChange)

	const defaultDataWithMinSize = useMemo(() => (
		defaultData?.clone().setMinSize({ rows: minRows, cols: minCols })
	), [ defaultData, minRows, minCols ])

	const onChangeMemoized = useCallback((excelData: ExcelData) => {
		onChangeRef.current(excelData)
	}, [ onChangeRef ])

	return defaultDataWithMinSize
		? <ExcelBase key={`${prefix}-${defaultDataWithMinSize.id}`} defaultData={defaultDataWithMinSize} onChange={onChangeMemoized} />
		: <ExcelBase key={prefix} defaultData={ExcelData.defaultData().setMinSize({ rows: minRows, cols: minCols })} />
}

export const ExcelBase = pure(({
	defaultData,
	onChange = noop,
}: ExcelProps & { defaultData: ExcelData }) => {

	const containerRef = useRef<HTMLDivElement | null>(null)
	const ref = useRef<HotTable | null>(null)
	const [ state, setState ] = useState(defaultData)
	const { handleChange } = useData(setState, ref)
	const { mergeSelectedCells, unmergeSelectedCells } = useMergedCells(setState, ref)
	const { alignCells, formatCells } = useAttributes(setState, ref)
	const { removeRows, removeCols, addRowAbove, addRowBelow, addColLeft, addColRight } = useAddOrRemove(setState, ref)
	const { undo, redo } = useUndoRedo(state, setState, { ignore: (state) => !state?.data.length })
	const { copy, paste, cut } = useCopyPaste(setState, ref)

	useUpdateEffect(() => {
		state && onChange(state)
	}, [ state ])


	useResizeObserver(containerRef, useCallbackDebounce(() => {
		const hot = ref.current?.hotInstance
		if (!hot) return
		hot.render()
	}, 50, false))


	const handleContextMenu = useCallback((e: React.MouseEvent) => {
		e.preventDefault()

		ContextMenu.show((close) => (
			<ExcelContextMenu
				close={close}
				mergeSelectedCells={mergeSelectedCells}
				unmergeSelectedCells={unmergeSelectedCells}
				alignCells={alignCells}
				formatCells={formatCells}
				removeRows={removeRows}
				removeCols={removeCols}
				addRowAbove={addRowAbove}
				addRowBelow={addRowBelow}
				addColLeft={addColLeft}
				addColRight={addColRight}
				undo={undo}
				redo={redo}
				copy={copy}
				paste={paste}
				cut={cut}
				selection={{
					rows: selection.getRows(ref),
					cols: selection.getCols(ref),
					list: selection.getList(ref),
					object: selection.getObject(ref),
					tuple: selection.getTuple(ref),
					translate: selection.translate,
				}}
			/>
		), { x: e.clientX, y: e.clientY })
	}, [ addColLeft, addColRight, addRowAbove, addRowBelow, alignCells, copy, cut, formatCells, mergeSelectedCells, paste, redo, removeCols, removeRows, undo, unmergeSelectedCells ])


	return (
		<div ref={containerRef} className='h-full flex-grow flex-shrink overflow-hidden'>
			<div className='flex items-center px-3 gap-2 overflow-hidden' style={{ height: TOOLBAR_HEIGHT }}>
				<Tooltip delay={TOOLTIP_DELAY} text='Undo' shortcut='Ctrl+Z'>
					<Button.Icon onClick={undo}><Icon icon='undo' /></Button.Icon>
				</Tooltip>
				<Tooltip delay={TOOLTIP_DELAY} text='Redo' shortcut='Ctrl+Y'>
					<Button.Icon onClick={redo}><Icon icon='redo' /></Button.Icon>
				</Tooltip>
				<Divisor className='m-1.5 h-4' y />
				<Tooltip delay={TOOLTIP_DELAY} text='Align left'>
					<Button.Icon onClick={alignCells('htLeft')}><Icon icon='align-left' /></Button.Icon>
				</Tooltip>
				<Tooltip delay={TOOLTIP_DELAY} text='Align center'>
					<Button.Icon onClick={alignCells('htCenter')}><Icon icon='align-center' /></Button.Icon>
				</Tooltip>
				<Tooltip delay={TOOLTIP_DELAY} text='Align right'>
					<Button.Icon onClick={alignCells('htRight')}><Icon icon='align-right' /></Button.Icon>
				</Tooltip>
				<Divisor className='m-1.5 h-4' y />
				<Tooltip delay={TOOLTIP_DELAY} text='Merge cells'>
					<Button.Icon onClick={mergeSelectedCells}><Icon icon='merge' /></Button.Icon>
				</Tooltip>
				<Tooltip delay={TOOLTIP_DELAY} text='Split cells'>
					<Button.Icon onClick={unmergeSelectedCells}><Icon icon='split' /></Button.Icon>
				</Tooltip>
				<Divisor className='mx-1.5 h-4' y />
				<Tooltip delay={TOOLTIP_DELAY} text='Bold'>
					<Button.Icon onClick={formatCells('tbBold')}><Icon icon='bold' /></Button.Icon>
				</Tooltip>
				<Tooltip delay={TOOLTIP_DELAY} text='Italic'>
					<Button.Icon onClick={formatCells('tbItalic')}><Icon icon='italic' /></Button.Icon>
				</Tooltip>
				{/* <Button.Icon onClick={alignCells('htTop')}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={alignCells('htMiddle')}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={alignCells('htBottom')}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={removeRows}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={removeCols}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={addRowAbove}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={addRowBelow}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={addColLeft}><Icon icon='' /></Button.Icon>
				<Button.Icon onClick={addColRight}><Icon icon='' /></Button.Icon> */}
			</div>
			<div onContextMenu={handleContextMenu} style={{ height: `calc(100% - ${TOOLBAR_HEIGHT})`, width: '100%' }}>
				<Hot
					id='hotTable'
					height='100%'
					width='100%'
					ref={ref}
					licenseKey='non-commercial-and-evaluation'
					data={state.data}
					cell={state.attributes}
					mergeCells={state.mergedCells}
					contextMenu={false}
					colHeaders
					rowHeaders
					outsideClickDeselects={false}
					allowInsertRow
					allowInsertColumn
					manualRowResize
					manualColumnResize
					afterChange={handleChange}
					autoColumnSize
					undo={false}
				/>
			</div>
		</div>
	)
})

const Hot = memo(HotTable)
