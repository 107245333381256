import { useEffect, useState } from 'react';
import { Button } from 'src/lib/button';
import { Text } from 'src/lib/text';
import { useFetchMembers } from 'src/API/members'
import { useParams } from 'react-router';
import { InnerBoard } from 'src/components';
import InnerBoardLegend, { GetInnerBoardLegendWidth, Legend } from './InnerBoardLegend';
import { useSetState } from 'src/hooks/state';
import { Member } from 'src/data/DEPRECATED-Member';
import { cn } from 'src/helpers';
import { Icon } from 'src/lib/icons';
import { notify } from 'src/lib/modals';


interface Params {
	projectId: string
}

interface Props {
	onLoad: () => void
}

const ViewMembers = ({
	onLoad
}: Props) => {

	const { projectId } = useParams<Params>()
	const { data: members, error } = useFetchMembers(projectId)
	const [ legend, setLegend ] = useSetState<Legend>({})

	useEffect(onLoad, []) //eslint-disable-line

	return (
		<div className='view_members'>

			<div className="flex items-end justify-between">
				<Button.Contained
					rounded
					onClick={() => notify('primary')('Not implemented yet')}
					fontSize={14}
					iconSize={16}>
					<Icon icon='plus' />
					Add Member
				</Button.Contained>

				<InnerBoardLegend legend={legend} />
			</div>

			<InnerBoard error={error} isLoading={!members} minHeight={36 * 11} className='overflow-hidden mt-3.5'>
				<table className='w-full'>
					<tbody>

						{!!members && members.map(member => (
							<Row member={member} key={`member-${member.id}`} />
						))}

						<GetInnerBoardLegendWidth
							onCol2Width={(w) => setLegend({ col2: { text: 'User ID', width: w } })}
							onCol3Width={(w) => setLegend({ col3: { text: 'Role', width: w } })}
						/>
					</tbody>
				</table>
			</InnerBoard>

		</div>
	)
}

export default ViewMembers;

interface RowProps {
	member: Member,
	onClick?: (member: Member) => void
}

const Row = ({
	member,
	onClick = () => { }
}: RowProps) => {

	const [ isHover, setIsHover ] = useState(false)

	const handleClick = () => onClick(member)
	const handleMouseEnter = () => setIsHover(true)
	const handleMouseLeave = () => setIsHover(false)

	const sharedProps = {
		onClick: handleClick,
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave,
	}

	return (
		<tr style={{ borderBottom: '1px solid rgba(238, 238, 238)' }}>
			<td className='w-full'>
				<div className='relative flex items-center pl-3' style={{ minHeight: '36px' }} {...sharedProps}>
					<Text truncate title={member.name}>{member.name}</Text>

					{isHover && (
						<div className={cn`
						absolute 
						left-0 
						h-full 
						w-screen 
						bg-primary-hover 
						text-primary 
						text-opacity-50 
						outline-current
					`}
						/>
					)}
				</div>
			</td>

			<td>
				<div className='flex items-center justify-end px-4' style={{ minHeight: '36px', minWidth: '100px' }} {...sharedProps}>
					<Text smaller>{member.id}</Text>
				</div>
			</td>

			<td>
				<div className='flex items-center justify-end px-4' style={{ minHeight: '36px', minWidth: '100px' }} {...sharedProps}>
					<Button.Inline
						onClick={() => notify('primary')('Not implemented yet')}
						fontSize={14}
						iconSize={10}>
						{member.role}
						<Icon icon='chevron-right' strokeWidth={4} style={{ transform: 'rotate(90deg)', margin: '2px 0 0 6px' }} />
					</Button.Inline>
				</div>
			</td>
		</tr>)
}