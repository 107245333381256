import React from 'react';
import { getHexFromWord, getDarkHexFromHex } from 'src/helpers'
import { pipe } from 'pipe-ts'


interface Props extends React.ComponentProps<'div'> {
	projectName: string
	small?: boolean
	xsmall?: boolean
}

const ProjectIcon = ({
	projectName,
	small = false,
	xsmall = false,
	className = '',
	...props
}: Props) => {

	const hex = pipe(
		getHexFromWord,
		getDarkHexFromHex
	)(projectName)

	const letter = (projectName[ 0 ] || 'P').toUpperCase()
	const size = xsmall ? '24px' : small ? '31px' : '42px'
	const fontSize = xsmall ? '16px' : small ? '20px' : '24px'

	const marginRightClass = xsmall ? 'mr-0.5' : small ? 'mr-2.5' : 'mr-3.5'

	const style = {
		fontSize,
		fontWeight: 600,
		color: hex,
		backgroundColor: hex + '30',
		height: size,
		width: size,
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '4px',
		flexShrink: 0,
	}

	return (
		<div className={`project_icon ${marginRightClass} ${className}`} style={style} {...props}>
			{letter}
		</div>
	)
}

export default ProjectIcon;