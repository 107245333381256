import { useCallback, useRef, useState } from 'react'
import { useUpdatedRef } from '../refs'


export const useStateThrottled = <T extends any>(
	initialValue: T,
	delay: number
): [ T, SetState<T> ] => {

	const [ state, _setState ] = useState<T>(initialValue)
	const stateRef = useUpdatedRef(state)
	const lastCallTsRef = useRef(0)

	const setState = useCallback<SetState<T>>((action) => {
		const now = Date.now()
		if (now - lastCallTsRef.current < delay) return
		const newState = typeof action === 'function' ? (action as FunctionalAction<T>)(stateRef.current) : action
		_setState(newState)
		lastCallTsRef.current = now
	}, [ delay, stateRef ])

	return [ state, setState ]
}

type FunctionalAction<T> = (prevState: T) => T
export type SetState<T> = (action: T | FunctionalAction<T>) => void