import React from 'react';
import { Children } from 'src/types';
import { Variant } from './types';
import { getClassName } from './utils';

interface Props extends React.ComponentProps<'p'> {
	children?: Children
	opacity?: number
	variant?: Variant
	className?: string
	inline?: boolean
	smaller?: boolean
	stronger?: boolean
	truncate?: boolean
}

const Text = ({
	opacity,
	children = null,
	variant = 'dark',
	className = '',
	inline = false,
	smaller = false,
	stronger = false,
	truncate = false,
	style = {},
	...props
}: Props) => {

	if (opacity)
		style = {
			...style,
			opacity,
		}

	const cnConfig = { variant, inline, className, smaller, stronger, truncate }

	return (
		<p style={style} className={getClassName(cnConfig)} {...props}>
			{children}
		</p>
	)
}

export default Text;

