import { Loader } from '../loader'


interface Props {
	className?: string
	animate: boolean
}

export const Placeholder = ({
	className = '',
	animate
}: Props) => {

	return (
		<div className={`flex-center h-full w-full text-primary-lighter ${className}`}>
			<Loader.Cool width='7%' animate={animate} />
		</div>
	)
}
