import { Import } from 'src/data/Import'
import { useProjectIdFromParams } from 'src/hooks/params'
import { cache } from 'swr'


export function useIsImportListBusy() {
	const projectId = useProjectIdFromParams()
	const cached = cache.get([ '/cpf/v1/import/list/', projectId + '' ]) as Import[] | undefined
	return !!cached?.some(imp => imp.isBusy)
}
