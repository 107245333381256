import { Dispatch, useEffect, useRef, useState } from "react";
import { BlockPicker, ChromePicker ,SketchPicker } from "react-color";
import { ITag } from "src/data/tag";
import { Button } from "src/lib/button";
import { Icon } from "src/lib/icons";
import { Loader } from "src/lib/loader";
import { Dialog, notify, Tooltip } from "src/lib/modals";
import { Text } from "src/lib/text";
import { tagColorsMap, TagUpdateData, TagViewMode } from "src/types/ui";
import { useOnClickOutside } from "usehooks-ts";
import NewTag from "./new-tag";
import Tag from "./tag";
import { postFileTag } from "src/API/tags";

interface IProps {
  tag: ITag;
  deleteItem: Dispatch<ITag>;
  updateItem: Dispatch<TagUpdateData>;
  className?: string;
  open?: boolean,
  file: number | undefined
  page: number
  project: any
}

const TagEditItem = ({ tag, deleteItem, className, updateItem, project, file, page }: IProps) => {
  const pickerRef = useRef(null);
  const [_tempTag, setTempTag] = useState<ITag | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<TagViewMode>("placeholder");
  const [temp, setTemp ] = useState<string>('')
  const [blockPickerColor, setBlockPickerColor] = useState(
    tag?.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`
  );
  const [pickerCoords, setPickerCoords] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const handlePickerOutsideClick = () => {
    setTemp('')
    setShowPicker(false);
    setIsOpen(false);
  };
  useOnClickOutside(pickerRef, handlePickerOutsideClick);
  const openModal = (tag: ITag) => {
    setTempTag(tag);
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setTempTag(null);
    setIsOpen(false);
  };
  const handleDeleteItem = async () => {  
    const pages = tag.pages.filter((p) => p === page);
    if(pages.length) {
      const addRequestResponse = await postFileTag({ project_id: project.id, page, file_id: file, tag: tag.tag,  action:'delete-all'})
      if(addRequestResponse.code === 1){
        deleteItem(_tempTag as ITag);
        notify("success")(addRequestResponse.info);
        setIsOpen(false);
      } 
      else {
        notify("danger")(addRequestResponse.reason);
      }
    }
    else {
      notify("danger")(`Tag "${_tempTag?.tag}" does not exist in current page!`);
    }
  };

  const onApplyColor = async () => {
    const pages = tag.pages.filter((p) => p === page);
    if(pages.length) {

      const addRequestResponse = await postFileTag({ project_id: project.id, page, file_id: file, tag: tag.tag,  action:'edit', color:  temp.substring(1)})
      if(addRequestResponse.code === 1){ 
        notify("success")(`Color for tag "${tag?.tag}" is updated successfully!`);
        setBlockPickerColor(temp);
        setShowPicker(false);
      }
      else {
        notify("danger")(`Color update for tag "${tag?.tag}" unsuccessfully!`);
        setBlockPickerColor(tag?.color || ''); 
        setTemp(''); 
        setShowPicker(false);
      }
    }
    else {
      notify("danger")(`Tag "${_tempTag?.tag}" does not exist in current page!`);
      setTemp(''); 
      setBlockPickerColor(tag?.color || ''); 
      setShowPicker(false);
    }
  }

  return (
    <>
      <div className={`flex justify-between items-center ${className}`}>
        {viewMode === "edit" ? (
          <NewTag
            updateHandler={(value)=>updateItem({...value, pages: tag.pages})}
            tagValue={tag.tag}
            color={temp || tag.color}
            viewMode="edit"
            viewModeEmitter={setViewMode}
          />
        ) : (
          <span>
            <Tag tag={tag} color={temp || blockPickerColor} />
          </span>
        )}
        <div className="flex">
        <Tooltip text="Edit Tag Name" delay={400}>

          <Icon
            className="cursor-pointer"
            icon="edit"
            onClick={(e) => setViewMode("edit")}
          />
          </Tooltip>
          <div>
            <div
              className="ml-2 mr-2 cursor-pointer"
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "3px",
                backgroundColor: blockPickerColor,
              }}
              onClick={(e) => {
                setPickerCoords({ x: e.clientX, y: e.clientY });
                setShowPicker(true);
              }}
            ></div>
          </div>
          {!showPicker ? null : (
            <div
              ref={pickerRef}
              className="fixed bg-white"
              style={{
                // top: "18px",
                // left: "-230px",
                left: pickerCoords.x - 230,
                top: pickerCoords.y + 10,
                zIndex: 999,
                boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'
              }}
              >

              <ChromePicker
                className="custom__color__picker"
                color={temp || tag.color}
                onChange={(color) => {
                  setTemp(color.hex)
                }}
              />
              <div className="flex justify-between" >
                <Button.Contained className="px-2 bg-white shadow-none mx text-primary " onClick={onApplyColor
                } >Apply</Button.Contained>
                <Button.Contained className="px-2 bg-white shadow-none mx text-primary " onClick={handlePickerOutsideClick} >Cancel</Button.Contained>
              </div>
            </div>
          )}
        <Tooltip text="Delete Tag" delay={400}>

          <Icon
            className="cursor-pointer"
            icon="trash"
            onClick={(e) => openModal(tag)}
            />
            </Tooltip>
        </div>
      </div>
    {
      isOpen &&
        <Dialog
          isOpen={isOpen}
          onClickOutside={() => setIsOpen(false)}
          header='Are you sure?'

          content={(
            <div className='flex flex-col items-center justify-center text-center opacity-60'>
              <Text>This action will delete the tag from the project database. <br/>This tag will be removed from all pages it is currently associated with.</Text>
            </div>
          )}
          footer={(
            <div className='flex justify-end'>
              <Button.Text onClick={handleDeleteItem}
                intent='danger'>
                PROCEED
              </Button.Text>
              <Button.Text
                onClick={handleCloseModal}>
                CANCEL
              </Button.Text>

            </div>
          )}
        />
    }
    </>
  );
};

export default TagEditItem;
