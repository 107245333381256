import HotTable from '@handsontable/react'
import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react'
import { ExcelData } from '.'
import { selection } from './utils'
import copyToClipboard from 'copy-to-clipboard'
import { notify } from '../modals'


export const useCopyPaste = (setState: Dispatch<SetStateAction<ExcelData>>, ref: MutableRefObject<HotTable | null>) => {

	const copy = useCallback(() => {
		setState(state => {
			const copiedData = selection.getText(ref, state)
			copyToClipboard(copiedData)
			return state
		})
	}, [ ref, setState ])

	const paste = useCallback(async () => {
		try {
			const startAt = ref.current?.hotInstance.getSelectedRange()?.[ 0 ].highlight
			if (!startAt) return
			const text = await navigator.clipboard.readText()
			const dataToPaste = text.split('\n').map(row => row.split('\t'))

			setState(state => state.clone().setData(data => {
				dataToPaste.forEach((row, rowI) => row.forEach((val, colI) => {
					data[ startAt.row + rowI ][ startAt.col + colI ] = val
				}))
				return data
			}))
		}
		catch (e) {
			navigator.permissions
				.query({ name: 'clipboard-read' as PermissionName })
				.then(() => notify('warning')('Could not Paste: this action has been blocked by your browser settings.'))
		}
	}, [ ref, setState ])

	const cut = useCallback(() => {
		setState(state => {
			const copiedData = selection.getText(ref, state)
			const selected = selection.getList(ref)
			if (!selected) return state
			copyToClipboard(copiedData)
			const newState = state.clone()
			selected.forEach(cell => {
				newState.data[ cell.row ][ cell.col ] = null
			})
			return newState
		})
	}, [ ref, setState ])

	return { copy, paste, cut }
}