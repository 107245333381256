import { ComponentProps } from 'react';

interface Props extends ComponentProps<'div'> {
	y?: boolean
}

const Divisor = ({
	y = false,
	className = '',
	style = {},
	...props
}: Props) => {

	style = {
		background: '#dedede',
		...style
	}

	return (
		<div
			className={`${y ? 'w-px' : 'h-px'} ${className}`}
			style={style}
			{...props}
		/>
	)
}

export default Divisor;
