import { Nullish } from 'src/types';

export interface ApiUser {
	uuid: string
	username: string
	email: string
	profile_picture: string | Nullish
	group: any[]
}

interface IUser {
	id: string
	username: string
	email: string
	picture: string | Nullish
	groups: any[]
}

export class User implements IUser {
	id: string
	username: string
	email: string
	picture: string | Nullish
	groups: any[]

	constructor(data: User | IUser) {
		this.id = data.id
		this.username = data.username
		this.email = data.email
		this.picture = data.picture
		this.groups = data.groups
	}

	static fromApi(apiUser: ApiUser): User {
		const data: IUser = {
			id: apiUser.uuid,
			username: apiUser.username,
			email: apiUser.email,
			picture: apiUser.profile_picture,
			groups: apiUser.group,
		}
		return new User(data)
	}

	static createInstance(user: IUser | User): User {
		return new User(user)
	}
}
