import React from 'react';
import { Menu } from 'src/lib/menu';
import { ProjectIcon } from 'src/components';
import { Heading, Text } from 'src/lib/text';
import { useFetchProjects } from 'src/API/projects';
import { Loader } from 'src/lib/loader';
import { cn } from 'src/helpers';
import Scrollbars from 'react-custom-scrollbars';
import Divisor from '../Divisor';


const ProjectsDropdown = () => {


	const { data: projects, isValidating } = useFetchProjects()

	return (
		<div className='flex'>
			<Menu.Root className='pr-1'>
				<Menu.Link to='/projects' className='pr-5'>
					My projects
				</Menu.Link>
				<Menu.Link to='/projects/new' className='pr-5'>
					New project
				</Menu.Link>
			</Menu.Root>

			<Divisor className='my-0 h-auto mx-0' y />

			<Menu.Root className='pl-1' style={{ minWidth: '220px' }}>
				<Scrollbars autoHeight autoHeightMin={160} autoHeightMax={385}>
					{!isValidating && !projects?.length && (
						<Text
							className={cn`
								h-40 
								flex-center
								opacity-0
								animate-fadein-full-slow
							`}
							variant='info'>
							<i>No project yet</i>
						</Text>
					)}

					{projects?.map(({ title, id }) => (
						<Menu.Link
							className='flex items-center py-1.5'
							to={`/projects/${id}/files/0`}
							key={`project-dropdown-${title}-${id}`}>
							<ProjectIcon projectName={title} small />
							<Heading className='project_name'>{title}</Heading>
						</Menu.Link>
					))}
				</Scrollbars>

				<div className='validating_indicator flex-center h-8 pt-2 mt-auto'>
					{isValidating && <Loader.Dots height={11} />}
				</div>
			</Menu.Root>
		</div>
	)
}

export default ProjectsDropdown;