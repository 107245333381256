import React from 'react'
import { ErrorMessage, ErrorMessageProps } from 'formik';
import { Text } from '../text';

interface Props extends ErrorMessageProps {
	style?: React.CSSProperties
}

const CustomErrorMessage = ({
	className = '',
	style = {},
	...props
}: Props) => {

	return (
		<ErrorMessage
			render={error => (
				<Text
					className={className}
					style={style}
					variant='error'
					smaller>
					{error}
				</Text>
			)}
			{...props}
		/>
	)
}

export default CustomErrorMessage