import React from 'react';

interface Props {
	progress: number
	className?: string
}

const ProgressBar = ({
	className = '',
	progress
}: Props) => {



	return (
		<div className={`relative w-full h-2 rounded-full overflow-hidden bg-success bg-opacity-25 ${className}`}>
			<div style={{ width: `${progress}%`, minWidth: '3px' }} className="h-full animate-pulse bg-success"></div>
		</div>
	)
}

export default ProgressBar;