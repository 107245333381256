import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom'
import { useOnClickOutside } from 'src/hooks/listeners';
import { Coordinates } from 'src/types';
import { Children } from 'src/types';
import { isInViewport } from 'src/helpers/dom/isInViewport';
import { preventDefault } from 'src/helpers/dom/preventDefault';


export const ContextMenu = {
	show: (content: (close: () => void) => Children, coordinates: Coordinates) => { },
	Provider: ContextMenuProvider,
}

function ContextMenuProvider() {

	const ref = useRef<HTMLDivElement | null>(null)
	const [ content, setContent ] = useState<Children>(null)
	const [ coordinates, setCoordinates ] = useState<Coordinates | null>(null)

	const close = useCallback(() => {
		setCoordinates(null)
		setContent(null)
	}, [])

	useEffect(() => {
		ContextMenu.show = (content, coordinates) => {
			setContent(content(close))
			setCoordinates(coordinates)
		}
	}, [ close ])

	useLayoutEffect(() => {
		const el = ref.current
		if (!el || !content) return
		const { bottom, right } = isInViewport(el)
		el.style.transform = `translate(${right ? 0 : '-100%'}, ${bottom ? 0 : '-100%'})`

		return () => {
			el.style.transform = ''
		}
	}, [ content ])


	useOnClickOutside('#context-menu', close)

	if (!content || !coordinates) return null

	const jsx = (
		<div ref={ref} onContextMenu={preventDefault} id='context-menu' className='absolute inline-flex animate-fadein-full-fast p-1 bg-white rounded-sm' style={{ top: coordinates.y + 'px', left: coordinates.x + 'px', boxShadow: '0 2px 12px 0 #bbb', zIndex: 10000 }}>
			{content}
		</div>
	)

	return ReactDOM.createPortal(jsx, document.getElementById('root')!)
}