import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

interface Location {
	pathname: string;
	search: string;
	state: any;
	hash: any;
	key?: string;
}

function parseUrl<T>(location: Location) {
	const stringified = queryString.parse(location.search);
	const parsed = JSON.parse((stringified.config as string) || "{}") as T;
	return Object.keys(parsed).length ? parsed : undefined;
}

export const useSetStateInUrl = <T extends {}>(defaultState: T) => {
	const history = useHistory();
	const location = useLocation();
	const state = parseUrl<T>(location) || defaultState;

	const set = (partial: Partial<T>) => {
		const newState = { ...state, ...partial };
		const stringified = queryString.stringify({ config: JSON.stringify(newState) });
		history.push(`${history.location.pathname}?${stringified}`);
	};

	return [state, set] as const;
};
