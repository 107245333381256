import { getFileSizeAndUnit } from 'src/helpers'
import * as yup from 'yup'
import { ApiModules, MODULE, Modules } from './Modules'
import { ApiMetadata, Metadata, IMetadata } from './ProjectMetadata'


export interface ApiProject {
	id: number
	title: string
	description: string
	modules: ApiModules
	metadata: ApiMetadata
}

export interface ProjectFormData {
	title: string
	description: string
	modules: MODULE[]
}

const emptyProject: IProject = {
	id: -1,
	title: "",
	description: "",
	modules: new Modules({ active: [] }),
}

export interface IProject {
	id: number
	title: string
	description: string
	modules: Modules
	metadata?: Metadata | IMetadata
}

export class Project implements IProject {
	id: number
	title: string
	description: string
	modules: Modules
	metadata: Metadata

	constructor(data: Project | IProject = emptyProject) {
		this.id = data.id
		this.title = data.title
		this.description = data.description
		this.modules = new Modules(data.modules)
		this.metadata = data.metadata
			? Metadata.createInstance(data.metadata)
			: Metadata.createDefaults()
	}

	formSchema = (projects: Project[] | undefined = []) => {
		const otherProjects = projects.filter(p => p.id !== this.id)
		const otherProjectsLowercaseNames = Project._getLowerCaseProjectNames(otherProjects)

		return yup.object({
			title: yup.lazy((value: string = '') => (
				yup.string()
					.required('Required')
					.min(3, `Minimum characters: 3 (${value.length})`)
					.max(50, `Maximum characters: 50 (${value.length})`)
					.lowercase()
					.trim()
					.notOneOf(otherProjectsLowercaseNames, 'This project title is already in use.')
			)),
			description: yup.lazy((value: string = '') => (
				yup.string()
					.required('Required')
					.max(200, `Maximum characters: 200 (${value.length})`)
			)),
			modules: yup.array().min(1, 'Please select at least one module.')
		})
	}

	static fromApi(apiData: ApiProject): Project {
		const data: IProject = {
			id: apiData.id,
			title: apiData.title,
			description: apiData.description,
			modules: Modules.fromApi(apiData.modules),
			metadata: Metadata.fromApi(apiData.metadata),
		}
		return new Project(data)
	}

	clone(): Project {
		return new Project(this)
	}

	static fromFormData(formData: ProjectFormData): Project {
		const data: IProject = {
			id: emptyProject.id,
			title: formData.title,
			description: formData.description,
			modules: new Modules({ active: formData.modules }),
			metadata: Metadata.createDefaults()
		}
		return new Project(data)
	}

	static toFormData(data: Project | IProject): ProjectFormData {

		return {
			title: data.title,
			description: data.description,
			modules: data.modules.active
		}
	}

	static toApi(data: Project | IProject): ApiProject {

		return {
			id: data.id,
			title: data.title.trim(),
			description: data.description.trim(),
			modules: Modules.toApi(data.modules),
			metadata: data.metadata
				? Metadata.toApi(data.metadata)
				: Metadata.createDefaults()
		}
	}

	updateWithFormData(formData: ProjectFormData): Project {
		this.title = formData.title
		this.description = formData.description
		this.modules = new Modules({ active: formData.modules })
		return this
	}

	private static _getLowerCaseProjectNames = (projects: Project[] | undefined = []) => {

		return projects.reduce((projectNames, project) => {
			return [ ...projectNames, project.title.toLowerCase() ]
		}, [] as string[])
	}


	get projectFileSize() {
		const [ total, unit ] = getFileSizeAndUnit(this.metadata.totalFileSize)
		return { total, unit }
	}

	get arkFileSize() {
		const [ total, unit ] = getFileSizeAndUnit(this.metadata.ark.totalFileSize)
		return { total, unit }
	}

	get bomFileSize() {
		const [ total, unit ] = getFileSizeAndUnit(this.metadata.bom.totalFileSize)
		return { total, unit }
	}

	get totalActiveModules(): number {
		return this.modules.active.length
	}

	get totalMembers(): number {
		return this.metadata.numberOfProjectMembers
	}

	get isEnabled() {
		return {
			ark: {
				module: !!this.modules.active.includes(MODULE.ARK_SEARCH)
			},
			bom: {
				module: !!this.modules.active.includes(MODULE.BOM_GENERATION),
				overview: true,
				process: !!this.metadata.bom.importedOn,
				findTables: this.metadata.bom.numberOfProcessedFiles > 0,
				reviewTables: this.metadata.bom.numberOfExtractedTables > 0,
			}
		}
	}
}

