import { Icon } from 'src/lib/icons';
import { Loader } from 'src/lib/loader'
import { Anchor, Button } from 'src/lib/button'
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser'


interface Props {
	message: string
	link?: {
		to: string
		message: string
	},
	backButton?: boolean
	retry?: () => void
	isRetrying?: boolean
	delay?: number
}

const ErrorPage = ({
	message,
	link,
	backButton = false,
	retry,
	isRetrying,
	delay = 0,
	...props
}: Props) => {

	const history = useHistory()

	return (
		<Loader.Container className='animate-fadein-full-slow' style={{ animationDuration: '1000ms', animationDelay: delay + 'ms', background: '#88888840' }}>

			<h3 className={`pb-3 flex-center gap-2 opacity-85`}>
				<Icon icon='warning-sign' className='text-warning' height='1.3rem' width='1.3rem' />
				{parse(message)}
			</h3>

			<div className='relative mb-20 flex-center gap-2'>
				{!!link && (
					<Anchor.Contained
						to={link.to}
						className='error_page-link'
						style={{ minWidth: '80px' }}>
						{link.message}
					</Anchor.Contained>
				)}
				{backButton && (
					<Button.Contained
						className='error_page-back'
						onClick={history.goBack}
						style={{ minWidth: '80px' }}>
						Back
					</Button.Contained>
				)}
				{!!retry && (
					<Button.Contained
						style={{ minWidth: '80px' }}
						className='error_page-retry'
						intent='primary'
						onClick={retry}>
						Retry
					</Button.Contained>
				)}

				{isRetrying && (
					<div className='absolute left-1/2 transform -translate-x-1/2 flex-center' style={{ top: 'calc(100% + 20px)' }}>
						<Loader.Circular
							intent='none'
							dark
							width={50}
							strokeWidth={3}
						/>
					</div>
				)}
			</div>

		</Loader.Container>
	)
}

export default ErrorPage;