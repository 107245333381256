import React, { useCallback, useEffect, useRef, useState } from 'react';
import { File, FileTreeNode, Modules } from 'src/data'
import { pure } from 'src/hoc';
import { Loader } from 'src/lib/loader';
import { Text } from 'src/lib/text';
import { ExtractArrayType } from 'src/types';
import { InnerBoard } from 'src/components';
import { cn } from 'src/helpers';
import { Icon } from 'src/lib/icons';
import { ReactChild } from 'react';
import { Resizable } from 're-resizable';


const noop = () => null

const ADDITIONAL_COLS_PROPS = {
	className: 'flex justify-end px-4',
	style: { minHeight: '36px', minWidth: '100px' },
}

interface Props {
	className?: string
	onFileIdChange: (fileId: number) => void
	onLoad?: () => void
	projectId: number
	fileId: number
	legend: string[]
	renderColumns: (file: File) => (JSX.Element | ReactChild | null)[]
	fileTreeNode: FileTreeNode | null | undefined
	isValidating: boolean
	error: Error | undefined
	onFileClick?: (file: File) => void
}

export const FileTree = pure(({
	className = '',
	onFileIdChange,
	onLoad = noop,
	fileId,
	renderColumns,
	legend,
	fileTreeNode,
	isValidating,
	error,
	onFileClick = noop,
}: Props) => {

	const legendContainerRef = useRef<HTMLDivElement | null>(null)
	const [ selectedFile, setSelectedFile ] = useState<File | null>(null)

	useEffect(onLoad, []) //eslint-disable-line

	useEffect(() => {
		setSelectedFile(null)
	}, [ fileTreeNode ])

	useEffect(() => {
		onFileIdChange(fileId)
	}, [ fileId ]) //eslint-disable-line


	const legendCbRef = useCallback(el => {
		const container = legendContainerRef.current
		if (!container || !el) return
		const copy = el.cloneNode(true) as HTMLElement
		copy.style.width = el.clientWidth + 'px'
		copy.classList.add('opacity-100', 'static', 'mb-9')
		container.appendChild(copy)
	}, [])

	const noFilesFound = !fileTreeNode?.nodes.length

	return (
		<div className={className}>
			<div className='relative z-m1'>
				<div ref={legendContainerRef} className='legend-container flex justify-end absolute left-0 right-0 bottom-0'></div>
			</div>

			<Resizable enable={{ bottom: true }} defaultSize={{ width: '100%', height: `${37 * 11 + 2}px` }}>
				<div className='flex h-full'>
					<InnerBoard error={error} isLoading={!fileTreeNode}>
						<table className='w-full'>
							<tbody>
								<tr>
									<td />
									<td className='w-full' />
									{legend.map((entry, i) => (
										<td key={`original-legend-entry-${i}`}>
											<div
												ref={legendCbRef}
												className={`relative legend-entry opacity-0 ${ADDITIONAL_COLS_PROPS.className}`}
												style={{ minWidth: ADDITIONAL_COLS_PROPS.style.minWidth }}>
												<div className='absolute'>{entry}</div>
											</div>
										</td>
									))}
								</tr>
								{!!fileTreeNode && (<>
									<RenderOutline
										fileTreeNode={fileTreeNode}
										isValidating={isValidating}
										onClick={onFileIdChange}
										fileId={fileId}
									/>

									{noFilesFound
										? (
											<tr>
												<td colSpan={40}>
													<div className='flex items-end justify-center h-52'>
														<Text variant='info' className='mb-6'><i>No files found...</i></Text>
													</div>
												</td>
											</tr>
										) : (
											<>
												{fileTreeNode.directories.map((directory, i) => (
													<RenderDirectory
														isLoading={isValidating && `${fileId}` === directory.id + ''}
														file={directory}
														key={directory.name + directory.id + '' + i}
														onClick={() => onFileIdChange(directory.id)}
														renderColumns={renderColumns}
													/>
												))}

												{fileTreeNode.files.map(file => (
													<RenderFile
														file={file}
														onClick={() => { setSelectedFile(file); onFileClick(file) }}
														key={file.name + file.id}
														isSelected={file === selectedFile}
														renderColumns={renderColumns}
													/>
												))}
											</>
										)}
								</>)}
							</tbody>
						</table>
					</InnerBoard>
				</div>
			</Resizable>
		</div>
	)
})

interface OutlineProps {
	fileTreeNode: FileTreeNode
	isValidating: boolean
	onClick: (id: number) => void
	fileId: number
}

const RenderOutline = pure(({
	fileTreeNode,
	isValidating,
	onClick,
	fileId,
}: OutlineProps) => {

	let areDotsRendered = false

	return (
		<tr>
			<td colSpan={40}>
				<div className='flex items-center flex-wrap pl-2 pr-4 py-0.5 w-full' style={{ minHeight: '36px' }}>

					<button onClick={() => onClick(0)} className='root opacity-80 hover:opacity-100 hover:underline flex items-center'>
						<Text className='whitespace-nowrap py-0.5 px-1.5' inline smaller>root</Text>
					</button>

					{isValidating && fileId === 0 && (() => {
						areDotsRendered = true
						return <Loader.Dots marginTop={3} marginRight={4} />
					})()}

					{fileTreeNode.parentDirectories.map(parent => (
						<React.Fragment key={`${fileTreeNode.id}-${fileTreeNode.name}-${Math.random()}`}>
							<Icon icon='chevron-right' className='no-shrink' iconSize={11} strokeWidth={2} style={{ marginTop: '2px' }} />

							<button onClick={() => onClick(parent.id)} className='hover:underline opacity-80 hover:opacity-100 flex items-center'>
								<Text className='whitespace-nowrap py-0.5 px-1.5' inline smaller>{parent.name}</Text>
							</button>

							{isValidating && fileId === parent.id && (() => {
								areDotsRendered = true
								return <Loader.Dots marginTop={3} marginRight={6} />
							})()}
						</React.Fragment>
					))}

					{fileTreeNode.name && (
						<div className='flex items-center'>
							<Icon icon='chevron-right' className='no-shrink' iconSize={11} strokeWidth={2} style={{ marginTop: '2px' }} />
							<Text className='whitespace-nowrap  py-0.5 px-1.5' inline smaller opacity={0.8}>{fileTreeNode.name}</Text>
						</div>
					)}

					{isValidating && !areDotsRendered && (
						<Loader.Dots marginTop={3} />
					)}
				</div>
			</td>
		</tr>
	)
})


interface DirectoryProps {
	file: File
	isLoading?: boolean
	onClick: () => void
	renderColumns: Props[ 'renderColumns' ]
}

const RenderDirectory = pure(({
	file,
	isLoading = false,
	onClick,
	renderColumns,
}: DirectoryProps) => {

	const [ isHover, setIsHover ] = useState(false)

	const handleMouseEnter = () => setIsHover(true)
	const handleMouseLeave = () => setIsHover(false)

	const sharedProps = {
		onClick,
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave,
	}

	return (
		<tr style={{ borderTop: '1px solid rgba(238, 238, 238)' }}>
			<td>
				<div className='relative flex-center pl-3 pr-2' style={{ minHeight: '36px', minWidth: '20px' }} {...sharedProps}>
					{isLoading
						? <Loader.Circular width={16} strokeWidth={6} dark intent='none' />
						: <Icon icon='folder-icon' className='text-primary' />
					}
					{isHover && (
						<div className={cn`
							absolute 
							-left-3 
							h-full 
							w-screen 
							bg-primary-hover 
							text-primary 
							text-opacity-50 
						`} />
					)}
				</div>
			</td>

			<td className='w-full'>
				<div className='flex items-center' style={{ minHeight: '36px' }} {...sharedProps}>
					<Text className='whitespace-normal break-words' title={file.name}>{file.name}</Text>
				</div>
			</td>

			{renderColumns(file).map((jsx, i) => (
				<td key={`renderColumn-${file.id}-${i}`}>
					<div {...ADDITIONAL_COLS_PROPS} {...sharedProps}>
						{jsx}
					</div>
				</td>
			))}
		</tr>
	)
})


// DEPENDENCIES

interface FileProps {
	file: File
	onClick: () => void
	isSelected: boolean
	renderColumns: Props[ 'renderColumns' ]
}

const RenderFile = pure(({
	file,
	onClick = noop,
	isSelected = false,
	renderColumns,
}: FileProps) => {

	const [ isHover, setIsHover ] = useState(false)

	const handleMouseEnter = () => setIsHover(true)
	const handleMouseLeave = () => setIsHover(false)

	const sharedProps = {
		onClick,
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave,
	}

	return (
		<tr style={{ borderTop: '1px solid rgba(238, 238, 238)' }}>
			<td>
				<div className='relative flex-center pl-3 pr-2' style={{ minHeight: '36px', minWidth: '20px' }} {...sharedProps}>
					<Icon icon='file-icon' />

					{(isHover || isSelected) && (
						<div className={cn`
							absolute 
							left-0 
							h-full 
							w-screen 
							bg-primary-hover 
							text-primary 
							text-opacity-50 
							${isSelected && 'outline-current'}
						`} />
					)}
				</div>
			</td>

			<td className='w-full'>
				<div className='flex items-center cursor-pointer' style={{ minHeight: '36px' }} {...sharedProps}>
					<Text truncate title={file.name}>{file.name}</Text>
				</div>
			</td>

			{renderColumns(file).map((jsx, i) => (
				<td key={`renderColumn-${file.id}-${i}`}>
					<div {...ADDITIONAL_COLS_PROPS} {...sharedProps}>
						{jsx}
					</div>
				</td>
			))}
		</tr>
	)
})

const RenderActiveModule = ({
	module
}: { module: ExtractArrayType<Modules[ 'active' ]> }) => (
	<div className='single_project_page-active_module' title={Modules.getName(module)}>
		<Text className='pre' opacity={0.7} smaller inline>
			{Modules.getNickname(module)}
		</Text>
	</div >
)

