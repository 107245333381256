import { array } from 'src/helpers'
import { ApiFile, File } from './File'

interface ApiParentDirectory {
	file_id: number
	file_name: string
}

export interface ApiFileTreeNode {
	file_id: number
	file_name: string
	parent_directories: ApiParentDirectory[]
	nodes: ApiFile[]
}

export class ParentDirectory {
	id: number
	name: string

	constructor(parentDirectory: ApiParentDirectory) {
		this.id = parentDirectory.file_id
		this.name = parentDirectory.file_name
	}
}

interface IFileTreeNode {
	readonly id: number
	readonly name: string
	readonly parentDirectories: ParentDirectory[]
	readonly nodes: File[]
}

export class FileTreeNode implements IFileTreeNode {
	readonly id: number
	readonly name: string
	readonly parentDirectories: ParentDirectory[]
	readonly nodes: File[]

	constructor(data: IFileTreeNode | FileTreeNode) {
		this.id = data.id
		this.name = data.name
		this.parentDirectories = data.parentDirectories
		this.nodes = data.nodes
	}

	static fromApi(apiNode: ApiFileTreeNode): FileTreeNode {
		const node: IFileTreeNode = {
			id: apiNode.file_id,
			name: apiNode.file_name,
			parentDirectories: apiNode.parent_directories.map(p => new ParentDirectory(p)).reverse(),
			nodes: apiNode.nodes.map(file => File.fromApi(file)),
		}
		return new FileTreeNode(node)
	}

	get directories() {
		let directories: File[]
		directories = this.nodes.filter(node => node.isDirectory)
		directories = array.sortBy('ascending', 'name')(directories)
		return directories
	}

	get files() {
		let files: File[]
		files = this.nodes.filter(node => !node.isDirectory)
		files = array.sortBy('ascending', 'name')(files)
		return files
	}

	clone(): FileTreeNode {
		return new FileTreeNode({
			id: this.id,
			name: this.name,
			parentDirectories: this.parentDirectories,
			nodes: this.nodes.map(node => new File(node)),
		})
	}
}