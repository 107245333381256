import { useHistory } from 'react-router-dom'
import { FileTree } from 'src/components'
import { useFileIdFromParams, useProjectIdFromParams } from 'src/hooks/params'
import { CheckboxSelectSingle } from './CheckboxSelectSingle'
import { MODULE } from 'src/data/Modules'
import { File } from 'src/data'
import { postDeselectFile, postSelectFile, useFetchFileTreeNodeWithPrevData } from 'src/API/files'
import { CheckboxSelectAll } from './CheckboxSelectAll'
import { isNullish } from 'src/helpers'



const noop = () => { }

interface Props {
	className?: string
	urlOnFileIdChange: (id: number, projectId: number) => string
	module: MODULE
	onFileClick?: (file: File) => void
}

export const SelectableFileTree = ({
	className = '',
	urlOnFileIdChange,
	module,
	onFileClick = noop,
}: Props) => {

	const history = useHistory()
	const projectId = useProjectIdFromParams()
	const fileId = useFileIdFromParams()

	const { data: fileTreeNode, isValidating, error, mutate, revalidate } = useFetchFileTreeNodeWithPrevData(projectId, fileId, module)


	function handleCheckboxChange(file: File, isChecked: boolean) {
		// At this point, a single request has been made inside of "Checkbox" without revalidating
		// this function is used to modify the main data when the value of a single checkbox is modified
		// - by click
		// - by request failed (fallback to prev state)
		mutate(fileTreeNode => {
			if (!fileTreeNode) return fileTreeNode
			const clone = fileTreeNode.clone()
			const node = clone.nodes.find(node => node.id === file.id)
			if (!node) return fileTreeNode
			if (isChecked)
				node.selectedInModules = Array.from(new Set([ ...node.selectedInModules, module ]))
			else
				node.selectedInModules = node.selectedInModules.filter(mod => mod !== module)
			return clone
		}, false)
	}

	function handleAllCheckboxChange(isChecked: boolean) {
		if (isNullish(projectId) || isNullish(fileId)) return
		isChecked
			? postSelectFile(projectId, fileId, module).finally(revalidate)
			: postDeselectFile(projectId, fileId, module).finally(revalidate)
	}

	if (isNullish(projectId) || isNullish(fileId)) return null

	return (
		<div className={className}>
			<FileTree
				className={`transition-opacity duration-75 ${isValidating ? 'opacity-60 pointer-events-none' : ''}`}
				projectId={projectId}
				onFileIdChange={id => history.push(urlOnFileIdChange(id, projectId))}
				fileId={fileId}
				legend={[ 'Files', 'Select' ]}
				fileTreeNode={fileTreeNode}
				isValidating={isValidating}
				error={error}
				onFileClick={onFileClick}
				renderColumns={file => [
					<div className='flex items-center'>
						{file.childrenCount || null}
					</div>,
					<CheckboxSelectSingle
						file={file}
						onChange={handleCheckboxChange}
						defaultChecked={file.selectedInModules.includes(module)}
						module={module}
					/>
				]}
			/>
			<div className='flex justify-end h-12 px-4 mx-px'>
				<CheckboxSelectAll
					defaultChecked={!!fileTreeNode?.nodes.every(node => node.selectedInModules.includes(module))}
					onChange={handleAllCheckboxChange}
				/>
			</div>
		</div>
	)
}