import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useGlobalMouseMove, useGlobalMouseUp } from 'src/hooks/listeners'
import { useUpdatedRef } from 'src/hooks/refs'
import { useSetState } from 'src/hooks/state'
import { NormalizedBounds, Size } from 'src/types'
import { dom, math } from 'src/helpers'
import { Box } from '.'
import { reorderBounds, useParentRef } from './utils'

const defaultBounds = { minX: -1, maxX: -1, minY: -1, maxY: -1 }
const noop = () => { }

interface Props {
	onSelectEnd?: (bounds: NormalizedBounds) => void
	borderColor?: string
	backgroundColor?: string
	disabled?: boolean
	borderWidth?: number
	minSize?: number
}

export const AreaSelect = ({
	onSelectEnd = noop,
	borderColor = '#6dcae8',
	borderWidth = 2,
	disabled = false,
	minSize = 20
}: Props) => {

	const ref = useRef<HTMLDivElement | null>(null)
	const parentRef = useParentRef(ref)
	const [ bounds, setBounds ] = useSetState<NormalizedBounds>(defaultBounds)
	const [ isMouseDown, setIsMouseDown ] = useState(false)

	useEffect(() => {
		const parent = parentRef.current
		if (!parent) return
		dom.toggleClass('cursor-crosshair', !disabled)(parent)
	}, [ disabled, parentRef ])

	useEffect(() => {
		disabled && setBounds(defaultBounds)
	}, [ disabled, setBounds ])

	useMouseDown(parentRef, e => {
		if (disabled) return
		setIsMouseDown(true)
		const parent = dom.getEventCurrentTarget(e)
		const { x, y, width: stageWidth, height: stageHeight } = parent.getBoundingClientRect()

		const layerX = e.clientX - x
		const layerY = e.clientY - y

		const minX = layerX / stageWidth
		const minY = layerY / stageHeight

		setBounds({ minX, minY, maxX: minX, maxY: minY })
	})

	useGlobalMouseMove(e => {
		if (disabled) return
		const parent = parentRef.current
		if (!parent || !isMouseDown) return

		const { x, y, width: stageWidth, height: stageHeight } = parent.getBoundingClientRect()

		const layerX = e.clientX - x
		const layerY = e.clientY - y

		const maxX = math.clamp(0, layerX / stageWidth, 1)
		const maxY = math.clamp(0, layerY / stageHeight, 1)

		setBounds({ maxX, maxY })
	})

	useGlobalMouseUp(e => {
		setIsMouseDown(false)
		setBounds(defaultBounds)
		if (disabled) return
		const orderedBounds = reorderBounds(bounds)
		const { width, height } = getPixelSize(parentRef, orderedBounds)
		if (width < minSize || height < minSize) return
		onSelectEnd(orderedBounds)
	})

	return (
		<Box
			ref={ref}
			minX={bounds.minX}
			maxX={bounds.maxX}
			minY={bounds.minY}
			maxY={bounds.maxY}
			borderWidth={borderWidth}
			borderColor={borderColor}
			anchorSize={0}
			readonly
		/>
	)
}

const useMouseDown = <T extends HTMLElement>(ref: MutableRefObject<T | null | undefined>, listener: (e: MouseEvent) => void) => {
	const listenerRef = useUpdatedRef(listener)

	useEffect(() => {
		const el = ref.current
		const _listener = (e: MouseEvent) => listenerRef.current(e)
		if (!el) return

		el.addEventListener('mousedown', _listener)
		return () => el.removeEventListener('mousedown', _listener)
	}, [ listenerRef, ref ])
}

const getPixelSize = (parentRef: MutableRefObject<HTMLElement | null>, bounds: NormalizedBounds): Size => {
	const parent = parentRef.current
	if (!parent) return { width: 0, height: 0 }
	const { width: parentWidth, height: parentHeight } = parent.getBoundingClientRect()
	return {
		width: parentWidth * (bounds.maxX - bounds.minX),
		height: parentHeight * (bounds.maxY - bounds.minY),
	}
}