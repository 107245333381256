import { useFetchPageContent } from 'src/API/cms'
import { PAGE_CONTENT_ENTRY_ID } from 'src/data/cms/PageContent'
import { Text } from 'src/lib/text'


interface Props {
	id: PAGE_CONTENT_ENTRY_ID
	className?: string
}

export const PageContent = ({
	id,
	className = ''
}: Props) => {

	const { data } = useFetchPageContent()


	return (
		<Text className={className}>
			<span dangerouslySetInnerHTML={{ __html: data?.getEntry(id)?.content || 'No content found.' }} />
		</Text>
	)
}