import { useEffect, useRef } from 'react'

export const useScrollToPage = (page: string | number | undefined, pages?: number[]) => {
	const currPageRef = useRef(page)
	const prevPageRef = useRef<string | number | undefined>(-1)

	function handleScrollToPage() {
		if (!page || prevPageRef.current === page) return
		prevPageRef.current = page
		const id = `pdf-page-${page}`
		const target = document.getElementById(id)
		const root = document.querySelector('.zoom_and_span_root > div')
		if (!target || !root) return

		target.scrollIntoView({ block: 'start', inline: 'center' })
	}

	useEffect(handleScrollToPage, [ page, pages ])

	useEffect(() => {
		prevPageRef.current = currPageRef.current
		currPageRef.current = page
	}, [ page ])

}