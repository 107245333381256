import { useFetchCurrentUser } from 'src/API/user'
import { LeftNav } from 'src/components/sidebar'
import { User } from 'src/data/User'
import { MainLayout } from 'src/lib/layout'
import { AsyncPage } from 'src/lib/page'
import { View } from './View'


const Setup = () => {

	const { data: user, error, isValidating, revalidate } = useFetchCurrentUser(true)

	return (
		<AsyncPage
			errors={[ error ]}
			retry={revalidate}
			isRetrying={isValidating}
			isLoading={!user}>

			<MainLayout
				content={<View user={user as User} />}
			/>

		</AsyncPage>
	)
}

export default Setup