import { useCurrentUserCache } from 'src/API/user';
import { Menu } from 'src/lib/menu';

interface Props {
	onLogout: () => void
}

const ProfileDropdown = ({
	onLogout,
}: Props) => {


	return (
		<Menu.Root>
			<Menu.Link to='/profile'>
				Profile
			</Menu.Link>

			{/* <Menu.Link to='/setup2fa' disabled>
				{is2FaOn ? 'Remove 2FA' : 'Setup 2FA'}
			</Menu.Link> */}

			<Menu.Link to='/' disabled>
				Manage users
			</Menu.Link>

			<Menu.Link to='/' disabled>
				Help
			</Menu.Link>

			<Menu.Button onClick={onLogout}>
				Logout
			</Menu.Button>
		</Menu.Root>
	)

}

export default ProfileDropdown;