import { useOnlineStatus } from 'src/hooks/listeners';
import { Loader } from 'src/lib/loader'


const ConnectivityStatus = () => {

	const isOnline = useOnlineStatus();

	if (isOnline) return null

	return (
		<div className='fixed bottom-9 right-12 flex z-50 rounded-lg overflow-hidden'>

			<div className="fixed inset-0 bg-black bg-opacity-30"></div>

			<div className="bg-warning flex items-center z-10 p-3">
				<Loader.Circular
					width={30}
					strokeWidth={4}
					intent='none'
				/>
			</div>
			<div className='bg-light1 py-2 px-3 z-10' style={{ width: '340px' }}>
				<h4 className='text-lg'>Reconnecting...</h4>
				<p className='opacity-80 leading-5 pb-1'>You will not be able to save changes until your connection is restored.</p>
			</div>

		</div>
	)
}

export default ConnectivityStatus;