import { Grid } from 'src/lib/grid'
import { Children } from 'src/types'

interface Props {
	children: Children
	className?: string
}

export const Steps = ({
	children,
	className = '',
}: Props) => {



	return (
		<Grid.Root columns={3} growCols={[ 1 ]} className={className}>
			{children}
		</Grid.Root>
	)
}