import { useRef, useState } from 'react';
import { Divisor, PageHead, ProjectIcon } from 'src/components';
import { Project } from 'src/data/Project';
import { Button } from 'src/lib/button';
import { ContentWithCustomScrollbars } from 'src/lib/layout';
import { Heading, Text } from 'src/lib/text';
import { Loader } from 'src/lib/loader';
import { Route, Switch, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import ViewFileTree from './ViewFileTree'
import ViewMembers from './ViewMembers'
import ViewSettings from './ViewSettings'
import { cn } from 'src/helpers';
import { Icon } from 'src/lib/icons';


interface Params {
	projectId: string
}

interface Props {
	project: Project
	isValidatingProject: boolean
}

export const View = ({
	project,
	isValidatingProject
}: Props) => {

	const { projectId } = useParams<Params>()
	const lastNodeIdRef = useRef(0)
	const [ matchingRoute, setMatchingRoute ] = useState<'files' | 'members' | 'settings'>('files')

	function updateLastNodeId(nodeId: string | number) {
		lastNodeIdRef.current = Number(nodeId)
	}

	return (
		<ContentWithCustomScrollbars className='single_project_page'>
			<PageHead
				title={(
					<>
						<ProjectIcon projectName={project.title} />
						<Heading tag='h2' className='whitespace-pre'>{project.title}</Heading>
						{isValidatingProject && <Loader.Dots height={11} className='ml-3' style={{ marginTop: '6px' }} />}
					</>
				)}
				metadata={[ {
					icon: <Icon icon='document' iconSize={14} />,
					count: project.metadata.numberOfImportedFiles,
					text: `file${project.metadata.numberOfImportedFiles > 1 ? 's' : ''}`
				}, {
					icon: <Icon icon='members' />,
					count: project.metadata.numberOfProjectMembers,
					text: project.metadata.numberOfProjectMembers > 1 ? 'members' : 'member'
				}, {
					icon: <Icon icon='layers' iconSize={14} />,
					count: project.modules.active.length,
					text: `module${project.totalActiveModules > 1 ? 's' : ''}`
				}, {
					icon: <Icon icon='inbox-update' iconSize={14} />,
					count: project.projectFileSize.total,
					text: project.projectFileSize.unit
				} ]}

				description={<Text className='col-6'>{project.description}</Text>}
			/>

			<div className='flex items-center'>
				<NavLink
					to={`/projects/${projectId}/files/${lastNodeIdRef.current}`}
					className={cn`
						single_project_page-tab 
						${matchingRoute === 'files' && 'active'}
					`}
					draggable={false}>
					<Icon icon='folder-outlined-icon' /><Text inline> Files</Text>
				</NavLink>

				<NavLink
					to={`/projects/${projectId}/members/`}
					className={cn`
						single_project_page-tab 
						${matchingRoute === 'members' && 'active'}
					`}
					draggable={false}>
					<Icon icon='members' /><Text inline> Members</Text>
				</NavLink>

				<NavLink
					to={`/projects/${projectId}/settings/`}
					className={cn`
						single_project_page-tab 
						${matchingRoute === 'settings' && 'active'}
					`}
					draggable={false}>
					<Icon icon='cog' /><Text inline> Settings</Text>
				</NavLink>
			</div>

			<Divisor className='mt-0 mb-6' />

			<Switch>
				<Route path='/projects/:projectId/files/:nodeId?' >
					<ViewFileTree onLoad={() => setMatchingRoute('files')} onNodeIdChange={updateLastNodeId} />
				</Route>

				<Route path='/projects/:projectId/members'>
					<ViewMembers onLoad={() => setMatchingRoute('members')} />
				</Route>

				<Route path='/projects/:projectId/settings'>
					<ViewSettings onLoad={() => setMatchingRoute('settings')} project={project} />
				</Route>
			</Switch>

		</ContentWithCustomScrollbars>
	)
}
