import { useEffect, useRef, useState } from 'react';
import { File, FileTreeNode, Modules } from 'src/data';
import { useHistory, useParams } from 'react-router-dom';
import { pure } from 'src/hoc';
import { useFetchFileTreeNode } from 'src/API/files';
import { Text } from 'src/lib/text';
import { Button } from 'src/lib/button';
import { FileTree, FilePreview } from 'src/components';
import { ImportFilesDialog } from './ImportFilesDialog';
import { Icon } from 'src/lib/icons';
import { MODULE } from 'src/data/Modules';
import { cn } from 'src/helpers';
import { Show } from 'src/lib/control-flow';

export interface Params {
  projectId: string;
  nodeId?: string;
}

interface Props {
  onNodeIdChange: (nodeId: string | number) => void;
  onLoad: () => void;
}

const ViewFileTree = ({ onNodeIdChange, onLoad }: Props) => {
  const history = useHistory();
  const prevProjectIdRef = useRef<string>();
  const prevFileTreeNodeRef = useRef<FileTreeNode | null>(null);
  const { projectId, nodeId = '0' } = useParams<Params>();
  const [previewFile, setPreviewFile] = useState<File | null>(null);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  const { data: fileTreeNode, isValidating, error, revalidate: revalidateFileTree } = useFetchFileTreeNode(
    projectId,
    nodeId,
    MODULE.NONE,
    {
      //keep displaying old values while fetching new nodeId
      //set to no data if project changes
      initialData: prevProjectIdRef.current === projectId ? prevFileTreeNodeRef.current : null,
      revalidateOnMount: true,
    }
  );

  useEffect(() => {
    if (fileTreeNode) prevFileTreeNodeRef.current = fileTreeNode;
    setPreviewFile(null);
  }, [fileTreeNode]);

  useEffect(() => {
    onNodeIdChange(nodeId);
  }, [nodeId]); //eslint-disable-line

  useEffect(() => {
    prevProjectIdRef.current = projectId;
  }, [projectId]);

  const noImportedFile = !fileTreeNode?.nodes.length;

  return (
    <>
      <ImportFilesDialog
        isOpen={isImportDialogOpen}
        close={() => setIsImportDialogOpen(false)}
        onImportSuccess={() => {}}
      />

      <div className="view_files pb-40">
        <div className="flex items-end justify-between pb-3">
          <Button.Contained
            className={cn`relative ${noImportedFile && 'text-primary'}`}
            rounded
            iconSize={16}
            fontSize={14}
            onClick={() => setIsImportDialogOpen(true)}
          >
            <Icon icon="plus" />
            Import Files
            <Show when={noImportedFile}>
              <div className="absolute right-0 top-0 transform bg-primary h-2.5 w-2.5 rounded-full" />
              <div className="absolute right-0 top-0 transform bg-primary h-2.5 w-2.5 rounded-full animate-ping" />
            </Show>
          </Button.Contained>
        </div>

        <FileTree
          error={error}
          fileId={Number(nodeId)}
          fileTreeNode={fileTreeNode}
          isValidating={isValidating}
          legend={['Modules', 'Files']}
          onFileIdChange={(id) => history.push(`/projects/${projectId}/files/${id}`)}
          projectId={Number(projectId)}
          onLoad={onLoad}
          onFileClick={setPreviewFile}
          renderColumns={(file) => [
            <div className="flex items-center">
              {file.usedInModules.map((module) => (
                <div key={file.id + module}>
                  <div className="single_project_page-active_module" title={Modules.getName(module)}>
                    <Text className="pre" opacity={0.7} smaller inline>
                      {Modules.getNickname(module)}
                    </Text>
                  </div>
                </div>
              ))}
            </div>,
            <Text className="flex items-center" opacity={0.8}>
              {file.childrenCount}
            </Text>,
          ]}
        />

        {previewFile && <FilePreview className="mt-8" file={previewFile} />}
      </div>
    </>
  );
};

export default pure(ViewFileTree);
