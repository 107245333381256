import { default as Overview } from './bom/0-overview'
import { default as Process } from './bom/1-process'
import { default as Find } from './bom/2-find'
import { default as Review } from './bom/3-review'
import { default as Dashboard } from './dashboard'
import { default as Projects } from './projects/Projects'
import { default as NewProject } from './projects/NewProject'
import { default as SingleProject } from './projects/SingleProject'
import { default as Ark } from './ark'
import { default as PdfFileView } from './pdf-file-view'
import { default as Hotspotting } from './hotspotting'
import { default as Login } from './login'
import { default as Profile } from './profile'

export const Page = {
	Ark,
	Bom: { Overview, Process, Find, Review },
	Dashboard,
	Hotspotting,
	Login,
	NewProject,
	PdfFileView,
	Profile,
	Projects,
	SingleProject,
}