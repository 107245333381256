import './index.css';
import React, { useMemo, useRef } from 'react';
import { Children } from 'src/types';
import Scrollbars from 'react-custom-scrollbars';
import { cn } from 'src/helpers';
import { useScale } from './useScale';
import { useSpan } from './useSpan';
import { DisableZoom } from './types';

const noop = () => {};

interface Props {
  children: Children;
  defaultZoom?: [number];
  centerX?: boolean;
  centerY?: boolean;
  disableSpan?: boolean;
  disableZoom?: DisableZoom;
  rootStyle?: React.CSSProperties;
  targetStyle?: React.CSSProperties;
  rootClassName?: string;
  targetClassName?: string;
  onZoom?: (scale: number) => void;
  onSpanStart?: (e: MouseEvent) => void;
  onSpan?: (e: MouseEvent) => void;
  onSpanEnd?: () => void;
  strength?: number;
  rootRef?: React.MutableRefObject<HTMLDivElement | null>;
  onClick?: (e: React.MouseEvent<Scrollbars>) => void;
  onMouseDown?: (e: React.MouseEvent<Scrollbars>) => void;
  // scrollToElementId?: string
}

const ZoomAndSpan = ({
  children,
  defaultZoom: defaultScale = [1],
  strength = 100,
  centerX = false,
  centerY = false,
  disableSpan = false,
  disableZoom = false,
  rootStyle = {},
  targetStyle = {},
  rootClassName = '',
  targetClassName = '',
  onZoom = noop,
  onSpanStart = noop,
  onSpan = noop,
  onSpanEnd = noop,
  rootRef: ref = { current: null },
  onClick = noop,
  onMouseDown = noop,
}: Props) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);

  useScale({ centerX, centerY, defaultScale, disableZoom, onZoom, rootRef, strength, targetRef });
  useSpan({ disableSpan, onSpan, onSpanEnd, onSpanStart, rootRef });

  function updateRefs(sb: Scrollbars | null) {
    if (!sb) return;
    rootRef.current = ref.current = (sb as any).container.firstChild;
  }

  return (
    <Scrollbars
      ref={updateRefs}
      style={rootStyle}
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={cn`
				h-full
				zoom_and_span_root
				select-none
				${rootClassName}
			`}
    >
      <div
        style={targetStyle}
        ref={targetRef}
        className={cn`
					zoom_and_span_target
					${centerY ? 'h-full' : 'min-h-full'}
					${centerX && 'flex items-start justify-center'}
					${centerY && 'flex items-center justify-start'}
					${centerX && centerY && 'flex-center'}
					${targetClassName}
				`}
      >
        {children}
      </div>
    </Scrollbars>
  );
};

export default ZoomAndSpan;
