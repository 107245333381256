import React from 'react';

interface Props extends React.ComponentProps<'div'> {
	large?: boolean
	full?: boolean
}

const Content = ({
	className = '',
	large = false,
	full = false,
	children,
	...props
}: Props) => {

	const style: React.CSSProperties = full
		? {}
		: { maxWidth: large ? '1300px' : '940px', }

	return (
		<div className='main_layout-content h-full w-full' {...props}>
			<div className={`main_layout-content-container ${!full && 'py-7 px-9'} my-0 mx-auto ${className}`} style={style}>
				{children}
			</div>
		</div>
	)
}

export default Content;