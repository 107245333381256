import React from 'react';
import { Children } from 'src/types';
import { cn } from 'src/helpers';

interface Props {
	pl?: 'text' | 'disc-icon'
	pr?: 'text' | 'disc-icon'
	top?: Children
	left?: Children
	center?: Children
	right?: Children
}

function Top({
	pl = 'text',
	pr = 'text',
	top,
	left,
	center,
	right,
}: Props) {

	const paddingLeft = pl === 'text' ? 'pl-5' : 'pl-2'
	const paddingRight = pr === 'text' ? 'pr-5' : 'pr-2'

	return (
		<>
			{top}
			<div className={cn`
				relative
				flex
				items-center
				gap-x-6
				h-12
				${paddingLeft}
				${paddingRight}
				py-0
			`}>
				<div className='flex items-center fill'>
					{left}
				</div>
				<div className='flex items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
					{center}
				</div>
				<div className='flex items-center ml-auto'>
					{right}
				</div>
			</div>
		</>
	)
}

export default Top;