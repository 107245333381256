import { AxiosError, AxiosResponse } from 'axios'
import { toast, ToastContent, ToastOptions } from 'react-toastify'
import { Intent } from 'src/types'


export const notify = (intent: Intent) => (content: ToastContent, options: ToastOptions = {}) => {


	if (intent === 'danger') {
		options.style = {
			background: '#E85642',
			color: 'white',
		}
		options.progressStyle = {
			background: '#ffffff90',
		}
	}
	if (intent === 'success') {
		options.style = {
			background: '#5dbd11',
			color: 'white',
		}
		options.progressStyle = {
			background: '#ffffff90',
		}
	}

	if (intent === 'warning') {
		options.style = {
			background: '#f7a200',
			color: 'white',
		}
		options.progressStyle = {
			background: '#ffffff90',
		}
	}

	if (intent === 'primary') {
		options.style = {
			background: 'var(--clr-primary)',
			color: 'white',
		}
		options.progressStyle = {
			background: '#ffffff90',
		}
	}

	if (intent === 'none') {
		options.style = {
			color: '#777',
		}
		options.progressStyle = {
			background: '#eeeeee',
		}
	}

	return toast(content, options)
}

export function notifyError(error: AxiosError) {
	if (!error) return

	if (error.isAxiosError && error.response) {
		notify('danger')(`${error.response.status} (${error.response.data?.reason || error.response.statusText})`)
	}
	else
		notify('danger')(error.message)
}