import useSWR, { SWRConfiguration } from 'swr'
import axios, { AxiosError } from 'axios'
import { ArkSearchResponse, ApiArkSearchResponse, ArkSearchRequest, ApiPnidByPageRecordId, PnidByPageRecordId } from 'src/data/module.ark'
import { useMemo } from 'react'
import { ApiFileRecord, FileRecord } from 'src/data/module.ark/FileRecord'

const fetchArkSearch = (url: string, stringifiedSearchConfig: string): Promise<ArkSearchResponse> => (
	axios
		.post(url, JSON.parse(stringifiedSearchConfig))
		.then(res => res.data as ApiArkSearchResponse)
		// .then(res => (console.log('ES search response', res), res))
		.then(apiResponse => new ArkSearchResponse(apiResponse))
	// .then(v => (console.log(v), v))
)

export const useFetchArkSearch = (search: ArkSearchRequest | undefined, config?: SWRConfiguration<ArkSearchResponse, AxiosError>) => {

	const apiSearch = useMemo(() => (
		search
			? ArkSearchRequest.toApi(search)
			: undefined
	), [ search ])

	return useSWR(
		apiSearch?.keyword && apiSearch?.project_id
			? [ '/cpf/v1/ark_graph/search/', JSON.stringify(apiSearch) ]
			: null,
		fetchArkSearch,
		config
	)
}

const fetchPnidByPageRecordId = (url: string, projectId: string, pageId: string) => (
	axios
		.get(url, {
			params: {
				project_id: projectId,
				page_record_id: pageId,
			}
		})
		.then((response) => response.data as ApiPnidByPageRecordId[])
		// .then((r) => (console.log('raw pnid record', r), r))
		.then(apiPnids => apiPnids.map(apiPnid => PnidByPageRecordId.fromApi(apiPnid)) as PnidByPageRecordId[])
		.then(pnids => pnids[ 0 ])
)

export const useFetchPnidByPageRecordId = (projectId: number | string, pageId: number | string | undefined, config?: SWRConfiguration<PnidByPageRecordId, Error>) => (
	useSWR(
		pageId
			? [ '/cpf/v1/ark_graph/query/pnid/pagerecord/', projectId + '', pageId + '' ]
			: null,
		fetchPnidByPageRecordId,
		config
	)
)

const fetchFileRecord = (url: string, projectId: string | number, fileRecordId: number) => (
	axios
		.get(url, {
			params: {
				project_id: projectId,
				file_record_id: fileRecordId
			}
		})
		.then(res => res.data as ApiFileRecord)
		// .then((r) => (console.log('raw file record', r), r))
		.then(apiData => FileRecord.fromApi(apiData) as FileRecord)
)

export const useFetchFileRecord = (projectId: number | string, fileRecordId: number | undefined, config?: SWRConfiguration<FileRecord, Error>) => (
	useSWR(
		fileRecordId
			? [ 'cpf/v1/ark_graph/query/filerecord/', projectId + '', fileRecordId ]
			: null,
		fetchFileRecord,
		config
	)
)
