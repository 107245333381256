export const setStyle = (property: keyof CSSStyleDeclaration, value: string | ((prevVal: string) => string)) => <T extends Element | HTMLElement | null | undefined>(element: T) => {
	if (!element) return
	const _element = element as HTMLElement

	_element.style[ property as any ] = typeof value === 'function'
		? value(_element.style[ property as any ])
		: value

	return element
}
