import axios from 'axios';
import { BomTable, BOM_TABLE_STATUS } from 'src/data/module.bom/BomTable';
import { ExcelData } from 'src/lib/excel';
import { NormalizedBounds } from 'src/types';

enum CODE {
	OK = 1,
	NONE_SELECTED = 2,
	BUSY = 3,
	ERROR = 4
}

export function postProcessDocuments(projectId: number): Promise<void> {
	return axios
		.put('/cpf/v1/generate_bom/process_document/', null, {
			params: {
				project_id: projectId
			}
		})
		.then(res => res.data.code)
		.then(code => {
			if (code === CODE.OK)
				return
			if (code === CODE.NONE_SELECTED)
				throw new Error('No file is currently selected.')
			if (code === CODE.BUSY)
				throw new Error('Could not Process the Documents, the server is currently busy.')
			if (code === CODE.ERROR)
				throw new Error('An unknown error has occurred, please try again later.')
		})
}

export function postIsFindTableComplete(fileId: string | number, isComplete: boolean) {
	return axios
		.patch('cpf/v1/file/attribute/', {
			isFindTablesComplete: isComplete
		}, {
			params: {
				file_id: fileId
			}
		})
}

export const postUpdateTable = (table: BomTable, step: keyof BomTable[ 'status' ]) => (
	axios
		.patch('cpf/v1/generate_bom/table/box/', {
			id: table.id,
			minX: table.minX,
			minY: table.minY,
			maxX: table.maxX,
			maxY: table.maxY,
			status: table.status[ step ]
		})
		.then(res => res.data)
)

export const postDeleteTable = (table: BomTable) => (
	axios
		.delete('cpf/v1/generate_bom/table/box', { params: { id: table.id } })
		.then(res => res.data)
)

export const postNewTable = (bounds: NormalizedBounds, page: number, fileId: number) => (
	axios
		.post('cpf/v1/generate_bom/table/box/', {
			document_id: fileId,
			page,
			...bounds
		})
		.then(res => res.data as { id: number })
)


export const postExcelData = (excelData: ExcelData) => (
	axios
		.post("cpf/v1/generate_bom/table/review/data/", excelData)
		.then(res => res.data)
	//  .catch(() => notify('danger')('Could not save the table.', { autoClose: 3000 }))
)

export const postTableReviewStatus = (tableId: string | number, status: BOM_TABLE_STATUS) => (
	axios
		.patch("cpf/v1/generate_bom/table/review/data/", null, {
			params: {
				id: tableId,
				review_status: status
			}
		})
		.then(res => res.data)
)

