import { useEffect, useRef, useState } from 'react';
import { Popover, PopoverProps, ArrowContainer } from 'react-tiny-popover'
import { useTimeout } from 'src/hooks/timers';
import { Children } from 'src/types';
import { getContainerParent } from './utils';

const defaultPositions = [ 'bottom', 'top', 'right', 'left' ] as ("bottom" | "top" | "right" | "left")[]

export interface BaseTooltipProps extends Omit<PopoverProps, 'isOpen' | 'content'> {
	content: Children
	disabled?: boolean
	className?: string
	delay?: number
	containerParentSelector?: string
	renderDivContainer?: boolean
	variant?: 'light' | 'dark'
}

export const BaseTooltip = ({
	children,
	content,
	padding = 5,
	disabled = false,
	className = '',
	delay = 0,
	containerParent,
	containerParentSelector,
	renderDivContainer = true,
	variant = 'dark',
	positions = defaultPositions,
	containerClassName = '',
	...props
}: BaseTooltipProps) => {

	const { childRef, isHover } = useIsHover()

	const isTimeoutElapsed = useTimeout(delay, [ isHover ])

	const background = variant === 'dark' ? '#71787b' : '#fcfcfc'
	const textColor = variant === 'dark' ? 'text-white' : 'text-default'

	if (!content) return <>{children}</>

	return (
		<Popover
			boundaryInset={8}
			containerParent={getContainerParent(containerParent, containerParentSelector)}
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={background}
					arrowSize={4}
					arrowStyle={{ transform: 'scale(2)' }}>
					<div
						className={`px-2 py-0.5 text-sm rounded-sm ${textColor} ${className}`}
						style={{ boxShadow: '0 3px 10px 0 #bbb', background }}>
						{content}
					</div>
				</ArrowContainer>
			)}
			isOpen={!disabled && isTimeoutElapsed && isHover}
			ref={childRef}
			positions={positions}
			padding={padding}
			{...props}>

			{renderDivContainer
				? <div className={`inline-flex ${containerClassName}`}> {children}</div>
				: children
			}

		</Popover>
	)
}


const useIsHover = () => {
	const childRef = useRef<any>(null)
	const [ isHover, setIsHover ] = useState(false)

	useEffect(() => {
		const child = childRef.current
		if (!child) return

		function setIsHoverTrue() {
			setIsHover(true)
		}

		function setIsHoverFalse() {
			setIsHover(false)
		}

		child.addEventListener('mouseenter', setIsHoverTrue)
		child.addEventListener('mouseleave', setIsHoverFalse)

		return () => {
			child.removeEventListener('mouseenter', setIsHoverTrue)
			child.removeEventListener('mouseleave', setIsHoverFalse)
		}
	}, [])

	return { childRef, isHover }
}