import { MainLayout } from 'src/lib/layout'
import { LeftNav } from 'src/components/sidebar';
import { useFetchActiveProject } from 'src/API/projects'
import { ErrorPage } from 'src/components';
import { View } from './View';
import { AsyncPage } from 'src/lib/page';
import { MODULE } from 'src/data/Modules';
import { useFetchSelectedFiles } from 'src/API/files';





const Setup = () => {

	const { data: project, error, isValidating, revalidate } = useFetchActiveProject()
	const { data: selectedFiles } = useFetchSelectedFiles(project?.id, MODULE.BOM_GENERATION)

	if (!project) return null

	return (
		<AsyncPage
			errors={[ error ]}
			retry={revalidate}
			isRetrying={isValidating}
			isLoading={!project}>

			<MainLayout
				left={<LeftNav />}
				content={project.isEnabled.bom.module
					? project.metadata.bom.importedOn
						? <View project={project} selectedFiles={selectedFiles || []} />
						: <ErrorPage.NoImportedFile />
					: <ErrorPage.ModuleNotActive />
				}
			/>
		</AsyncPage>
	)
}

export default Setup;