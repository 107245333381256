import styles from './Step.module.css'
import { Fragment, useMemo } from 'react'
import { Anchor } from 'src/lib/button'
import { Grid } from 'src/lib/grid'
import { Heading, Text } from 'src/lib/text'
import { v4 } from 'uuid'


interface Props {
	title: string
	description: string[]
	stats: { name: string, value: string | number }[]
	link: {
		to: string
		disabled: boolean
	}
}

export const Step = ({
	title,
	description,
	stats,
	link,
}: Props) => {

	const prefix = useMemo(() => v4(), [])

	return (
		<>
			<div className={styles.left}>
				<Heading tag='h4'>{title}</Heading>
				<Text opacity={0.9}>
					{description.map((text, i) => (
						<Fragment key={`${prefix}-${i}`}>{text} <br /></Fragment>)
					)}
				</Text>
			</div>

			<div className={styles.stats}>
				<Grid.Root columns={2} growCols={[ 2 ]} className={styles.grid}>
					{stats.map((stat, i) => (
						<Fragment key={`${prefix}-${stat.name}-${i}`}>
							<Grid.Col className={styles.value}>{stat.value}</Grid.Col>
							<Grid.Col className={styles.name}>{stat.name}</Grid.Col>
						</Fragment>
					))}
				</Grid.Root>
			</div>

			<div className={styles.link}>
				<Anchor.Contained
					intent='primary'
					className='px-4'
					to={link.to}
					disabled={link.disabled}>
					Start
				</Anchor.Contained>
			</div>
		</>
	)
}