import { CSSProperties, MouseEvent } from 'react';
import ReactDOM from 'react-dom'
import { CONFIG } from 'src/cp.config';
import { Children } from 'src/types';
import { cn } from 'src/helpers';


const noop = () => { }


const paddingLeft = '30px'
const paddingRight = '30px'
const paddingTop = '12px'
const paddingBottom = '14px'


interface DialogProps {
	className?: string
	content: Children
	footer?: Children
	header?: Children
	minHeight?: string | number
	minWidth?: string | number
	style?: CSSProperties
}

const Dialog = ({
	className = '',
	content,
	footer,
	header,
	minHeight = 'unset',
	minWidth = 'unset',
	style = {},
}: DialogProps) => {

	style = {
		minHeight: typeof minHeight === 'number' ? minHeight + 'px' : minHeight,
		minWidth: typeof minWidth === 'number' ? minWidth + 'px' : minWidth,
		maxWidth: CONFIG.DIALOG.MAX_WIDTH,
		maxHeight: CONFIG.DIALOG.MAX_HEIGHT,
		boxShadow: '0 15px 150px 0 #00000030, 0 4px 15px 0 #00000030',
		paddingTop,
		paddingBottom,
		...style
	}



	return (
		<div className={`bg-light3 rounded flex flex-col ${className}`} style={style}>
			{header && (
				<header className='text-lg font-bolder opacity-60 text-center leading-8' style={{ paddingLeft, paddingRight }}>
					{header}
				</header>
			)}

			<main className='flex-auto my-6 relative' style={{ paddingLeft, paddingRight, overflow: 'auto' }}>
				{content}
				{/* bottom shadow */}
				<div className='sticky bottom-0 left-0 right-0 h-4 bg-gradient-to-b from-transparent to-light3' />
			</main>

			{footer && (
				<footer style={{ paddingLeft: `calc(${paddingLeft} - 8px)`, paddingRight: `calc(${paddingRight} - 8px)` }}>
					{footer}
				</footer>
			)}
		</div>
	)
}


interface BackdropProps {
	backdropClassName?: string
	children: Children
	onClickOutside: () => void
}

const DialogBackdrop = ({
	backdropClassName = '',
	children,
	onClickOutside,
}: BackdropProps) => {

	function handleClick(e: MouseEvent<HTMLDivElement>) {
		if (e.target === e.currentTarget)
			onClickOutside()
	}

	return (
		<div
			onClick={handleClick}
			className={cn`
				custom-dialog
				bg-black
				bg-opacity-30
				fixed
				inset-0
				z-50
				flex-center
				${backdropClassName}
			`} >
			{children}
		</div>
	)
}


interface DialogPortalProps extends DialogProps, Omit<BackdropProps, 'children'> {
	isOpen: boolean
}

const DialogPortal = ({
	backdropClassName = '',
	isOpen,
	onClickOutside = noop,
	...dialogProps
}: DialogPortalProps) => {

	if (!isOpen) return null

	return ReactDOM.createPortal((
		<DialogBackdrop onClickOutside={onClickOutside} backdropClassName={backdropClassName}>
			<Dialog {...dialogProps} />
		</DialogBackdrop>
	), document.getElementById('root')!)
}

export default DialogPortal;
