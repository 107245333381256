import deepEqual from 'react-fast-compare'
import HotTable from '@handsontable/react'
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useState } from 'react'
import { ExcelData } from '.'
import { selection } from './utils'

export const useMergedCells = (setState: Dispatch<SetStateAction<ExcelData>>, ref: MutableRefObject<HotTable | null>) => {

	const mergeSelectedCells = useCallback(() => {
		const newEntry = selection.getObject(ref)
		if (!newEntry) return

		setState(state => state.clone().setMergedCells([
			...state.mergedCells.filter(mc => (
				mc.row < newEntry.row ||
				mc.col < newEntry.col ||
				mc.row + mc.rowspan > newEntry.row + newEntry.rowspan ||
				mc.col + mc.colspan > newEntry.col + newEntry.colspan
			)),
			newEntry
		]))
	}, [ ref, setState ])

	const unmergeSelectedCells = useCallback(() => {
		const cellToUnmerge = selection.getObject(ref)
		if (!cellToUnmerge) return
		setState(state => state.clone().setMergedCells(
			state.mergedCells.filter(c => !deepEqual(c, cellToUnmerge))
		))
	}, [ ref, setState ])

	return { mergeSelectedCells, unmergeSelectedCells } as const
}