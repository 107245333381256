import React from 'react';
import { Children } from 'src/types';

interface Props {
	className?: string
	style?: React.CSSProperties
	children?: Children
}

const Menu = ({
	className = '',
	style = {},
	children = null,
}: Props) => {

	style = {
		minWidth: '160px',
		...style
	}

	return (
		<ul className={`flex flex-col ${className}`} style={style}>
			{children}
		</ul>
	)
}

export default Menu;