export const toggleClass = (className: string, activate: boolean) => <T extends Element | HTMLElement | null | undefined>(element: T) => {
	if (!element) return

	const classNames = className.split(' ').filter(x => x)

	for (let cn of classNames) {
		if (activate) {
			if (!element.classList.contains(cn))
				element.classList.add(cn)
		}
		else {
			if (element.classList.contains(cn))
				element.classList.remove(cn)
		}
	}

	return element
}
