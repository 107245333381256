import { array } from 'src/helpers'

interface Config {
	totalHits: number,
	maxPageSize: number,
	currentPageIndex: number,
	maxLength?: number
}

export function getPageMap({
	totalHits,
	maxPageSize,
	currentPageIndex,
	maxLength = 5
}: Config): number[] {
	const totalPagesCount = Math.ceil(totalHits / maxPageSize)
	const mapOfAllPages = array.range(1, totalPagesCount)
	const maxPagesUnderCurrentPage = Math.floor(maxLength / 2)
	const startAtPageIndex = Math.max(0, currentPageIndex - maxPagesUnderCurrentPage)
	const endAtPageIndex = startAtPageIndex + maxLength
	return mapOfAllPages.slice(startAtPageIndex, endAtPageIndex)
}