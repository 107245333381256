import { cn } from 'src/helpers';
import { Variant } from './types';

interface Config {
	variant: Variant,
	inline: boolean,
	className: string,
	smaller: boolean,
	stronger: boolean,
	truncate: boolean
}

export function getClassName({
	variant,
	inline,
	className,
	smaller,
	stronger,
	truncate,
}: Config) {
	let _variant: string;

	switch (variant) {
		case 'dark':
			_variant = 'text-dark1'
			break;

		case 'error':
			_variant = 'text-error'
			break;

		case 'info':
			_variant = 'text-info'
			break;

		case 'light':
			_variant = 'text-light1'
			break;

		case 'success':
			_variant = 'text-success'
			break;

		case 'primary':
			_variant = 'text-primary'
			break;

		case 'primary-darker':
			_variant = 'text-primary-darker'
			break;

		case 'primary-xdarker':
			_variant = 'text-primary-xdarker'
			break;

		case 'primary-lighter':
			_variant = 'text-primary-lighter'
			break;
	}

	return cn`
		custom_text_element
		overflow-ellipsis
		overflow-hidden
		${_variant} 
		${inline && 'inline-flex'} 
		${smaller && 'text-sm'} 
		${stronger && 'font-bolder'} 
		${truncate && 'truncate'}
		${className}
	`
}