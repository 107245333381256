import { useActiveProjectCache } from 'src/API/projects';
import { ErrorPage } from 'src/lib/page';

export const NoImportedFile = () => {

	const { data: activeProject } = useActiveProjectCache()

	return (
		<ErrorPage
			message={'No file was found. Please import the files for this module.'}
			link={{
				to: `/projects/${activeProject?.id}/files`,
				message: 'Go to Import files'
			}}
		/>
	)
}
