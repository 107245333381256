import './MainLayout.css'
import React from 'react';

interface Props {
	left?: React.ReactChild | React.ReactChildren | React.ReactNode | null
	right?: React.ReactChild | React.ReactChildren | React.ReactNode | null
	top?: React.ReactChild | React.ReactChildren | React.ReactNode | null
	bottom?: React.ReactChild | React.ReactChildren | React.ReactNode | null
	content: React.ReactChild | React.ReactChildren | React.ReactNode | null
	className?: string
}

const Main = ({
	left,
	right,
	top,
	bottom,
	content,
	className = ''
}: Props) => {



	return (
		<div className={`main_layout_container ${className}`}>
			{!!left && (
				<div className='main_layout_container-left'>
					{left}
				</div>
			)}

			<div className='main_layout_container-center'>
				{!!top && (
					<div className='main_layout_container-top'>
						{top}
					</div>
				)}
				<div className={`main_layout_container-content ${left ? '' : 'no_left'} ${right ? '' : 'no_right'}`}>
					{content}
				</div>
				{!!bottom && (
					<div className='main_layout_container-bottom'>
						{bottom}
					</div>
				)}
			</div>
			{!!right && (
				<div className='main_layout_container-right'>
					{right}
				</div>
			)}
		</div>
	)
}

export default Main;