import { pure } from 'src/hoc'
import { Children } from 'src/types'


interface Props {
	when: boolean
	fallback?: () => JSX.Element
	children: Children
}

export const Show = pure(({
	when,
	fallback,
	children
}: Props) => {


	if (!when)
		return <>{fallback?.() || null}</>

	return <>{children}</>
})