import React from 'react';
import { Text } from 'src/lib/text';

export interface MetadataSnippet {
	icon: JSX.Element
	count?: number
	text: string
}

interface Props extends React.ComponentProps<'div'> {
	data: MetadataSnippet[]
}

const PageMetadata = ({
	data,
	className = '',
	...props
}: Props) => {



	return (
		<div className={`flex items-center ${className}`} {...props}>
			{data.map(({ icon, count, text }) => (
				<div className='inline-flex items-center mr-6 my-2' key={`metadata-${count}-${text}`}>
					{icon}
					<Text className='whitespace-pre' smaller>
						{count !== undefined && <b>  {count}</b>} {text}
					</Text>
				</div>
			))
			}
		</div>)
}

export default PageMetadata;