
import { DocumentTypeAbbreviation, DocType, docTypesDictionary } from './documentTypes'

type ApiFilter =
	| { documentType: DocumentTypeAbbreviation[] }
	| { latest: (0 | 1 | null)[] }

export type ApiArkSearchFilters = ApiFilter[]

export interface ApiArkSearchRequest {
	keyword: string,
	project_id: number
	pagination: number,
	batch_size: number,
	fragment_size: number,
	filters: ApiArkSearchFilters,
	excludes: ApiArkSearchFilters
}

export class ArkSearchRequest {
	keyword: string
	pid: number
	pageIndex: number
	pageSize: number
	docType: DocType
	latest: boolean
	fragmentSize: number

	constructor(data: ArkSearchRequest) {
		this.keyword = data.keyword
		this.pid = data.pid
		this.pageIndex = data.pageIndex
		this.pageSize = data.pageSize
		this.docType = data.docType
		this.latest = data.latest
		this.fragmentSize = data.fragmentSize
	}

	static toApi(data: ArkSearchRequest): ApiArkSearchRequest {
		const apiData: ApiArkSearchRequest = {
			keyword: data.keyword,
			project_id: data.pid,
			pagination: data.pageIndex,
			batch_size: data.pageSize,
			fragment_size: data.fragmentSize,
			filters: [ { documentType: docTypesDictionary[ data.docType ] } ],
			excludes: [ { latest: [ data.latest ? 0 : null ] } ],
		}
		return apiData
	}
}
