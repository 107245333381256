import React from 'react';
import { cn } from 'src/helpers';
import { Type } from './types';
import { Field } from 'formik';

// PostCSS Whitelist
// DO NOT REMOVE THIS COMMENT
// 
// 'custom_input-checkbox'
// 'custom_input-email'
// 'custom_input-number'
// 'custom_input-password'
// 'custom_input-radio'
// 'custom_input-search'
// 'custom_input-text'
// 'ch-2'
// 'ch-3'
// 'ch-4'
// 'ch-5'

interface Props extends Omit<React.ComponentProps<'input'>, 'type'> {
	type: Exclude<Type, 'search'>
	maxDigits?: 1 | 2 | 3 | 4 | 5
	rounded?: boolean
	name: string
}

const Input = ({
	type,
	className = '',
	rounded = false,
	maxDigits,
	...props
}: Props) => {


	return (
		<Field
			type={type}
			className={cn`
				custom_input-${type} 
				ch-${maxDigits}
				${rounded && 'round'}
				${className}
			`}
			{...props}
		/>
	)
}

export default Input;